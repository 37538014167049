import { LOADING, STATUS_CODE_SUCCESS } from "../../../constants/constant";
import {
  GET_LAUNCH_SUMMARY,
  GET_LAUNCH_SUMMARY_RESET,
  GET_LAUNCH_SUMMARY_SUCCESS,
  GET_LAUNCH_SUMMARY_FAILED,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function getLaunchSummaryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LAUNCH_SUMMARY:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_LAUNCH_SUMMARY_SUCCESS:
      let userRes = Object.assign({}, state);

      userRes.status = action?.data?.status_message;
      userRes.isLoading = false;
      userRes.statusCode = action?.data?.status_code;

      if (userRes.statusCode !== STATUS_CODE_SUCCESS) {
        userRes.error = true;
        userRes.errorMessage = action?.data?.data.message;
        return userRes;
      }
      userRes.error = false;
      userRes.summaryList = action?.data?.data;
      return userRes;

    case GET_LAUNCH_SUMMARY_FAILED:
      return Object.assign({}, state, action.error, {
        isLoading: false
      });
    case GET_LAUNCH_SUMMARY_RESET:
      return initialState;

    default:
      return state;
  }
}
