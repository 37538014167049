import { LOADING } from "../../../constants/constant";
import {
  UPDATE_RCS_BOT_ONB_REQUESTED,
  UPDATE_RCS_BOT_ONB_FAILED,
  UPDATE_RCS_BOT_ONB_SUCCESS,
  UPDATE_RCS_BOT_ONB_RESET
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function updateRCSBotOnboardingReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_RCS_BOT_ONB_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case UPDATE_RCS_BOT_ONB_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.status = action.data.status_message;
      userRes.statusCode = action.data.status_code;
      userRes.isLoading = false;
      userRes.data = action.data.data;
      userRes.statusMessage = action.data.message;
    //   console.log("response in Update bot reducer :",userRes);
      return userRes;

    case UPDATE_RCS_BOT_ONB_FAILED:     
      let errorRes = Object.assign({}, state, action.data);
      errorRes.error=action.data
      errorRes.isLoading = false;
      console.error("Error while updating bot", action.data);
      return errorRes;

      case UPDATE_RCS_BOT_ONB_RESET: {
        return initialState;
      }
    default:
      return state;
  }
}
