import { LOADING } from "../../../../constants/constant";
import {
  ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION,
  ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_FAILED,
  ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_RESET,
  ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_SUCCESS,
  ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION,
  ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_FAILED,
  ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_RESET,
  ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_SUCCESS,
} from "../../../actions/ActionType";
import { GET_SUSPENDED_BOTS, GET_SUSPENDED_BOTS_FAILED, GET_SUSPENDED_BOTS_SUCCESS } from "../../../actions/AdminActions";

const initialState = {
  status: "INIT",
  isLoading: null,
};
const iState = {
  verification_submitted: undefined,
  verification_updated: undefined,
  verification_pending: undefined,
  verification_approved: undefined,
  verification_rejected: undefined,
  created: undefined,
};

export function suspendedBotsReducer(state = iState, action) {
  switch (action.type) {
    case GET_SUSPENDED_BOTS: {
      return { ...state, loading: true };
    }

    case GET_SUSPENDED_BOTS_SUCCESS: {
      return { ...state, ...action.data, isLoading: false };
    }
    case GET_SUSPENDED_BOTS_FAILED: {
      return { ...state, error: action.error, isLoading: false, isError: true };
    }

    case ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
