import { LOADING } from "../../constants/constant";
import {
  CARRIER_LIST_FAILED,
  CARRIER_LIST_REQUESTED,
  CARRIER_LIST_SUCCESS,
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function carrierListReducer(state = initialState, action) {
  switch (action.type) {
    case CARRIER_LIST_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case CARRIER_LIST_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log("came to the get bot list  success", action);
      userRes.status = action.data.status_message;
      userRes.statusCode = action.data.status_code;
      userRes.isLoading = false;
      //   userRes.statusCode = action.data.status;
      userRes.carrierList = action.data.data;

      return userRes;

    case CARRIER_LIST_FAILED:
      return Object.assign({}, state, action.error);

    default:
      return state;
  }
}
