const FormError = ({ errorMessage, absolute = false, ...rest }) => {
  return (
    <p
      className={`text-red font-color-red ${absolute ? "position-abs" : ""} ${
        rest.className ? rest.className : ""
      }`}
    >
      {/* &#9888;&nbsp; */}
      {errorMessage}
    </p>
  );
};
export default FormError;
