import { put, takeLatest, call } from "redux-saga/effects";
import {
    FORWARD_BRAND_FOR_ADMIN_SUCCESS,
    FORWARD_BRAND_FOR_ADMIN,
    FORWARD_BRAND_FOR_ADMIN_FAILED
} from "../../../actions/ActionType";
import { API_ENDPOINT } from "../../../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../../../appApi/AppApi";
import { createActionOf } from "../../../actions/ActionCreator";

const TAG = "forwardModel.js";

function* workerForwardBrandSaga(payLoad) {
  // console.log("payload sub-------------------->",payLoad.data.data);
  try {
    const response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.FORWARD_BRAND_FOR_ADMIN,
      payLoad.data
    );
    yield put(createActionOf(FORWARD_BRAND_FOR_ADMIN_SUCCESS, 1, response));
  } catch (error) {
    console.error(TAG, "Worker forward bots failed: " + error.message);
    yield put(createActionOf(FORWARD_BRAND_FOR_ADMIN_FAILED, 2, error));
  }
}

export const watchForwardBrand = takeLatest(
    FORWARD_BRAND_FOR_ADMIN,
    workerForwardBrandSaga
);
