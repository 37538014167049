import { LOADING } from "../../../../constants/constant";
import {
  ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION,
  ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_FAILED,
  ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_RESET,
  ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_SUCCESS,
  ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION,
  ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_FAILED,
  ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_RESET,
  ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_SUCCESS,
} from "../../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: null,
};
const iState = {
  verification_submitted: undefined,
  verification_updated: undefined,
  verification_pending: undefined,
  verification_approved: undefined,
  verification_rejected: undefined,
  created: undefined,
};

export function adminSubmittedForVerification(state = iState, action) {
  switch (action.type) {
    case ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION: {
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;
    }

    case ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_SUCCESS: {
      return { ...state, ...action.data, isLoading: false };
    }
    case ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_FAILED: {
      return Object.assign({}, state, action.error);
    }

    case ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}

export function adminApproveRejectForVerification(state = initialState, action) {
  switch (action.type) {
    case ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION: {
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;
    }

    case ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_SUCCESS: {
      let userRes = Object.assign({}, state);
      userRes.status = action.data.statusMessage;

      userRes.statusCode = action.data.status_code;
      userRes.data = action.data;
      userRes.isLoading = false;
      return userRes;
    }
    case ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_FAILED: {
      return Object.assign({}, state, action.error);
    }

    case ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
