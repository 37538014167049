import React from "react";
import { NavLink } from "react-router-dom";

const ComingSoon = () => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="coming-soon">
              <h1>Coming Soon</h1>
              <p className="text-18">This feature is under development</p>
              <div className="text-center">
                <NavLink to="/" className="btn btn-red-round text-no-underline">
                  Go To dashboard
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ComingSoon;
