import React, { useContext } from "react";
// import { NavLink } from "react-router-dom";
import { AuthContext } from "../../Providers/ContextProviders/AuthContext";

const Footer = (props) => {
const { isLoggedIn } = useContext(AuthContext);
  return (
    <React.Fragment>
      <section className="footer-section">
        <div className="container">
          <div className="row">
            <div
              className="col-md-12"
              style={{ fontSize: "13px", paddingBottom: "13px" }}
            >
              <p>
                Copyright ©  VI 2023-2024. All rights reserved. &nbsp; &nbsp;
                | &nbsp; &nbsp;{" "}
                <a href="https://developer.virbm.in/developer-privacypolicy" className="" target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>&nbsp; &nbsp; | &nbsp; &nbsp;{" "}
                <a href="https://developer.virbm.in/developer-tos" className="" target="_blank" rel="noreferrer">
                  Terms of Service
                </a>&nbsp; &nbsp; | &nbsp; &nbsp;{" "}
                <a href="https://developer.virbm.in/rbm-policies" className="" target="_blank" rel="noreferrer">
                  RBM Policies
                </a>
                {isLoggedIn ? (
                  <>
                    &nbsp; &nbsp; | &nbsp; &nbsp;
                    <a href="/escalation-matrix" className="" target="_blank">
                      Escalation Matrix
                    </a>
                  </>
                ) : (
                  <></>
                )}
                {/* &nbsp; &nbsp; | &nbsp; &nbsp;{" "}
                <NavLink className="" target="_blank" to="/developer-privacypolicy">
                  Privacy Policy
                </NavLink>
                &nbsp; &nbsp; | &nbsp; &nbsp;{" "}
                <NavLink className="" target="_blank" to="/developer-tos">
                  Terms of Service
                </NavLink> */}
                {/* &nbsp; &nbsp; | &nbsp; &nbsp;{" "}
                <NavLink className="" target="_blank" to="/rbm-terms">
                  RBM Terms
                </NavLink> */}
                {/* &nbsp; &nbsp; | &nbsp; &nbsp;{" "}
                <NavLink className="" target="_blank" to="/rbm-policies">
                  RBM Policies
                </NavLink> */}
                <span style={{float: "right"}}>
                  Powered By &nbsp;
                  <a href="https://www.dotgo.com" style={{textDecoration : "underline"}} target="_blank" rel="noreferrer">
                    Dotgo
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Footer;
