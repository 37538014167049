import { useEffect, useState } from "react";
import styles from "./escalationMatrix.module.css";
import { useFetchAsync } from "../../../store/hooks/useFetchAsync";
import { API_ENDPOINT } from "../../../store/URLs/ApiEndpoints";

const EscalationMatrix = () => {
  const { esTable, esTableRow, esTableContainer } = styles;
  const { startFetch, responseData, isFetching } = useFetchAsync();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const cfg = {
      url: API_ENDPOINT.GET_ESCALATION_MATRIX,
      method: "GET",
    };
    startFetch(cfg, null);
  }, []);

  useEffect(() => {
    try {
      const parsedData = JSON.parse(responseData?.data?.table_json);
      setTableData(parsedData);
    } catch {
      console.error("Invalid JSON");
      setTableData({});
    }
  }, [responseData?.data?.table_json]);

  return (
    <>
      <div className="container body-margin-top">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="font-size-24 font-weight-600">Escalation Matrix</h2>
            <div className={`${esTableContainer}`}>
              {isFetching ? (
                <h2 className="font-size-16 text-center">Loading...</h2>
              ) : tableData?.headers?.length > 0 && tableData?.rows?.length > 0 ? (
                <table class={`table table-bordered ${esTable}`}>
                  <thead>
                    <tr className={esTableRow}>
                      {tableData?.headers?.map(({ label, key }) => {
                        return <th>{label}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.rows?.map((row) => {
                      return (
                        <tr className={esTableRow}>
                          {tableData?.headers?.map(({ key }, index) => {
                            return <td key={`esm-td-${index}`}>{row[key]}</td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <h2 className="font-size-16 text-center">Data not Present</h2>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EscalationMatrix;
