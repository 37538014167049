import { LOADING, STATUS_CODE_SUCCESS } from "../../../constants/constant";
import {
  SUBMIT_VERIFIED_CARRIERS,
  SUBMIT_VERIFIED_CARRIERS_SUCCESS,
  SUBMIT_VERIFIED_CARRIERS_FAILED,
  SUBMIT_VERIFIED_CARRIERS_RESET,
} from "../../actions/ActionType";

const initialState = {
  status: "",
  isLoading: false,
};

export default function submitVerifiedCarriers(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_VERIFIED_CARRIERS:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case SUBMIT_VERIFIED_CARRIERS_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log("came to the submitVerifiedCarriers success", action);
      userRes.status = action.data.status_message;
      userRes.isLoading = false;
      userRes.statusCode = action.data.status_code;

      if (userRes.statusCode !== STATUS_CODE_SUCCESS) {
        userRes.error = true;
        userRes.errorMessage = action.data.status_message;
        return userRes;
      }
      userRes.entity = action.data.status_message;
      userRes.data = action.data.data;
      userRes.error = false;

      return userRes;

    case SUBMIT_VERIFIED_CARRIERS_FAILED:
      return Object.assign({}, state, action.error);
    case SUBMIT_VERIFIED_CARRIERS_RESET:
      return initialState;

    default:
      return state;
  }
}
