import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

const TAG = "templateHistory.js";

function* workerGetTestSummary(payload) {
  try {
    if (!payload.data) {
      throw new Error(
        "bot id is undefined: Payload=>" + JSON.stringify(payload)
      );
    }
    const response = yield call(
      Api.makeSecuredGetRequest,
      API_ENDPOINT.TEST_SUMMARY + encodeURIComponent(payload.data)
    );
    yield put(memberAction.createActionOf(memberType.TEST_SUMMARY_SUCCESS,1,response));
  } catch (error) {
    console.error(TAG, "Error: while getting test template summary: ", error.message);   
     yield put(memberAction.createActionOf(memberType.TEST_SUMMARY_FAILED,2,error));

  }
}

export const watchGetTestSummarySaga = takeLatest(
  memberType.TEST_SUMMARY,
  workerGetTestSummary
);