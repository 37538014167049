import {
  BOT_CONSOLE_GET_TEMPLATES,
  BOT_CONSOLE_GET_TEMPLATES_SUCCESS,
  BOT_CONSOLE_GET_TEMPLATES_FAILED,
} from "../actions/ActionType"

import { LOADING, STATUS_CODE_SUCCESS } from "../../constants/constant"

const initialState = {
  status: "",
  isLoading: false,
}

export default function getBotTemplates(state = initialState, action) {
  switch (action.type) {
    case BOT_CONSOLE_GET_TEMPLATES:
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      return reqstState

    case BOT_CONSOLE_GET_TEMPLATES_SUCCESS:
      
      let userRes = Object.assign({}, state)
      userRes.status = action.data.data.status_message
      userRes.isLoading = false
      userRes.statusCode = action.data.data.status_code
      if (userRes.statusCode !== STATUS_CODE_SUCCESS) {
        userRes.error = true
        userRes.errorMessage = action.data.data.message
        return userRes
      }
      userRes.templateList = action.data.data.data
      userRes.error = false
      return userRes

    case BOT_CONSOLE_GET_TEMPLATES_FAILED:
      return Object.assign({}, state, action.error)

    default:
      return state
  }
}
