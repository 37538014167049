import { useLocation } from "react-router-dom";
import NavbarContainer from "../../containers/DotGoDevelopers/Private/Navbar/NavbarContainer";
import { isTokenValid } from "../../utils/otherUtils";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";

const Layout = (props) => {
  const { pathname } = useLocation();
  // no need to show navbar while redirect to landing page if this check is not there then navbar will be visible for milliseconds
  if (pathname === "/" && !isTokenValid()) {
    return <div>{props.children}</div>;
  } else {
    return (
      <>
        <div
          style={{ minHeight: "97vh", paddingBottom: "30px" }}
          className={pathname === "/sign-up" ? "sign-up-background" : ""}
        >
          {isTokenValid() ? <NavbarContainer /> : <Header />}
          {props.children}
        </div>

        <Footer />
      </>
    );
  }
};
export default Layout;
