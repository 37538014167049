import React, { useEffect } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { useQuery } from "../../store/hooks/useQuery";
import { isTokenValid } from "../../utils/otherUtils";
import SignInRedirect from "../../components/util/SignInRedirect";
import { usePartnerRole } from "../../store/hooks/usePartnerRole";
import PageNotFound from "../../components/Pages/PageNotFound";
/**
 * @author Sumant K
 * Wrapper for routes that requires authentication.
 * Checks token is valid or not depending on it will allow to-
 * define private route.
 */
const RoleProtectedRoute = ({
  component: Component,
  secureFor = "",
  ...rest
}) => {
  let query = useQuery();

  useEffect(() => {
    if (query.get("action")) {
      if (
        query.get("action").match("logout") ||
        query.get("action").match("resetPassword") ||
        query.get("status").match("success")
      ) {
        rest.history.push("/");
      }
    }
  }, []);

  const { role = "", isLoading } = usePartnerRole(rest.history);

  const checkRole = () => {
    if (typeof secureFor === "string") {
      return new RegExp(secureFor, "i").test(role);
    } else if (Array.isArray(secureFor)) {
      return secureFor.includes(role);
    } else {
      return false;
    }
  };


  return (
    <Route
      {...rest}
      render={(props) =>
        isTokenValid() ? (
          isLoading && !role ? (
            <div className="vh-min"></div>
          ) : checkRole() ? (
            <Component {...props} />
          ) : (
            <PageNotFound />
          )
        ) : query.get("action") ? (
          <Redirect to="/" />
        ) : (
          <SignInRedirect />
        )
      }
    />
  );
};
export default withRouter(React.memo(RoleProtectedRoute));
