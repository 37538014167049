import { put, takeLatest, call } from "redux-saga/effects";
import { GET_APPROVED_BOTS_FOR_ADMIN_SUCCESS, GET_APPROVED_BOTS_FOR_ADMIN, GET_APPROVED_BOTS_FOR_ADMIN_FAILED } from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";

function* workerGetBots(payLoad) {
  try {
    const response = yield call(makeSecuredPostRequest, API_ENDPOINT.GET_APPROVED_BOTS_FOR_ADMIN);
    yield put(createActionOf(GET_APPROVED_BOTS_FOR_ADMIN_SUCCESS, 1, response));
  } catch (error) {
    yield put(createActionOf(GET_APPROVED_BOTS_FOR_ADMIN_FAILED, 2, error));
  }
}

export const watchGetAdminApprovedBots = takeLatest(GET_APPROVED_BOTS_FOR_ADMIN, workerGetBots);
