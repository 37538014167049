import { LOADING } from "../../../constants/constant";
import {
  INVITE_USER_FAILED_ADMIN_PORTAL,
  INVITE_USER_REQUESTED_ADMIN_PORTAL,
  INVITE_USER_RESET_ADMIN_PORTAL,
  INVITE_USER_SUCCESS_ADMIN_PORTAL,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function getInviteUserAdminPortalReducer(state = initialState, action) {
  switch (action.type) {
    case INVITE_USER_REQUESTED_ADMIN_PORTAL:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case INVITE_USER_SUCCESS_ADMIN_PORTAL:
      let userRes = Object.assign({}, state);
      console.log(
        "Invite user reducer => Case => Success: while getting category list: " +
          JSON.stringify(action.data)
      );
      userRes.status = action.data.status_message;
      userRes.statusCode = action.data.status_code;
      userRes.isLoading = false;
      userRes.message = action.data.message;
      userRes.data = action.data.data;

      return userRes;

    case INVITE_USER_FAILED_ADMIN_PORTAL:
      console.error(
        "Invite User Reducer => Case => Error: while getting category list: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);

    case INVITE_USER_RESET_ADMIN_PORTAL: {
      return initialState;
    }

    default:
      return state;
  }
}
