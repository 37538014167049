import { LOADING } from "../../../constants/constant";
import {
    GET_AGGREGATOR_INFO,
    GET_AGGREGATOR_INFO_SUCCESS,
    GET_AGGREGATOR_INFO_FAILED,
    GET_AGGREGATOR_INFO_RESET,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function getAggregatorInfoReducer(state = initialState, action) {
  switch (action.type) {
    case GET_AGGREGATOR_INFO: {
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;
    }

    case GET_AGGREGATOR_INFO_SUCCESS: {
      let userRes = Object.assign({}, state);
      console.log(
        "getAggregatorInfoReducer => Case => Success: while getting aggregator info: " +
          JSON.stringify(action.data)
      );
      userRes.status = action.data.status_message;
      userRes.statusCode = action.data.status_code;
      userRes.isLoading = false;
      userRes.aggregator = action.data.data;

      return userRes;
    }
    case GET_AGGREGATOR_INFO_FAILED: {
      console.error(
        "getAggregatorInfoReducer => Case => Error: while getting aggregator info: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);
    }

    case GET_AGGREGATOR_INFO_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}