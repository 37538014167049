import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import * as Api from "../../appApi/AppApi";

const TAG = "countryList.js";

function* workerCountries(payLoad) {
  console.log(TAG, "worker countries : " + JSON.stringify(payLoad));
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.COUNTRY_LIST,
      {}
    );
    yield put(memberAction.countryListSuccess(response));
  } catch (error) {
    console.error(TAG, "Worker get country failed: " + error.message);
    yield put(memberAction.countryListFailed(error.message));
    // throw new Error("Error: inside saga", JSON.stringify(error));
  }
}

export const watchCountries = takeLatest(
  memberType.COUNTRY_LIST_REQUESTED,
  workerCountries
);
