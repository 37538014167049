import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import * as Api from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";

function* workerDeleteBots(payLoad) {
  console.log(
    " Payload:: " + JSON.stringify(payLoad)
  );
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.DELETE_BOT,
      payLoad.data
    );
    console.log(
      "Worker: => Response:: " +
        JSON.stringify(response)
    );
    yield put(memberAction.createActionOf(memberType.DELETE_BOT_SUCCESS,1,response));
  } catch (error) {
    console.error("Worker: => Error::" + error.message);
    yield put(memberAction.createActionOf(memberType.DELETE_BOT_FAILED,2,error));
  }
}

export const watchDeleteBots= takeLatest(
  memberType.DELETE_BOT,
  workerDeleteBots
);
