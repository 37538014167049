import {put, call, takeLatest} from "redux-saga/effects";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";
import {UPDATE_CARRIER_PROFILE_SUCCESS,
    UPDATE_CARRIER_PROFILE_FAILED,
    UPDATE_CARRIER_PROFILE
} from "../../actions/ActionType";


const TAG = "AccountDetails.js";

function* workerUpdateCarrierProfile (payload){
    console.log(TAG, "account details : " + JSON.stringify(payload.data));
    try{
        const response = yield call(

            Api.makeSecuredPostRequest,
            API_ENDPOINT.UPDATE_CARRIER_PROFILE,
            payload.data
            );
            yield put(createActionOf(UPDATE_CARRIER_PROFILE_SUCCESS, 1, response));      

    }   catch (error){
        console.error(TAG,"update carrier profile failed",+ error.message);
        yield put(createActionOf(UPDATE_CARRIER_PROFILE_FAILED,2,error));
    }

}

export const watchUpdateCarrierProfile = takeLatest(
    UPDATE_CARRIER_PROFILE,
    workerUpdateCarrierProfile
);