import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

const TAG = "deleteUser.js";

function* workerDeleteUser(payLoad) {
  console.log(TAG, "Delete User : " + JSON.stringify(payLoad));
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.DELETE_USER + payLoad.data
    );
    yield put(memberAction.deleteUserSuccess(response));
  } catch (error) {
    console.error(TAG, "Worker get country failed: " + error.message);
    // yield put(memberAction.deleteUserFailed(error.message));
    // throw new Error("Error: inside saga", JSON.stringify(error));
  }
}

export const watchDeleteUser = takeLatest(
  memberType.DELETE_USER_REQUESTED,
  workerDeleteUser
);
