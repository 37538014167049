import { LOADING, STATUS_CODE_SUCCESS } from "../../../constants/constant";
import {
  GET_LAUNCH_CARRIER,
  GET_LAUNCH_CARRIER_SUCCESS,
  GET_LAUNCH_CARRIER_FAILED,
  GET_LAUNCH_CARRIER_RESET_STATE,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function getLaunchCarrierList(state = initialState, action) {
  switch (action.type) {
    case GET_LAUNCH_CARRIER:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_LAUNCH_CARRIER_SUCCESS:
      let userRes = Object.assign({}, state);

      console.log("came to the getLaunchCarrierList   ", action);
      userRes.status = action.data.data.status_message;
      userRes.isLoading = false;
      userRes.statusCode = action.data.data.status_code;

      if (action.data.data.status_code !== STATUS_CODE_SUCCESS) {
        userRes.entity = [];
        userRes.error = action.data.data.message;
        userRes.isError = true;
        console.log("[availaility]", action.data.data);
        return userRes;
      }
      userRes.isError = false;
      userRes.entity = action.data.data.data;
      return userRes;

    case GET_LAUNCH_CARRIER_FAILED:
      return Object.assign({}, state, action.error);
    case GET_LAUNCH_CARRIER_RESET_STATE:
      return initialState;

    default:
      return state;
  }
}
