import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
//import "../../../../components/Pages/assets/styles/onboarding.css";
import { HomePage } from "../../../../components/Pages/SignupPages/Index";
import {
  requestLogin,
  STATUS_CODE_CUSTOM,
  STATUS_CODE_FAILED,
  STATUS_CODE_SUCCESS,
} from "../../../../constants/constant";
import { createActionOf } from "../../../../store/actions/ActionCreator";
import {
//  GET_ALL_CARRIER_AND_COUNTRY_LIST_REQUESTED,
  VERIFY_EMAIL_FAILED,
  VERIFY_EMAIL_REQUESTED,
  VERIFY_EMAIL_RESET,
  VERIFY_EMAIL_SUCCESS,
} from "../../../../store/actions/ActionType";
import { API_ENDPOINT } from "../../../../store/URLs/ApiEndpoints";
import { isTokenValid } from "../../../../utils/otherUtils";
/**
 * Landing page for the portal
 */
const Signup = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const onEmailSubmit = async ({ user_email }) => {
    dispatch(createActionOf(VERIFY_EMAIL_REQUESTED, 0));
    try {
      const response = await fetch(API_ENDPOINT.VERIFY_EMAIL, {
        method: "POST",
        body: user_email,
      });
      const parsedData = await response.json();
      if (parsedData.status_code === STATUS_CODE_SUCCESS) {
        dispatch(createActionOf(VERIFY_EMAIL_SUCCESS, 1, parsedData));
      } else {
        dispatch(createActionOf(VERIFY_EMAIL_FAILED, 2, parsedData));
      }
    } catch (error) {
      dispatch(createActionOf(VERIFY_EMAIL_FAILED, 2, error));
    }
  };

  const response = useSelector((state) => {
    if (state.verifyEmailReducer !== null) {
      return {
        data: state.verifyEmailReducer,
        statusCode: state.verifyEmailReducer.statusCode,
        isLoading: state.verifyEmailReducer.isLoading,
        status: state.verifyEmailReducer.statusMessage,
        message: state.verifyEmailReducer.message,
        error: state.verifyEmailReducer.error,
        errorMessage: state.verifyEmailReducer.errorMessage,
        // carrierList: state.getAllCarrierAndCountryDetailsReducer.carrierList,
        // countryList: state.getAllCarrierAndCountryDetailsReducer.countryList,
      };
    }
  }, shallowEqual);

  useEffect(() => {
    if (response.statusCode === STATUS_CODE_SUCCESS) {
      props.history.push({
        pathname: "/email-verify",
        state: { status: "ok", isAuthorized: true },
      });
    }
    if (
      response.statusCode === STATUS_CODE_CUSTOM ||
      response.statusCode === STATUS_CODE_FAILED
    ) {
      setErrorMessage(response.errorMessage);
    } else {
      setErrorMessage("");
    }
  }, [response.statusCode]);

  useEffect(() => {
    return () => {
      dispatch(createActionOf(VERIFY_EMAIL_RESET, 3, {}));
    };
  }, []);

/*   useEffect(() => {
    dispatch({
      type: GET_ALL_CARRIER_AND_COUNTRY_LIST_REQUESTED,
      data: {},
    });
  }, []); */

  const onSignIn = () => {
    const signUpInput = document.getElementById("user-signup-email");
    signUpInput.value = "";
    requestLogin();
  };

  const resetEmailVerification = () => {
    dispatch(createActionOf(VERIFY_EMAIL_RESET, 3, {}));
  };


  if (isTokenValid()) {
    props.history.push("/developer/dashboard");
    return <></>;
  } else {
    return (
      
      <HomePage
        onSignIn={onSignIn}
        isLoading={response.isLoading}
        errorMessage={errorMessage}
        onEmailSubmit={onEmailSubmit}
        // carrierList={response.carrierList ?? ""}
        // countryList={response.countryList ?? ""}
        resetEmailVerification={resetEmailVerification}
      />
    );
  }
};
export default withRouter(Signup);
