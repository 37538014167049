import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import * as Api from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";

function* getLaunchSummarySaga(payLoad) {
  try {
    let apiEndpoint = API_ENDPOINT.LAUNCH_SUMMARY;
    if (/admin/i.test(payLoad.data?.whoIsThis)) {
      apiEndpoint = API_ENDPOINT.ADMIN_SUBMIT_BOT_LAUNCH_SUMMARY;
    }
    const response = yield call(
      Api.makeSecuredPostRequest,
      apiEndpoint,
      payLoad.data
    );

    yield put(
      memberAction.createActionOf(
        memberType.GET_LAUNCH_SUMMARY_SUCCESS,
        1,
        response
      )
    );
  } catch (error) {
    console.error("Worker getLaunchSummarySaga error: " + error.message);
    yield put(
      memberAction.createActionOf(
        memberType.GET_LAUNCH_SUMMARY_FAILED,
        2,
        error
      )
    );
  }
}

export const watchgetLaunchSummary = takeLatest(
  memberType.GET_LAUNCH_SUMMARY,
  getLaunchSummarySaga
);
