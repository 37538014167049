import { LOADING, STATUS_CODE_SUCCESS } from "../../constants/constant";
import {
  PRICING_REQUESTED,
  PRICING_SUCCESS,
  PRICING_FAILED,
  PRICING_RESET,
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: false,
};

export default function getPricingReducer(state = initialState, action) {
  switch (action.type) {
    case PRICING_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case PRICING_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log(
        "came to the PRICING_SUCCESS success   " + JSON.stringify(action)
      );
      userRes.status = action.data.status_message;
      userRes.statusCode = action.data.status_code;
      userRes.isLoading = false;
      userRes.data = action.data.data;
      return userRes;
    //   } else {
    //     userRes.error = true;
    //     userRes.errorMessage = action.data.message;
    //     return userRes;
    //   }

    case PRICING_FAILED:
      return Object.assign({}, state, action.error);

    case PRICING_RESET:
      return initialState;

    default:
      return state;
  }
}
