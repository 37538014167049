import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import * as Api from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";

const TAG = "submitPreselectedCarriersSaga.js";

function* submitPreselectedCarrierList(payLoad) {
  console.log(
    TAG,
    "worker submitPreselectedCarrierList : " + JSON.stringify(payLoad)
  );
  console.log(
    TAG,
    "submitPreselectedCarrierList : " + JSON.stringify(payLoad.data)
  );

  /** Payload should be of form: 
  ** @param {number} bot_id
  ** @param {number} brand_id
  ** @param {array} carrier_list
  **/

  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.SUBMIT_PRE_SELECTED_CARRIER,
      payLoad.data
    );
    console.log(
      TAG,
      "worker submitPreselectedCarrierList Response: " +
        JSON.stringify(response)
    );
    yield put(
      memberAction.createActionOf(
        memberType.SUBMIT_PRE_SELECTED_CARRIER_SUCCESS,
        1,
        response
      )
    );
  } catch (error) {
    console.log(
      TAG,
      "Worker submitPreselectedCarrierList error: " + error.message
    );
    yield put(
      memberAction.createActionOf(
        memberType.SUBMIT_PRE_SELECTED_CARRIER_FAILED,
        2,
        error
      )
    );
  }
}

export const watchSubmitPreselectedCarrierList = takeLatest(
  memberType.SUBMIT_PRE_SELECTED_CARRIER,
  submitPreselectedCarrierList
);
