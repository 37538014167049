import { NavLink } from "react-router-dom";
import "./404.css";
const PageNotFound = () => {
  return (
    <div className="page-404">
      <div className="page-window">
        <div className="page-window__actions">
          <span className="action__item--1"></span>
          <span className="action__item--1"></span>
          <span className="action__item--1"></span>
        </div>

        <img
          src="images/public_images_page_not_fount404_icon.svg"
          alt="Page Not Fount"
          className="page-404__image"
        />
        <div className="billboard">
          <h1 className="billboard__heading">Oops! Page not found.</h1>
          <p className="billboard__message">
            We can't find what you looking for. You can either go to home page
            or contact our support team.
          </p>
          <div className="btn-set">
            <NavLink to="/" replace className="btn-set__direction">
              <button className="btn btn-red-round">Go Home</button>
            </NavLink>
            <a
              className="btn-set__help"
              href="mailto:rbm-support@virbm.in"
              rel="noreferrer noopener"
              target="_blank"
            >
              <button className="sec-btn">Contact Us</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
