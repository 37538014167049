import { put, takeLatest, call } from "redux-saga/effects";
import {createActionOf} from "../../actions/ActionCreator";
import {
    APPROVE_OR_REJECT_CARRIER_AGGREGATOR,
    APPROVE_OR_REJECT_CARRIER_AGGREGATOR_SUCCESS,
    APPROVE_OR_REJECT_CARRIER_AGGREGATOR_FAILED,
} from "../../actions/ActionType";
import {makeSecuredPostRequest} from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";

const TAG = "approveOrRejectCarrierAggregatorSaga.js";

function* workerApproveOrRejectAggregators(payLoad) {
  try {
    const response = yield call(
        makeSecuredPostRequest,
       API_ENDPOINT.APPROVE_OR_REJECT_CARRIER_AGGREGATOR,
       payLoad.data
    );
    console.log(
      TAG,
      "worker workerApproveOrRejectAggregators Response: " + JSON.stringify(response)
    );
    yield put(createActionOf(APPROVE_OR_REJECT_CARRIER_AGGREGATOR_SUCCESS, 1, response));
} catch (error) {
  console.error(TAG, "Worker get country failed: " + error.message);
  yield put(createActionOf(APPROVE_OR_REJECT_CARRIER_AGGREGATOR_FAILED, 2, error));
}
}

export const watchApproveOrRejectAggregators = takeLatest(
    APPROVE_OR_REJECT_CARRIER_AGGREGATOR,
    workerApproveOrRejectAggregators
);