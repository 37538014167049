import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import * as Api from "../../appApi/AppApi";

function* workerGetSingleBrandDetails(payload) {
  try {
    let apiEndpoint =
      API_ENDPOINT.GET_SINGLE_BRAND_DETAILS + payload.data?.brand_id;
    if (/admin/i.test(payload.data?.whoIsThis)) {
      apiEndpoint =
        API_ENDPOINT.ADMIN_GET_SINGLE_BRAND_DETAILS + payload.data?.brand_id?.replace("?status=i","");
    }

    const response = yield call(Api.makeSecuredPostRequest, apiEndpoint);
    yield put(memberAction.getBrandSuccess(response));
  } catch (error) {
    console.error("Error: while getting brand details: ", error.message);
    yield put(memberAction.getBrandFailed(error.message));
  }
}

export const watchGetBrandDetailsSaga = takeLatest(
  memberType.GET_SINGLE_BRAND_REQUESTED,
  workerGetSingleBrandDetails
);
