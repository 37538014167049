import { LOADING } from "../../../constants/constant";
import {
  CARRIER_GET_SUBMITTED_BRANDS_FAILED,
  CARRIER_GET_SUBMITTED_BRANDS_RESET,
  CARRIER_GET_SUBMITTED_BRANDS_SUCCESS,
  CARRIER_GET_SUBMITTED_BRANDS_REQESTED
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: false,
};

export default function CarrierGetSubmittedBrandsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case CARRIER_GET_SUBMITTED_BRANDS_REQESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true
      return reqstState;

    case CARRIER_GET_SUBMITTED_BRANDS_SUCCESS:
      let userRes = Object.assign({}, state)
      userRes.status = action.data.status_message;
      userRes.isLoading = false;
      userRes.statusCode = action.data.status_code
      userRes.data = action.data;
      return userRes;

    case CARRIER_GET_SUBMITTED_BRANDS_FAILED:
      console.error(
        "carriergetsubmittedbrandsReducer => Case => Error: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);

    case CARRIER_GET_SUBMITTED_BRANDS_RESET:
      return initialState;

    default:
      return state;
  }
}
