import { getIPRange } from "get-ip-range";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Popup } from "semantic-ui-react";
import validator from "validator";
import { getUniqueUID } from "../../../utils/otherUtils";
import FormError from "../../Errors/FormError";
import "./signup.util.css";

const getFormattedIPObject = (renderedFields = [], fieldData) => {
  try {
    const formattedIPObject = {};
    renderedFields.forEach((fieldName = "") => {
      let fieldUID = fieldName.split("_").pop();
      if (fieldName.includes(`from_${fieldUID}`)) {
        formattedIPObject[fieldUID] = {
          from: fieldData[fieldName],
          to: undefined,
          fieldUID,
        };
      }
      for (let i = 0; i < renderedFields.length; i++) {
        let element = renderedFields[i];
        if (element.includes(`to_${fieldUID}`)) {
          formattedIPObject[fieldUID].to = fieldData[element];
        }
      }
    });

    return Object.values(formattedIPObject).map((ele) => {
      if (ele.from && ele.to) {
        return { ...ele, range: getIPRange(ele.from, ele.to) };
      } else {
        return { ...ele, range: [ele.from] };
      }
    });
  } catch (error) {
    throw Error(error);
  }
};

const IPInputWrapper = ({ transactionType = "Domestic" }) => {
  const [ipPersisted, setIpPersisted] = useState(undefined);
  useEffect(() => {
    try {
      const data = JSON.parse(localStorage.getItem("__user__d") ?? "{}");
      setIpPersisted(data?.ipFieldSet);
    } catch (error) {}
  }, []);

  return (
    <>
      {transactionType === "Domestic" || transactionType === "Both" ? (
        <div className="row">
          <div className="col-lg-10 col-md-10 margin-bottom-15">
            <div className="form-group form-input">
              <label className="font-size-13">
                Domestic Host Address<span style={{ color: "red" }}>*</span>{" "}
                <Popup
                  trigger={
                    <i
                      className="fa fa-info-circle text-light-gray"
                      style={{ fontSize: "100%" }}
                    />
                  }
                  size="huge"
                  pinned
                  hoverable
                  hideOnScroll={false}
                  position="right center"
                  content={
                    <p style={{ color: "gray" }}>
                      Kindly share your server IP addresses from which the API
                      request originates.
                    </p>
                  }
                />
              </label>
              <IPInput
                dataPersisted={ipPersisted?.domesticFieldSet}
                transactionType={"Domestic"}
              />
            </div>
          </div>
        </div>
      ) : null}
      {transactionType === "International" || transactionType === "Both" ? (
        <div className="row">
          <div className="col-lg-10 col-md-10 margin-bottom-15">
            <div className="form-group form-input">
              <label className="font-size-13">
                International Host Address
                <span style={{ color: "red" }}>*</span>{" "}
                <Popup
                  trigger={
                    <i
                      className="fa fa-info-circle text-light-gray"
                      style={{ fontSize: "100%" }}
                    />
                  }
                  size="huge"
                  pinned
                  hoverable
                  hideOnScroll={false}
                  position="right center"
                  content={
                    <p style={{ color: "gray" }}>
                      Kindly share your server IP addresses from which the API
                      request originates.
                    </p>
                  }
                />
              </label>
              <IPInput
                dataPersisted={ipPersisted?.internationalFieldSet}
                transactionType={"International"}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default IPInputWrapper;
const IPInput = (props) => {
  const [ipFieldSet, setIpFieldSet] = useState([{ fieldID: getUniqueUID() }]);
  const { formState, getValues } = useFormContext();

  const onAddMoreIP = () => {
    if (ipFieldSet.length <= 10) {
      const arrayTemp = [...ipFieldSet];
      arrayTemp.push({ fieldID: getUniqueUID() });
      setIpFieldSet(arrayTemp);
    } else {
      return;
    }
  };

  const onDeleteIP = (fieldID) => {
    if (ipFieldSet.length >= 1) {
      let arrayTemp = ipFieldSet.filter((ele) => ele.fieldID !== fieldID);
      setIpFieldSet(arrayTemp);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (props.dataPersisted) {
      setIpFieldSet(props.dataPersisted);
    }
  }, [props.dataPersisted]);

  return (
    <>
      {ipFieldSet?.map((ipSet, index) => (
        <IP
          defaultValue={ipSet}
          transactionType={props.transactionType}
          key={ipSet.fieldID}
          id={ipSet.fieldID}
          onDeleteIP={onDeleteIP}
          ipFieldSet={ipFieldSet}
        />
      ))}

      {ipFieldSet.length <= 10 && (
        <button
          type="button"
          onClick={onAddMoreIP}
          className="btn-link font-color-purple margin-top-20"
        >
          +Add more
        </button>
      )}
    </>
  );
};

const IP = ({ id, onDeleteIP, ...rest }) => {
  const [toggleRange, setToggleRange] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { register, errors, watch, getValues } = useFormContext();

  const onToggleRange = () => {
    setToggleRange(!toggleRange);
  };

  const validateDuplicateIP = (inputIP) => {
    const fieldData = getValues() ?? {};
    const renderedIpFields = Object.keys(fieldData).filter((key) =>
      key.includes("_host_address_")
    );
    const isDuplicate = () => {
      const array = getFormattedIPObject(renderedIpFields, fieldData);
      let theField = {};
      return (
        array
          .filter((ele) => {
            if (ele.fieldUID !== getFieldID()) {
              return true;
            } else {
              theField = ele;
              return false;
            }
          })
          .some((ele) => ele.range?.includes(inputIP)) ||
        theField?.from === theField.to
      );
    };
    try {
      return !isDuplicate();
    } catch (error) {
      console.log("error while validating IPs ", error);
      return true;
    }
  };

  // defaultValue={rest.defaultValue?.to}
  const getFromFieldName = () => {
    if (rest.defaultValue?.from) {
      return `${rest.transactionType}_host_address_from_${rest.defaultValue?.fieldID}`;
    } else {
      return `${rest.transactionType}_host_address_from_${id}`;
    }
  };

  const getToFieldName = () => {
    if (rest.defaultValue?.from) {
      return `${rest.transactionType}_host_address_to_${rest.defaultValue?.fieldID}`;
    } else {
      return `${rest.transactionType}_host_address_to_${id}`;
    }
  };

  const getFieldID = () => {
    if (rest.defaultValue?.from) {
      return rest.defaultValue?.fieldID;
    } else {
      return id;
    }
  };

  useEffect(() => {
    if (rest.defaultValue?.to) {
      setToggleRange(true);
    }
  }, [rest.defaultValue?.to]);

  return (
    <>
      <div className="d-flex-ip-comp flex-align-items-center margin-top-30">
        <div className="col-lg-6 col-md-8 p-0">
          <input
            type="text"
            name={getFromFieldName()}
            className="form-control"
            ref={register({
              required: true,
              validate: {
                validIP: validator.isIP,
                duplicateIP: validateDuplicateIP,
              },
            })}
            defaultValue={rest.defaultValue?.from}
          />
          {errors[rest.transactionType + "_host_address_from_" + id] &&
            errors[rest.transactionType + "_host_address_from_" + id]?.type ===
              "required" && (
              <FormError absolute errorMessage="⚠ Please enter host address." />
            )}
          {errors[rest.transactionType + "_host_address_from_" + id] &&
            errors[rest.transactionType + "_host_address_from_" + id]?.type ===
              "validIP" && (
              <FormError
                absolute
                errorMessage="⚠ Please enter valid host address."
              />
            )}
          {errors[rest.transactionType + "_host_address_from_" + id] &&
            errors[rest.transactionType + "_host_address_from_" + id]?.type ===
              "duplicateIP" && (
              <FormError
                absolute
                errorMessage="⚠ Please remove duplicate host address."
              />
            )}
        </div>
        {toggleRange === false ? (
          <button
            type="button"
            onClick={onToggleRange}
            className="btn-link font-color-purple margin-left-20"
          >
            Add IP Address Range
          </button>
        ) : (
          <>
            <div className="margin-left-10 margin-right-10">to</div>
            <div className="col-lg-6 col-md-8 p-0">
              <input
                type="text"
                name={getToFieldName()}
                className="form-control"
                ref={register({
                  required: true,
                  //   validate: dupicalteHostAddressValidator,
                  validate: {
                    validIP: validator.isIP,
                    // validIPRange: validator.isIPRange,
                    duplicateIP: validateDuplicateIP,
                  },
                  // pattern: regexIPV4,
                })}
                defaultValue={rest.defaultValue?.to}
              />
              {errors[rest.transactionType + "_host_address_to_" + id] &&
                errors[rest.transactionType + "_host_address_to_" + id]
                  ?.type === "required" && (
                  <FormError
                    absolute
                    errorMessage="⚠ Please enter host address."
                  />
                )}
              {errors[rest.transactionType + "_host_address_to_" + id] &&
                errors[rest.transactionType + "_host_address_to_" + id]
                  ?.type === "validIP" && (
                  <FormError
                    absolute
                    errorMessage="⚠ Please enter valid host address."
                  />
                )}
              {errors[rest.transactionType + "_host_address_to_" + id] &&
                errors[rest.transactionType + "_host_address_to_" + id]
                  ?.type === "duplicateIP" && (
                  <FormError
                    absolute
                    errorMessage="⚠ Please remove duplicate host address."
                  />
                )}
            </div>

            <button
              type="button"
              onClick={onToggleRange}
              className="btn-link font-color-purple ws-nowrap margin-left-20"
            >
              Remove Range
            </button>
          </>
        )}
        {rest.ipFieldSet?.length > 1 && (
          <button
            onClick={() => onDeleteIP(id)}
            type="button"
            className="btn-link ml-1"
          >
            <img src="images/icon_delete.svg" alt="" width="16" />
          </button>
          // <Popup
          //   open={openConfirmation}
          //   trigger={
          //     <button
          //       onOpen={() => setOpenConfirmation(true)}
          //       type="button"
          //       className="btn-link ml-1"
          //     >
          //       <img src="images/icon_delete.svg" alt="" width="16" />
          //     </button>
          //   }
          //   size="tiny"
          //   on="click"
          //   position="bottom center"
          //   onClose={() => setOpenConfirmation(false)}
          //   onOpen={() => setOpenConfirmation(true)}
          //   content={
          //     <div className="text-center" style={{ padding: "1rem 1.5rem" }}>
          //       <p className="font-size-14 fw-4">
          //         Are you sure want to delete the attachment?
          //       </p>
          //       <div className="margin-top-15">
          //         <button
          //           type="button"
          //           onClick={() => onDeleteIP(id)}
          //           className="btn btn-purple-round-sm font-size-10"
          //         >
          //           Delete
          //         </button>
          //         <button
          //           onClick={() => setOpenConfirmation(false)}
          //           type="button"
          //           className="btn btn-gray-round-bdr-sm font-size-10 ml-1"
          //         >
          //           Cancel
          //         </button>
          //       </div>
          //     </div>
          //   }
          // />
        )}
      </div>

      {/*  */}
    </>
  );
};
