import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import * as Api from "../../appApi/AppApi";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";

function* workerChangePassword(payLoad) {
  try {
    const response = yield call(
      Api.makeNewSecuredPostRequest,
      API_ENDPOINT.CHANGE_PASSWORD,
      payLoad.data
    );
    yield put(memberAction.changePasswordSuccess(response));
  } catch (error) {
    yield put(memberAction.changePasswordFailed(error.data));
  }
}

export const watchChangePassword = takeLatest(
  memberType.CHANGE_PASSWORD_REQUESTED,
  workerChangePassword
);
