import { LOADING } from "../../constants/constant"
import { GET_AGENT_MSG_TYPE_FAILED, GET_AGENT_MSG_TYPE_REQUESTED, GET_AGENT_MSG_TYPE_RESET, GET_AGENT_MSG_TYPE_SUCCESS } from "../actions/ActionType"


const initialState = {
  status: "INIT",
  isLoading:undefined,
}

export default function agentMsgTypeListReducer(state = initialState, action) {
  switch (action.type) {
    case GET_AGENT_MSG_TYPE_REQUESTED:
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      return reqstState

    case GET_AGENT_MSG_TYPE_SUCCESS:
      let userRes = Object.assign({}, state)
      userRes.status = action.data.data.status_message
      userRes.isLoading = false
      userRes.statusCode = action.data.data.status_code
      userRes.agentMsgTypes = action.data.data.data??[]
      return userRes

    case GET_AGENT_MSG_TYPE_FAILED:
      return Object.assign({}, state, action.error)

    case GET_AGENT_MSG_TYPE_RESET:
      return initialState

    default:
      return state
  }
}
