import React from "react"
import { CountriesAndCarrier } from "../../components/CarrierAndCountries/CountriesAndCarrier"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import { useEffect } from "react"
import { GET_ALL_CARRIER_AND_COUNTRY_LIST_REQUESTED } from "../../store/actions/ActionType"
/**
 * @author
 * @function CountryAndCarrierContainer
 **/

export const CountryAndCarrierContainer = (props) => {
  const dispatch = useDispatch()
  const response = useSelector((state) => {
    if (state.verifyEmailReducer !== null) {
      return {
        carrierList: state.getAllCarrierAndCountryDetailsReducer.carrierList,
        countryList: state.getAllCarrierAndCountryDetailsReducer.countryList,
      }
    }
  }, shallowEqual)

  useEffect(() => {
    dispatch({
      type: GET_ALL_CARRIER_AND_COUNTRY_LIST_REQUESTED,
      data: {},
    })
  }, [])
  return (
    <>
      {Array.isArray(response.countryList) ? (
        <CountriesAndCarrier
          carrierList={response.carrierList}
          countryList={response.countryList}
        />
      ) : (
        <div className="vh-min" />
      )}
    </>
  )
}
