import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import * as Api from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";

const TAG = "getInitialDetailsSaga.js";

function* workerGetInitialDetails(payLoad) {
  console.log(
    TAG,
    "worker workerGetInitialDetails : " + JSON.stringify(payLoad)
  );
  console.log(TAG, "workerGetInitialDetails : " + JSON.stringify(payLoad.data));
  try {
    const response = yield call(
      Api.makeSecuredGetRequest,
      API_ENDPOINT.LAUNCH_COMPANY_INITIAL_DETAILS
    );
    console.log(
      TAG,
      "worker workerGetInitialDetails Response: " + JSON.stringify(response)
    );
    yield put(
      memberAction.createActionOf(
        memberType.GET_INITIAL_DETAILS_SUCCESS,
        2,
        response
      )
    );
  } catch (error) {
    console.log(TAG, "Worker workerGetInitialDetails error: " + error.message);
    yield put(
      memberAction.createActionOf(
        memberType.GET_INITIAL_DETAILS_FAILED,
        2,
        error
      )
    );
  }
}

export const watchGetInitialDetails = takeLatest(
  memberType.GET_INITIAL_DETAILS,
  workerGetInitialDetails
);
