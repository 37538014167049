import { PhoneNumberUtil } from "google-libphonenumber"
const phoneUtil = new PhoneNumberUtil()
export const formatNumbers = (tel_number = "") => {
  if (tel_number.indexOf("+") === -1) {
    tel_number = "+" + tel_number
  }
  try {
    const number = phoneUtil.parseAndKeepRawInput(tel_number)
    console.log("[tel formatting]=> country iso", getCountryIso(tel_number))
    console.log(
      "[tel formatting]=> format iso",
      phoneUtil.formatInOriginalFormat(number, getCountryIso(tel_number))
    )
    return phoneUtil.formatInOriginalFormat(number, getCountryIso(tel_number))
  } catch (error) {
    return tel_number
  }
}

export const getCountryIso = (tel_number = "") => {
  if (!/^\+/.test(tel_number)) {
    tel_number = "+" + tel_number
  }
  try {
    const number = phoneUtil.parseAndKeepRawInput(tel_number)
    return phoneUtil.getRegionCodeForNumber(number)
  } catch (error) {
    return "US"
  }
}
export const getCountryCode = (tel_number) => {
  const number = phoneUtil.parseAndKeepRawInput(tel_number)
  return number.getCountryCode()
}

export const removeFormatting = (tel_number = "+234-80-923-89547") => {
  try {
    const number = phoneUtil.parseAndKeepRawInput("+" + tel_number)
    return (
      number.getCountryCode().toString() + number.getNationalNumber().toString()
    )
  } catch (e) {
    return tel_number.replace(/[^\w]/gi, "")
  }
}
export const removeFormattingManual = (tel_number = "+234-80-923-89547") => {
  return tel_number.replace(/[^\w]/gi, "")
}
export const removeISDCode = (tel_number = "+234-80-923-89547") => {
  try {
    const number = phoneUtil.parseAndKeepRawInput(tel_number)
    console.log(
      "[tel removed formatting]==>",
      number.getNationalNumber().toString()
    )
    return number.getNationalNumber().toString()
  } catch (error) {
    return "  "
  }
}

export const formatRPNIDefaultValue = (tel_number = '') => {
  try {
    if (!tel_number) {
      return '';
    }
    return /\+/.test(tel_number) ? tel_number : `+${tel_number}`;
  } catch (error) {
    return tel_number;
  }
};
