import { put, takeLatest, call } from "redux-saga/effects";
import {
  GRAPH_DATA_SUCCESS,
  GRAPH_DATA_REQUESTED,
  GRAPH_DATA_FAILED,
} from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";

function* workerGraphSummary(payLoad) {
  try {
    let endpoint = API_ENDPOINT.GET_DASHBOARD_ANALYTICS_API;
    if(payLoad.data.whoIsThis==="super-admin") endpoint = API_ENDPOINT.DOTGO_ANALYTICS_API;
    if(payLoad.data.whoIsThis==="vi-admin") endpoint = API_ENDPOINT.GET_DASHBOARD_CARRIER_ANALYTICS_API;
    if(payLoad.data.whoIsThis==="aggregator") endpoint = API_ENDPOINT.GET_AGGREGATOR_BILLING_INFO;


    const response = yield call(
      makeSecuredPostRequest,
      endpoint,
      payLoad.data
    );
    yield put(createActionOf(GRAPH_DATA_SUCCESS, 1, response));
  } catch (error) {
    yield put(createActionOf(GRAPH_DATA_FAILED, 2, error));
  }
}

export const watchFilterDashboardSummaryForGraph = takeLatest(
  GRAPH_DATA_REQUESTED,
  workerGraphSummary
);
