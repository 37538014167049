import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import * as Api from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";

function* workerSubmitSelectedCarrier(payLoad) {
  console.log(
    "Worker: workerSubmitSelectedCarrier => Payload:: " + JSON.stringify(payLoad)
  );
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.SUBMIT_SELECTED_CARRIER,
      payLoad.data
    );
    console.log(
      "Worker: workerSubmitSelectedCarrier => Response:: " +
        JSON.stringify(response)
    );
    yield put(memberAction.createActionOf(memberType.SUBMIT_SELECTED_CARRIER_SUCCESS,1,response));
  } catch (error) {
    console.error("Worker: workerSubmitSelectedCarriers => Error::" + error.message);
    yield put(memberAction.createActionOf(memberType.SUBMIT_SELECTED_CARRIER_SUCCESS,2,error));
  }
}

export const watchSubmitSelectedCarriers= takeLatest(
  memberType.SUBMIT_SELECTED_CARRIER,
  workerSubmitSelectedCarrier
);
