import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import { Loader } from "semantic-ui-react"

export default function CommonModalFile({
  open = true,
  modalHeader = "",
  modalText = "",
  dismissButton = true,
  dismissButtonLabel = "Cancel",
  actionButton = true,
  actionButtonLabel = "Submit",
  isActionLoading = false,
  actionButtonDisabled = false,
  noActions = false,
  maxWidth = undefined,
  minWidth="300px",
  onDismiss = function () {
    return
  },
  onAction = function () {
    return
  },
  ...props
}) {
  const widthProps = {
    maxWidth:props?.maxWidth1===""?undefined:"lg",
    // maxWidth,
    fullWidth: props.noFullWidth ? false : true,
  }
  return (
    <>
      <Dialog {...widthProps} open={open} onClose={onDismiss} >
        {/* {props?.closeButton && (
          <div className="modal-header1">
            <button
              onClick={onDismiss}
              type="button"
              className="close"
              style={{ paddingTop: "7px", paddingRight: "10px" }}
            >
              <img src="images/icon_close.svg" alt="Close" width={18} />
            </button>
          </div>
        )} */}
        {/* {modalHeader && (
          <div className="common__modal-title">
            <DialogTitle className="common__modal-title-text text-center">
              {modalHeader}
            </DialogTitle>
          </div>
        )} */}

        {(
          <DialogContent className={props?.isImage?"common__modal":"common__modal paddingTop-0"}>
            {typeof modalText === "object" ? (
              <>{modalText}</>
            ) : (
              <DialogContentText className="common__modal-text text-center" >
                {modalText}
              </DialogContentText>
            )}

            {/* </DialogActions> */}
          </DialogContent>
        )}
      </Dialog>
    </>
  )
}
