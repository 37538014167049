import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import * as Api from "../../appApi/AppApi";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";

const TAG = "confirmRCSSubmissionSaga.js";

function* workerConfirmRCSSubmission(payLoad) {
  console.log(
    TAG,
    "worker confirmRCSSubmissionSaga : " + JSON.stringify(payLoad)
  );
  console.log(
    TAG,
    "worker confirmRCSSubmissionSaga : " + JSON.stringify(payLoad.data)
  );
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.CONFIRM_BOT_SUBMISSION +
        `?bot_id=${payLoad.data.bot_id}&channel_type=${payLoad.data.channel_type}`,
      {}
    );
    console.log(
      TAG,
      "worker confirmRCSSubmissionSaga Response: " + JSON.stringify(response)
    );
    yield put(memberAction.confirmRCSSubmissionSuccess(response));
  } catch (error) {
    console.log(TAG, "Worker confirmRCSSubmissionSaga error: " + error.message);
    yield put(memberAction.confirmRCSSubmissionFailed(error.message));
  }
}

export const watchConfimRCSSubmission = takeLatest(
  memberType.CONFIRM_RCS_SUBMITION,
  workerConfirmRCSSubmission
);
