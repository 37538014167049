import { call, put, takeLatest } from "redux-saga/effects"
import {
  makeNewSecuredPostRequest,
  makeSecuredPostRequest,
} from "../../../../appApi/AppApi";
import { createActionOf } from "../../../actions/ActionCreator";
import {
  ADMIN_APPROVE_REJECT_BOT_FOR_CREATION,
  ADMIN_APPROVE_REJECT_BOT_FOR_CREATION_FAILED,
  ADMIN_APPROVE_REJECT_BOT_FOR_CREATION_SUCCESS,
  ADMIN_INSERT_AGENT,
  ADMIN_INSERT_AGENT_SUCCESS,
  ADMIN_INSERT_AGENT_FAILED,
  ADMIN_INSERT_MaaP_AGENT,
  ADMIN_INSERT_MaaP_AGENT_SUCCESS,
  ADMIN_INSERT_MaaP_AGENT_FAILED,
  ADMIN_KRCS_CREATION,
  ADMIN_KRCS_CREATION_SUCCESS,
  ADMIN_KRCS_CREATION_FAILED,
  GET_AVAILABLE_MaaP,
  GET_AVAILABLE_MaaP_SUCCESS,
  GET_AVAILABLE_MaaP_FAILED,
  GET_AGENT_INFO,
  GET_AGENT_INFO_SUCCESS,
  GET_AGENT_INFO_FAILED,
} from "../../../actions/ActionType";
import { API_ENDPOINT } from "../../../URLs/ApiEndpoints";

function* workerApproveRejectBotForCreation(payLoad) {
  try {
    const response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.ADMIN_APPROVE_REJECT_CREATION,
      payLoad.data
    );
    yield put(
      createActionOf(ADMIN_APPROVE_REJECT_BOT_FOR_CREATION_SUCCESS, 1, response)
    );
  } catch (error) {
    yield put(
      createActionOf(ADMIN_APPROVE_REJECT_BOT_FOR_CREATION_FAILED, 2, error)
    );
  }
}
function* workerKRCSCreation(payLoad) {
  try {
    const response = yield call(
      makeNewSecuredPostRequest,
      API_ENDPOINT.ADMIN_CREATE_BOT_ON_KRCSAPI,
      payLoad.data
    );
    yield put(createActionOf(ADMIN_KRCS_CREATION_SUCCESS, 1, response));
  } catch (error) {
    yield put({ type: ADMIN_KRCS_CREATION_FAILED, error });
  }
}
function* workerInsertAgent(payLoad) {
  try {
    const response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.ADMIN_REGISTER_BOT_ON_KRCSAPI,
      payLoad.data
    )
    yield put(createActionOf(ADMIN_INSERT_AGENT_SUCCESS, 1, response))
  } catch (error) {
    yield put(createActionOf(ADMIN_INSERT_AGENT_FAILED, 2, error))
  }
}
function* workerInsertMaaPAgent(payLoad) {
  try {
    const response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.ASSOCIATE_BOT_WITH_CHATBOT_PLATFORM,
      payLoad.data
    )
    yield put(createActionOf(ADMIN_INSERT_MaaP_AGENT_SUCCESS, 1, response))
  } catch (error) {
    yield put(createActionOf(ADMIN_INSERT_MaaP_AGENT_FAILED, 2, error))
  }
}
function* workerGetAvailableMaaP(payLoad) {
  try {
    const response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.GET_AVAILABLE_MaaP,
      payLoad.data
    )
    yield put(createActionOf(GET_AVAILABLE_MaaP_SUCCESS, 1, response))
  } catch (error) {
    yield put(createActionOf(GET_AVAILABLE_MaaP_FAILED, 2, error))
  }
}

function* workerGetAgentInfo(payLoad) {
  try {
    const response = yield call(
      makeSecuredPostRequest,
      `${API_ENDPOINT.GET_AGENT_INFO}?bot_id=${payLoad.data}`,
      payLoad.data
    )
    yield put(createActionOf(GET_AGENT_INFO_SUCCESS, 1, response))
  } catch (error) {
    yield put(createActionOf(GET_AGENT_INFO_FAILED, 2, error))
  }
}

export const watchApproveRejectBotForCreation = takeLatest(
  ADMIN_APPROVE_REJECT_BOT_FOR_CREATION,
  workerApproveRejectBotForCreation
)
export const watchKRCSCreation = takeLatest(
  ADMIN_KRCS_CREATION,
  workerKRCSCreation
)
export const watchInsertAgent = takeLatest(
  ADMIN_INSERT_AGENT,
  workerInsertAgent
)
export const watchMaaPAgent = takeLatest(
  ADMIN_INSERT_MaaP_AGENT,
  workerInsertMaaPAgent
)
export const watchGetAvailableMaaP = takeLatest(
  GET_AVAILABLE_MaaP,
  workerGetAvailableMaaP
)
export const watchGetAgentInfo = takeLatest(GET_AGENT_INFO, workerGetAgentInfo)
