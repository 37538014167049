import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

const TAG = "InviteUser.js";

function* workerInviteUser(payLoad) {
  console.log(TAG, "INvite User : " + JSON.stringify(payLoad));
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.INVITE_USER,
      payLoad.data
    );
    yield put(memberAction.inviteUserSuccess(response));
  } catch (error) {
    console.error(TAG, "Worker get country failed: " + error.message);
    yield put(memberAction.inviteUserFailed(error.message));
    // throw new Error("Error: inside saga", JSON.stringify(error));
  }
}

export const watchInviteUser = takeLatest(
  memberType.INVITE_USER_REQUESTED,
  workerInviteUser
);
