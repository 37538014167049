/**
 * @author Sumant K 
 * @param {Object} param props
 *
 * A component for creating extra space by putting margin.
 */
const MarginOf = ({ margin = "0", ...rest }) => {
  return (
    <>
      <div style={{ margin: margin }}>{rest.children}</div>
    </>
  );
};
export default MarginOf;
