import { put, takeLatest, call } from "redux-saga/effects";
import {
  
  GET_ADMIN_CAMPAIGN_REQUESTED,
  GET_ADMIN_CAMPAIGN_SUCCESS,
  GET_ADMIN_CAMPAIGN_FAILED,
  GET_ADMIN_CAMPAIGN_RESET
} from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";

const TAG = "AdminCampaignContainer.js";

function* workerGetCampaign(payLoad) {
  console.log("payload sub-------------------->",payLoad);
  try {
    const response = yield call(
      makeSecuredPostRequest,
      payLoad.isVIAdmin ? API_ENDPOINT.GET_ADMIN_CARRIER_CAMPAIGN : API_ENDPOINT.GET_ADMIN_CAMPAIGN ,
      payLoad.data
    );
    yield put(createActionOf(GET_ADMIN_CAMPAIGN_SUCCESS, 1, response));
  } catch (error) {
    console.error(TAG, "Worker get campaign failed: " + error.message);
    yield put(createActionOf(GET_ADMIN_CAMPAIGN_FAILED, 2, error));
  }
}

export const watchGetAdminCampaigns = takeLatest(
  GET_ADMIN_CAMPAIGN_REQUESTED,
  workerGetCampaign
);
