import { put, takeLatest, call } from "redux-saga/effects";
import { createActionOf } from "../actions/ActionCreator";
import {
  SET_PASSWORD_FAILED,
  SET_PASSWORD_REQUESTED,
  SET_PASSWORD_SUCCESS,
} from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import * as Api from "../../appApi/AppApi";

const TAG = "setPasswordSaga.js";

function* workerSetPassword(payLoad) {
  console.log(TAG, "worker SetPassword : " + JSON.stringify(payLoad));
  console.log(TAG, "worker SetPassword : " + JSON.stringify(payLoad.data));
  try {
    const response = yield call(
      Api.makeUnsecuredPostRequest,
      API_ENDPOINT.CREATE_USER,
      payLoad.data
    );
    console.log(
      TAG,
      "worker SetPassword Response: " + JSON.stringify(response)
    );
    yield put(createActionOf(SET_PASSWORD_SUCCESS, 1, response));
  } catch (error) {
    console.log(TAG, "Worker SetPassword error: " + error.message);
    yield put(createActionOf(SET_PASSWORD_FAILED, 2, error.message));
  }
}

export const watchSetPassword = takeLatest(
  SET_PASSWORD_REQUESTED,
  workerSetPassword
);
