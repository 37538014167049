import { put, takeLatest, call } from "redux-saga/effects";
import {
    GET_LIST_OF_AGGREGATOR,
    GET_LIST_OF_AGGREGATOR_SUCCESS,
    GET_LIST_OF_AGGREGATOR_FAILED
} from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";

const TAG = "getCarrierProfileSaga.js";

function* workerGetCarrierProfile(payLoad) {

  try {
    const response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.GET_LIST_OF_AGGREGATOR,
    );
    yield put(createActionOf(GET_LIST_OF_AGGREGATOR_SUCCESS, 1, response));
  } catch (error) {
    console.error(TAG, "Worker get approved bots failed: " + error.message);
    yield put(createActionOf(GET_LIST_OF_AGGREGATOR_FAILED, 2, error));
  }
}

export const watchGetListOfAggregator = takeLatest(
    GET_LIST_OF_AGGREGATOR,
  workerGetCarrierProfile
);
