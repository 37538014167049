import { LOADING } from "../../../constants/constant";
import {
  GET_INITIAL_DETAILS,
  GET_INITIAL_DETAILS_SUCCESS,
  GET_INITIAL_DETAILS_FAILED,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function getInitialDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INITIAL_DETAILS:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_INITIAL_DETAILS_SUCCESS:
      let userRes = Object.assign({}, state);
      try {
        console.log("came to the getInitialDetailsReducer   ", action.data);
        if (action.data.data.data.message === "request has been submitted") {
          userRes.status = action.data.data.status_message;
          userRes.isLoading = false;
          userRes.statusCode = action.data.data.status_code;

          userRes.isRequestSubmitted = true;
        } else {
          userRes.status = action.data.data.status_message;
          userRes.isLoading = false;
          userRes.statusCode = action.data.data.status_code;
          userRes.profile = action.data.data.data.profile;
          userRes.company = action.data.data.data.company;
          userRes.address = action.data.data.data.address;
          userRes.isRequestSubmitted = false;
        }

        //console.log("[parse]", userRes.entity);
        return userRes;
      } catch (e) {
        return console.error("error in success launch", e);
      }

    case GET_INITIAL_DETAILS_FAILED:
      return Object.assign({}, state, action.error);

    default:
      return state;
  }
}
