import { put, takeLatest, call } from "redux-saga/effects";
import { createActionOf } from "../actions/ActionCreator";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import {
  VERIFY_EMAIL_FAILED,
  VERIFY_EMAIL_REQUESTED,
  VERIFY_EMAIL_SUCCESS,
} from "../actions/ActionType";
import * as Api from "../../appApi/AppApi";

function* workerVerifyEmail(payLoad) {
  console.log("Worker: VerifyEmail => Pyload:: " + JSON.stringify(payLoad));
  try {
    const response = yield call(
      Api.makeUnsecuredPostRequest,
      API_ENDPOINT.VERIFY_EMAIL,
      payLoad.data
    );
    console.log(
      "Worker: VerifyEmail => Response:: " + JSON.stringify(response)
    );
    yield put(createActionOf(VERIFY_EMAIL_SUCCESS, 1, response));
  } catch (error) {
    console.error("Worker: VerifyEmail => Error:: " + error.message);
    yield put(createActionOf(VERIFY_EMAIL_FAILED, 2, error.message));
  }
}

export const watchVerifyEmail = takeLatest(
  VERIFY_EMAIL_REQUESTED,
  workerVerifyEmail
);
