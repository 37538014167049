import { LOADING } from "../../../../constants/constant";
import {
    APPROVE_OR_REJECT_ADMIN_BRANDS_REQUESTED,
    APPROVE_OR_REJECT_ADMIN_BRANDS_SUCCESS,
    APPROVE_OR_REJECT_ADMIN_BRANDS_FAILED,
    APPROVE_OR_REJECT_ADMIN_BRANDS_RESET
} from "../../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function AdminApproveOrRejectBrandReducer(state = initialState, action) {
  switch (action.type) {
    case APPROVE_OR_REJECT_ADMIN_BRANDS_REQUESTED: {
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;
    }

    case APPROVE_OR_REJECT_ADMIN_BRANDS_SUCCESS: {
      let userRes = Object.assign({}, state);
      console.log(
        "approveORRejectBrand Reducer admin=> Case => Success:" +
          JSON.stringify(action.data)
      );
      userRes.status = action.data.statusMessage;
      userRes.statusCode = action.data.status_code;
      userRes.statusMessage = action.data.status_message;
      userRes.isLoading = false;
      //   userRes.statusCode = action.data.status;
      userRes.statusSting = action.data.data;

      return userRes;
    }
    case APPROVE_OR_REJECT_ADMIN_BRANDS_FAILED: {
      console.error(
        "approveORRejectbrsnd Reducer => Case => Error:" +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);
    }

    case APPROVE_OR_REJECT_ADMIN_BRANDS_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
