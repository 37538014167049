import { put, takeLatest, call } from "redux-saga/effects";
import {
  BOTS_HISTORY_FOR_ADMIN_SUCCESS,
  BOTS_HISTORY_FOR_ADMIN,
  BOTS_HISTORY_FOR_ADMIN_FAILED,
} from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";

function* workerGetHistory(payLoad) {
  try {
    let apiEndpoint = API_ENDPOINT.BOTS_HISTORY_FOR_ADMIN;
    if (/admin/i.test(payLoad.data?.whoIsThis)) {
      apiEndpoint = API_ENDPOINT.ADMIN_BOTS_HISTORY;
    }
    const response = yield call(
      makeSecuredPostRequest,
      apiEndpoint,
      payLoad.data
    );
    yield put(createActionOf(BOTS_HISTORY_FOR_ADMIN_SUCCESS, 1, response));
  } catch (error) {
    console.error("Worker get bots failed: " + error.message);
    yield put(createActionOf(BOTS_HISTORY_FOR_ADMIN_FAILED, 2, error));
  }
}

export const watchBotHistory = takeLatest(
  BOTS_HISTORY_FOR_ADMIN,
  workerGetHistory
);
