import { call, put, takeLatest } from "redux-saga/effects"
import { makeSecuredPostRequest } from "../../../../appApi/AppApi"
import { createActionOf } from "../../../actions/ActionCreator"
import {
  ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION,
  ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_FAILED,
  ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_SUCCESS,
  ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION,
  ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_FAILED,
  ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_SUCCESS,
} from "../../../actions/ActionType"
import { API_ENDPOINT } from "../../../URLs/ApiEndpoints"

function* workerGetSubmittedBotsForVerification(payLoad) {
  console.log("🚀 ~ file: AdminVerificationSagas.js ~ line 15 ~ function*workerGetSubmittedBotsForVerification ~ payLoad", payLoad)
  let isVIAdmin = false;
  if(payLoad.data.isVIAdmin){
    isVIAdmin = true;
    delete payLoad.data["isVIAdmin"];
  }
  try {
    const response = yield call(
      makeSecuredPostRequest,
      isVIAdmin ? API_ENDPOINT.ADMIN_GET_ALL_BOTS_SUBMITTED_FOR_VERIFICATION_CARRIER : API_ENDPOINT.ADMIN_GET_ALL_BOTS_SUBMITTED_FOR_VERIFICATION 
      // API_ENDPOINT.ADMIN_GET_ALL_BOTS_SUBMITTED_FOR_VERIFICATION 

    )
    yield put(
      createActionOf(ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_SUCCESS, 1, response)
    )
  } catch (error) {
    yield put(
      createActionOf(ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_FAILED, 2, error)
    )
  }
}

function* workerApproveRejectBotForVerification(payLoad) {
  console.log("🚀 ~ file: AdminVerificationSagas.js ~ line 32 ~ function*workerApproveRejectBotForVerification ~ payLoad", payLoad)
  let isVIAdmin = false;
  if(payLoad.data.isVIAdmin){
    isVIAdmin = true;
    delete payLoad.data["isVIAdmin"];
  }
  try {
    const response = yield call(
      makeSecuredPostRequest,
      // API_ENDPOINT.ADMIN_APPROVE_REJECT_VERIFICATION,
      isVIAdmin ? API_ENDPOINT.ADMIN_APPROVE_REJECT_LISTING_CARRIER: API_ENDPOINT.ADMIN_APPROVE_REJECT_VERIFICATION,

      payLoad.data
    )
    yield put(
      createActionOf(
        ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_SUCCESS,
        1,
        response
      )
    )
  } catch (error) {
    yield put(
      createActionOf(ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_FAILED, 2, error)
    )
  }
}

export const watchGetSubmittedBotsForVerification = takeLatest(
  ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION,
  workerGetSubmittedBotsForVerification
)
export const watchApproveRejectBotForVerification = takeLatest(
  ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION,
  workerApproveRejectBotForVerification
)
