import { LOADING, STATUS_CODE } from "../../constants/constant";
import {
  GET_ALL_INDUSTRY_TYPES_FAILED,
  GET_ALL_INDUSTRY_TYPES_REQUESTED,
  GET_ALL_INDUSTRY_TYPES_SUCCESS,
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function getIndustryTypesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_INDUSTRY_TYPES_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      reqstState.statusCode = STATUS_CODE;
      return reqstState;

    case GET_ALL_INDUSTRY_TYPES_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.status = action.data.data.status_message;
      userRes.statusCode = action.data.data.status_code;
      userRes.isLoading = false;
      // userRes.statusCode = action.data.data.statusCode;

      userRes.industry_list = action.data.data;
      return userRes;

    case GET_ALL_INDUSTRY_TYPES_FAILED:
      return Object.assign({}, state, action.error);

    default:
      return state;
  }
}
