import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import * as Api from "../../appApi/AppApi";

const TAG = "brandList.js";

function* workerGetBrandList(payload) {
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.GET_BRANDS,
      {}
    );
    console.log(TAG, "worker brandList: " + JSON.stringify(response));
    yield put(memberAction.brandListuccess(response));
  } catch (error) {
    console.log(TAG, "Worker brandList: " + error.message);
    yield put(memberAction.brandListFailed(error.message));
  }
}

export const watchGetBrandsSaga = takeLatest(
  memberType.GET_BRANDS_REQUESTED,
  workerGetBrandList
);
