import { LOADING, STATUS_CODE } from "../../constants/constant";
import {
  PARTNER_BRANDS_LIST_REQUESTED,
  PARTNER_BRANDS_LIST_SUCCESS,
  PARTNER_BRANDS_LIST_FAILED,
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function getPartnersBrandsListReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case PARTNER_BRANDS_LIST_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      reqstState.statusCode = STATUS_CODE;
      return reqstState;

    case PARTNER_BRANDS_LIST_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log("came to the getPartnersBrandsListReducer success", action);
      userRes.status = action.data.data.status_message;
      userRes.statusCode = action.data.data.status_code;
      userRes.isLoading = false;
      // userRes.statusCode = action.data.data.statusCode;

      userRes.submitted_brands = action.data.data.submmited_brands;
      userRes.brands_for_store = action.data.data.brands_for_store;
      userRes.verified_brands = action.data.data.verified_brands;
      return userRes;

    case PARTNER_BRANDS_LIST_FAILED:
      console.error("Error while getting partners brand list", action.error);
      return Object.assign({}, state, action.error);

    default:
      return state;
  }
}
