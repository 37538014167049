import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

const TAG = "carrierGetApprovedBrands.js";

function* workerCarrierGetApprovedBrands(payLoad) {
  console.log(TAG, "get Approve brands : " + JSON.stringify(payLoad));
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.GET_APPROVED_BRANDS , payLoad.data
    );
    yield put(memberAction.carrierGetApprovedBrandsSuccess(response));
  } catch (error) {
    console.error(TAG, "Worker get Approved brands failed: " + error.message);
     yield put(memberAction.carrierGetRejectedBrandsFailed(error.message));
    // throw new Error("Error: inside saga", JSON.stringify(error));
  }
}

export const watchCarrierGetApprovedBrands = takeLatest(
  memberType.CARRIER_GET_APPROVED_BRANDS_REQESTED,
  workerCarrierGetApprovedBrands
);
