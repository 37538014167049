import { put, takeLatest, call } from "redux-saga/effects";
import {createActionOf} from "../../actions/ActionCreator";
import {
    APPROVE_OR_REJECT_CARRIER_BOTS,
    APPROVE_OR_REJECT_CARRIER_BOTS_SUCCESS,
    APPROVE_OR_REJECT_CARRIER_BOTS_FAILED,
    APPROVE_OR_REJECT_CARRIER_BOTS_RESET
} from "../../actions/ActionType";
import {makeSecuredPostRequest} from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";

const TAG = "mybot.js";

function* workerApproveOrRejectBots(payLoad) {
  console.log(
    TAG,
    "workerApproveOrRejectBots : " + JSON.stringify(payLoad)
  );
  console.log(
    TAG,
    "worker workerApproveOrRejectBots : " + JSON.stringify(payLoad.data)
  );
  try {
    const response = yield call(
        makeSecuredPostRequest,
       API_ENDPOINT.APPROVE_OR_REJECT_CARRIER_BOTS,
       payLoad.data
    );
    console.log(
      TAG,
      "worker workerApproveOrRejectBots Response: " + JSON.stringify(response)
    );
    yield put(createActionOf(APPROVE_OR_REJECT_CARRIER_BOTS_SUCCESS, 1, response));
} catch (error) {
  console.error(TAG, "Worker get country failed: " + error.message);
  yield put(createActionOf(APPROVE_OR_REJECT_CARRIER_BOTS_FAILED, 2, error));
}
}

export const watchApproveOrRejectBots = takeLatest(
    APPROVE_OR_REJECT_CARRIER_BOTS,
    workerApproveOrRejectBots
);
