import { LOADING } from "../../../constants/constant";
import {
    APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN,
    APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN_SUCCESS,
    APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN_FAILED,
    APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN_RESET
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function approveORRejectDotgoAggregatorReducerAdmin(state = initialState, action) {
  switch (action.type) {
    case APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN: {
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;
    }

    case APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN_SUCCESS: {
      let userRes = Object.assign({}, state);
      console.log(
        "approveORRejectBotReducer => Case => Success: while approving or rejecting bots list: " +
          JSON.stringify(action.data)
      );
      userRes.status = action.data.statusMessage;
      userRes.statusCode = action.data.status_code;
      userRes.statusMessage = action.data.status_message;
      userRes.isLoading = false;
      //   userRes.statusCode = action.data.status;
      userRes.statusSting = action.data.data;

      return userRes;
    }
    case APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN_FAILED: {
      console.error(
        "approveORRejectBotReducer => Case => Error: while approving or rejecting bots list: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);
    }

    case APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
