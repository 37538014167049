import { SEND_BOT_REVIEWS, SEND_BOT_REVIEWS_SUCCESS, SEND_BOT_REVIEWS_FAILED } from "../actions/ActionType";

const initialState = {
  data: { status: "INIT", isLoading: true, error: false },
};
const submitBotReviewsReducer = (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case SEND_BOT_REVIEWS:
      newState = { ...state, data: { isLoading: true, error: false } };
      return newState;
    case SEND_BOT_REVIEWS_SUCCESS:
      console.log("In the bot submit reviews reducer success", action.data.data);
      let rqstState = {
        data: {
          ...action.data,
          error: false,
          isLoading: false,
        },
      };
      return rqstState;
    case SEND_BOT_REVIEWS_FAILED:
      console.log("In the bot submit reviews reducer Fail", action.data.data);
      return {
        data: { ...action.data, error: true, isLoading: false },
      };
    default:
      return state;
  }
};
export default submitBotReviewsReducer;
