import { LOADING } from "../../../constants/constant";
import {
  CARRIER_GET_APPROVED_BRANDS_FAILED,
  CARRIER_GET_APPROVED_BRANDS_RESET,
  CARRIER_GET_APPROVED_BRANDS_SUCCESS,
  CARRIER_GET_APPROVED_BRANDS_REQESTED
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: false,
};

export default function CarrierGetApprovedBrandsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case CARRIER_GET_APPROVED_BRANDS_REQESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case CARRIER_GET_APPROVED_BRANDS_SUCCESS:
        let userRes = Object.assign({}, state)
        userRes.status = action.data.status_message;
        userRes.isLoading = false;
        userRes.statusCode = action.data.status_code;
        // userRes.message = action.data.status_message;
        userRes.data = action.data;
        return userRes;
    case CARRIER_GET_APPROVED_BRANDS_FAILED:
      console.error(
        "carrierget approved brandsReducer => Case => Error: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);

    case CARRIER_GET_APPROVED_BRANDS_RESET:
      return initialState;

    default:
      return state;
  }
}
