import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_DASHBOARD_ANALYTICS,
  GET_DASHBOARD_ANALYTICS_SUCCESS,
  GET_DASHBOARD_ANALYTICS_FAILED,
} from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";

function* workerDashboardAnalytics(payLoad) {
  let response = {};
  try {
    if (payLoad.data.whoIsThis === "super-admin") {
      response = yield call(
        makeSecuredPostRequest,
        API_ENDPOINT.DOTGO_ANALYTICS_API,
        payLoad.data
      );
    } 
    else if (payLoad.data.whoIsThis === "vi-admin") {
      response = yield call(
        makeSecuredPostRequest,
        API_ENDPOINT.GET_DASHBOARD_CARRIER_ANALYTICS_API,
        payLoad.data
      );
    } 
    else {
      response = yield call(
        makeSecuredPostRequest,
        API_ENDPOINT.GET_DASHBOARD_ANALYTICS_API,
        payLoad.data
      );
    }
    yield put(createActionOf(GET_DASHBOARD_ANALYTICS_SUCCESS, 1, response));
  } catch (error) {
    console.error("Worker: dashboard summary failed: " + error.message);
    yield put(createActionOf(GET_DASHBOARD_ANALYTICS_FAILED, 2, error));
  }
}

export const watchDashboardAnalytics = takeLatest(
  GET_DASHBOARD_ANALYTICS,
  workerDashboardAnalytics
);
