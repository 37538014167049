import { LOADING } from "../../../constants/constant";
import {
    GET_BOT_CREATION_PREVIEW_REQUESTED,
    GET_BOT_CREATION_PREVIEW_SUCCESS,
    GET_BOT_CREATION_PREVIEW_FAILED,
    GET_BOT_CREATION_PREVIEW_RESET
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function getBotCreationPreviewReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BOT_CREATION_PREVIEW_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_BOT_CREATION_PREVIEW_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.status = action.data.status_message;
      userRes.statusCode = action.data.status_code;
      userRes.isLoading = false;
      // userRes.statusCode = action.data.statusCode;
      userRes.previewData = action.data.data;
      
      return userRes;

    case GET_BOT_CREATION_PREVIEW_FAILED:
      console.error("Error while getting Bot Creation preview", action.error);
      return Object.assign({}, state, action.error);

      case GET_BOT_CREATION_PREVIEW_RESET: {
        return initialState;
      }
    default:
      return state;
  }
}
