import { LOADING } from "../../../constants/constant"
import {
  BOT_CONSOLE_INFO,
  BOT_CONSOLE_INFO_SUCCESS,
  BOT_CONSOLE_INFO_FAILED,
} from "../../actions/ActionType"

const initialState = {
  status: "INIT",
  isLoading: true,
}

export default function botConsole(state = initialState, action) {
  switch (action.type) {
    case BOT_CONSOLE_INFO:
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      return reqstState

    case BOT_CONSOLE_INFO_SUCCESS:
      let userRes = Object.assign({}, state)
      userRes.status = action.data.status_message
      userRes.isLoading = false
      userRes.statusCode = action.data.status_code
      userRes.botDetails = action.data.data
      return userRes

    case BOT_CONSOLE_INFO_FAILED:
      return Object.assign({}, state, action.error)

    default:
      return state
  }
}
