import { useState } from "react";
import { useForm } from "react-hook-form";
import { regexPasswordPattern } from "../../../utils/Regex";
import FormError from "../../Errors/FormError";
import { STATUS_CODE_SUCCESS } from "../../../constants/constant";
import { Loader } from "semantic-ui-react";

const SetPasswordPage = ({ onSetPassword, ...rest }) => {
  const { register, errors, handleSubmit, watch } = useForm();

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [step, setStep] = useState(1);

  const toggleHandler = () => {
    setShow(!show);
  };

  const toggleHandler2 = () => {
    setShow2(!show2);
  };

  const passwdMinLength = 8;
  const showMatchError = () => {
    return (
      (watch("new_password") ?? "").length >= passwdMinLength &&
      watch("confirm_password")
    );
  };

  if (
    rest.statusCode &&
    rest.statusCode === STATUS_CODE_SUCCESS &&
    step === 2
  ) {
    setStep(step + 1);
  }

  const goToNextPhase = () => {
    rest.domHistory.push({
      pathname: "/developer/mobile-verification",
      state: { status: "ok", isAuthorized: true },
    });
  };

  return (
    <section className="hero-section body-margin-top">
      <div className="container">
        <div className="row align-hz-vr-center">
          <div className="col-md-7 col-lg-5 vi-yl-card mobile-card password-card-media-query">
            {step === 1 ? (
              <>
                <br />
                <h2>Email id successfully verified!</h2>
                <img
                  width={90}
                  src={"images/vi_icon_tick_red.svg"}
                  alt="Successfully Verified!"
                />
                <p>
                  <a
                    className="link-crimson"
                    style={{ cursor: "pointer" }}
                    onClick={() => setStep(step + 1)}
                  >
                    continue to set password
                  </a>
                </p>
                <br />
              </>
            ) : step === 2 ? (
              <>
                <h2>Set Account Password</h2>
                <p
                  style={{margin: "20px 65px", lineHeight:"21px"}}
                >
                  Your password must be a minimum of eight characters<br />
                  long and contain at least one uppercase and one<br />
                  lowercase letter (A, z), one numeric character (0-9),<br />
                  and one special character (such as . $ @ ! % * ? & # ^ &lt; &gt; ()~`+_-).
                </p>
                <form
                  className="mobile-form"
                  onSubmit={handleSubmit(onSetPassword)}
                >
                  <div
                    className="change-mobile"
                    style={{ marginLeft: "80px", marginRight: "80px" }}
                  >
                    <div className="row">
                      <div className="col">
                        <label htmlFor="">
                          <i className="fa fa-lock" aria-hidden="true" />
                          Set your Password
                        </label>
                        <div style={{ position: "relative" }}>
                          <input
                            ref={register({
                              required: true,
                              minLength: 8,
                              pattern: regexPasswordPattern,
                            })}
                            onPaste={(event) => event.preventDefault()}
                            autoComplete="off"
                            type={show ? "text" : "password"}
                            // type={isRevealPassword1 ? "password" : "text"}
                            name="new_password"
                            className="form-control input-lg"
                            placeholder="New Password"
                          />
                          <span onClick={toggleHandler}>
                            {show === false ? (
                              <i
                                className="fa fa-eye password-icon"
                                style={{
                                  position: "absolute",
                                  left: "auto",
                                  right: "5%",
                                  top: "39%",
                                  text_indent: "32px",
                                  paddingTop: "-50px",
                                }}
                              ></i>
                            ) : (
                              <i
                                className="fa fa-eye-slash password-icon"
                                style={{
                                  position: "absolute",
                                  left: "auto",
                                  right: "5%",
                                  top: "39%",
                                  text_indent: "32px",
                                  paddingTop: "-50px",
                                }}
                              ></i>
                            )}
                          </span>
                        </div>
                        {errors.new_password &&
                          errors.new_password.type === "required" && (
                            <p
                              id="errorMsg"
                              className="error-highlight text-center mt-10"
                            >
                              Field cannot be empty
                            </p>
                          )}
                        {errors.new_password &&
                          errors.new_password.type === "pattern" && (
                            <p
                              id="errorMsg"
                              className="error-highlight text-center mt-10"
                            >
                              ⚠ Password length should be minimum 8 characters and
                            should contain atleast one uppercase and one lowercase alphabet,
                            special character [ such as . $ @ ! % * ? & # ^ &lt; &gt; ()~`+_- ] and digit
                            </p>
                          )}

                        {errors.new_password &&
                          errors.new_password.type === "minLength" && (
                            <p
                              id="errorMsg"
                              className="error-highlight text-center mt-10"
                            >
                              ⚠ Password length should be minimum 8 characters and
                            should contain atleast one uppercase and one lowercase alphabet,
                            special character [ such as . $ @ ! % * ? & # ^ &lt; &gt; ()~`+_- ] and digit
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="row margin-top-30">
                      <div className="col">
                        <label htmlFor="">
                          <i className="fa fa-lock" aria-hidden="true" />
                          Confirm your Password
                        </label>
                        <div style={{ position: "relative" }}>
                          <input
                            ref={register({
                              required: true,
                              minLength: 8,
                              pattern: regexPasswordPattern,
                              validate: {
                                equalToPassword: (value) =>
                                  value === watch("new_password"),
                              },
                            })}
                            onPaste={(event) => event.preventDefault()}
                            autoComplete="off"
                            type={show2 ? "text" : "password"}
                            // type={isRevealPassword2 ? "password" :"text" }
                            name="confirm_password"
                            className="form-control input-lg"
                            placeholder="Confirm Password"
                          />

                          <span onClick={toggleHandler2}>
                            {show2 === false ? (
                              <i
                                className="fa fa-eye password-icon"
                                style={{
                                  position: "absolute",
                                  left: "auto",
                                  right: "5%",
                                  top: "39%",
                                  text_indent: "32px",
                                  paddingTop: "-50px",
                                }}
                              ></i>
                            ) : (
                              <i
                                className="fa fa-eye-slash password-icon"
                                style={{
                                  position: "absolute",
                                  left: "auto",
                                  right: "5%",
                                  top: "39%",
                                  text_indent: "32px",
                                  paddingTop: "-50px",
                                }}
                              ></i>
                            )}
                          </span>
                        </div>
                        {/* <div className="info form-info margin-top-5">
                          <p>
                            <i
                              className="fa fa-exclamation-circle font-size-20"
                              aria-hidden="true"
                            />
                            &nbsp;&nbsp;⚠ Password length should be minimum 8 characters and
                            should contain atleast one uppercase and one lowercase alphabet,
                            special character [ such as . $ @ ! % * ? & # ^ &lt; &gt; ()~`+_- ] and digit
                          </p>
                        </div> */}
                        {errors.confirm_password &&
                          errors.confirm_password.type === "required" && (
                            <p
                              id="errorMsg"
                              className="error-highlight text-center mt-10"
                            >
                              Field cannot be empty
                            </p>
                          )}
                        {errors.confirm_password &&
                          errors.confirm_password.type === "pattern" && (
                            <p
                              id="errorMsg"
                              className="error-highlight text-center mt-10"
                            >
                             ⚠ Password length should be minimum 8 characters and
                            should contain atleast one uppercase and one lowercase alphabet,
                            special character [ such as . $ @ ! % * ? & # ^ &lt; &gt; ()~`+_- ] and digit
                            </p>
                          )}
                        {errors.confirm_password &&
                          errors.confirm_password.type === "minLength" && (
                            <p
                              id="errorMsg"
                              className="error-highlight text-center mt-10"
                            >
                              ⚠ Password length should be minimum 8 characters and
                            should contain atleast one uppercase and one lowercase alphabet,
                            special character [ such as . $ @ ! % * ? & # ^ &lt; &gt; ()~`+_- ] and digit
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="input-group-btns">
                    <button
                      ref={rest.count}
                      className=" pri-btn"
                      type="submit"
                      style={{ border: "none" }}
                      // disabled={rest.isLoading}
                    >
                      confirm
                      <span hidden={!rest.isLoading}>&nbsp;</span>
                      <Loader
                        inverted
                        active={rest.isLoading}
                        inline
                        size="tiny"
                      />
                    </button>
                  </div>
                  <div className="text-center error-highlight">
                    {watch("new_password") === watch("confirm_password") &&
                      rest.error && (
                        <FormError errorMessage={rest.errorMessage} />
                      )}
                    {errors.confirm_password &&
                      errors.confirm_password.type === "equalToPassword" && (
                        <p
                          id="errorMsg"
                          className="error-highlight text-center mt-10"
                        >
                          Password doesn't match
                        </p>
                      )}
                  </div>
                </form>
              </>
            ) : (
              <>
                <br />
                <h2>Password set successfully!</h2>
                    <img
                      width={90}
                  src={"images/vi_icon_tick_red.svg"}
                  alt="Successfully Verified!"
                />
                <p>
                  <a
                    className="link-crimson"
                    style={{ cursor: "pointer" }}
                    onClick={goToNextPhase}
                  >
                    continue to sign in
                  </a>
                </p>
                <br />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default SetPasswordPage;
