import React from "react";
import MarginOf from "../../Layout/MarginOf";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";

const SignupSuccessPage = ({ onContinue, ...rest }) => {
  const { register, handleSubmit } = useForm();

  return (
    <React.Fragment>
      <section
        className="congrats_complete_your_details"
        style={{ margin: "120px 0 0 0" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 text-center">
              <img src="images/icon_congrats.svg" width={120} alt="" />
              <h1>Congrats!</h1>
              <p className="text-16">
                You have successfully created your developer account on{" "}
                <a>developers.dotgo.com</a>
              </p>
              <p className="text-16">
                Remember, your <a>developers.dotgo.com</a> account is different
                from your account on bot store, and allows you to do much more !
              </p>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-12 col-md-12"
              style={{ margin: "20px 0 0 0" }}
            >
              <hr />
            </div>
          </div>
          <div className="row">
            <form onSubmit={handleSubmit(onContinue)}>
              <div className="col-lg-12 col-md-12 text-center">
                <h2>Complete your details</h2>
              </div>
              <div className="col-lg-4 col-md-4 col-lg-offset-2 col-md-offset-2">
                <div className="form-group form-input">
                  <label>
                    <img
                      src="images/icon_name.svg"
                      width={20}
                      alt="First Name"
                    />{" "}
                    First Name
                  </label>
                  <br />
                  <input
                    ref={register}
                    type="text"
                    className="form-control"
                    name="first_name"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group form-input">
                  <label>
                    <img src="images/icon_name.svg" width={20} alt="Name" />{" "}
                    Last Name
                  </label>
                  <input
                    ref={register}
                    type="text"
                    className="form-control"
                    name="last_name"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-lg-offset-2 col-md-offset-2">
                <div className="form-group form-input">
                  <label>
                    <img
                      src="images/icon_contact_number.svg"
                      width={20}
                      alt="Contact Number"
                    />{" "}
                    Contact Number*
                  </label>
                  <input
                    ref={register({ required: true })}
                    id="phone"
                    type="tel"
                    className="form-control"
                    name="contact_number"
                  />
                  <span id="valid-msg" className="hide">
                    Valid
                  </span>
                  <span id="error-msg" className="hide">
                    Invalid number
                  </span>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 text-center">
                <div className="form-group margin-top-25 padding-top-15 padding-bottom-15">
                  <button className="btn btn-red-round">Continue</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <MarginOf margin="100px" />
    </React.Fragment>
  );
};
export default withRouter(SignupSuccessPage);
