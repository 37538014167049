import { LOADING, STATUS_CODE_SUCCESS } from "../../../constants/constant";
import {
    GET_PRICE_PAGE_DETAILS,
    GET_PRICE_PAGE_DETAILS_SUCCESS,
    GET_PRICE_PAGE_DETAILS_FAILED,
    GET_PRICE_PAGE_DETAILS_RESET,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function getPricePageDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRICE_PAGE_DETAILS:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_PRICE_PAGE_DETAILS_SUCCESS:
      let userRes = Object.assign({}, state);

      console.log("came to the getPricePageDetails   ", action);
      userRes.status = action.data.status_message;
      userRes.isLoading = false;
      userRes.statusCode = action.data.status_code;

      if (userRes.statusCode !== STATUS_CODE_SUCCESS) {
        userRes.error = true;
        userRes.errorMessage = action.data.message;
        return userRes;
      }
      userRes.isError = false;
      userRes.priceList = action.data.data;
      return userRes;

    case GET_PRICE_PAGE_DETAILS_FAILED:
      return Object.assign({}, state, action.error);
    case GET_PRICE_PAGE_DETAILS_RESET:
      return initialState;

    default:
      return state;
  }
}
