import axios from "axios";
import { REACT_APP_AXIOS_TIMEOUT } from "../constants/stringConstants";

/**
 * Creates axios instance
 * @link https://github.com/axios/axios
 */
let instance;
if (!instance) {
  instance = axios.create({
    timeout: REACT_APP_AXIOS_TIMEOUT,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
}

/**
 * Request Interceptor:
 * It intercept the request, used for making some change in request before
 * it reaches to server.
 */
instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/**
 * Response Interceptor:
 * It intercept the response, used for making some change in response before
 * it reaches to component who requested it
 */
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default class Service {
  /**
   * Get Request
   * @param {url} url url for request
   */
  static getCall(url) {
    return new Promise(function (resolve, reject) {
      instance
        .get(url)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  /**
   * Posts Request
   * @param {url} url url for posting request
   * @param {data} data data object to post to server
   */
  static postCall(url, data) {
    return new Promise(function (resolve, reject) {
      instance
        .post(url, data)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  static putCall(url, data) {
    return new Promise(function (resolve, reject) {
      instance
        .put(url, data)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
}
