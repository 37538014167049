import React from "react";
import { useEffect } from "react";
import { onBackByOne } from "../../utils/otherUtils";

/**
 * @author Sachin S Kothiwale<skothiwale@dotgo.com>
 * @function CountriesAndCarrier
 **/

export const CountriesAndCarrier = (props) => {
  useEffect(() => {
    console.log("response in carrier:", props?.carrierList);
    console.log("response in countries :", props?.countryList);
  }, [props]);
  return (
    <>
      <div>
        <section className="rcs-body-margin-top">
          <div className="container rcs-body-min-height">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                <h2>List of countries and carriers</h2>
              </div>
              <div className="col-lg-12 col-md-12 margin-top-10 margin-bottom-15">
                <hr />
              </div>
            </div>
            <form>
              <div className="row">
                <div className="col-lg-12 col-md-12  margin-bottom-15">
                  <div className="table-responsive carrier-logo-name-table">
                    <table width="100%" style={{ minWidth: "1050px" }}>
                      <thead>
                        <tr>
                          <th width="30%">Countries</th>
                          <th width="70%">Carriers</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props?.carrierList &&
                          props?.countryList.map((value) => {
                            return (
                              <tr>
                                <td>
                                  <img
                                    src={
                                      value.logo
                                        ? value.logo
                                        : "http://devrcs.dotgo.com/rcsbotdirectory/image/bot/916/logoimage/1/Default.png"
                                    }
                                    alt="img"
                                  />{" "}
                                  {value.country_name}
                                </td>
                                <td>
                                {props?.carrierList.map((carrierValue) => {
                                  return (
                                    carrierValue.country_id ===
                                      value.country_id && (
                                      
                                        <div className="carrier-logo-name">
                                          <img
                                            src={
                                              carrierValue.logo
                                                ? carrierValue.logo
                                                : "http://devrcs.dotgo.com/rcsbotdirectory/image/bot/916/logoimage/1/Default.png"
                                            }
                                            alt="img"
                                            width={20}
                                            style={{ borderRadius: "50%" }}
                                          />
                                          &nbsp;&nbsp;
                                          {carrierValue.carrier_name}
                                        </div>
                                    )
                                  );
                                })}
                                </td>
                                {/* <td>
                                  <div className="carrier-logo-name">
                                    <img
                                      src="images/logo_carriers_bouyguesTelecom.svg"
                                      alt="Bouygues Telecom"
                                      width={20}
                                    />{" "}
                                    Bouygues Telecom
                                  </div>
                                  <div className="carrier-logo-name">
                                    <img
                                      src="images/logo_carriers_freeMobile.svg"
                                      alt="Free Mobile"
                                      width={20}
                                    />{" "}
                                    Free Mobile
                                  </div>
                                  <div className="carrier-logo-name">
                                    <img
                                      src="images/logo_carriers_orange.svg"
                                      alt="Orange"
                                      width={20}
                                    />{" "}
                                    Orange
                                  </div>{" "}
                                </td> */}
                              </tr>
                            );
                          })}
                        {/* <tr>
                    <td><img src="images/flag_france.svg" alt="France" /> France</td>
                    <td>
                      <div className="carrier-logo-name">
                        <img src="images/logo_carriers_bouyguesTelecom.svg" alt="Bouygues Telecom" width={20} /> Bouygues Telecom
                      </div> 
                      <div className="carrier-logo-name">
                        <img src="images/logo_carriers_freeMobile.svg" alt="Free Mobile" width={20} /> Free Mobile
                      </div> 
                      <div className="carrier-logo-name">
                        <img src="images/logo_carriers_orange.svg" alt="Orange" width={20} /> Orange
                      </div> </td>
                  </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 margin-bottom-35 margin-top-35 text-center">
                  <button
                    className="btn btn-red-round"
                    type="button"
                    onClick={onBackByOne}
                  >
                    Back
                  </button>
                </div>
              </div> */}
            </form>
          </div>
        </section>
      </div>
    </>
  );
};
