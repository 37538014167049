import React from 'react'
import { onBackByOne } from '../../utils/otherUtils'

/**
* @author
* @function 
**/

export const ComingSoonVI = (props) => {
    const onBackByOne = () => {
        window.location.href="/"
      };
  return(
    <>
  <section className="hero-section align-center vh-min">
    <div className="container">
      <div className="row">
        <div className="col-md-6 hero-main-text1">
          <img src="images/vi_under_construction.svg" alt="" width="80%" />
        </div>
        <div className="col-lg-6 col-md-6 under-construction-text-pad padding-bottom-35">
          <h3 className="font-size-28">sorry!<br />
            <span className="font-color-purple-dark">this page is under construction</span></h3>
          <p className="font-size-18 font-color-gray margin-top-20">we are still working on this page...</p>
          <button className="btn btn-red-round font-size-16 margin-top-40"
          type='button'
          onClick={onBackByOne}
          >go back</button>
        </div>
      </div>
    </div>
  </section>
    </>
   )
  }
