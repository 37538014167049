import { LOADING, STATUS_CODE } from "../../../constants/constant";
import {
    TEST_SUMMARY,
    TEST_SUMMARY_FAILED,
    TEST_SUMMARY_RESET,
    TEST_SUMMARY_SUCCESS
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function getTestTemplateSummaryReducer(state = initialState, action) {
  switch (action.type) {
    case TEST_SUMMARY:
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      reqstState.statusCode = STATUS_CODE
      return reqstState

    case TEST_SUMMARY_SUCCESS:
      let userRes = Object.assign({}, state)
      //userRes.status = action.data.status;
      userRes.isLoading = false
      userRes.status = action.data.data.status_message
      userRes.statusCode = action.data.data.status_code
      userRes.message = action.data.data.message
      userRes.summary = action.data.data.data
      return userRes

    case TEST_SUMMARY_FAILED:
      console.error(
        "getTemplateDetailsReducer => Error: while getting template details: " +
          JSON.stringify(action.data.error)
      )
      return Object.assign({}, state, action.data.error)

    case TEST_SUMMARY_RESET:
      return initialState

    default:
      return state
  }
}
