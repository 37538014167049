
const RBMPrivacyPolicy = (props) => {
  return (

    <div>
  <section className="body-margin-top disabled">
    <div className="container">
      <div className="row">
        <div className="col-lg-10 col-md-10 col-lg-offset-1 col-md-offset-1">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h2 className="font-size-24 font-weight-600">Privacy Policy</h2>
            </div>
            <div className="col-lg-12 col-md-12">
              <h3 className="font-size-18 font-weight-600">Scope</h3>
              <p className="font-weight-400 font-size-14 margin-top-15">This document details the Privacy Policy (Policy) applicable to Vodafone Idea Business Services Ltd (herein will be called ‘ViBSL’ or ‘Company’), its third-party service providers (partners and vendors) and customers.</p>
              <p className="font-weight-600 font-size-14 margin-top-25">Detailed Section</p>
              <p className="margin-top-10">PLEASE READ THIS POLICY CAREFULLY. “YOU” AND “YOUR” SHALL REFER TO THE CUSTOMER LIMITED TO CORPORATE ENTITY, BRANDS, FIRM OR USER OF VODAFONE IDEA BUSINESS SERVICES LIMITED’s PRODUCTS AND SERVICES, “WE,” “OUR” OR “US” SHALL REFER TO Vodafone Idea Business Services LIMITED (hereinafter ‘ViBSL’) AND ITS AUTHORISED SERVICE PROVIDERS, PROVIDING OR OFFERING SERVICES TO YOU.</p>
              <p className="margin-top-20">Vodafone Idea Business Services limited (ViBSL) works into providing technology-based connectivity services to its customers in integration with its authorized service providers.</p>
              <p className="margin-top-20">Our Privacy Policy (Policy) is designed and developed to understand, uphold and protect the privacy and security of Your personal information.</p>
              <p className="margin-top-20">This Policy explains the collection, use, protection, disclosure, sharing and transfer, if any, of “personal information” by Us. Your registration or usage of Vodafone Idea Business Services Limited’s services implies your express consent and agreement to this Policy and ViBSL will no longer be able to provide you services, if this consent is withdrawn.<br />
                We reserve our right to amend this Policy from time to time based on the changes in business, legal, regulatory requirements &amp; applicable laws. You are encouraged to periodically visit this page to review the Policy.</p>
              <p className="margin-top-20">This Policy is provided for Your information and is not intended to limit or exclude Your rights under laws and regulations applicable in India.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="privacy-policy-inner-box">
                <p className="font-weight-500">Collection of Personal Information</p>
                The Information Technology Act 2000, its subsequent amendments and The Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 includes extensive definitions for Personal Information and Sensitive Personal Information. For the purposes of this Policy, Personal Information (PI) means any information relating to a natural person   that could be used directly or indirectly in combination with other information available with Us and is capable of identifying a natural individual.
              </div>
              <p className="font-weight-600 font-size-14 margin-top-35">Detailed Section</p>
              <h2 className="font-size-18 font-weight-600 margin-top-20">1. Collection of Personal Information</h2>
              <ol type="a">
                <li className="margin-top-20">The Information Technology Act 2000, its subsequent amendments, and The Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 includes extensive definitions for Personal Information and Sensitive Personal Information. For the purposes of this Policy, Personal Information (PI) means any&nbsp; information relating to a natural person&nbsp; that could be used directly or indirectly in combination&nbsp; with other information available with Us and is capable of identifying&nbsp; a natural individual&nbsp; and Sensitive Personal Data or Information which may reveal, or be related to or constitute official identifier (example Proof of identity submitted at the time of activation of services),&nbsp; passwords (to help you securely access your own accounts only ), etc.</li>
                <li className="margin-top-20">Personal Information (PI) does not include information that is anonymous, aggregated or is no longer identifiable to a natural person and such information is classified as Non-Personal Information.</li>
                <li className="margin-top-20">We and our authorized Service providers (Our partners, vendors and affiliates) may collect PI  pertaining to:
                  <ol type="i">
                    <li className="margin-top-20">Entity’s name, e-mail address, identification numbers, and other information if You register with us.&nbsp; If You correspond with us by e-mail, we may retain the content of Your e-mail, Your e-mail address and our responses. Any information used for creating an account for You shall be construed as Your Information. Also, your customer's Personal Data or any other information shared by you including phone number (MSISDN) required in order to provide the services as per the contract.</li>
                    <li className="margin-top-20">Your use of Our systems, products and services e.g. message history, including phone numbers.</li>
                    <li className="margin-top-20">Your communication history with Us for customer support e.g.&nbsp; email or letter etc.</li>
                  </ol>
                </li>
              </ol>
              <div className="privacy-policy-inner-box">
                <p className="font-weight-500">Use of Personal Information </p>
                The information that we collect from you is held and used in accordance with applicable laws and regulations in India.&nbsp; It may be used to verify your identity before providing services, contact you/respond to you regarding matters relevant to the underlying services, generate bills and statements of use etc.
              </div>
              <h2 className="font-size-18 font-weight-600 margin-top-35">2. Use of Personal Information</h2>
              <ol type="a">
                <li className="margin-top-20">The information that we collect from you will be processed only for the purpose defined under the contract and will be held and used in accordance with applicable laws and regulations in India.&nbsp; It may be used to:
                  <ol type="i">
                    <li className="margin-top-20">Verify your identity before providing services</li>
                    <li className="margin-top-20">contact you/respond to you regarding matters relevant to the underlying services</li>
                    <li className="margin-top-20">generate bills and statements of use</li>
                    <li className="margin-top-20">to send periodic e-mails to respond to your inquiries, questions, and/or other requests and keep you informed about our latest products, services and offers&nbsp; and that of our carefully selected third parties.</li>
                    <li className="margin-top-20">to respond to services or product related queries</li>
                    <li className="margin-top-20">Carry out any activity in connection with a legal, governmental or regulatory requirement on us or in connection with legal proceedings, crime or fraud prevention, detection or prosecution;</li>
                    <li className="margin-top-20">to action out legal, governmental or regulatory requirement</li>
                    <li className="margin-top-20">Other usages that you may have expressly consent to</li>
                  </ol>
                </li>
              </ol>
              <p className="font-weight-600 margin-top-25">Use of Non-personal Information</p>
              <p>Non-personal information can be used to deliver services based on your interests including verifying your identity as an organization, for analyzing different trends and determining the performance of these services.</p>
              <div className="privacy-policy-inner-box">
                <p className="font-weight-500">How long do we store Your data?</p>
                <p>We may retain Your information for so long as we are obligated to as per the legal, regulatory directions for the time being in force.</p>
                If there is no legal requirement, we may only store Your information for as long as we have the justified business requirement and we need it for providing services to you.
              </div>
              <p className="font-weight-600 font-size-14 margin-top-30">Detailed Section</p>
              <h2 className="font-size-18 font-weight-600 margin-top-20">3. How long do we store Your data?</h2>
              <ol type="a">
                <li className="margin-top-20">We may retain Your information for so long as We are obligated to as per the legal, regulatory directions for the time being in force.</li>
                <li className="margin-top-20">If there is no legal requirement, we may only store Your information for as long as we have the justified business requirement and we need it for providing services to you.</li>
                <li className="margin-top-20">After that, we will destroy it by making it unreadable or undecipherable with appropriate regard to privacy and security.</li>
              </ol>
              <div className="privacy-policy-inner-box">
                <p className="font-weight-500">How do We Protect Your Personal Information?</p>
                We will strive on best effort basis to ensure the security of Your information. Access to Your Personal Information within ViBSL is restricted and shared with ViBSL personnel on a need-to-know basis. All ViBSL personnel are obligated to protect confidentiality and ensure security.
              </div>
              <p className="font-weight-600 font-size-14 margin-top-35">Detailed Section</p>
              <h2 className="font-size-18 font-weight-600 margin-top-20">4. How do We Protect Your Personal Information?</h2>
              <ol type="a">
                <li className="margin-top-20">We will strive on best effort basis to ensure the security of Your information. Access to Your Personal Information, within ViBSL is restricted and shared with ViBSL personnel on a need-to-know basis. All ViBSL personnel are obligated to protect confidentiality and ensure security. We adopt reasonable security practices and procedures, in line with international standard IS/ISO/IEC 27001 administered by specialized security teams to include technical, operational, managerial, and physical security controls in order to protect Your personal information. It may suit You well to note that no data transmission over the Internet is completely secure and for reasons outside of our control, security risks may still arise.</li>
                <li className="margin-top-20">We also take steps to ensure that our contracted third parties adopt reasonable level of security practices and procedures to ensure security of personal information.</li>
                <li className="margin-top-20">We cannot protect any information that you make available to the general public in any publicly accessible area of our website or any other media.</li>
              </ol>
              <div className="privacy-policy-inner-box">
                <p className="font-weight-500">Disclosure, Sharing and Transfer of Personal Information</p>
                We may store Your PI collected within the territorial limits of India or outside India to fulfill the terms of contract we have with you or if you have consented (at the time of collection of information) to the same.&nbsp; As stated before, “We” comprises of our authorized service providers; Your PI may be shared with Authorized Third Parties and service providers&nbsp; including&nbsp; Partners or agents who are involved in delivering the products and services You may have ordered or used.
              </div>
              <p className="font-weight-600 font-size-14">Detailed Section</p>
              <h2 className="font-size-18 font-weight-600 margin-top-20">5. Disclosure, Sharing and Transfer of Personal Information</h2>
              <ol type="a">
                <li className="margin-top-20">ViBSL may use third party service providers to operate business and the Website or administer activities on ViBSL`s behalf, such as providing Services, or sending out newsletters or surveys. ViBSL may share your Personal Information with these third parties if needed to carry out the obligations and provide you with the services in an efficient manner. Except as otherwise described in this Policy, ViBSL will not disclose Personal Information to any third party unless required to do so by law or if such action is necessary to conform to the law, comply with legal process served on ViBSL or&nbsp; affiliates, or investigate, prevent, or act regarding suspected or actual illegal activities; to enforce ViBSL`s Terms of Use, take precautions against liability, to investigate and defend ViBSL against any third-party claims or allegations, to assist government enforcement agencies, or to protect the security or integrity of the Website; and to exercise or protect the rights, property, or personal safety of the Company, our users or others.</li>
                <li className="margin-top-20">We may store Your PI within the territorial limits of India or outside India to fulfill the terms of contract we have with you or if you have consented (at the time of collection of information) to the same.</li>
              </ol>
              <h2 className="font-size-18 font-weight-600 margin-top-40">6. Other Scenarios</h2>
              <ol type="a">
                <li className="margin-top-20">We may disclose Contact Information in special cases where we have reason to believe that disclosing this information is necessary to identify, contact or bring legal action against someone who may be violating our terms and conditions of use or may be causing injury or interference with our rights, property, our customers or anyone who could be harmed by such activities.</li>
              </ol>
              <div className="privacy-policy-inner-box">
                <p className="font-weight-500">Contact Us</p>
                We are committed to safeguarding Your personal information collected and handled by Us and look forward to Your continued support for the same. In case of any feedback, concern or grievance regarding protection of Your personal information, you may reach us&nbsp;by contacting our <span className="font-weight-600">Data Privacy Officer</span> at <a href="mailto:privacyofficer@vodafoneidea.com" target="_blank" className="font-weight-600 link-purple">privacyofficer@vodafoneidea.com</a>
              </div>
              <p className="font-weight-600 font-size-14">Detailed Section</p>
              <h2 className="font-size-18 font-weight-600 margin-top-20">7. Contact Us</h2>
              <ol type="a">
                <li className="margin-top-20">We are committed to safeguarding Your personal information collected and handled by Us and look forward to Your continued support for the same. In case of any feedback, concern or grievance regarding protection of Your personal information, you may reach us&nbsp;by contacting our <span className="font-weight-600">Data Privacy Officer</span> at <a href="mailto:privacyofficer@vodafoneidea.com" target="_blank" className="font-weight-600 link-purple">privacyofficer@vodafoneidea.com</a>
                </li>
              </ol>
              <p className="margin-top-30">I hereby acknowledge that I have read this privacy notice and consent for my data to be collected, processed, shared and stored by ViBSL in accordance with the procedures and policies mentioned herein.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>


  )
};
export default RBMPrivacyPolicy;
