export const AGGREGATOR_POPULATE_LIST = "AGGREGATOR_POPULATE_LIST";
export const AGGREGATOR_POPULATE_LIST_SUCCESS =
  "AGGREGATOR_POPULATE_LIST_SUCCESS";
export const AGGREGATOR_POPULATE_LIST_FAILED =
  "AGGREGATOR_POPULATE_LIST_FAILED";
export const AGGREGATOR_POPULATE_LIST_RESET = "AGGREGATOR_POPULATE_LIST_RESET";

export const AGGREGATOR_BILLING_INFO = "AGGREGATOR_BILLING_INFO";
export const AGGREGATOR_BILLING_INFO_SUCCESS =
  "AGGREGATOR_BILLING_INFO_SUCCESS";
export const AGGREGATOR_BILLING_INFO_FAILED = "AGGREGATOR_BILLING_INFO_FAILED";
export const AGGREGATOR_BILLING_INFO_RESET = "AGGREGATOR_BILLING_INFO_RESET";
