import { LOADING } from "../../../constants/constant";
import {
  BOT_SCREENS,
  BOT_SCREENS_FAILED,
  BOT_SCREENS_SUCCESS,
  BOT_SCREENS_RESET
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function getBotScreensReducer(
  state = initialState,
  action
) {
  console.log()
  switch (action.type) {
    case BOT_SCREENS:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case BOT_SCREENS_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.status = action.data.status_message;
      userRes.isLoading = false;
      userRes.statusCode = action.data.status_code;

      if (userRes.statusCode === 10) {
        userRes.error = false;
        userRes.screens = action.data.data.screens;
        userRes.description = action.data.data.description
      } else {
        userRes.error = true;
        userRes.errorMessage = action.data.message;
      }
      return userRes;

    case BOT_SCREENS_FAILED:
      return Object.assign({}, state, action.error);

    case BOT_SCREENS_RESET: return initialState;
    default:
      return state;
  }
}
