import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import * as Api from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";

const TAG = "addTempalteSaga.js";

function* addRichTempalte(payLoad) {
  console.log(TAG, "worker addRichTempalte : " + JSON.stringify(payLoad));
  console.log(TAG, "addRichTempalte : " + JSON.stringify(payLoad.data));

  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.ADD_RICH_TEMPLATE,
      payLoad.data
    );
    console.log(
      TAG,
      "worker addRichTempalte Response: " + JSON.stringify(response)
    );
    yield put(
      memberAction.createActionOf(
        memberType.ADD_RICH_TEMPLATE_SUCCESS,
        1,
        response
      )
    );
  } catch (error) {
    console.log(TAG, "Worker addRichTempalte error: " + error.message);
    yield put(
      memberAction.createActionOf(
        memberType.ADD_RICH_TEMPLATE_FAILED,
        2,
        error
      )
    );
  }
}

export const watchAddRichTemplate = takeLatest(
  memberType.ADD_RICH_TEMPLATE,
  addRichTempalte
);
