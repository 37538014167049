import { put, takeLatest, call } from "redux-saga/effects"
import {
  ApproveOrRejectBrandFailed,
  ApproveOrRejectBrandSuccess,
  createActionOf,
} from "../../actions/ActionCreator"
import { APPROVE_OR_REJECT_CARRIER_BRANDS_REQUESTED } from "../../actions/ActionType"
import { makeSecuredPostRequest } from "../../../appApi/AppApi"
import { API_ENDPOINT } from "../../URLs/ApiEndpoints"

function* workerApproveOrRejectBrands(payLoad) {
  try {
    const response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.APPROVE_OR_REJECT_CARRIER_BRANDS,
      payLoad.data
    )

    yield put(ApproveOrRejectBrandSuccess(response))
  } catch (error) {
    yield put(createActionOf(ApproveOrRejectBrandFailed(error)))
  }
}

export const watchApproveOrRejectBrands = takeLatest(
  APPROVE_OR_REJECT_CARRIER_BRANDS_REQUESTED,
  workerApproveOrRejectBrands
)
