import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

const TAG = "getAllUsers.js";

function* workerGetAllUseres(payLoad) {
  console.log(TAG, "get all Users : " + JSON.stringify(payLoad));
  try {
    const response = yield call(
      Api.makeSecuredGetRequest,
      API_ENDPOINT.GET_ALL_USERS
    );
    yield put(memberAction.getAllUsersSuccess(response));
  } catch (error) {
    console.error(TAG, "Worker get All Users failed: " + error.message);
    yield put(memberAction.getAllUsersFailed(error.message));
    // throw new Error("Error: inside saga", JSON.stringify(error));
  }
}

export const watchGetAllUsers = takeLatest(
  memberType.GET_ALL_USERS_REQUESTED,
  workerGetAllUseres
);
