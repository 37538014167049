import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import * as Api from "../../appApi/AppApi";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";

function* workerUpdatePartnersProfile(payLoad) {
  console.log(
    "Worker: workerUpdatePartnersProfile => payload:: " +
      JSON.stringify(payLoad.data)
  );
  try {
    const response = yield call(
      Api.makePostRequestForMultipart,
      API_ENDPOINT.UPDATE_PARTNERS_PROFILE,
      payLoad.data
    );
    console.log(
      "Worker: workerUpdatePartnersProfile => Response::" +
        JSON.stringify(response)
    );
    yield put(memberAction.updatePartnersProfileSuccess(response));
  } catch (error) {
    console.error(
      "Worker: workerUpdatePartnersProfile => Error::: " + error.message
    );
    yield put(memberAction.updatePartnersProfileFailed(error.message));
  }
}

export const watchUpdatePartnersProfile = takeLatest(
  memberType.UPDATE_PARTNERS_PROFILE_REQUESTED,
  workerUpdatePartnersProfile
);
