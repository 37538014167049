import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import * as Api from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";

const TAG = "submitVerifiedCarriersSaga.js";

function* workerSubmitVerifiedCarriers(payLoad) {
  console.log(
    TAG,
    "worker submitVerifiedCarriersSaga : " + JSON.stringify(payLoad)
  );
  console.log(
    TAG,
    "worker submitVerifiedCarriersSaga : " + JSON.stringify(payLoad.data)
  );
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.SUBMIT_VERIFIED_CARRIERS,
      payLoad.data
    );
    console.log(
      TAG,
      "worker submitVerifiedCarriersSaga Response: " + JSON.stringify(response)
    );
    yield put(
      memberAction.createActionOf(
        memberType.SUBMIT_VERIFIED_CARRIERS_SUCCESS,
        1,
        response
      )
    );
  } catch (error) {
    console.log(
      TAG,
      "Worker submitVerifiedCarriersSaga error: " + error.message
    );
    yield put(
      memberAction.createActionOf(
        memberType.SUBMIT_VERIFIED_CARRIERS_FAILED,
        2,
        error
      )
    );
  }
}

export const watchSubmitVerifiedCarriers = takeLatest(
  memberType.SUBMIT_VERIFIED_CARRIERS,
  workerSubmitVerifiedCarriers
);
