import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

const TAG = "updateRCSBotOnboardingSaga.js";

function* workerUpdateRCSBot(payload) {

  try {
    if (!payload.data) {
      throw new Error(
        "Payload is undefined =>" + JSON.stringify(payload)
      );
    }
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.UPDATE_RCS_BOT_ONB,payload.data
    );
    yield put(memberAction.updateRCSBotONBSuccess(response));
  } catch (error) {
    console.error(TAG, "Error: while updating RCS bot details: ", error.message);
    yield put(memberAction.updateRCSBotONBFailed(error.message));
  }
}

export const watchUpdateRCSBotOnboardingSaga = takeLatest(
  memberType.UPDATE_RCS_BOT_ONB_REQUESTED,
  workerUpdateRCSBot
);