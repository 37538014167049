import { useEffect } from "react";
import { useQuery } from "../../store/hooks/useQuery";

export default function ErrorBoundaryFallback(props) {
  const query = useQuery();

  return (
    <>
      <section className="error-boundary">
        <i
          className="fa fa-frown-o error-boundary__emoji"
          aria-hidden="true"
        ></i>
        <h1 className="error-boundary__heading">OOPS! Something went wrong</h1>
        <p className="error-boundary__message">
          Application has encountered unexpected error. We will be looking into.
        </p>
        {query.get("ref") === "redirect" && (
          <button
            type="button"
            onClick={() => (window.location.href = "/")}
            className="btn-red-round margin-top-30"
          >
            Go To Home
          </button>
        )}
        <p className="error-boundary__help">
          Improve your experience by sending an error report to{" "}
          <a href="mailto:rbm-support@virbm.in">rbm-support@virbm.in</a>.
        </p>
      </section>
    </>
  );
}
