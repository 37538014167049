import { LOADING, STATUS_CODE_SUCCESS } from "../../../constants/constant";
import {
  SET_BILLING_USER_PASSWORD_REQUESTED,
  SET_BILLING_USER_PASSWORD_SUCCESS,
  SET_BILLING_USER_PASSWORD_FAILED,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function setBillingUserPasswordReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_BILLING_USER_PASSWORD_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case SET_BILLING_USER_PASSWORD_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log("came to the setPasswordReducer   " + JSON.stringify(action));
      userRes.status = action.data.status_message;
      userRes.isLoading = false;
      userRes.statusCode = action.data.status_code;
      if (userRes.statusCode !== STATUS_CODE_SUCCESS) {
        userRes.error = true;
        userRes.errorMessage = action.data.message;
        return userRes;
      }
      userRes.message = action.data.message;
      userRes.error = false;
      return userRes;

    case SET_BILLING_USER_PASSWORD_FAILED:
      return Object.assign({}, state, action.error);

    default:
      return state;
  }
}
