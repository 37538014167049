import { LOADING } from "../../../constants/constant";
import {
  DELETE_USER_FAILED_ADMIN_PORTAL,
  DELETE_USER_REQUESTED_ADMIN_PORTAL,
  DELETE_USER_RESET_ADMIN_PORTAL,
  DELETE_USER_SUCCESS_ADMIN_PORTAL,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function getDeleteUserAdminPortalReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_USER_REQUESTED_ADMIN_PORTAL:
      let reqstState = Object.assign({}, state);
      reqstState.deleteUserisLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case DELETE_USER_SUCCESS_ADMIN_PORTAL:
      let userRes = Object.assign({}, state);
      console.log(
        "DELETE user reducer => Case => Success:admin portal " +
          JSON.stringify(action.data)
      );
      userRes.deleteUserStatus = action.data.status_message;
      userRes.deleteUserStatusCode = action.data.status_code;
      userRes.deleteUserisLoading = false;
      // userRes.reInviteUserMessage = action.data.message;
      // userRes.deleteUserData = action.data.data;

      return userRes;

    case DELETE_USER_FAILED_ADMIN_PORTAL:
      console.error(
        "DELETE User Reducer => Case => Error:admin portal " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);

    case DELETE_USER_RESET_ADMIN_PORTAL: {
      return initialState;
    }

    default:
      return state;
  }
}
