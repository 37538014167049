import { LOADING } from "../../../../constants/constant";
import {
    BRAND_HISTORY_FOR_ADMIN_REQUESTED,
    BRAND_HISTORY_FOR_ADMIN_RESET,
    BRAND_HISTORY_FOR_ADMIN_FAILED,
    BRAND_HISTORY_FOR_ADMIN_SUCCESS
} from "../../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function getBrandHistoyReducer(state = initialState, action) {
  switch (action.type) {
    case BRAND_HISTORY_FOR_ADMIN_REQUESTED: {
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;
    }

    case BRAND_HISTORY_FOR_ADMIN_SUCCESS: {
      let userRes = Object.assign({}, state);
      console.log(
        "brand history=> Success:" +
          JSON.stringify(action.data)
      );
      userRes.status = action.data.statusMessage;
      userRes.statusCode = action.data.status_code;
      userRes.statusMessage = action.data.status_message;
      userRes.isLoading = false;
      //   userRes.statusCode = action.data.status;
      // userRes.statusSting = action.data.data;
      userRes.historyList = action.data.data;

      return userRes;
    }
    case BRAND_HISTORY_FOR_ADMIN_FAILED: {
      console.error(
        "brand history=> Error:" +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);
    }

    case BRAND_HISTORY_FOR_ADMIN_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
