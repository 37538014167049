import { config } from "../../constants/constant";
import { campaignEndpoints } from "./campaignEndpoints";
import { testerInviteEndpoints } from "./testerInvites.endpoints";
import { aggregatorEndpoints } from "./AggregatorEndpoints";
import aggregatorBillingEndpoints from "./AggregatorBilling";

let apiURL = {
  ...campaignEndpoints,
  ...aggregatorBillingEndpoints,
  ...aggregatorEndpoints,
  ...testerInviteEndpoints,
};

apiURL.GET_DASHBOARD_ANALYTICS_API =
  config.BASE_URI + "/secure/api/v2/get_carrier_revenue";
apiURL.GET_DASHBOARD_CARRIER_ANALYTICS_API =
  config.BASE_URI + "/secure/api/v3/get_carrier_revenue";

apiURL.DOTGO_ANALYTICS_API =
  config.BASE_URI + "/secure/api/v3/get_superadmin_revenue";
apiURL.DOTGO_ADMIN_SUMMARY =
  config.BASE_URI + "/secure/api/v2/get_admin_request_count";
apiURL.UPDATE_CARRIER_PROFILE =
  config.BASE_URI + "/secure/api/v2/update_carrier_profile";
apiURL.UPDATE_CARRIER_PROFILE_ADMIN_PORTAL =
  config.BASE_URI + "/secure/api/v2/update_admin_profile";
apiURL.GET_AGGREGATOR_DOCUMENTS = config.BASE_URI + "/api/v2/aggri_docs";
apiURL.GENERATE_OTP = config.BASE_URI + "/secure/api/v2/generate-devotp";
apiURL.VALIDATE_OTP = config.BASE_URI + "/secure/api/v2/validate-devotp";
apiURL.OTP_CHANNEL = config.BASE_URI + "/api/v1/otp_channel?msisdn=";
apiURL.GET_SUMMARY_CARDS = config.BASE_URI + "/secure/api/v2/get_cards_data";

apiURL.GET_ALL_BOTS_FOR_CARRIERS =
  config.BASE_URI + "/secure/api/v2/get_all_bots_for_carrier";
apiURL.GET_ALL_BOTS_FOR_DOTGO =
  config.BASE_URI + "/secure/api/v3/get_all_bots_for_super_admin";
apiURL.GET_ALL_BOTS_FOR_VI =
  config.BASE_URI + "/secure/api/v3/get_all_bots_for_carrier_admin";

apiURL.GET_SUBMITTED_BOTS_FOR_CARRIER =
  config.BASE_URI + "/secure/api/v2/get_submitted_bots_for_carrier";

apiURL.GET_REJECTED_BOTS_FOR_CARRIER =
  config.BASE_URI + "/secure/api/v2/get_rejected_bots_for_carrier";

apiURL.GET_APPROVED_BOTS_FOR_CARRIER =
  config.BASE_URI + "/secure/api/v2/get_approved_bots_for_carrier";

apiURL.UPLOAD_CAMPAIGN_FILE = config.RCS_API_BASE_URI + "/secure/audience_file";
apiURL.UPLOAD_TEMPLATE_FILE = config.RCS_API_BASE_URI + "/secure/media_file";
apiURL.UPLOAD_CAMPAIGN = config.RCS_API_BASE_URI + "/secure/rcsCampaign";

apiURL.GET_ALL_BRANDS_FOR_CARRIERS =
  config.BASE_URI + "/secure/api/v2/get_all_brands_for_carrier";
apiURL.GET_ALL_BRANDS_FOR_DOTGO =
  config.BASE_URI + "/secure/api/v3/get_all_brands_for_super_admin";
apiURL.GET_ALL_BRANDS_FOR_VI =
  config.BASE_URI + "/secure/api/v3/get_all_brands_for_carrier_admin";
apiURL.GET_ALL_AGGREGATORS_FOR_CARRIERS =
  config.BASE_URI + "/secure/api/v2/get_all_aggregators_for_carrier";
apiURL.GET_ALL_AGGREGATORS_FOR_DOTGO =
  config.BASE_URI + "/secure/api/v3/get_all_aggregators_for_super_admin";
apiURL.GET_ALL_AGGREGATORS_FOR_VI =
  config.BASE_URI + "/secure/api/v3/get_all_aggregators_for_carrier_admin";

apiURL.VERIFY_EMAIL = config.BASE_URI + "/api/v1/verify_email";
apiURL.CREATE_USER = config.BASE_URI + "/api/v1/create_user";
apiURL.SET_UP_USER_DETAILS =
  config.BASE_URI + "/secure/api/v2/submit_basic_user_details";
apiURL.SET_UP_ENTERPRISE_DETAILS =
  config.BASE_URI + "/secure/api/v1/update_company_details";

apiURL.GENERATE_OTP = config.BASE_URI + "/secure/api/v2/generate-devotp";

apiURL.PARTNER_DETAILS = config.BASE_URI + "/secure/api/v1/partner_details";
apiURL.STORE_LISTING = config.BASE_URI + "/secure/api/v2/bot_dashboard"; // TODO: remove it
apiURL.STORE_UNLISTING = config.BASE_URI + "/secure/api/v1/list_unlist";
apiURL.BOT_DETAILS = config.BASE_URI + "/secure/api/v1/get_bot_details";
apiURL.ADMIN_GET_BOT_DETAILS =
  config.BASE_URI + "/secure/api/v1/admin/get_bot_details";
apiURL.SUBMIT_BOT = config.BASE_URI + "/secure/api/v1/submit_bot";
apiURL.UPDATE_BOT = config.BASE_URI + "/secure/api/v1/update_bot";

apiURL.BOT_LIST = config.BASE_URI + "/secure/api/v2/bot_dashboard";
apiURL.CATEGORY_LIST =
  config.BASE_URI +
  "/secure/api/v1/get_all_category?start_index=0&end_index=30";
apiURL.CARRIER_LIST =
  config.BASE_URI + "/secure/api/v1/get_all_carrier?start_index=0&end_index=30";
apiURL.COUNTRY_LIST =
  config.BASE_URI + "/secure/api/v1/get_all_country?start_index=0&end_index=30";
apiURL.IMG_UPLOAD = config.BASE_URI + "/secure/rcsbotdirectory/imgupload";

apiURL.GET_BRANDS = config.BASE_URI + "/secure/api/v1/get_partners_brand";

apiURL.CONFIRM_BOT_SUBMISSION =
  config.BASE_URI + "/secure/api/v1/confirm_bot_submission";

apiURL.GET_BOT_DETAILS = config.BASE_URI + "/secure/api/v1/get_bot_details";

apiURL.LAUNCH_UPDATE_COMPANY_DETAILS =
  config.BASE_URI + "/secure/api/v1/update_launch_company_details";
apiURL.LAUNCH_COMPANY_INITIAL_DETAILS =
  config.BASE_URI + "/secure/api/v1/get_initial_launch_details";
apiURL.LAUNCH_CARRIER_LIST =
  config.BASE_URI + "/secure/api/v1/get_all_launch_carrier";
apiURL.LAUNCH_SUBMIT_CARRIER =
  config.BASE_URI + "/secure/api/v1/submit_launch_carrier_verification";

apiURL.IMAGE_BASE_URL = config.IMAGE_URI;
apiURL.GET_VERIFIED_LAUNCH_CARRIERS =
  config.BASE_URI + "/secure/api/v1/get_launch_verified_carrier";

apiURL.PARTNERS_BRAND_LIST = config.BASE_URI + "/secure/api/v1/brands_listing";
apiURL.GET_ALL_INDUSTRY_TYPES =
  config.BASE_URI +
  "/secure/api/v1/get_all_industry_type?start_index=0&end_index=500";
apiURL.SUBMIT_BRAND = config.BASE_URI + "/secure/api/v1/submit_brand_details";

apiURL.GET_SINGLE_BRAND_DETAILS =
  config.BASE_URI + "/secure/api/v2/get_brand_details?brand_id=";

apiURL.ADMIN_GET_SINGLE_BRAND_DETAILS =
  config.BASE_URI + "/secure/api/v1/admin/get_brand_details?brand_id=";

apiURL.GET_SINGLE_BRAND_DETAILS_PREVIEW =
  config.BASE_URI + "/secure/api/v2/get_brand_details?brand_id=";
apiURL.IMAGE_BASE_URL = config.IMAGE_BASE_URI;

apiURL.PARTNERS_BRAND_LIST = config.BASE_URI + "/secure/api/v1/brands_listing";
apiURL.GET_ALL_INDUSTRY_TYPES =
  config.BASE_URI +
  "/secure/api/v1/get_all_industry_type?start_index=0&end_index=500";
apiURL.SUBMIT_BRAND = config.BASE_URI + "/secure/api/v1/submit_brand_details";

apiURL.UPDATE_BRAND = config.BASE_URI + "/secure/api/v1/update_brand_details";
apiURL.SUBMIT_VERIFIED_CARRIERS =
  config.BASE_URI + "/secure/api/v1/submit_carrier_selection_details";

apiURL.SUBMIT_BOT_LAUNCH_SUMMARY =
  config.BASE_URI + "/secure/api/v1/submit_bot_launched_summary";
apiURL.ADMIN_SUBMIT_BOT_LAUNCH_SUMMARY =
  config.BASE_URI + "/secure/api/v1/admin/bot_launch_summary";

apiURL.SUBMIT_BOT_LOA = config.BASE_URI + "/secure/api/v1/submit_bot_loa";
apiURL.GET_PARTNERS_CARRIERS =
  config.BASE_URI + "/secure/api/v1/get_partners_carrier";

apiURL.UPDATE_PARTNERS_PROFILE =
  config.BASE_URI + "/secure/api/v1/update_partners_profile";
apiURL.LAUNCH_SUMMARY = config.BASE_URI + "/secure/api/v1/bot_launch_summary";

apiURL.CHANGE_PASSWORD = config.BASE_URI + "/secure/api/v1/update_password";
apiURL.GET_ESCALATION_MATRIX = config.BASE_URI + "/secure/api/v1/get_escalation_matrix";

// apiURL.GET_DASHBOARD_CARRIER_ANALYTICS_API =
//   config.BASE_URI + "/secure/api/v3/get_carrier_revenue"

export const GET_PRICE =
  config.BASE_URI + "/secure/api/v1/get_carrier_rateplan?carrierId=";
export const API_ENDPOINT = apiURL;
export const RCS_INFO_KEY = "KIlCc6LKUJUE7eHT_rcs_details";
export const JWT_SECRETE_KEY = "KIlCc6LKUJUE7eHT_SIgN_det@il$";

apiURL.INVITE_USER = config.BASE_URI + "/secure/api/v2/invite_billing_user";
apiURL.INVITE_USER_ADMIN_PORTAL =
  config.BASE_URI + "/secure/api/v2/invite_dotgo_admin";
apiURL.REINVITE_USER = config.BASE_URI + "/secure/api/v2/reinvite_billing_user";
apiURL.REINVITE_USER_ADMIN_PORTAL =
  config.BASE_URI + "/secure/api/v2/reinvite_dotgo_admin";
apiURL.DELETE_USER =
  config.BASE_URI + "/secure/api/v2/delete_billing_user?email=";
apiURL.DELETE_USER_ADMIN_PORTAL =
  config.BASE_URI + "/secure/api/v2/delete_dotgo_admin?email=";
apiURL.SET_BILLING_USER_PASSWORD =
  config.BASE_URI + "/secure/api/v1/create_user";
apiURL.PENDING_APPROVAL_BRANDS =
  config.BASE_URI + "/secure/api/v2/get_forwarded_brands_for_admin";

apiURL.GET_ALL_USERS =
  config.BASE_URI + "/secure/api/v2/get_all_users_for_carrier";

apiURL.GET_ALL_USERS_ADMIN_PORTAL =
  config.BASE_URI + "/secure/api/v2/get_all_users_for_admin";

apiURL.GET_SUBMITTED_BRANDS =
  config.BASE_URI + "/secure/api/v2/get_submitted_brands_for_carrier";

apiURL.ADMIN_GET_SUBMITTED_BRANDS =
  config.BASE_URI + "/secure/api/v2/get_submitted_brands_for_admin";

apiURL.GET_REJECTED_BRANDS =
  config.BASE_URI + "/secure/api/v2/get_rejected_brands_for_carrier";

apiURL.ADMIN_GET_REJECTED_BRANDS =
  config.BASE_URI + "/secure/api/v2/get_rejected_brands_for_admin";

apiURL.GET_APPROVED_BRANDS =
  config.BASE_URI + "/secure/api/v2/get_approved_brands_for_carrier";

apiURL.GET_ADMIN_APPROVED_BRANDS =
  config.BASE_URI + "/secure/api/v2/get_approved_brands_for_admin";

apiURL.GET_LIST_OF_AGGREGATOR =
  config.BASE_URI + "/secure/api/v2/list_of_aggregators";

apiURL.APPROVE_OR_REJECT_CARRIER_BOTS =
  config.BASE_URI + "/secure/api/v2/approve_or_reject_bot";

apiURL.APPROVE_OR_REJECT_CARRIER_BRANDS =
  config.BASE_URI + "/secure/api/v2/approve_or_reject_brand";

apiURL.ADMIN_APPROVE_OR_REJECT_CARRIER_BRANDS =
  config.BASE_URI + "/secure/api/v2/approve_or_reject_brand_by_dotgo";

apiURL.GET_SUBMITTED_AGGREGATORS_FOR_CARRIER =
  config.BASE_URI + "/secure/api/v2/get_submitted_aggregators_for_carrier";

apiURL.GET_APPROVED_AGGREGATORS_FOR_CARRIER =
  config.BASE_URI + "/secure/api/v2/get_approved_aggregators_for_carrier";

apiURL.GET_REJECTED_AGGREGATORS_FOR_CARRIER =
  config.BASE_URI + "/secure/api/v2/get_rejected_aggregators_for_carrier";

apiURL.APPROVE_OR_REJECT_CARRIER_AGGREGATOR =
  config.BASE_URI + "/secure/api/v2/approve_or_reject_aggregator";

apiURL.GET_AGGREGATOR_INFO =
  config.BASE_URI + "/secure/api/v2/get_aggregator_details";
//
apiURL.GENERATE_PDF_MY_BOTS =
  config.BASE_URI + "/secure/api/v2/download_pdf_report_for_my_bots";

apiURL.GENERATE_PDF_MY_BRANDS =
  config.BASE_URI + "/secure/api/v2/download_pdf_report_for_my_brands";
apiURL.GENERATE_PDF_MY_AGGREGATORS =
  config.BASE_URI + "/secure/api/v2/download_pdf_report_for_my_aggregators";

apiURL.GENERATE_EXCEL_MY_BOTS =
  config.BASE_URI + "/secure/api/v2/download_excel_report_for_my_bots";
apiURL.BOT_UPDATE_VERIFICATION_DETAILS =
  config.BASE_URI + "/secure/api/v2/update_bot_verification_details";

apiURL.GENERATE_EXCEL_MY_BRANDS =
  config.BASE_URI +
  "/secure/api/v2/download_excel_report_for_my_brands?status_id=";
apiURL.GENERATE_EXCEL_MY_AGGREGATORS =
  config.BASE_URI + "/secure/api/v2/download_excel_report_for_my_aggregators";

apiURL.GENERATE_EXCEL_DASHBOARD_SUMMARY =
  config.BASE_URI +
  "/secure/api/v3/download_excel_report_for_billing_dashboard";

apiURL.GENERATE_PDF_DASHBOARD_SUMMARY =
  config.BASE_URI + "/secure/api/v3/download_pdf_report_for_billing_dashboard";

apiURL.GENERATE_EXCEL_CAMPAIGN_SUMMARY =
  config.BASE_URI + "/secure/api/v2/download_excel_report_for_campaigns_list";

apiURL.GENERATE_PDF_CAMPAIGN_SUMMARY =
  config.BASE_URI + "/secure/api/v2/download_pdf_report_for_campaigns_list";

//=================ADMIN API END POINT =================================================

apiURL.GET_SUBMITTED_BOTS_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/get_submitted_bots_for_admin";
apiURL.GET_ADMIN_DOCUMENTS = config.BASE_URI + "/secure/api/v2/admin_docs";
apiURL.GET_APPROVED_BOTS_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/get_approved_bots_for_admin";
apiURL.GET_REJECTED_BOTS_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/get_rejected_bots_for_admin";
apiURL.GET_FORWARDED_BOTS_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/get_forwarded_bots_for_admin";
apiURL.APPROVE_OR_REJECT_BOTS_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/approve_or_reject_bot_by_dotgo";
apiURL.BOTS_HISTORY_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/get_bot_history";

apiURL.ADMIN_BOTS_HISTORY =
  config.BASE_URI + "/secure/api/v2/admin/get_bot_history";

apiURL.FORWARD_BOTS_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/forward_bot_by_dotgo";
apiURL.FORWARD_BRAND_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/forward_brand_by_dotgo";

apiURL.BRAND_HISTORY_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/get_brand_history";
apiURL.LOAD_REVIEW = config.BASE_URI + "/secure/api/v1/get_bot_review_reply";
apiURL.LOAD_REVIEW = config.BASE_URI + "/secure/api/v1/get_bot_review_reply";
apiURL.REPLY_REVIEW =
  config.BASE_URI + "/secure/api/v1/submit_bot_review_reply";

apiURL.GET_FORWARDED_AGGREGATOR_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/get_forwarded_aggregators_for_admin";
apiURL.GET_SUBMITTED_AGGREGATOR_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/get_submitted_aggregators_for_admin";
apiURL.GET_APPROVED_AGGREGATOR_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/get_approved_aggregators_for_admin";
apiURL.GET_REJECTED_AGGREGATOR_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/get_rejected_aggregators_for_admin";
apiURL.APPROVE_OR_REJECT_AGGREGATOR_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/approve_or_reject_aggregator_by_dotgo";
apiURL.APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/accept_or_reject_aggregator_by_dotgo";
apiURL.FORWARD_AGGREGATOR_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/forward_aggregator_by_dotgo";
apiURL.AGGREGATOR_HISTORY_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/get_aggregator_history";

apiURL.GET_SUBMITTED_DOTGO_AGGREGATOR_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/get_all_dotgo_aggregator_requests";

apiURL.GET_TEMPLATES_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/admin_templates_list";
apiURL.GET_TEMPLATES_FOR_CARRIER_ADMIN =
  config.BASE_URI + "/secure/api/v2/carrier_admin_templates_list";
apiURL.APPROVE_REJECT_TEMPLATES_FOR_CARRIER_ADMIN =
  config.BASE_URI + "/secure/api/v2/approve_or_reject_template_by_carrier";
apiURL.APPROVE_TEMPLATES_FOR_ADMIN =
  config.BASE_URI + "/secure/api/v2/forward_or_approve_template_by_dotgo";
apiURL.REJECT_TEMPLATES_FOR_CARRIER_ADMIN =
  config.BASE_URI + "/secure/api/v2/reject_template_by_dotgo";

// IMPORTANT:  Add billing-admin, dotgo-admin related endpoints above this line
export const API_ONBOARDING_ENDPOINTS = {
  CREATE_BOT_AGENT: config.BASE_URI + "/secure/api/v1/create_bot_agent",
  SUBMIT_CARRIERS:
    config.BASE_URI + "/secure/api/v1/submit_bot_agent_carrier_details",
};

apiURL.GENERATE_PDF_MY_BRANDS_ADMIN =
  config.BASE_URI + "/secure/api/v2/download_pdf_report_for_admin_my_brands";

apiURL.GENERATE_EXCEL_MY_BRANDS_ADMIN =
  config.BASE_URI + "/secure/api/v2/download_excel_report_for_admin_my_brands";

apiURL.GENERATE_PDF_BOTS_ADMIN =
  config.BASE_URI + "/secure/api/v2/download_pdf_report_for_admin_my_bots";

apiURL.GENERATE_EXCEL_BOTS_ADMIN =
  config.BASE_URI + "/secure/api/v2/download_excel_report_for_admin_my_bots";

apiURL.GENERATE_PDF_TEMPLATES_ADMIN =
  config.BASE_URI + "/secure/api/v2/download_pdf_report_for_templates";

apiURL.GENERATE_EXCEL_TEMPLATES_ADMIN =
  config.BASE_URI + "/secure/api/v2/download_excel_report_for_templates";

apiURL.GENERATE_PDF_AGGREGATORS_ADMIN =
  config.BASE_URI +
  "/secure/api/v2/download_pdf_report_for_admin_my_aggregators";

apiURL.GENERATE_PDF_DOTGO_AGGREGATORS_ADMIN =
  config.BASE_URI + "/secure/api/v2/download_pdf_report_for_dotgo_aggregators";

apiURL.GENERATE_EXCEL_AGGREGATORS_ADMIN =
  config.BASE_URI +
  "/secure/api/v2/download_excel_report_for_admin_my_aggregators";

apiURL.GENERATE_EXCEL_DOTGO_AGGREGATORS_ADMIN =
  config.BASE_URI +
  "/secure/api/v2/download_excel_report_for_dotgo_aggregators";

apiURL.GENERATE_EXCEL_ADMIN_BOTS =
  config.BASE_URI +
  "/secure/api/v2/download_excel_report_for_bots";

apiURL.GENERATE_EXCEL_ADMIN_TEMPLATES =
  config.BASE_URI +
  "/secure/api/v2/download_excel_report_for_templates";

apiURL.GENERATE_EXCEL_ADMIN_BRANDS =
  config.BASE_URI +
  "/secure/api/v2/download_excel_report_for_brands";


apiURL.GET_PRICE_PAGE_DETAILS = config.BASE_URI + "/secure/api/v1/pricing";

apiURL.COUNTRY_AND_CARRIER_LIST =
  config.BASE_URI + "/api/v2/get_all_country_carrier_list";
apiURL.GET_FAQ =
  config.BASE_URI + "/api/v2/get_dotgo_faqs?source_type=Vodafone India";

apiURL.SUBMIT_PRE_SELECTED_CARRIER =
  config.BASE_URI + "/secure/api/v2/submit_pre_selected_carrier";

apiURL.SUBMIT_SELECTED_CARRIER =
  config.BASE_URI + "/secure/api/v2/submit_selected_carrier";

apiURL.CREATE_RCS_BOT = config.BASE_URI + "/secure/api/v2/submit_bot";
apiURL.BOT_CONSOLE_INFO = config.BASE_URI + "/secure/api/v2/bot_console";
apiURL.BOT_CONSOLE_SUBMIT_KYC_DETAILS =
  config.BASE_URI + "/secure/api/v2/submit_bot_for_verification";
apiURL.BOT_CONSOLE_SUBMIT_KYC_CONFIRMATION =
  config.BASE_URI + "/secure/api/v2/confirm_bot_verification?bot_id=";
apiURL.BOT_CONSOLE_GET_TEMPLATES =
  config.RCS_API_BASE_URI + "/secure/templates?botId=";

// apiURL.CREATE_RCS_BOT =
//   config.BASE_URI +
//   "/secure/api/v2/submit_bot"

apiURL.ADD_RICH_TEMPLATE = config.RCS_API_BASE_URI + "/secure/richTemplate";
apiURL.CREATE_RCS_BOT = config.BASE_URI + "/secure/api/v2/submit_bot";
apiURL.BOT_CONSOLE_INFO = config.BASE_URI + "/secure/api/v2/bot_console";
export const PRO_API_URL = process.env.REACT_APP_PRO_API_URL;
apiURL.BOT_SCREENS = config.BASE_URI + "/secure/api/v2/bot_screens";

apiURL.UPDATE_LISTING = config.BASE_URI + "/secure/api/v2/update_listing";
// Dotgo admin listing endpoints
apiURL.ADMIN_GET_ALL_BOTS_SUBMITTED_FOR_LISTING =
  config.BASE_URI + "/secure/api/v2/admin_store_listing";
apiURL.ADMIN_GET_ALL_BOTS_SUBMITTED_FOR_VERIFICATION =
  config.BASE_URI + "/secure/api/v2/admin_bot_creation_list";
apiURL.ADMIN_APPROVE_REJECT_LISTING =
  config.BASE_URI + "/secure/api/v2/list_or_reject_bot_by_dotgo";
apiURL.ADMIN_APPROVE_REJECT_VERIFICATION =
  config.BASE_URI + "/secure/api/v2/verify_or_reject_bot_by_dotgo";
apiURL.ADMIN_APPROVE_REJECT_CREATION =
  config.BASE_URI + "/secure/api/v2/create_or_reject_bot_by_dotgo";

apiURL.ADMIN_GET_ALL_BOTS_SUBMITTED_FOR_VERIFICATION_CARRIER =
  config.BASE_URI + "/secure/api/v2/get_verification_bots_for_carrier";
apiURL.ADMIN_APPROVE_REJECT_LISTING_CARRIER =
  config.BASE_URI + "/secure/api/v2/verify_or_reject_bot_by_carrier";

apiURL.ADMIN_CREATE_BOT_ON_KRCSAPI =
  config.BASE_URI + "/secure/api/v2/create_bot_on_krcsapi";

apiURL.ADMIN_REGISTER_BOT_ON_KRCSAPI =
  config.BASE_URI + "/secure/api/v2/register_bot_on_krcsapi";

apiURL.GOOGLE_CREDENTIALS_FILE =
  config.BASE_URI + "/secure/api/v2/upload_credentials_file_krcsapi";
apiURL.ASSOCIATE_BOT_WITH_CHATBOT_PLATFORM =
  config.BASE_URI + "/secure/api/v2/associate_bot_with_chatbot_platform";

apiURL.GET_AVAILABLE_MaaP =
  config.BASE_URI + "/secure/api/v2/get_all_supported_platform";
apiURL.GET_AGENT_INFO = config.BASE_URI + "/secure/api/v2/get_agent_api_info";

apiURL.GET_ADMIN_CAMPAIGN =
  config.BASE_URI + "/secure/api/v2/admin_campaigns_list";
apiURL.GET_ADMIN_CARRIER_CAMPAIGN =
  config.BASE_URI + "/secure/api/v2/carrier_admin_campaigns_list";
apiURL.GET_ADMIN_CAMPAIGN_DETAILS =
  config.BASE_URI + "/secure/api/v2/get_campaign_detail";

apiURL.ADMIN_LISTING_BOT_HISTORY =
  config.BASE_URI + "/secure/api/v2/get_bot_history";

apiURL.DELETE_BOT = config.BASE_URI + "/secure/api/v2/delete_bot";

apiURL.GET_BOT_CREATION_PREVIEW_DETAILS =
  config.BASE_URI + "/secure/api/v2/bot_creation_preview?bot_id=";
apiURL.ADMIN_GET_BOT_CREATION_PREVIEW_DETAILS =
  config.BASE_URI + "/secure/api/v2/admin/bot_creation_preview?bot_id=";

apiURL.GET_VERIFIED_BOT_DETAILS =
  config.BASE_URI + "/secure/api/v2/bot_verification_preview?bot_id=";

apiURL.ADMIN_GET_VERIFIED_BOT_DETAILS =
  config.BASE_URI + "/secure/api/v2/admin/bot_verification_preview?bot_id=";

apiURL.DELETE_TEMPLATE = config.RCS_API_BASE_URI + "/secure/richTemplate/";

apiURL.DELETE_DOCS = config.BASE_URI + "/rcsbotdirectory/delete_temp_file";
apiURL.GET_TEST_DEVICES = config.BASE_URI + "/secure/api/v2/get_test_devices/";

apiURL.ADD_TEST_DEVICES = config.BASE_URI + "/secure/api/v2/submit_test_device";

apiURL.DELETE_TEST_DEVICES =
  config.BASE_URI + "/secure/api/v2/delete_test_device/";

apiURL.SEND_MESSAGE = config.BASE_URI + "/secure/api/v2/send_test_message";

apiURL.GET_TEMPLATE_DETAILS = config.RCS_API_BASE_URI + "/secure/richTemplate/";
apiURL.GET_TEMPLATE_VERIFICATION_DETAILS =
  config.BASE_URI + "/secure/api/v2/get_template_detail?template_id=";

apiURL.APPROVE_OR_REJECT_TEMPLATE =
  config.BASE_URI + "/secure/api/v2/forward_or_approve_template_by_dotgo";
apiURL.REJECT_TEMPLATE_ADMIN =
  config.BASE_URI + "/secure/api/v2/reject_template_by_dotgo";

apiURL.GET_TEMPLATE_HISTORY =
  config.RCS_API_BASE_URI + "/secure/richTemplateHistory/";

apiURL.GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY =
  config.ANALYTICS_REPORT_REDIRECT + "/secure/getCampaignSummary";
apiURL.UPDATE_RCS_BOT_ONB =
  config.BASE_URI + "/secure/api/v2/update_bot_creation";

apiURL.GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_DETAILED_REPORT =
  config.ANALYTICS_REPORT_REDIRECT + "/secure/getRichPromotionDetailedXlsx";

// Press release
apiURL.PRESS_RELEASE_CMS = config.BASE_URI + "/secure/api/v1/submit_dot_press";
// apiURL.BOT_VERIFICATION_PRICING =
//   config.BASE_URI + "/secure/api/v2/bot_verification_pricing";

apiURL.ADMIN_BOT_VERIFICATION_PRICING =
  config.BASE_URI + "/secure/api/v2/brand_verification_pricing";

  apiURL.BRAND_VERIFICATION_PRICING =
  config.BASE_URI + "/secure/api/v2/brand_verification_pricing";

export const FILE_UPLOAD = config.BASE_URI + "/secure/media_file";
// export const GENERATE_OTP = config.BASE_URI + "/secure/media_file"

apiURL.TEST_TEMPLATE = config.RCS_API_BASE_URI + "/secure/testTemplate";
apiURL.TEST_SUMMARY = config.RCS_API_BASE_URI + "/secure/testTemplateSummary/";
apiURL.RETEST_TEMPLATE = config.RCS_API_BASE_URI + "/secure/retestTemplate/";

export const GET_AGENT_MSG_LIST = config.BASE_URI + "/api/v2/get_agent_message_types";
apiURL.GET_TEMPLATE_JSON = config.RCS_API_BASE_URI + "/secure/downloadRichTemplate/";

apiURL.SUB_AGG_LIST_ENDPOINT = config.BASE_URI + "/secure/api/v2/sub_aggregators";

apiURL.ENCRYPTED_FILE_DOWNLOAD = config.BASE_URI + "/secure/api/fileDownload?fileUrl=";

apiURL.GET_FAQ_SECURE =
  config.BASE_URI + "/secure/api/v2/get_dotgo_faqs?source_type=Vodafone India";

apiURL.BILLING_CATEGORY =
    config.BASE_URI + "/api/v2/billing_category";