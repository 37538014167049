import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { isTokenValid } from "../../utils/otherUtils";
import { partnerDetails } from "../actions/ActionCreator";

export const usePartnerRole = (routeHistory) => {
  const [role, setRole] = useState();
  const dispatch = useDispatch();
  const isAuthenticated = isTokenValid();

  const response = useSelector((state) => {
    return {
      partnerDetails: state.partnerDetailsReducer.partnerDetails,
      partnerRole: state.partnerDetailsReducer.partnerDetails?.role,
      isLoading: state.partnerDetailsReducer.isLoading,
    };
  }, shallowEqual);

  useEffect(() => {
    if (response.partnerDetails || response.isLoading) {
      return;
    }
    if (isAuthenticated) {
      if (!response.partnerDetails || !response.isLoading) {
        dispatch(partnerDetails());
      }
    }
  }, [isAuthenticated, response.isLoading, response.partnerDetails]);

  useEffect(() => {
    if (response.partnerDetails?.role) {
      setRole(response.partnerDetails.role);
    }
  }, [response.partnerDetails]);

  useEffect(() => {
    if (
      response.partnerDetails?.isAggregator === true &&
      window.location.href.includes("admin/dashboard")
    ) {
      routeHistory?.replace("/developer/dashboard");
    }
  }, [response.partnerDetails?.isAggregator]);

  return { role: response.partnerRole ?? role, isLoading: response.isLoading };
};
