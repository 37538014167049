import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import * as Api from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";

const TAG = "getVerifiedCarriersSaga.js";

function* getVerifiedCarrier(payLoad) {
  console.log(TAG, "worker getVerifiedCarrier : " + JSON.stringify(payLoad));
  console.log(TAG, "getVerifiedCarrier : " + JSON.stringify(payLoad.data));

  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.GET_VERIFIED_LAUNCH_CARRIERS,
      payLoad.data.bot_id
    );
    console.log(
      TAG,
      "worker getVerifiedCarrier Response: " + JSON.stringify(response)
    );
    yield put(
      memberAction.createActionOf(
        memberType.GET_VERIFIED_LAUNCH_CARRIER_SUCCESS,
        1,
        response
      )
    );
  } catch (error) {
    console.log(TAG, "Worker getVerifiedCarrier error: " + error.message);
    yield put(
      memberAction.createActionOf(
        memberType.GET_VERIFIED_LAUNCH_CARRIER_FAILED,
        2,
        error
      )
    );
  }
}

export const watchGetVerifiedCarriers = takeLatest(
  memberType.GET_VERIFIED_LAUNCH_CARRIER,
  getVerifiedCarrier
);
