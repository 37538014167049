import { LOADING } from "../../constants/constant";
import {
  COUNTRY_LIST_FAILED,
  COUNTRY_LIST_REQUESTED,
  COUNTRY_LIST_SUCCESS,
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function carrierListReducer(state = initialState, action) {
  switch (action.type) {
    case COUNTRY_LIST_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case COUNTRY_LIST_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log("came to the get bot list  success", action);
      try {
        userRes.status = action.data.status_message;
        userRes.statusCode = action.data.status_code;
        userRes.isLoading = false;
        //   userRes.statusCode = action.data.status;
        userRes.countryList = action.data.data;

        return userRes;
      } catch (error) {
        throw new Error("Error CountryList: ", error);
      }

    case COUNTRY_LIST_FAILED:
      return Object.assign({}, state, action.error);

    default:
      return state;
  }
}
