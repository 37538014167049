import { LOAD_BOT_REVIEWS, LOAD_BOT_REVIEWS_SUCCESS, LOAD_BOT_REVIEWS_FAILED } from "../actions/ActionType";

const initialState = {
  data: { status: "INIT", isLoading: true, error: false, CommentReplyList:[] },
};
const LoadBotReviewsReducer = (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case LOAD_BOT_REVIEWS:
      newState = { ...state, data: { isLoading: true, error: false, CommentReplyList:[] } };
      return newState;
    case LOAD_BOT_REVIEWS_SUCCESS:
      console.log("In the bot reviews reducer success", action.data.data);
      let rqstState = {
        data: {
          ...action.data,
          error: false,
          isLoading: false,
        },
      };
      return rqstState;
    case LOAD_BOT_REVIEWS_FAILED:
      console.log("In the bot reviews reducer Fail", action.data.data);
      return {
        data: { ...action.data, error: true, isLoading: false },
      };
    default:
      return state;
  }
};
export default LoadBotReviewsReducer;
