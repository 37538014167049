import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import * as Api from "../../appApi/AppApi";


const TAG = "richPromotionsAndNotificationsCampaignDetailedReport.js";

function* workerGetRichPromotionsAndNotificationsCampaignDetailedReport(
  payload
) {
  try {
    const response = yield call(
      Api.makeSecurePostRequestWithFileSave,
      API_ENDPOINT.GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_DETAILED_REPORT,
      //"https://devanalytics.dotgo.com/analytics/secure/getRichPromotionDetailedXlsx",
      payload.data,
      payload.fileInfo.fileName,
      payload.fileInfo.fileExtension
    );
    yield put(
      memberAction.getRichPromotionsAndNotificationsCampaignDetailedReportSuccess(
        response
      )
    );
  } catch (error) {
    yield put(
      memberAction.getRichPromotionsAndNotificationsCampaignDetailedReportFailed(
        error.message
      )
    );
  }
}

export const watchGetRichPromotionsAndNotificationsCampaignDetailedReport =
  takeLatest(
    memberType.GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_DETAILED_REPORT_REQUESTED,
    workerGetRichPromotionsAndNotificationsCampaignDetailedReport
  );
