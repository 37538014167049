import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_REJECTED_BOTS_FOR_ADMIN_SUCCESS,
  GET_REJECTED_BOTS_FOR_ADMIN,
  GET_REJECTED_BOTS_FOR_ADMIN_FAILED
} from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";

const TAG = "get_forwarded_bots_for_admin.js";

function* workerGetBots(payLoad) {
  console.log("payload sub-------------------->",payLoad);
  try {
    const response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.GET_REJECTED_BOTS_FOR_ADMIN
      // payLoad.data
    );
    yield put(createActionOf(GET_REJECTED_BOTS_FOR_ADMIN_SUCCESS, 1, response));
  } catch (error) {
    console.error(TAG, "Worker get bots failed: " + error.message);
    yield put(createActionOf(GET_REJECTED_BOTS_FOR_ADMIN_FAILED, 2, error));
  }
}

export const watchGetAdminRejectedBots = takeLatest(
  GET_REJECTED_BOTS_FOR_ADMIN,
  workerGetBots
);
