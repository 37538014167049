import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import * as Api from "../../appApi/AppApi";
import { STATUS_CODE_CUSTOM_LAUNCH_APPROVED_FALSE } from "../../constants/constant";

function* workerBotList(payLoad) {
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.BOT_LIST,
      payLoad.data
    );
    
    if(response?.status_code === STATUS_CODE_CUSTOM_LAUNCH_APPROVED_FALSE)
     {
      // console.log("fbfgbgfbgf :",response)
      yield put(memberAction.createActionOf("PARTNER_DETAILS_MODIFIED",2));
     }
     else{
     yield put(memberAction.botListSuccess(response));}
  } catch (error) {
    yield put(memberAction.botListFailed(error.message));
  }
}

export const watchBotList = takeLatest(
  memberType.BOT_LIST_REQUESTED,
  workerBotList
);
