import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

const TAG = "testTemplate.js";

function* workerTestTemplate(payLoad) {
  console.log(TAG, "Test Template : " + JSON.stringify(payLoad));
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.TEST_TEMPLATE,
      payLoad.data
    );
    yield put(memberAction.createActionOf(memberType.TEST_TEMPLATE_SUCCESS,1,response));
  } catch (error) {
    console.error(TAG, "Worker get country failed: " + error.message);
    const failedAction =memberAction.createActionOf(memberType.TEST_TEMPLATE_FAILED, 2, error)
    yield put(failedAction)
  }
}

export const watchTestTemplate = takeLatest(
  memberType.TEST_TEMPLATE,
  workerTestTemplate
);
