const TimezoneSelector = ({ name, defaultValue, register, onChange, isSelected = false }) => {

    if(defaultValue==="Asia/Calcutta") defaultValue = "Asia/Kolkata"
    else if(defaultValue==="Asia/Katmandu") defaultValue = "Asia/Kathmandu"

    const customProp = timezone => {
        try{
            if(isSelected) return {
                selected : defaultValue === timezone.value
                }
            else return {}
        }catch(e){
            return {}
        }
    }

    return(
        <div className="form-group">
            <label className="margin-bottom-10">
                <img src="images/icon_time.svg" width="18" alt="" />{" "}
                Campaign Timezone<span style={{color:"red"}}>*</span>
            </label>
            <select className="form-control" name={name} ref={register({ required: true })} defaultValue={defaultValue} onChange={onChange??""}>
                {
                    timezones.map((timezone, i) => {
                        return(
                            <option 
                            key={i} 
                            value={timezone.value}
                            {...customProp(timezone)}
                            >
                                {`${timezone.label.match(/\(([^)]+)\)/g)} ${timezone.value}`}                            
                            </option>
                        )
                    })
                }
            </select>
        </div>
    )
}

export default TimezoneSelector;

const timezones = [
    {
        "value": "Pacific/Midway",
        "label": "(GMT-11:00) Midway Island, Samoa",
        "offset": -11,
        "abbrev": "SST",
        "altName": "Samoa Standard Time"
    },
    {
        "value": "Pacific/Honolulu",
        "label": "(GMT-10:00) Hawaii",
        "offset": -10,
        "abbrev": "HAST",
        "altName": "Hawaii-Aleutian Standard Time"
    },
    {
        "value": "America/Juneau",
        "label": "(GMT-9:00) Alaska",
        "offset": -9,
        "abbrev": "AKST",
        "altName": "Alaska Standard Time"
    },
    {
        "value": "America/Dawson",
        "label": "(GMT-8:00) Dawson, Yukon",
        "offset": -8,
        "abbrev": "MST",
        "altName": "Mountain Standard Time"
    },
    {
        "value": "America/Tijuana",
        "label": "(GMT-8:00) Tijuana",
        "offset": -8,
        "abbrev": "PST",
        "altName": "Pacific Standard Time"
    },
    {
        "value": "America/Los_Angeles",
        "label": "(GMT-8:00) Pacific Time",
        "offset": -8,
        "abbrev": "PST",
        "altName": "Pacific Standard Time"
    },
    {
        "value": "America/Boise",
        "label": "(GMT-7:00) Mountain Time",
        "offset": -7,
        "abbrev": "MST",
        "altName": "Mountain Standard Time"
    },
    {
        "value": "America/Chihuahua",
        "label": "(GMT-7:00) Chihuahua, La Paz, Mazatlan",
        "offset": -7,
        "abbrev": "HNPMX",
        "altName": "Mexican Pacific Standard Time"
    },
    {
        "value": "America/Phoenix",
        "label": "(GMT-7:00) Arizona",
        "offset": -7,
        "abbrev": "MST",
        "altName": "Mountain Standard Time"
    },
    {
        "value": "America/Chicago",
        "label": "(GMT-6:00) Central Time",
        "offset": -6,
        "abbrev": "CST",
        "altName": "Central Standard Time"
    },
    {
        "value": "America/Regina",
        "label": "(GMT-6:00) Saskatchewan",
        "offset": -6,
        "abbrev": "CST",
        "altName": "Central Standard Time"
    },
    {
        "value": "America/Mexico_City",
        "label": "(GMT-6:00) Guadalajara, Mexico City, Monterrey",
        "offset": -6,
        "abbrev": "CST",
        "altName": "Central Standard Time"
    },
    {
        "value": "America/Belize",
        "label": "(GMT-6:00) Central America",
        "offset": -6,
        "abbrev": "CST",
        "altName": "Central Standard Time"
    },
    {
        "value": "America/Detroit",
        "label": "(GMT-5:00) Eastern Time",
        "offset": -5,
        "abbrev": "EST",
        "altName": "Eastern Standard Time"
    },
    {
        "value": "America/Bogota",
        "label": "(GMT-5:00) Bogota, Lima, Quito",
        "offset": -5,
        "abbrev": "COT",
        "altName": "Colombia Standard Time"
    },
    {
        "value": "America/Lima",
        "label": "(GMT-5:00) Pittsburgh",
        "offset": -5,
        "abbrev": "PET",
        "altName": "Peru Standard Time"
    },
    {
        "value": "America/Caracas",
        "label": "(GMT-4:00) Caracas, La Paz",
        "offset": -4,
        "abbrev": "VET",
        "altName": "Venezuela Time"
    },
    {
        "value": "America/St_Johns",
        "label": "(GMT-3:30) Newfoundland and Labrador",
        "offset": -3.5,
        "abbrev": "HNTN",
        "altName": "Newfoundland Standard Time"
    },
    {
        "value": "America/Santiago",
        "label": "(GMT-3:00) Santiago",
        "offset": -3,
        "abbrev": "CLST",
        "altName": "Chile Summer Time"
    },
    {
        "value": "America/Sao_Paulo",
        "label": "(GMT-3:00) Brasilia",
        "offset": -3,
        "abbrev": "BRT",
        "altName": "Brasilia Standard Time"
    },
    {
        "value": "America/Montevideo",
        "label": "(GMT-3:00) Montevideo",
        "offset": -3,
        "abbrev": "UYT",
        "altName": "Uruguay Standard Time"
    },
    {
        "value": "America/Argentina/Buenos_Aires",
        "label": "(GMT-3:00) Buenos Aires, Georgetown",
        "offset": -3,
        "abbrev": "America/Argentina/Buenos_Aires",
        "altName": "America/Argentina/Buenos_Aires"
    },
    {
        "value": "America/Godthab",
        "label": "(GMT-3:00) Greenland",
        "offset": -3,
        "abbrev": "UTC-3/-4",
        "altName": "Nuuk Time"
    },
    {
        "value": "Atlantic/Azores",
        "label": "(GMT-1:00) Azores",
        "offset": -1,
        "abbrev": "AZOT",
        "altName": "Azores Standard Time"
    },
    {
        "value": "Atlantic/Cape_Verde",
        "label": "(GMT-1:00) Cape Verde Islands",
        "offset": -1,
        "abbrev": "CVT",
        "altName": "Cape Verde Standard Time"
    },
    {
        "value": "Etc/GMT",
        "label": "(GMT+0:00) UTC",
        "offset": 0,
        "abbrev": "GMT",
        "altName": "ETC/GMT"
    },
    {
        "value": "Europe/London",
        "label": "(GMT+0:00) Edinburgh, London",
        "offset": 0,
        "abbrev": "GMT",
        "altName": "Greenwich Mean Time"
    },
    {
        "value": "Europe/Dublin",
        "label": "(GMT+0:00) Dublin",
        "offset": 0,
        "abbrev": "GMT",
        "altName": "Greenwich Mean Time"
    },
    {
        "value": "Europe/Lisbon",
        "label": "(GMT+0:00) Lisbon",
        "offset": 0,
        "abbrev": "WET",
        "altName": "Western European Standard Time"
    },
    {
        "value": "Africa/Casablanca",
        "label": "(GMT+0:00) Casablanca, Monrovia",
        "offset": 0,
        "abbrev": "WET",
        "altName": "Western European Standard Time"
    },
    {
        "value": "Atlantic/Canary",
        "label": "(GMT+0:00) Canary Islands",
        "offset": 0,
        "abbrev": "WET",
        "altName": "Western European Standard Time"
    },
    {
        "value": "Europe/Belgrade",
        "label": "(GMT+1:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
        "offset": 1,
        "abbrev": "CET",
        "altName": "Central European Standard Time"
    },
    {
        "value": "Europe/Sarajevo",
        "label": "(GMT+1:00) Sarajevo, Skopje, Warsaw, Zagreb",
        "offset": 1,
        "abbrev": "CET",
        "altName": "Central European Standard Time"
    },
    {
        "value": "Europe/Brussels",
        "label": "(GMT+1:00) Brussels, Copenhagen, Madrid, Paris",
        "offset": 1,
        "abbrev": "CET",
        "altName": "Central European Standard Time"
    },
    {
        "value": "Europe/Amsterdam",
        "label": "(GMT+1:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
        "offset": 1,
        "abbrev": "CET",
        "altName": "Central European Standard Time"
    },
    {
        "value": "Africa/Algiers",
        "label": "(GMT+1:00) West Central Africa",
        "offset": 1,
        "abbrev": "CET",
        "altName": "Central European Standard Time"
    },
    {
        "value": "Europe/Berlin",
        "label": "(GMT+1:00) Frankfurt",
        "offset": 1,
        "abbrev": "CET",
        "altName": "Central European Standard Time"
    },
    {
        "value": "Europe/Bucharest",
        "label": "(GMT+2:00) Bucharest",
        "offset": 2,
        "abbrev": "EET",
        "altName": "Eastern European Standard Time"
    },
    {
        "value": "Africa/Cairo",
        "label": "(GMT+2:00) Cairo",
        "offset": 2,
        "abbrev": "EET",
        "altName": "Eastern European Standard Time"
    },
    {
        "value": "Europe/Helsinki",
        "label": "(GMT+2:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
        "offset": 2,
        "abbrev": "EET",
        "altName": "Eastern European Standard Time"
    },
    {
        "value": "Europe/Athens",
        "label": "(GMT+2:00) Athens, Minsk",
        "offset": 2,
        "abbrev": "EET",
        "altName": "Eastern European Standard Time"
    },
    {
        "value": "Asia/Jerusalem",
        "label": "(GMT+2:00) Jerusalem",
        "offset": 2,
        "abbrev": "IST",
        "altName": "Israel Standard Time"
    },
    {
        "value": "Africa/Harare",
        "label": "(GMT+2:00) Harare, Pretoria",
        "offset": 2,
        "abbrev": "CAT",
        "altName": "Central Africa Time"
    },
    {
        "value": "Europe/Moscow",
        "label": "(GMT+3:00) Istanbul, Moscow, St. Petersburg, Volgograd",
        "offset": 3,
        "abbrev": "MSK",
        "altName": "Moscow Standard Time"
    },
    {
        "value": "Asia/Kuwait",
        "label": "(GMT+3:00) Kuwait, Riyadh",
        "offset": 3,
        "abbrev": "AST",
        "altName": "Arabian Standard Time"
    },
    {
        "value": "Africa/Nairobi",
        "label": "(GMT+3:00) Nairobi",
        "offset": 3,
        "abbrev": "EAT",
        "altName": "East Africa Time"
    },
    {
        "value": "Asia/Baghdad",
        "label": "(GMT+3:00) Baghdad",
        "offset": 3,
        "abbrev": "AST",
        "altName": "Arabian Standard Time"
    },
    {
        "value": "Asia/Tehran",
        "label": "(GMT+3:30) Tehran",
        "offset": 3.5,
        "abbrev": "IRST",
        "altName": "Iran Standard Time"
    },
    {
        "value": "Asia/Dubai",
        "label": "(GMT+4:00) Abu Dhabi, Muscat",
        "offset": 4,
        "abbrev": "GST",
        "altName": "Gulf Standard Time"
    },
    {
        "value": "Asia/Kabul",
        "label": "(GMT+4:30) Kabul",
        "offset": 4.5,
        "abbrev": "AFT",
        "altName": "Afghanistan Time"
    },
    {
        "value": "Asia/Baku",
        "label": "(GMT+5:00) Baku, Tbilisi, Yerevan",
        "offset": 5,
        "abbrev": "AZT",
        "altName": "Azerbaijan Standard Time"
    },
    {
        "value": "Asia/Yekaterinburg",
        "label": "(GMT+5:00) Ekaterinburg",
        "offset": 5,
        "abbrev": "YEKT",
        "altName": "Yekaterinburg Standard Time"
    },
    {
        "value": "Asia/Karachi",
        "label": "(GMT+5:00) Islamabad, Karachi, Tashkent",
        "offset": 5,
        "abbrev": "PKT",
        "altName": "Pakistan Standard Time"
    },
    {
        "value": "Asia/Kolkata",
        "label": "(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi",
        "offset": 5.5,
        "abbrev": "IST",
        "altName": "India Standard Time"
    },
    {
        "value": "Asia/Colombo",
        "label": "(GMT+5:30) Sri Jayawardenepura",
        "offset": 5.5,
        "abbrev": "IST",
        "altName": "India Standard Time"
    },
    {
        "value": "Asia/Kathmandu",
        "label": "(GMT+5:45) Kathmandu",
        "offset": 5.75,
        "abbrev": "UTC+5.75",
        "altName": "Kathmandu Time"
    },
    {
        "value": "Asia/Dhaka",
        "label": "(GMT+6:00) Astana, Dhaka",
        "offset": 6,
        "abbrev": "BST",
        "altName": "Bangladesh Standard Time"
    },
    {
        "value": "Asia/Almaty",
        "label": "(GMT+6:00) Almaty, Novosibirsk",
        "offset": 6,
        "abbrev": "ALMT",
        "altName": "East Kazakhstan Time"
    },
    {
        "value": "Asia/Rangoon",
        "label": "(GMT+6:30) Yangon Rangoon",
        "offset": 6.5,
        "abbrev": "Asia/Yangon",
        "altName": "Asia/Yangon"
    },
    {
        "value": "Asia/Bangkok",
        "label": "(GMT+7:00) Bangkok, Hanoi, Jakarta",
        "offset": 7,
        "abbrev": "ICT",
        "altName": "Indochina Time"
    },
    {
        "value": "Asia/Krasnoyarsk",
        "label": "(GMT+7:00) Krasnoyarsk",
        "offset": 7,
        "abbrev": "KRAT",
        "altName": "Krasnoyarsk Standard Time"
    },
    {
        "value": "Asia/Shanghai",
        "label": "(GMT+8:00) Beijing, Chongqing, Hong Kong SAR, Urumqi",
        "offset": 8,
        "abbrev": "CST",
        "altName": "China Standard Time"
    },
    {
        "value": "Asia/Kuala_Lumpur",
        "label": "(GMT+8:00) Kuala Lumpur, Singapore",
        "offset": 8,
        "abbrev": "MYT",
        "altName": "Malaysia Time"
    },
    {
        "value": "Asia/Taipei",
        "label": "(GMT+8:00) Taipei",
        "offset": 8,
        "abbrev": "CST",
        "altName": "Taipei Standard Time"
    },
    {
        "value": "Australia/Perth",
        "label": "(GMT+8:00) Perth",
        "offset": 8,
        "abbrev": "AWST",
        "altName": "Australian Western Standard Time"
    },
    {
        "value": "Asia/Irkutsk",
        "label": "(GMT+8:00) Irkutsk, Ulaanbaatar",
        "offset": 8,
        "abbrev": "IRKT",
        "altName": "Irkutsk Standard Time"
    },
    {
        "value": "Asia/Seoul",
        "label": "(GMT+9:00) Seoul",
        "offset": 9,
        "abbrev": "KST",
        "altName": "Korean Standard Time"
    },
    {
        "value": "Asia/Tokyo",
        "label": "(GMT+9:00) Osaka, Sapporo, Tokyo",
        "offset": 9,
        "abbrev": "JST",
        "altName": "Japan Standard Time"
    },
    {
        "value": "Australia/Darwin",
        "label": "(GMT+9:30) Darwin",
        "offset": 9.5,
        "abbrev": "ACST",
        "altName": "Australian Central Standard Time"
    },
    {
        "value": "Asia/Yakutsk",
        "label": "(GMT+10:00) Yakutsk",
        "offset": 10,
        "abbrev": "YAKT",
        "altName": "Yakutsk Standard Time"
    },
    {
        "value": "Australia/Brisbane",
        "label": "(GMT+10:00) Brisbane",
        "offset": 10,
        "abbrev": "AEST",
        "altName": "Australian Eastern Standard Time"
    },
    {
        "value": "Asia/Vladivostok",
        "label": "(GMT+10:00) Vladivostok",
        "offset": 10,
        "abbrev": "VLAT",
        "altName": "Vladivostok Standard Time"
    },
    {
        "value": "Pacific/Guam",
        "label": "(GMT+10:00) Guam, Port Moresby",
        "offset": 10,
        "abbrev": "ChST",
        "altName": "Chamorro Standard Time"
    },
    {
        "value": "Australia/Adelaide",
        "label": "(GMT+10:30) Adelaide",
        "offset": 10.5,
        "abbrev": "ACDT",
        "altName": "Australian Central Daylight Time"
    },
    {
        "value": "Australia/Sydney",
        "label": "(GMT+11:00) Canberra, Melbourne, Sydney",
        "offset": 11,
        "abbrev": "AEDT",
        "altName": "Australian Eastern Daylight Time"
    },
    {
        "value": "Australia/Hobart",
        "label": "(GMT+11:00) Hobart",
        "offset": 11,
        "abbrev": "AEDT",
        "altName": "Australian Eastern Daylight Time"
    },
    {
        "value": "Asia/Magadan",
        "label": "(GMT+11:00) Magadan, Solomon Islands, New Caledonia",
        "offset": 11,
        "abbrev": "MAGT",
        "altName": "Magadan Standard Time"
    },
    {
        "value": "Asia/Kamchatka",
        "label": "(GMT+12:00) Kamchatka, Marshall Islands",
        "offset": 12,
        "abbrev": "PETT",
        "altName": "Petropavlovsk-Kamchatski Standard Time"
    },
    {
        "value": "Pacific/Fiji",
        "label": "(GMT+12:00) Fiji Islands",
        "offset": 12,
        "abbrev": "FJT",
        "altName": "Fiji Standard Time"
    },
    {
        "value": "Pacific/Auckland",
        "label": "(GMT+13:00) Auckland, Wellington",
        "offset": 13,
        "abbrev": "NZDT",
        "altName": "New Zealand Daylight Time"
    },
    {
        "value": "Pacific/Tongatapu",
        "label": "(GMT+13:00) Nuku'alofa",
        "offset": 13,
        "abbrev": "TOT",
        "altName": "Tonga Standard Time"
    }                                        
]

export { timezones }