import { memo } from "react";
import { requestLogout } from "../../constants/constant";
import { withRouter, NavLink } from "react-router-dom";
import Header from "./Header";
import { useContext } from "react";
import { AuthContext } from "../../Providers/ContextProviders/AuthContext";
import AsPieProgress2 from "../util/AsPieProgress2";
import {
  BILLING_ANALYTICS_URL,
  IS_AGGREGATOR_TRAFFIC_AVAILABLE,
} from "../../constants/stringConstants";

const Navbar = (props) => {
  const { isLoggedIn } = useContext(AuthContext);
  const routeArray = [
    "/setup-enterprise",
    "/developer/setup-user",
    "/signup-success",
    "/developer/mobile-verification",
    "/developer/review-details",
  ];
  const activeArray = [
    "/developer/dashboard",
    "/developer/my-brands",
    "/developer/launch/",
    "/developer/my-carriers",
    "/billing/",
    "/developer/price",
    "/developer/api",
    "/developer/create-bot/whatsapp-bot-details",
    "/developer/create-bot/rcs-bot-details",
    "/developer/create-bot/brand-details",
    "/developer/create-bot/select-carriers",
    "/developer/listing",
    "/developer/bot-console",
    "/developer/launch-details",
    "/developer/view-bot-details/whatsapp/", //14
    "/developer/update-whatsapp-bot/", //15
    "/developer/add-view-template/",
    "/developer/view-template-details",
    "/developer/update-brand",
    /my-campaigns/,
    /add-new-campaign/,
    /my-billing/, // 21
    "/developer/addTemplate", //22
  ];

  return (
    <>
      {!routeArray.includes(props.location.pathname) && !props.isDataPresent ? (
        <nav className="navbar navbar-default navbar-fixed-top navbar-bg">
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
                aria-expanded="false"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              {!isLoggedIn ? (
                <a className="navbar-brand" href="/">
                  <img
                    src="images/vi_logo.svg"
                    className="vi-navbar-logo-size"
                    alt="VI"
                  />
                </a>
              ) : (
                <NavLink className="navbar-brand" to="/developer/dashboard">
                  <img
                    src="images/vi_logo.svg"
                    className="vi-navbar-logo-size"
                    alt="VI"
                  />
                </NavLink>
              )}
            </div>
            {/* TODO: conditions refactor */}
            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <ul className="nav navbar-nav  navbar-left">
                {/* {props.partnerDetails.isAggregator === true && (
                  <li
                    className={
                      activeArray[21].test(props.location.pathname)
                        ? "active"
                        : ""
                    }
                  >
                    <NavLink className="ol-nav-link" to="/developer/my-billing">
                      MY BILLING
                    </NavLink>
                  </li>
                )} */}
                <li
                  className={
                    (props.location.pathname === activeArray[0] && "active") ||
                    (props.location.pathname === activeArray[7] && "active") ||
                    (props.location.pathname === activeArray[8] && "active") ||
                    (props.location.pathname === activeArray[9] && "active") ||
                    (props.location.pathname === activeArray[10] && "active") ||
                    (props.location.pathname.includes(activeArray[11]) &&
                      "active") ||
                    (props.location.pathname.includes(activeArray[12]) &&
                      "active") ||
                    (props.location.pathname.includes(activeArray[13]) &&
                      "active") ||
                    (props.location.pathname.includes(activeArray[14]) &&
                      "active") ||
                    (props.location.pathname.includes(activeArray[15]) &&
                      "active") ||
                    (props.location.pathname.includes(activeArray[16]) &&
                      "active") ||
                    (props.location.pathname.includes(activeArray[17]) &&
                      "active") ||
                    (props.location.pathname.includes(activeArray[22]) &&
                      "active")
                  }
                >
                  <NavLink className="ol-nav-link" to="/developer/dashboard">
                    MY BOTS <span className="sr-only">(current)</span>
                  </NavLink>
                </li>
                <li
                  className={
                    props.location.pathname === activeArray[4] ? "active" : ""
                  }
                ></li>

                {props.partnerDetails.isAggregator === true &&
                props.partnerDetails.launchApproved === false ? (
                  <li
                  // className={
                  //   props.location.pathname === activeArray[0] ? "active" : ""
                  // }
                  >
                    <a
                      style={{ cursor: "not-allowed" }}
                      className="ol-nav-link"
                      //to="/developer/dashboard"
                    >
                      MY BRANDS
                    </a>
                  </li>
                ) : (
                  <li
                    className={
                      // props.location.pathname === activeArray[1] ? "active" : ""
                      (props.location.pathname.includes(activeArray[1]) &&
                        "active") ||
                      (props.location.pathname.includes(activeArray[18]) &&
                        "active")
                    }
                  >
                    <NavLink className="ol-nav-link" to="/developer/my-brands">
                      MY BRANDS
                    </NavLink>
                  </li>
                )}
                {props.partnerDetails.isAggregator === true &&
                props.partnerDetails.launchApproved === false ? (
                  <li>
                    <a
                      style={{
                        cursor: "not-allowed",
                        pointerEvents: "painted",
                      }}
                      className="ol-nav-link"
                      // to="/developer/dashboard"
                    >
                      MY CAMPAIGNS
                    </a>
                  </li>
                ) : (
                  <li
                    className={
                      (activeArray[19].test(props.location.pathname) &&
                        "active") ||
                      (activeArray[20].test(props.location.pathname) &&
                        "active")
                    }
                  >
                    <NavLink
                      className="ol-nav-link"
                      to="/developer/my-campaigns"
                    >
                      MY CAMPAIGNS
                    </NavLink>
                  </li>
                )}

                {/* <li
                  className={
                    props.location.pathname.match(activeArray[3]) && "active"
                  }
                >
                  <NavLink className="ol-nav-link" to="/developer/my-carriers">
                    MY CARRIERS
                  </NavLink>
                </li> */}

                {/* {
                      props.partnerDetails.isAggregator === true && props.partnerDetails.launchApproved  === false ?
                      <li
                      // className={
                      //   props.location.pathname === activeArray[0] ? "active" : ""
                      // }
                    >
                  <NavLink
                      style={{cursor:"not-allowed"}}
                  className="ol-nav-link" to="/developer/dashboard">
                   API
                 </NavLink>
                 </li>
                 : */}
                <li
                  className={
                    props.location.pathname === activeArray[6] && "active"
                  }
                >
                  <NavLink className="ol-nav-link" to="/developer/api">
                    API
                  </NavLink>
                </li>
                {props.partnerDetails.isAggregator === true &&
                props.partnerDetails.launchApproved === false ? (
                  <li>
                    <a
                      style={{ cursor: "not-allowed" }}
                      className="ol-nav-link"
                      //  to="/developer/dashboard"
                    >
                      MY BILLING
                    </a>
                  </li>
                ) : (
                  <li
                    className={
                      activeArray[21].test(props.location.pathname)
                        ? "active"
                        : ""
                    }
                  >
                    <a
                      href={`${BILLING_ANALYTICS_URL}/analytics/my-billing`}
                      className="ol-nav-link"
                    >
                      MY BILLING
                    </a>
                  </li>
                )}
                <li
                  className={
                    activeArray[21].test(props.location.pathname)
                      ? "active"
                      : ""
                  }
                >
                  {IS_AGGREGATOR_TRAFFIC_AVAILABLE === "true" ? (
                    <a
                      href={`${BILLING_ANALYTICS_URL}/analytics/traffic-dashboard`}
                      className="ol-nav-link"
                    >
                      TRAFFIC
                    </a>
                  ) : null}
                </li>
              </ul>

              <ul className="nav navbar-nav navbar-right dropdown-z-index">
                {/* <li><a href="#"><img src="images/icon_wallet_purple.svg"/></a></li> */}
                {/* <li><a href="#"><i className="fa fa-1x fa-bell font-size-24"></i><span className="badge badge-blue-bg"></span></a></li> */}
                {isLoggedIn && <li id="faq-btn-padding-top-6">
                  <NavLink to="/faq" className="ol-nav-link">
                  <button className="ol-nav-link faq-btn-post-l">FAQs</button>
                  </NavLink>
                </li>}
                <li className="dropdown">
                  <a
                    className="ol-nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      src={
                        props.partnerProfileImage
                          ? props.partnerProfileImage +
                            `?img=${new Date().getTime()}`
                          : "images/profile_img.png"
                      }
                      className="img-circle"
                      width={35}
                      alt="Welcome User"
                      style={{ marginTop: "-8px" }}
                    />
                    <span className="caret" />
                  </a>
                  <ul
                    id="profile_dropdown"
                    className={`dropdown-menu dropdown-menu--profile ${
                      +props.profileCompleted === 100
                        ? "dropdown-menu--profile_complete"
                        : "dropdown-menu--profile_incomplete"
                    } `}
                  >
                    <li
                      hidden={props.profileCompleted === 100}
                      className="dropdown-menu--pie"
                    >
                      <NavLink
                        className="ol-nav-link"
                        to="/developer/edit-profile"
                      >
                        <div
                          className="dropdown-profile-pic"
                          style={{ display: "inline-block" }}
                        >
                          <div
                            className="percent"
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          >
                            <AsPieProgress2 progress={props.profileCompleted} />
                          </div>
                        </div>
                        <div className="dropdown-profile-text dropdown-profile-text-m">
                          <small className="dropdown-profile-text--percent">
                            Your Profile is {props.profileCompleted}% Complete{" "}
                          </small>{" "}
                          <br />
                          {/* <span className="link-purple visited dropdown-menu--pie_complete-link">
                            Complete Profile
                          </span> */}
                          <span>
                            <a href="#" className="link-purple font-size-12">
                              Complete Profile
                            </a>
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="dropdown-menu--usercontent">
                      <NavLink
                        className="ol-nav-link"
                        to="/developer/edit-profile"
                      >
                        <div className="dropdown-profile-pic">
                          <img
                            src={
                              props.partnerProfileImage
                                ? props.partnerProfileImage +
                                  `?img=${new Date().getTime()}`
                                : "images/profile_img.png"
                            }
                            className="img-circle"
                            width={35}
                            alt="Welcome User"
                          />
                        </div>
                        <div className="dropdown-profile-text dropdown-profile-text-m ">
                          <span
                            className="dropdown-profile-text-m--username"
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              maxWidth: "150px",
                              display: "inline-block",
                            }}
                          >
                            {props.partnerDetails &&
                              props.partnerDetails.firstName}
                            &nbsp;
                            {props.partnerDetails &&
                              props.partnerDetails.lastName}
                          </span>
                          <br />
                          <span className="dropdown-profile-text-m--email text-light-gray text-right">
                            <small
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {props.partnerDetails &&
                                props.partnerDetails.email}
                            </small>
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    {props.partnerDetails &&
                      props.partnerDetails.isCarrier &&
                      props.partnerDetails.role === "ADMIN_BILLING" && (
                        <>
                          <li>
                            <NavLink
                              className="ol-nav-link"
                              to="/billing/edit-profile/manage-users"
                            >
                              Manage Users
                            </NavLink>
                          </li>
                        </>
                      )}
                    {props.partnerDetails &&
                      props.partnerDetails.isCarrier &&
                      props.partnerDetails.role === "USER_BILLING" && (
                        <>
                          <li>
                            <NavLink
                              className="ol-nav-link"
                              to="/billing/edit-profile/account-details"
                              // params={{ UserBilling: "UserBilling" }}
                            >
                              Edit Profile
                            </NavLink>
                          </li>
                        </>
                      )}
                    {props.partnerDetails &&
                      !props.partnerDetails.isCarrier && (
                        <li>
                          <NavLink
                            className="ol-nav-link"
                            to="/developer/edit-profile"
                          >
                            Edit Profile
                          </NavLink>
                        </li>
                        //  </>
                      )}

                    <li>
                      <NavLink
                        className="ol-nav-link"
                        to="/developer/change-password"
                      >
                        Change Password
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/Aggregator/documents">Downloads</NavLink>
                    </li>
                    <li role="separator" className="divider" />
                    <li>
                      <a
                        onClick={requestLogout}
                        className="text-center font-color-red cursor-pointer"
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      ) : (
        <Header />
      )}
    </>
  );
};

export default memo(withRouter(Navbar));
