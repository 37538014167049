import { STORE_VERIFICATION_DETAILS } from "../actions/ActionType"

const initialState = {
  status: "",
  isLoading: false,
}

export default function storeVerificationDetails(state = initialState, action) {
  switch (action.type) {
    case STORE_VERIFICATION_DETAILS:
      return action.data

    default:
      return state
  }
}
