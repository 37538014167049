import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import * as Api from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";

const TAG = "getLaunchCarrierSaga.js";

function* workerGetLaunchCarriers(payLoad) {
  console.log(TAG, "worker getLaunchCarrierSaga : " + JSON.stringify(payLoad));
  console.log(
    TAG,
    "worker getLaunchCarrierSaga : " + JSON.stringify(payLoad.data)
  );
  try {
    const response = yield call(
      Api.makeSecuredGetRequest,
      API_ENDPOINT.LAUNCH_CARRIER_LIST
    );
    console.log(
      TAG,
      "worker getLaunchCarrierSaga Response: " + JSON.stringify(response)
    );
    yield put(
      memberAction.createActionOf(
        memberType.GET_LAUNCH_CARRIER_SUCCESS,
        2,
        response
      )
    );
  } catch (error) {
    console.log(TAG, "Worker getLaunchCarrierSaga error: " + error.message);
    yield put(
      memberAction.createActionOf(
        memberType.GET_LAUNCH_CARRIER_FAILED,
        2,
        error
      )
    );
  }
}

export const watchGetLaunchCarriers = takeLatest(
  memberType.GET_LAUNCH_CARRIER,
  workerGetLaunchCarriers
);
