import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import * as Api from "../../appApi/AppApi";

const TAG = "ValidateOTP";

function* workerValidateOtp(payLoad) {
  console.log("Validate OTP payload: ", payLoad)  
  try {
    const response = yield call(
      Api.makeNewSecuredPostRequest,
      API_ENDPOINT.VALIDATE_OTP,
      payLoad.data  
    );
    yield put(memberAction.createActionOf(memberType.VALIDATE_OTP_SUCCESS, 1, response));
  } catch (error) {
    yield put(memberAction.createActionOf(memberType.VALIDATE_OTP_FAILED, 2, error));
  }
}

export const watchValidateOtp = takeLatest(
  memberType.VALIDATE_OTP,
  workerValidateOtp
);