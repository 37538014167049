
import { withRouter } from "react-router-dom";
import { requestLogin } from "../../../../constants/constant";
import { isTokenValid } from "../../../../utils/otherUtils";
const Home = (props) => {
  // window.location.replace("https://www.google.com")
  if (isTokenValid()) {
    props.history.replace("/developer/dashboard");
    return <></>;
  }else{
    requestLogin()
    return null
}
};
export default withRouter(Home);
