export const Verification = {
    CHAR_LENGTH_3072: 3072,
    CHAR_LENGTH_512: 512,
    CHAR_LENGTH_2048: 2048,
    CHAR_LENGTH_128: 128,
    CHAR_LENGTH_100: 100,
    CHAR_LENGTH_256: 256
  };

  export const BotCreation = {
    CHAR_LENGTH_128: 128,
    CHAR_LENGTH_2048: 2048,
    CHAR_LENGTH_256: 256,
    CHAR_LENGTH_1024: 1024,
    CHAR_LENGTH_40: 40,
    CHAR_LENGTH_100: 100,
    CHAR_LENGTH_25: 25,
    CHAR_LENGTH_45: 45,
  };

  export const SetupUser = {
    CHAR_LENGTH_128: 128,
    CHAR_LENGTH_2048: 2048,
    CHAR_LENGTH_100: 100,
   
  };