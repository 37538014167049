import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

const TAG = "deleteTemplate.js";

function* workerDeleteTemplate(payLoad) {
  console.log(TAG, "Delete Template : " + JSON.stringify(payLoad));
  try {
    const response = yield call(
      Api.makeSecuredDeleteRequest,
      API_ENDPOINT.DELETE_TEMPLATE + payLoad.data
    );
    yield put(memberAction.createActionOf(memberType.DELETE_TEMPLATE_SUCCESS,1,response));
  } catch (error) {
    console.error(TAG, "Worker get country failed: " + error.message);
    // yield put(memberAction.deleteUserFailed(error.message));
    // throw new Error("Error: inside saga", JSON.stringify(error));
  }
}

export const watchDeleteTemplate = takeLatest(
  memberType.DELETE_TEMPLATE,
  workerDeleteTemplate
);
