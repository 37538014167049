import { LOADING, STATUS_CODE_SUCCESS } from "../../constants/constant";

import {
  GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY_FAILED,
  GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY_SUCCESS,
  GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY_REQUESTED,
  RESET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY,
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: false,
};

export default function getRichPromotionsAndNotificationsCampaignSummaryReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.status = action.data.status_message;
      userRes.statusCode = action.data.status_code;
      userRes.data = action.data.data;
      userRes.isLoading = false;
      return userRes;

    case GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY_FAILED:
      return Object.assign({}, state, action.error);

    case RESET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY:
      let resetObj = Object.assign({}, state);
      resetObj.status = "constant";
      resetObj.statusCode = 0;
      return resetObj;

    default:
      return state;
  }
}
