import { LOADING, STATUS_CODE } from "../../constants/constant"
import {
  RESET_STATE,
  GET_BOT_WHATSAPP_DETAILS,
  GET_BOT_WHATSAPP_DETAILS_SUCCESS,
  GET_BOT_WHATSAPP_DETAILS_FAILED,
  GET_BOT_WHATSAPP_DETAILS_RESET,
} from "../actions/ActionType"

const initialState = {
  status: "INIT",
  isLoading: true,
}

export default function getBotWhatsAppReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BOT_WHATSAPP_DETAILS:
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      reqstState.statusCode = STATUS_CODE
      return reqstState

    case GET_BOT_WHATSAPP_DETAILS_SUCCESS:
      let userRes = Object.assign({}, state)

      userRes.isLoading = false
      userRes.status = action.data.status_message
      userRes.statusCode = action.data.status_code
      userRes.bot_details = action.data.data.bot_details
      return userRes

    case GET_BOT_WHATSAPP_DETAILS_FAILED:
      return Object.assign({}, state, action.error)
    case GET_BOT_WHATSAPP_DETAILS_RESET:
      return initialState

    case RESET_STATE:
      return initialState

    default:
      return state
  }
}
