import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import * as Api from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../../store/URLs/ApiEndpoints";

const TAG = "stepTwoSaga.js";

function* workerStepTwo(payLoad) {
  console.log(TAG, "worker stepTwoSaga : " + JSON.stringify(payLoad));
  console.log(TAG, "worker stepTwoSaga : " + JSON.stringify(payLoad.data));
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.LAUNCH_UPDATE_COMPANY_DETAILS,
      payLoad.data
    );
    console.log(
      TAG,
      "worker stepTwoSaga Response: " + JSON.stringify(response)
    );
    yield put(
      memberAction.createActionOf(
        memberType.LAUNCH_SETUP_COMPANY_DETAILS_SUCCESS,
        1,
        response
      )
    );
  } catch (error) {
    console.log(TAG, "Worker stepTwoSaga error: " + error.message);
    yield put(
      memberAction.createActionOf(
        memberType.LAUNCH_SETUP_COMPANY_DETAILS_FAILED,
        2,
        error
      )
    );
  }
}

export const watchStepTwo = takeLatest(
  memberType.LAUNCH_SETUP_COMPANY_DETAILS,
  workerStepTwo
);
