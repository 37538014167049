import { LOADING, STATUS_CODE_SUCCESS } from "../../../constants/constant";
import {
  GET_DOTGO_SUMMARY,
  GET_DOTGO_SUMMARY_SUCCESS,
  GET_DOTGO_SUMMARY_FAILED,
  GET_DOTGO_SUMMARY_RESET,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function getDotgoSummaryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DOTGO_SUMMARY:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_DOTGO_SUMMARY_SUCCESS:
      let userRes = Object.assign({}, state);

      userRes.status = action.data.data.status_message;
      userRes.isLoading = false;
      userRes.statusCode = action.data.data.statusCode;
      if (userRes.statusCode !== STATUS_CODE_SUCCESS) {
        userRes.error = true;
        userRes.errorMessage = action.data.data.data.message;
        return userRes;
      }
      userRes.error = false;
      userRes.summaryList = action.data.data.data;
      return userRes;

    case GET_DOTGO_SUMMARY_FAILED:
      return Object.assign({}, state, action.error);
    case GET_DOTGO_SUMMARY_RESET:
      return initialState;

    default:
      return state;
  }
}
