import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import * as Api from "../../appApi/AppApi";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";

function* workerPartnerDetails() {
  try {
    const response = yield call(
      Api.makeSecuredGetRequest,
      API_ENDPOINT.PARTNER_DETAILS
    );
    yield put(memberAction.partnerDetailsSuccess(response));
  } catch (error) {
    yield put(memberAction.partnerDetailsFailed(error.message));
  }
}

export const watchPartnerDetails = takeLatest(
  memberType.PARTNER_DETAILS_REQUESTED,
  workerPartnerDetails
);
