import React, { useEffect, useState } from 'react'
import { downloadEncryptedFile } from '../../../../utils/fileUtility';
import CommonModal from '../../../Modals/CommonModal';
import { Loader } from 'semantic-ui-react';
import isValidURL from "validator/lib/isURL";

export default function FilePreview(props) {

  const [blobURL, setURL] = useState();
  const [fileDownloadError, setFileDownloadError] = useState(false);
  const [fileDownloadErrorMessage, setFileDownloadErrorMessage] = useState("");
  const [loadingFileDownload, setLoadingFileDownload] = useState(false);


  const fileDownloadHandler = async () => {
    setLoadingFileDownload(true);
    const encryptedURL = props.uri;
    if (
      isValidURL(encryptedURL, {
        protocols: ["https", "http"],
        require_protocol: true,
      })
    ) {
      setLoadingFileDownload(false);
      setURL(encryptedURL);
    } else
    {
      try {
        const response = await downloadEncryptedFile(encryptedURL); 
        if (response.headers["content-type"] === "application/json") {
          const plainText = await response.data?.text();
          const newResponse = JSON.parse(plainText);
          setLoadingFileDownload(false);
          setFileDownloadError(true);
          setFileDownloadErrorMessage(
            newResponse?.message ??
              "Error occurred while downloading file, Please try again"
          );
        }else if((props?.fileExtension==="PDF"||props?.fileExtension==="pdf"))
        {
          setLoadingFileDownload(false);
          const file=new Blob([response.data],{type:"application/pdf"});
          const fileURL = URL.createObjectURL(file);
        setURL(fileURL);
        }else if(props?.fileExtension?.toLowerCase()==="jpeg" || props?.fileExtension?.toLowerCase()==="jpg"|| props?.fileExtension?.toLowerCase()==="png")
        {
          setLoadingFileDownload(false);
          const file=new Blob([response.data],{type:`image/${props?.fileExtension?.toLowerCase()}`});
          const fileURL = URL.createObjectURL(file);

          setURL(fileURL);
        }
      } catch (error) {
        setLoadingFileDownload(false);
        setFileDownloadError(true);
        setFileDownloadErrorMessage(
          error?.response?.message ??
            "Error occurred while downloading file, Please try again"
        );
      } finally {
        setLoadingFileDownload(false);
      }
    
  };
}
  useEffect(()=>{
    if(props.uri)
    {
      fileDownloadHandler()
    }
  },[props.uri])

    const image_extensions = [
        "png",
        "jpg",
        "jpeg",
        "gif",
        "PNG",
        "JPG",
        "JPEG",
        "GIF",
        "pdf",
        "PDF"
      ];
      const image_extensions_docs = [
       
        "ppt",
        "pptx",
        "xls",
        "xlsx",
        "PPT",
        "PPTX",
        "XLS",
        "XLSX",
      ];
     function getType()
     {
        if(image_extensions.includes(props?.fileExtension))
        return "image_doc"
        else  if(image_extensions_docs.includes(props?.fileExtension))
        return "documents_doc"
        else
        return "not found"

     }
      return(
      
      <>
      {loadingFileDownload ? (
        <div className="margin-left-20" style={{minHeight:"250px",paddingTop:"100px", textAlign:"center"}}>
          <Loader inline active size="huge" />
        </div>
      ) :
      (props?.fileExtension==="PDF"||props?.fileExtension==="pdf")?
      <iframe
                  src={blobURL}
                  title="docLabiFrame"
                  width="100%"
                  height="850px"
                  frameborder="0"
                />
      :
      getType()==="image_doc"?
      (
        <div style={{minHeight:"150px", paddingRight:"2rem", paddingLeft:"2rem",paddingTop:"2rem"
        }}>
          <img alt="" src={blobURL} width="100%" height="100%" />
        </div>
      )
    //   :getType()==="documents_doc"?
    //   <iframe
    //   src={"https://view.officeapps.live.com/op/embed.aspx?src=" +props?.uri
    //   }
    //   title="docLabiFrame2"
    //   width="100%"
    //   height="850px"
    //   frameborder="0"
    // />
      
      :
      <div className='text-center' style={{minHeight:"200px",minWidth:"200px"}}>
        <h2 className='margin-top-40' style={{marginTop:"90px"}}>Preview unavailable</h2></div>
      }
      <CommonModal
        dismissButton={false}
        actionButton={true}
        actionButtonLabel={"Ok"}
        open={fileDownloadError}
        modalHeader={
          <>
            <p style={{ fontSize: "20px" }}>
              {fileDownloadErrorMessage ?? (
                <>
                  Error Occurred while uploading Image
                  <br />
                  Please check internet connection or try again
                </>
              )}
            </p>
          </>
        }
        onAction={() => {
          props?.setOpenViewer(false)
          setFileDownloadErrorMessage("");
          setFileDownloadError(false);
        }}
      />
      </>
      
      )
    
}
