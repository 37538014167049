import { LOADING } from "../../../../constants/constant";
import {
  ADMIN_GET_APPROVED_BRANDS_FAILED,
  ADMIN_GET_APPROVED_BRANDS_RESET,
  ADMIN_GET_APPROVED_BRANDS_SUCCESS,
  ADMIN_GET_APPROVED_BRANDS_REQESTED
} from "../../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: false,
};

export default function AdminGetApprovedBrandsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ADMIN_GET_APPROVED_BRANDS_REQESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case ADMIN_GET_APPROVED_BRANDS_SUCCESS:
        let userRes = Object.assign({}, state);
        console.log(
          "CarrierGetSubmittedBrandsReducer admin=> Case => Success: " +
            JSON.stringify(action.data)
        );
        userRes.status = action.data.status_message;
        userRes.isLoading = false;
        userRes.statusCode = action.data.status_code;
        // userRes.message = action.data.status_message;
        userRes.data = action.data;
        return userRes;
    case ADMIN_GET_APPROVED_BRANDS_FAILED:
      console.error(
        "carrierget approved brandsReducer admin => Case => Error: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);

    case ADMIN_GET_APPROVED_BRANDS_RESET:
      return initialState;

    default:
      return state;
  }
}
