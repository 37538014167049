import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

function* workerGetVerifiedBotDetails(payload) {
  console.log("payload.data?.bot_id:: ", payload.data)
  try {
    let apiEndpoint =
      API_ENDPOINT.GET_VERIFIED_BOT_DETAILS +
      payload.data?.bot_id +
      "&channel_type_id=1";
    if (/admin/i.test(payload.data?.whoIsThis)) {
      apiEndpoint =
        API_ENDPOINT.ADMIN_GET_VERIFIED_BOT_DETAILS +
        payload.data?.bot_id +
        "&channel_type_id=1";
    }
    const response = yield call(Api.makeSecuredPostRequest, apiEndpoint);
    yield put(memberAction.getVerifiedBotDetailsSuccess(response));
  } catch (error) {
    yield put(memberAction.getVerifiedBotDetailsFailed(error.message));
  }
}

export const watchGetVerifiedBotDetailsSaga = takeLatest(
  memberType.GET_VERIFIED_BOT_DETAILS,
  workerGetVerifiedBotDetails
);
