import { put, takeLatest, call } from "redux-saga/effects";
import {
    GET_SUBMITTED_AGGREGATORS_FOR_CARRIER,
    GET_SUBMITTED_AGGREGATORS_FOR_CARRIER_SUCCESS,
    GET_SUBMITTED_AGGREGATORS_FOR_CARRIER_FAILED,
} from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";

const TAG = "getSubmittedAggregatorsForCarrierSaga.js";

function* workerGetSubmittedAggregatorsForCarrier(payLoad) {
  try {
    const response = yield call(
        makeSecuredPostRequest,
        API_ENDPOINT.GET_SUBMITTED_AGGREGATORS_FOR_CARRIER,
    );
    yield put(createActionOf(GET_SUBMITTED_AGGREGATORS_FOR_CARRIER_SUCCESS, 1, response));
  } catch (error) {
    console.error(TAG, "Worker get country failed: " + error.message);
    yield put(createActionOf(GET_SUBMITTED_AGGREGATORS_FOR_CARRIER_FAILED, 2, error));
  }
}

export const watchGetSubmittedAggregatorsForCarrier = takeLatest(
    GET_SUBMITTED_AGGREGATORS_FOR_CARRIER,
    workerGetSubmittedAggregatorsForCarrier,
);