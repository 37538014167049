import { LOADING, STATUS_CODE_SUCCESS } from "../../../constants/constant";
import {
  GET_DOCUMENT_LIST,
  GET_DOCUMENT_LIST_SUCCESS,
  GET_DOCUMENT_LIST_FAILED,
  GET_DOCUMENT_LIST_RESET,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};
export default function getDocumentTypes(state = initialState, action) {
    switch (action.type) {
      case GET_DOCUMENT_LIST:
        let reqstState = Object.assign({}, state);
        reqstState.isLoading = true;
        reqstState.status = LOADING;
        return reqstState;

      case GET_DOCUMENT_LIST_SUCCESS:
        let docList = {};

        docList.status = action.data.resData.status_message;
        docList.isLoading = false;
        docList.statusCode = action.data.resData.status_code;

        if (docList.statusCode !== STATUS_CODE_SUCCESS) {
          docList.error = true;
          docList.errorMessage = action.data.resData.message;
          return docList;
        }
        docList.error = false;
        docList.documentList = action.data.resData.data;
        return docList;

      case GET_DOCUMENT_LIST_FAILED:
        return Object.assign({}, state, action.error);
      case GET_DOCUMENT_LIST_RESET:
        return initialState;

      default:
        return state;
    }
}
