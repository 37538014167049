import { put, takeLatest, call } from "redux-saga/effects";
import {
    FORWARD_AGGREGATOR_FOR_ADMIN_SUCCESS,
    FORWARD_AGGREGATOR_FOR_ADMIN,
    FORWARD_AGGREGATOR_FOR_ADMIN_FAILED
} from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";

const TAG = "forward_agg_for_admin.js";

function* workerForwardAggregator(payLoad) {
  console.log("payload subO-------------------->",JSON.stringify( payLoad.data));
  try {
    const response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.FORWARD_AGGREGATOR_FOR_ADMIN,
      payLoad.data
    );

    console.log(TAG,"payload subI-------------------->",JSON.stringify( response));
    yield put(createActionOf(FORWARD_AGGREGATOR_FOR_ADMIN_SUCCESS, 1, response));
  } catch (error) {
    console.error(TAG, "Worker forward bots failed: " + error.message);
    yield put(createActionOf(FORWARD_AGGREGATOR_FOR_ADMIN_FAILED, 2, error));
  }
}

export const watchForwardAggregator = takeLatest(
    FORWARD_AGGREGATOR_FOR_ADMIN,
  workerForwardAggregator
);
