import { LOADING } from "../../constants/constant"
import {
  BOT_LIST_FAILED,
  BOT_LIST_REQUESTED,
  BOT_LIST_RESET,
  BOT_LIST_SUCCESS,
} from "../actions/ActionType"

const initialState = {
  status: "INIT",
  isLoading: true,
}

export default function botListReducer(state = initialState, action) {
  switch (action.type) {
    case BOT_LIST_REQUESTED:
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      return reqstState

    case BOT_LIST_SUCCESS:
      let userRes = Object.assign({}, state)
      userRes.status = action.data.status_message
      userRes.isLoading = false
      userRes.statusCode = action.data.status_code
      userRes.rcsBots = action.data.data.rcs_bots
      userRes.whatsAppBots = action.data.data.wahtsapp_bots
      userRes.changeUnderReviewBots =
        action.data.data.change_under_bots ?? []
      return userRes

    case BOT_LIST_FAILED:
      return Object.assign({}, state, action.error)
    case BOT_LIST_RESET:
      return initialState

    default:
      return state
  }
}
