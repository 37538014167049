import { LOADING, STATUS_CODE_SUCCESS } from "../../../../constants/constant";
import {
  AGGREGATOR_POPULATE_LIST,
  AGGREGATOR_POPULATE_LIST_SUCCESS,
  AGGREGATOR_POPULATE_LIST_FAILED,
  AGGREGATOR_POPULATE_LIST_RESET,
  AGGREGATOR_BILLING_INFO,
  AGGREGATOR_BILLING_INFO_SUCCESS,
  AGGREGATOR_BILLING_INFO_FAILED,
  AGGREGATOR_BILLING_INFO_RESET,
} from "../../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export function getAllListsAggregatorBilling(state = initialState, action) {
  switch (action.type) {
    case AGGREGATOR_POPULATE_LIST:
      return { isLoading: true, status: LOADING, ...state };

    case AGGREGATOR_POPULATE_LIST_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.status = action.data.resData.status_message;
      userRes.isLoading = false;
      userRes.statusCode = action.data.resData.status_code;
      console.log("populate list ", action.data.resData);
      if (userRes.statusCode !== STATUS_CODE_SUCCESS) {
        userRes.error = true;
        userRes.errorMessage = action.data.resData.message;
        return userRes;
      }
      userRes.error = false;
      userRes.botList = action.data.resData?.bot_list ?? [];
      userRes.brandList = action.data.resData?.brand_list ?? [];
      userRes.carrierList = action.data.resData?.carrier_list ?? [];
      return userRes;

    case AGGREGATOR_POPULATE_LIST_FAILED:
      return Object.assign({}, state, action.error);
    case AGGREGATOR_POPULATE_LIST_RESET:
      return initialState;

    default:
      return state;
  }
}
export function getAggregatorBillingInfo(state = initialState, action) {
  switch (action.type) {
    case AGGREGATOR_BILLING_INFO:
      return { isLoading: true, status: LOADING, ...state };

    case AGGREGATOR_BILLING_INFO_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.status = action.data.resData.status_message;
      userRes.isLoading = false;
      userRes.statusCode = action.data.resData.status_code;
      console.log("populate list ", action.data.resData);
      if (userRes.statusCode !== STATUS_CODE_SUCCESS) {
        userRes.error = true;
        userRes.errorMessage = action.data.resData.message;
        return userRes;
      }
      userRes.error = false;
      userRes.botList = action.data.resData?.bot_list ?? [];
      userRes.brandList = action.data.resData?.brand_list ?? [];
      userRes.carrierList = action.data.resData?.carrier_list ?? [];
      return userRes;

    case AGGREGATOR_BILLING_INFO_FAILED:
      return Object.assign({}, state, action.error);
    case AGGREGATOR_BILLING_INFO_RESET:
      return initialState;

    default:
      return state;
  }
}
