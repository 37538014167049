import { useEffect } from "react";
import { useLocation } from "react-router-dom";
/**
 * Scrolls up every component to top whenever route changes.
 */
export default function ScrollToTop({children}) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
}
