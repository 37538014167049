import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import * as Api from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";

const TAG = "botScreensSaga.js";

function* getBotScreensWorker(payLoad) {

  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.BOT_SCREENS,
      payLoad.data
    );
    console.log(
      TAG,
      " Response: " + JSON.stringify(response)
    );
    yield put(
      memberAction.createActionOf(
        memberType.BOT_SCREENS_SUCCESS,
        1,
        response
      )
    );
  } catch (error) {
    console.log(TAG, " error: " + error.message);
    yield put(
      memberAction.createActionOf(
        memberType.BOT_SCREENS_FAILED,
        2,
        error
      )
    );
  }
}

export const watchGetBotScreens = takeLatest(
  memberType.BOT_SCREENS,
  getBotScreensWorker
);
