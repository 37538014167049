import { LOADING } from "../../../constants/constant";
import {
  GET_BOT_LIST_REQUESTED,
  GET_BOT_LIST_SUCCESS,
  GET_BOT_LIST_FAILED,
  GET_BRANDS_LIST_REQUESTED,
  GET_BRANDS_LIST_SUCCESS,
  GET_BRANDS_LIST_FAILED,
  GET_AGGREGATOR_LIST,
  GET_AGGREGATOR_LIST_SUCCESS,
  GET_AGGREGATOR_LIST_FAILED,
  GET_SUMMARY_CARDS,
  GET_SUMMARY_CARDS_FAILED,
  GET_SUMMARY_CARDS_SUCCESS,
  GET_DASHBOARD_ANALYTICS_RESET,
  GRAPH_DATA_REQUESTED,
  GRAPH_DATA_SUCCESS,
  GRAPH_DATA_FAILED,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export function getAggrigatorsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_AGGREGATOR_LIST:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_AGGREGATOR_LIST_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.status = action?.data?.data?.status_message;
      userRes.statusCode = action?.data?.data?.status_code;
      userRes.isLoading = false;
      //   userRes.statusCode = action.data.status;
      userRes.categoryList = action?.data?.data?.data;

      return userRes;

    case GET_AGGREGATOR_LIST_FAILED:
      return Object.assign({}, state, action.error);

    default:
      return state;
  }
}
export function getBrandsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BRANDS_LIST_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_BRANDS_LIST_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.status = action.data?.data?.status_message;
      userRes.statusCode = action.data?.data?.status_code;
      userRes.isLoading = false;
      //   userRes.statusCode = action.data.status;
      userRes.brandList = action.data?.data?.data;

      return userRes;

    case GET_BRANDS_LIST_FAILED:
      return Object.assign({}, state, action.error);

    default:
      return state;
  }
}
export function getBotsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BOT_LIST_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_BOT_LIST_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.status = action.data?.data.status_message;
      userRes.statusCode = action.data?.data.status_code;
      userRes.isLoading = false;
      //   userRes.statusCode = action.data.status;
      userRes.botList = action.data?.data?.data;

      return userRes;

    case GET_BOT_LIST_FAILED:
      console.error(
        "getBotsReducer => Case => Error: while getting category list: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);

    default:
      return state;
  }
}
export function getSummaryCardsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUMMARY_CARDS:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_SUMMARY_CARDS_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log(
        "getSummaryCardsReducer => Case => Success: while getting summary cards: " +
          action.data
      );
      userRes.status = action.data?.data.status_message;
      userRes.statusCode = action.data?.data.status_code;
      userRes.isLoading = false;
      //   userRes.statusCode = action.data.status;
      userRes.summaryCards = action.data?.data?.data;

      return userRes;

    case GET_SUMMARY_CARDS_FAILED:
      console.error(
        "getSummaryCardsReducer => Case => Error: while getting summary cards: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);

    default:
      return state;
  }
}

//
export function graphData(state = initialState, action) {
  switch (action.type) {
    case GRAPH_DATA_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GRAPH_DATA_SUCCESS:
      let filteredData = Object.assign({}, state);
      filteredData.isLoading = false;
      filteredData.statusCode = action.data.status_code;
      filteredData.cardSummary = action.data.data.finalMap
        ? action.data.data.finalMap
        : [];
      filteredData.tableData = action.data.data.summaryList
        ? action.data.data.summaryList
        : [];
      return filteredData;

    case GRAPH_DATA_FAILED:
      return Object.assign({}, state, action.error);

    case GET_DASHBOARD_ANALYTICS_RESET:
      return initialState;

    default:
      return state;
  }
}
