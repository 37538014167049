import { put, takeLatest, call } from "redux-saga/effects"
import {
  ADMIN_SUBMITTED_BOTS_FOR_LISTING,
  ADMIN_SUBMITTED_BOTS_FOR_LISTING_SUCCESS,
  ADMIN_SUBMITTED_BOTS_FOR_LISTING_FAILED,
  ADMIN_APPROVE_BOT_FOR_LISTING,
  ADMIN_APPROVE_BOT_FOR_LISTING_SUCCESS,
  ADMIN_APPROVE_BOT_FOR_LISTING_FAILED,
  ADMIN_REJECT_BOT_FOR_LISTING,
  ADMIN_REJECT_BOT_FOR_LISTING_SUCCESS,
  ADMIN_REJECT_BOT_FOR_LISTING_FAILED,
  ADMIN_GET_BOT_LISTING_HISTORY,
  ADMIN_GET_BOT_LISTING_HISTORY_SUCCESS,
  ADMIN_GET_BOT_LISTING_HISTORY_FAILED,
} from "../../../actions/ActionType"
import { API_ENDPOINT } from "../../../URLs/ApiEndpoints"
import { makeSecuredPostRequest } from "../../../../appApi/AppApi"
import { createActionOf } from "../../../actions/ActionCreator"

function* workerGetSubmittedBotsForListing(payLoad) {
  try {
    const response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.ADMIN_GET_ALL_BOTS_SUBMITTED_FOR_LISTING
    )
    yield put(
      createActionOf(ADMIN_SUBMITTED_BOTS_FOR_LISTING_SUCCESS, 1, response)
    )
  } catch (error) {
    yield put(createActionOf(ADMIN_SUBMITTED_BOTS_FOR_LISTING_FAILED, 2, error))
  }
}
function* workerApproveBotForListing(payLoad) {
  try {
    const response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.ADMIN_APPROVE_REJECT_LISTING,
      payLoad.data
    )
    yield put(
      createActionOf(ADMIN_APPROVE_BOT_FOR_LISTING_SUCCESS, 1, response)
    )
  } catch (error) {
    yield put(createActionOf(ADMIN_APPROVE_BOT_FOR_LISTING_FAILED, 2, error))
  }
}
function* workerRejectBotForListing(payLoad) {
  try {
    const response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.ADMIN_APPROVE_REJECT_LISTING,
      payLoad.data
    )
    yield put(createActionOf(ADMIN_REJECT_BOT_FOR_LISTING_SUCCESS, 1, response))
  } catch (error) {
    yield put(createActionOf(ADMIN_REJECT_BOT_FOR_LISTING_FAILED, 2, error))
  }
}
function* workerGetListingHistory(payLoad) {
  try {
    const response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.ADMIN_LISTING_BOT_HISTORY,
      payLoad.data
    )
    yield put(createActionOf(ADMIN_GET_BOT_LISTING_HISTORY_SUCCESS, 1, response))
  } catch (error) {
    yield put(createActionOf(ADMIN_GET_BOT_LISTING_HISTORY_FAILED, 2, error))
  }
}

export const watchGetSubmittedBotsForListing = takeLatest(
  ADMIN_SUBMITTED_BOTS_FOR_LISTING,
  workerGetSubmittedBotsForListing
)
export const watchGetApprovedBotsForListing = takeLatest(
  ADMIN_APPROVE_BOT_FOR_LISTING,
  workerApproveBotForListing
)
export const watchGetRejectedBotsForListing = takeLatest(
  ADMIN_REJECT_BOT_FOR_LISTING,
  workerRejectBotForListing
)
export const watchGetBotListingHistory = takeLatest(
  ADMIN_GET_BOT_LISTING_HISTORY,
  workerGetListingHistory
)
