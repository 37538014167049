import { lazy, Suspense } from "react";
import { Route, withRouter } from "react-router-dom";
import Fallback from "../../../components/Layout/Fallback";

const AdminDocumentationPage = lazy(() => import("../../../components/Pages/DocumentaionPages/AdminDocumentationPage"));

const CarrierLatestDocs = () => {
  return (
    <>
      <Suspense fallback={<Fallback />}>
        <Route path="/Carrier/Admin/latestdocumentation" exact component={AdminDocumentationPage} />
      </Suspense>
    </>
  );
};

export default withRouter(CarrierLatestDocs);
