import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

const TAG = "sendMessage.js";

function* workerSendMessage(payload) {

  try {
    if (!payload.data) {
      throw new Error(
        "Payload is undefined =>" + JSON.stringify(payload)
      );
    }
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.SEND_MESSAGE,payload.data
    );
    // console.log(
    //   TAG,
    //   "[worker get test devices:]" + JSON.stringify(response)
    // );
    yield put(memberAction.sendMessageSuccess(response));
  } catch (error) {
    console.error(TAG, "Error: while sending message: ", error.message);
    yield put(memberAction.sendMessageFailed(error.message));
  }
}

export const watchSendMessageSaga = takeLatest(
  memberType.SEND_MESSAGE_REQUESTED,
  workerSendMessage
);