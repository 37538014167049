import { put, takeLatest, call } from "redux-saga/effects"
import * as Api from "../../appApi/AppApi"
import { API_ENDPOINT } from "../URLs/ApiEndpoints"
import { createActionOf } from "../actions/ActionCreator"
import {
  SETUP_USER_DETAILS_FAILED,
  SETUP_USER_DETAILS_REQUESTED,
  SETUP_USER_DETAILS_SUCCESS,
} from "../actions/ActionType"

function* workerSetupUserDetails(payLoad) {
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.SET_UP_USER_DETAILS,
      payLoad.data
    )
    const successAction = createActionOf(
      SETUP_USER_DETAILS_SUCCESS,
      1,
      response
    )
    yield put(successAction)
  } catch (error) {
    const errorAction = createActionOf(
      SETUP_USER_DETAILS_FAILED,
      2,
      error.message
    )
    yield put(errorAction)
  }
}

export const watchSetupuserDetails = takeLatest(
  SETUP_USER_DETAILS_REQUESTED,
  workerSetupUserDetails
)
