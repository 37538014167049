import { put, takeLatest, call } from "redux-saga/effects"
import { createActionOf } from "../actions/ActionCreator"
import {
  CREATE_RCS_BOT,
  CREATE_RCS_BOT_FAILED,
  CREATE_RCS_BOT_SUCCESS,
} from "../actions/ActionType"
import * as Api from "../../appApi/AppApi"
import { API_ENDPOINT } from "../URLs/ApiEndpoints"

function* workerCreateRCSBot(payLoad) {
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.CREATE_RCS_BOT,
      payLoad.data
    )
    const successAction = createActionOf(CREATE_RCS_BOT_SUCCESS, 1, response)
    yield put(successAction)
  } catch (error) {
    const failedAction = createActionOf(CREATE_RCS_BOT_FAILED, 2, error)
    yield put(failedAction)
  }
}
export const watchCreateRCSBot = takeLatest(CREATE_RCS_BOT, workerCreateRCSBot)
