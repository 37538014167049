import { lazy, Suspense } from "react";
import { useRouteMatch, withRouter } from "react-router-dom";
import Fallback from "../../../components/Layout/Fallback";
import ProtectedRoute from "../../Routes/ProtectedRoute";

const DotgoMarketing = lazy(() => import("../../Routes/DotgoMarketing"));

const Marketing = (props) => {
  const { path } = useRouteMatch();

  return (
    <>
      <Suspense fallback={<Fallback />}>
        <ProtectedRoute key="marketing" path={`${path}`} component={DotgoMarketing} />
      </Suspense>
    </>
  );
};
export default withRouter(Marketing);
