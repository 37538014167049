import { LOADING } from "../../../constants/constant";
import {
    AGGREGATOR_HISTORY_FOR_ADMIN,
    AGGREGATOR_HISTORY_FOR_ADMIN_SUCCESS,
    AGGREGATOR_HISTORY_FOR_ADMIN_FAILED,
    AGGREGATOR_HISTORY_FOR_ADMIN_RESET
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function getAggHistoyReducer(state = initialState, action) {
  switch (action.type) {
    case AGGREGATOR_HISTORY_FOR_ADMIN: {
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;
    }

    case AGGREGATOR_HISTORY_FOR_ADMIN_SUCCESS: {
      let userRes = Object.assign({}, state);
      console.log(
        "agg history ----->" +
          JSON.stringify(action.data.data)
      );
      userRes.status = action.data.statusMessage;
      userRes.statusCode = action.data.status_code;
      userRes.statusMessage = action.data.status_message;
      userRes.isLoading = false;
      //   userRes.statusCode = action.data.status;
      // userRes.statusSting = action.data.data;
      userRes.historyList = action.data.data;

      return userRes;
    }
    case AGGREGATOR_HISTORY_FOR_ADMIN_FAILED: {
      console.error(
        "approveORRejectBotReducer => Case => Error: while approving or rejecting bots list: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);
    }

    case AGGREGATOR_HISTORY_FOR_ADMIN_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
