export const DEFAULT_STARTING_DATE =
  process.env.REACT_APP_DEFAULT_AT_START_DATE;
export const BILLING_ANALYTICS_URL =
  process.env.REACT_APP_BILLING_ANALYTICS_URL;

export const REACT_APP_AXIOS_TIMEOUT = parseInt(
  process.env.REACT_APP_AXIOS_TIMEOUT ?? "600000"
);
export const IS_TRAFFIC_AVAILABLE = process.env.REACT_APP_IS_TRAFFIC_AVAILABLE;

export const IS_AGGREGATOR_TRAFFIC_AVAILABLE =
  process.env.REACT_APP_IS_AGGREGATOR_TRAFFIC_AVAILABLE;

export const PUBLIC_KEY =
  process.env.REACT_APP_PUBLIC_KEY;  

  
export const CHARS_USED_STRING = "chars used";
export const STATUS_PENDING = "pending";

export let param_error = "Only alphanumeric characters and underscore are allowed in custom param. Spaces/other special characters are not allowed."
export let TRANS24_VALUE = "Trans24";

