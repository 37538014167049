import { put, takeLatest, call } from "redux-saga/effects";
import {
    AGGREGATOR_HISTORY_FOR_ADMIN_SUCCESS,
    AGGREGATOR_HISTORY_FOR_ADMIN,
    AGGREGATOR_HISTORY_FOR_ADMIN_FAILED
} from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";

const TAG = "get_approved_bots_for_admin.js";

function* workerGetHistory(payLoad) {
  // console.log("payload sub-------------------->",payLoad.data.data);
  try {
    const response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.AGGREGATOR_HISTORY_FOR_ADMIN,
      payLoad.data
    );
    yield put(createActionOf(AGGREGATOR_HISTORY_FOR_ADMIN_SUCCESS, 1, response));
  } catch (error) {
    console.error(TAG, "Worker get bots failed: " + error.message);
    yield put(createActionOf(AGGREGATOR_HISTORY_FOR_ADMIN_FAILED, 2, error));
  }
}

export const watchAggHistory = takeLatest(
    AGGREGATOR_HISTORY_FOR_ADMIN,
  workerGetHistory
);
