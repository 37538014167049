import { useContext, useEffect, lazy, Suspense } from "react";
import { Route, useRouteMatch, withRouter,Switch } from "react-router-dom";
import ProtectedRoute from "./Routes/ProtectedRoute";
import { AuthContext } from "../Providers/ContextProviders/AuthContext";
import { JSO_CLIENT_CONFIG, redirectToPage } from "../constants/constant";
import { JSO } from "../utils/jso/src/JSO";
import ComingSoon from "../components/Pages/ComingSoon";
import FAQ from "../containers/DotGoDevelopers/Public/FAQ/FAQ.container";
import PageNotFound from "../components/Pages/PageNotFound";
import Home from "./DotGoDevelopers/Public/Signup/Home";

import Developer from "./Routes/SuspenseRoutes/Developer";
import Billing from "./Routes/SuspenseRoutes/Billing";
import AggregatorDocs from "./Routes/SuspenseRoutes/AggregatorDocs";
import CarrierLatestDocs from "./Routes/SuspenseRoutes/CarrierLatestDocs";
import Marketing from "./Routes/SuspenseRoutes/Marketing";
import DotgoAdmin from "./Routes/SuspenseRoutes/DotgoAdmin";

import PrivacyPolicy from "../components/Pages/PrivacyPolicy";
import { ComingSoonVI } from "../components/Shared/ComingSoonVI";

import { CountryAndCarrierContainer } from "./CountryAndCarrierContainer/CountryAndCarrierContainer";
import EmailVerify from "./DotGoDevelopers/Public/Signup/EmailVerify";
import SetPassword from "./DotGoDevelopers/Public/Signup/SetPassword";
import Signup from "./DotGoDevelopers/Public/Signup/Signup";
import RBMPrivacyPolicy from "../components/Pages/RBMPrivacyPolicy";
import RBMPolicies from "../components/Pages/RBMPolicies";
import TermsOfService from "../components/Pages/TermsOfService";
import EsclationMatrix from "../components/Pages/EscalationMatrix/index.js";

/**
 * A root of all routes. Entry point for the app.
 *
 * Base path is "/"
 */
const Main = (props) => {
  const { path } = useRouteMatch(); // here path="/"
  let { setIsLoggedIn } = useContext(AuthContext);
  let client = new JSO(JSO_CLIENT_CONFIG);
  const callbackInfo = client.callback();
  let token = client.checkToken({ name: "user_info" });

  useEffect(() => {
    if (token !== null) {
      setIsLoggedIn(true);
    }
  }, [token]);

  useEffect(() => {
    // legacy path redirection
    if (props.history.location.pathname === "/terms-of-use") {
      props.history.replace("/developer-tos");
    }
    if (props.history.location.pathname === "/privacy-policy") {
      props.history.replace("/developer-pp");
    }
  }, [props.history]);

  if (callbackInfo?.access_token) {
    redirectToPage(props.history);
    return null;
  } else {
    return (
      <Switch>
        <Route path={path} component={Home} exact />
        <Route path={[`${path}developer/`]} key="developer-dashboard" component={Developer} />
        <Route path={`${path}billing/`} key="billing-root" component={Billing} />
        <Route key="dotgo-admin-root" path={`${path}dotgo-admin/`} component={DotgoAdmin} />
        <Route key="marketing" path={`${path}marketing/`} component={Marketing} />
        <Route path="/Carrier/Admin/latestdocumentation" exact component={CarrierLatestDocs} />
        <Route path="/Aggregator/documents" exact component={AggregatorDocs} />
        <Route path={`${path}escalation-matrix`} component={EsclationMatrix} exact />

        {/* <Suspense fallback={<Fallback />}>
          <ProtectedRoute path={[`${path}developer/`]} key="developer-dashboard" component={DotgoDeveloper} />
        </Suspense>
        <Suspense fallback={<Fallback />}>
          <RoleProtectedRoute secureFor={["VI_CARRIER_ADMIN", "VI_CARRIER_READONLY", "VI_CARRIER_READWRITE"]} key="billing-root" path={`${path}billing/`} component={DotGoBilling} />
        </Suspense>
        <Suspense fallback={<Fallback />}>
          <RoleProtectedRoute
            // secureFor={"admin"}
            secureFor={["SUPER_ADMIN_VI_DOTGO", "VI_DOTGO_ADMIN_READWRITE", "VI_DOTGO_ADMIN_READONLY", "VI_CARRIER_ADMIN", "VI_CARRIER_READONLY", "VI_CARRIER_READWRITE", "ACC_MANAGER"]}
            key="dotgo-admin-root"
            path={`${path}dotgo-admin/`}
            component={DotGoAdmin}
          />
        </Suspense>

        <Suspense fallback={<Fallback />}>
          <ProtectedRoute key="marketing" path={`${path}marketing/`} component={DotgoMarketing} />
          <Route path="/Carrier/Admin/latestdocumentation" exact component={AdminDocumentationPage} />
          <Route path="/Aggregator/documents" exact component={AggregatorDocumentationPage} />
        </Suspense> */}

        <Route path="/under-development" exact component={ComingSoon} />
        {/* <Route path="/Carrier/Admin/latestdocumentation" exact component={AdminDocumentationPage} /> */}
        <Route path="/faq" exact component={FAQ} />
        {/* <Route path={path} component={PublicRoutes} /> */}
        {/* <Route path="/Carrier/Admin/latestdocumentation" exact component={AdminDocumentationPage} />
        <Route path="/Aggregator/documents" exact component={AggregatorDocumentationPage} /> */}
        <Route path={`${path}sign-up`} component={Signup} exact />
        <Route path={`${path}email-verify`} component={EmailVerify} exact />
        <Route path={`${path}set-password`} component={SetPassword} exact />
        <Route path={`${path}developer-pp`} component={PrivacyPolicy} exact />
        <Route path={`${path}developer-tos`} component={TermsOfService} exact />
        <Route path={`${path}under-development`} component={ComingSoonVI} exact />

        <Route path={`${path}country-carrier-page`} component={CountryAndCarrierContainer} exact />
        <Route path={`${path}rbm-policies`} component={RBMPolicies} exact />

        <Route path={`${path}rbm-terms`} component={ComingSoonVI} exact />
        <Route path={`${path}404`} component={PageNotFound} exact />

        <Route path={`${path}developer-privacypolicy`} component={RBMPrivacyPolicy} exact />
        <Route path={"*"}>
          <PageNotFound />
        </Route>
      </Switch>
    );
  }
};
export default withRouter(Main);
