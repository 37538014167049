import { LOADING } from "../../../constants/constant";
import {
  GET_VERIFIED_LAUNCH_CARRIER,
  GET_VERIFIED_LAUNCH_CARRIER_SUCCESS,
  GET_VERIFIED_LAUNCH_CARRIER_FAILED,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function getVerifiedCarriers(state = initialState, action) {
  switch (action.type) {
    case GET_VERIFIED_LAUNCH_CARRIER:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_VERIFIED_LAUNCH_CARRIER_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.status = action.data.status_message;
      userRes.isLoading = false;
      userRes.statusCode = action.data.status_code;

      if (userRes.statusCode === 10) {
        userRes.error = false;
        userRes.carrierList = action.data.data;
        userRes.isRequestSubmitted = false;
      } else {
        userRes.error = true;
        userRes.errorMessage = action.data.message;
      }
      return userRes;

    case GET_VERIFIED_LAUNCH_CARRIER_FAILED:
      return Object.assign({}, state, action.error);

    default:
      return state;
  }
}
