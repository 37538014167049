import { lazy, Suspense } from "react";
import { useRouteMatch, withRouter } from "react-router-dom";
import Fallback from "../../../components/Layout/Fallback";
import RoleProtectedRoute from "../../Routes/RoleProtectedRoute";

const DotGoBilling = lazy(() => import("../../Routes/DotGoBilling"));

const Billing = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Suspense fallback={<Fallback />}>
        <RoleProtectedRoute secureFor={["VI_CARRIER_ADMIN", "VI_CARRIER_READONLY", "VI_CARRIER_READWRITE"]} key="billing-root" path={`${path}`} component={DotGoBilling} />
      </Suspense>
    </>
  );
};

export default withRouter(Billing);
