import { LOADING, STATUS_CODE } from "../../../constants/constant";
import {
    GET_TEMPLATE_JSON,
    GET_TEMPLATE_JSON_SUCCESS,
    GET_TEMPLATE_JSON_FAILED,
    GET_TEMPLATE_JSON_RESET
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function getTemplateJSONReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TEMPLATE_JSON:
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      reqstState.statusCode = STATUS_CODE
      return reqstState

    case GET_TEMPLATE_JSON_SUCCESS:
      let userRes = Object.assign({}, state)
      console.log(
        "getTemplateJSONReducer Success: " +
          JSON.stringify(action.data.data.data)
      )
      //userRes.status = action.data.status;
      userRes.isLoading = false
      userRes.message = action.data.data.message
      userRes.statusCode = action.data.data.status_code
      // userRes.message="action.data.data.mesaage"
      // userRes.statusCode= "11"

      userRes.data = action.data.data.data
      return userRes

    case GET_TEMPLATE_JSON_FAILED:
      console.error(
        "getTemplateJSONReducer => Error: while getting template JSON: " +
          JSON.stringify(action.data.error)
      )
      return Object.assign({}, state, action.data.error)

    case GET_TEMPLATE_JSON_RESET:
      return initialState

    default:
      return state
  }
}
