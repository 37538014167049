import { put, takeLatest, call } from "redux-saga/effects";
import {
    BRAND_HISTORY_FOR_ADMIN_SUCCESS,
    BRAND_HISTORY_FOR_ADMIN_REQUESTED,
    BRAND_HISTORY_FOR_ADMIN_FAILED
} from "../../../actions/ActionType";
import { API_ENDPOINT } from "../../../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../../../appApi/AppApi";
import { createActionOf } from "../../../actions/ActionCreator";

const TAG = "BrandHistory.js.js";

function* workerGetBrandHistory(payLoad) {
  // console.log("payload sub-------------------->",payLoad.data.data);
  try {
    const response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.BRAND_HISTORY_FOR_ADMIN,
      payLoad.data
    );
    console.log('history respo in saga:',response);
    yield put(createActionOf(BRAND_HISTORY_FOR_ADMIN_SUCCESS, 1, response));
  } catch (error) {
    console.error(TAG, "Worker get history failed: " + error.message);
    yield put(createActionOf(BRAND_HISTORY_FOR_ADMIN_FAILED, 2, error));
  }
}

export const watchBrandHistory = takeLatest(
    BRAND_HISTORY_FOR_ADMIN_REQUESTED,
    workerGetBrandHistory
);
