import { LOADING, STATUS_CODE_SUCCESS } from "../../../constants/constant";
import {
  GET_PARTNERS_CARRIERS,
  GET_PARTNERS_CARRIERS_SUCCESS,
  GET_PARTNERS_CARRIERS_FAILED,
  GET_PARTNERS_CARRIERS_RESET_STATE,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function getPartnersCarriers(state = initialState, action) {
  switch (action.type) {
    case GET_PARTNERS_CARRIERS:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_PARTNERS_CARRIERS_SUCCESS:
      let userRes = Object.assign({}, state);

      console.log("came to the getPartnersCarriers   ", action.data.data);
      userRes.status = action.data.data.status_message;
      userRes.isLoading = false;
      userRes.statusCode = action.data.data.status_code;

      if (userRes.statusCode !== STATUS_CODE_SUCCESS) {
        userRes.error = true;
        userRes.errorMessage = action.data.data.message;
        return userRes;
      }
      userRes.isError = false;
      userRes.carrierList = action.data.data.data;
      return userRes;

    case GET_PARTNERS_CARRIERS_FAILED:
      return Object.assign({}, state, action.error);
    case GET_PARTNERS_CARRIERS_RESET_STATE:
      return initialState;

    default:
      return state;
  }
}
