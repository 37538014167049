import { LOADING } from "../../../../constants/constant"
import {
  ADMIN_SUBMITTED_BOTS_FOR_LISTING,
  ADMIN_SUBMITTED_BOTS_FOR_LISTING_RESET,
  ADMIN_SUBMITTED_BOTS_FOR_LISTING_SUCCESS,
  ADMIN_SUBMITTED_BOTS_FOR_LISTING_FAILED,
  ADMIN_APPROVE_BOT_FOR_LISTING,
  ADMIN_APPROVE_BOT_FOR_LISTING_SUCCESS,
  ADMIN_APPROVE_BOT_FOR_LISTING_FAILED,
  ADMIN_APPROVE_BOT_FOR_LISTING_RESET,
  ADMIN_REJECT_BOT_FOR_LISTING,
  ADMIN_REJECT_BOT_FOR_LISTING_SUCCESS,
  ADMIN_REJECT_BOT_FOR_LISTING_FAILED,
  ADMIN_REJECT_BOT_FOR_LISTING_RESET,
  ADMIN_GET_BOT_LISTING_HISTORY,
  ADMIN_GET_BOT_LISTING_HISTORY_RESET,
  ADMIN_GET_BOT_LISTING_HISTORY_SUCCESS,
  ADMIN_GET_BOT_LISTING_HISTORY_FAILED,
} from "../../../actions/ActionType"

const initialState = {
  status: "INIT",
  isLoading: true,
}
const approveInitialState = {
  status: "INIT",
  isLoading: null,
}

export function adminSubmittedForListing(state = initialState, action) {
  switch (action.type) {
    case ADMIN_SUBMITTED_BOTS_FOR_LISTING: {
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      return reqstState
    }

    case ADMIN_SUBMITTED_BOTS_FOR_LISTING_SUCCESS: {
      let userRes = Object.assign({}, state)
      userRes.status = action.data.statusMessage
      userRes.statusCode = action.data.statusCode
      userRes.isLoading = false
      userRes.approvedBotList = action.data.data?.listed_bots ?? []
      userRes.rejectedBotList = action.data.data?.rejected_bots ?? []
      userRes.submittedBots = action.data.data?.submmited_bots ?? []

      return userRes
    }
    case ADMIN_SUBMITTED_BOTS_FOR_LISTING_FAILED: {
      return Object.assign({}, state, action.error)
    }

    case ADMIN_SUBMITTED_BOTS_FOR_LISTING_RESET: {
      return initialState
    }

    default:
      return state
  }
}
export function adminApproveBotForListing(state = approveInitialState, action) {
  switch (action.type) {
    case ADMIN_APPROVE_BOT_FOR_LISTING: {
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      return reqstState
    }

    case ADMIN_APPROVE_BOT_FOR_LISTING_SUCCESS: {
      let userRes = Object.assign({}, state)
      userRes.status = action.data.status_message
      userRes.statusCode = action.data.status_code
      userRes.isLoading = false
      userRes.message = action.data.message

      return userRes
    }
    case ADMIN_APPROVE_BOT_FOR_LISTING_FAILED: {
      return Object.assign({}, state, action.error)
    }

    case ADMIN_APPROVE_BOT_FOR_LISTING_RESET: {
      return approveInitialState
    }

    default:
      return state
  }
}
export function adminRejectBotForListing(state = approveInitialState, action) {
  switch (action.type) {
    case ADMIN_REJECT_BOT_FOR_LISTING: {
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      return reqstState
    }

    case ADMIN_REJECT_BOT_FOR_LISTING_SUCCESS: {
      let userRes = Object.assign({}, state)
      userRes.status = action.data.status_message
      userRes.statusCode = action.data.status_code
      userRes.isLoading = false
      userRes.message = action.data.message

      return userRes
    }
    case ADMIN_REJECT_BOT_FOR_LISTING_FAILED: {
      return Object.assign({}, state, action.error)
    }

    case ADMIN_REJECT_BOT_FOR_LISTING_RESET: {
      return approveInitialState
    }

    default:
      return state
  }
}
export function adminListingHistory(state = approveInitialState, action) {
  switch (action.type) {
    case ADMIN_GET_BOT_LISTING_HISTORY: {
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      return reqstState
    }

    case ADMIN_GET_BOT_LISTING_HISTORY_SUCCESS: {
      let userRes = Object.assign({}, state)
      userRes.status = action.data.status_message
      userRes.statusCode = action.data.status_code
      userRes.isLoading = false
      userRes.message = action.data.message
      userRes.historyList = action.data.data
      userRes.isHistoryPresent = action.data.data !== "No history present"

      return userRes
    }
    case ADMIN_GET_BOT_LISTING_HISTORY_FAILED: {
      return Object.assign({}, state, action.error)
    }

    case ADMIN_GET_BOT_LISTING_HISTORY_RESET: {
      return approveInitialState
    }

    default:
      return state
  }
}
