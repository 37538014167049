import { put, takeLatest, call } from "redux-saga/effects";
import * as Api from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../../store/URLs/ApiEndpoints";
import { createActionOf } from "../../actions/ActionCreator";
import {
  SUBMIT_LAUNCH_CARRIER,
  SUBMIT_LAUNCH_CARRIER_FAILED,
  SUBMIT_LAUNCH_CARRIER_SUCCESS,
} from "../../actions/ActionType";

function* workerSubmitCarrier(payLoad) {
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.LAUNCH_SUBMIT_CARRIER,
      payLoad.data
    );

    yield put(createActionOf(SUBMIT_LAUNCH_CARRIER_SUCCESS, 1, response));
  } catch (error) {
    yield put(createActionOf(SUBMIT_LAUNCH_CARRIER_FAILED, 2, error));
  }
}

export const watchSubmitCarrier = takeLatest(
  SUBMIT_LAUNCH_CARRIER,
  workerSubmitCarrier
);
