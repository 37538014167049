import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import * as Api from "../../appApi/AppApi";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";

function* workerSubmitBrand(payLoad) {
  console.log("worker workerSubmitBrand : " + JSON.stringify(payLoad.data));
  try {
    const response = yield call(
      Api.makePostRequestForMultipart,
      API_ENDPOINT.SUBMIT_BRAND,
      payLoad.data
    );
    console.log(
      "worker workerSubmitBrand Response: " + JSON.stringify(response)
    );
    yield put(memberAction.submitBrandSuccess(response));
  } catch (error) {
    console.log("worker workerSubmitBrand error: " + error.message);
    yield put(memberAction.submitBrandFailed(error.message));
  }
}

export const watchSubmitBrand = takeLatest(
  memberType.SUBMIT_BRAND_REQUESTED,
  workerSubmitBrand
);
