import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

const TAG = "deleteTestDevices.js";

function* workerDeleteTestDevices(payload) {
  try {
    if (!payload.data) {
      throw new Error(
        "test device id is undefined: Payload=>" + JSON.stringify(payload)
      );
    }
    const response = yield call(
      Api.makeSecuredDeleteRequest,
      API_ENDPOINT.DELETE_TEST_DEVICES+payload.data
    );
    // console.log(
    //   TAG,
    //   "[worker get test devices:]" + JSON.stringify(response)
    // );
    yield put(memberAction.deleteTestDevicesSuccess(response));
  } catch (error) {
    console.error(TAG, "Error: while deletingtest devices: ", error.message);
    yield put(memberAction.deleteTestDevicesFailed(error.message));
  }
}

export const watchDeleteTestDevicesSaga = takeLatest(
  memberType.DELETE_TEST_DEVICES_REQUESTED,
  workerDeleteTestDevices
);