import { call, put, takeLatest } from "redux-saga/effects";
import * as Api from "../../appApi/AppApi";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";

function* workerGetBotDetails(payload) {
  try {
    let apiEndpoint = API_ENDPOINT.GET_BOT_DETAILS;
    if (/admin/i.test(payload.data?.whoIsThis)) {
      apiEndpoint = API_ENDPOINT.ADMIN_GET_BOT_DETAILS;
    }
    const response = yield call(
      Api.makeSecuredPostRequest,
      apiEndpoint,
      payload.data
    );
    yield put(memberAction.getBotDetailsSuccess(response));
  } catch (error) {
    yield put(memberAction.getBotDetailsFailed(error.message));
  }
}

export const watchGetBotDetailsSaga = takeLatest(
  memberType.GET_BOT_DETAILS,
  workerGetBotDetails
);
