import { LOADING, STATUS_CODE } from "../../../constants/constant";
import {
  GET_VERIFIED_BOT_DETAILS,
  GET_VERIFIED_BOT_DETAILS_SUCCESS,
  GET_VERIFIED_BOT_DETAILS_FAILED,
  GET_VERIFIED_BOT_DETAILS_RESET
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function getVerifiedBotDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_VERIFIED_BOT_DETAILS:
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      reqstState.statusCode = STATUS_CODE
      return reqstState

    case GET_VERIFIED_BOT_DETAILS_SUCCESS:
      let userRes = Object.assign({}, state)
      console.log(
        "getVerifiedBotDetailsReducer  Success: " +
          JSON.stringify(action.data)
      )
      //userRes.status = action.data.status;
      userRes.isLoading = false
      userRes.status = action.data.status_message
      userRes.statusCode = action.data.status_code

      userRes.data = action.data.data
      return userRes

    case GET_VERIFIED_BOT_DETAILS_FAILED:
      console.error(
        "getVerifiedBotDetailsReducer => Error: while getting bot details: " +
          JSON.stringify(action.error)
      )
      return Object.assign({}, state, action.error)

    case GET_VERIFIED_BOT_DETAILS_RESET:
      return initialState

    default:
      return state
  }
}
