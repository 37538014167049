import { Loader } from "semantic-ui-react";
const Spinner = (props) => {
  return (
    <div
      style={{ height: `${props.vh ? "100vh" :props.hightVH?"15vh": "auto"}`,
      maxWidth: `${props.vw ? "100vh" :props.hightVW?"15vh": "auto"}`
    }}
      className="container spinner"
    >
      <Loader {...props} />
    </div>
  );
};
export default Spinner;
