import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import {GET_AGENT_MSG_LIST } from "../URLs/ApiEndpoints";
import * as Api from "../../appApi/AppApi";

function* workerAgentMsgTypeList(payLoad) {
  try {
    const response = yield call(
      Api.makeUnsecureGetRequest,
      GET_AGENT_MSG_LIST
    );
    yield put(memberAction.agentMessageTypeListSuccess(response));
  } catch (error) {
    yield put(memberAction.agentMessageTypeListFailed(error.message));
  }
}

export const watchAgentMsgTypeList = takeLatest(
  memberType.GET_AGENT_MSG_TYPE_REQUESTED,
  workerAgentMsgTypeList
);
