import { LOADING } from "../../../constants/constant";
import {
    DELETE_TEMPLATE_FAILED,
    DELETE_TEMPLATE,
    DELETE_TEMPLATE_RESET,
    DELETE_TEMPLATE_SUCCESS,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function deleteTemplateReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_TEMPLATE:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case DELETE_TEMPLATE_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log(
        "DELETE template reducer => Case => Success: " +
          JSON.stringify(action.data)
      );
      userRes.status_message = action.data.data.status_message;
      userRes.statusCode = action.data.data.status_code;
      userRes.isLoading = false;
      userRes.message=action.data?.data?.message;

      return userRes;

    case DELETE_TEMPLATE_FAILED:
      console.error(
        "DELETE template Reducer => Case => Error: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);

    case DELETE_TEMPLATE_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
