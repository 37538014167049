import { LOADING } from "../../../constants/constant";
import {
  UPDATE_CARRIER_PROFILE_ADMIN_PORTAL,
  UPDATE_CARRIER_PROFILE_FAILED_ADMIN_PORTAL,
  UPDATE_CARRIER_PROFILE_RESET_ADMIN_PORTAL,
  UPDATE_CARRIER_PROFILE_SUCCESS_ADMIN_PORTAL
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
  statusCode : LOADING,
};

export default function updateCarrierProfileAdminPortalReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CARRIER_PROFILE_ADMIN_PORTAL: {
      let reqState = Object.assign({}, state); //doubt y this instead of simple object creation
      reqState.isLoading = true;
      reqState.status = LOADING;
      return reqState;
    }

    case UPDATE_CARRIER_PROFILE_SUCCESS_ADMIN_PORTAL: {
      let userResponse = Object.assign({}, state);
    //   console.log(
    //     "updateCarrierProfileReducer ====>>>y " +
    //       JSON.stringify(action.data)
    //   );
      userResponse.status = action.data.status_message;
      userResponse.statusCode = action.data.status_code;
      userResponse.isLoading = false;
      // userResponse.updatedCarrierProfileData = action.data.data;

      return userResponse;
    }
    case UPDATE_CARRIER_PROFILE_FAILED_ADMIN_PORTAL: {
      console.error(
        "updateCarrierProfileReducer =====>>failed: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);
    }

    case UPDATE_CARRIER_PROFILE_RESET_ADMIN_PORTAL: {
      return initialState;
    }

    default:
      return state;
  }
}
