import { API_ENDPOINT, JWT_SECRETE_KEY } from "../store/URLs/ApiEndpoints";
import {
  BOT_CONSOLE_LAUNCH_DETAILS_SUBMITTED,
  BOT_STATUS_SUBMITTED_FOR_LAUNCH,
  BOT_STATUS_LAUNCHED,
  BOT_STATUS_LAUNCH_FAILED,
  DOTGO_BOT_STATUS_APPROVED,
  DOTGO_BOT_STATUS_REJECTED,
  DOTGO_STATUS_BOT_TO_BE_APPROVED_BY_CARRIER,
  DOTGO_STATUS_BOT_APPROVED_BY_DOTGO_PENDING_FROM_CARRIER,
  DOTGO_BOT_STATUS_FORWARDED,
  config,
} from "../constants/constant";
import CryptoJS from "crypto-js";
import moment from "moment";
import { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import cookie from "js-cookie";
import { DEFAULT_STARTING_DATE, PUBLIC_KEY } from "../constants/stringConstants";
import JSEncrypt from "jsencrypt";
const uuid = require("uuid");
/**
 * @param {Object} payload
 */
export const makeSign = (payload) => {
  // let signedData = jwt.sign(payload, JWT_SECRETE_KEY);
  let cipherText = CryptoJS.AES.encrypt(
    JSON.stringify(payload),
    JWT_SECRETE_KEY
  ).toString();
  return cipherText;
};
/**
 * @param {Object} payload
 */
export const decodeSign = (cipherText = "") => {
  try {
    let bytes = CryptoJS.AES.decrypt(cipherText, JWT_SECRETE_KEY);
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (error) {
    return "";
  }
};
/**
 * Checks the substring "pdf" present in mime-type of file.
 * @param {File} file
 * @returns {boolean}
 */
export const isFilePDF = (file) => {
  try {
    return file.type.indexOf("pdf") !== -1;
  } catch (error) {
    return false;
  }
};
/**
 * Checks whether token is valid or not.
 * Check is based on "token expiry time" and "current time".
 * @returns {boolean} true/false
 */
export const isTokenValid = () => {
  // const token = localStorage.getItem("dotgo-token")
  const token = cookie.get("dotgo-token");
  if (!token) {
    return false;
  }
  let tokenExpiryTime = JSON.parse(token)[0].expires;
  let currentTime = Math.floor(new Date().getTime() / 1000);
  return tokenExpiryTime > currentTime;
};

export const onBackByOne = () => {
  window.history.go(-1);
};
export const capitalizeFirst = (string) =>
  string.trim().replace(string.charAt(0), string.charAt(0).toUpperCase());

export const sortGroupedArray = (mainArray) => {
  try {
    let currencyCode = mainArray.currency_code;
    let items = Object.entries(mainArray);
    let sorting = [
      "basic_message",
      "single_message",
      "a2p_conversation",
      "p2a_conversation",
      // "rich_otp",
      "revenue",
    ];
    let result = [];

    sorting.forEach(function (key) {
      var found = false;
      items = items.filter(function (item) {
        if (!found && item[0] === key) {
          result.push(item);
          found = true;
          return false;
        } else {
          return true;
        }
      });
    });
    let finalResult = result.map((ele) => ele[1]);
    let lastIndex = finalResult.length - 1;
    // finalResult[lastIndex] = inDollarFormat(finalResult[lastIndex]);
    finalResult[lastIndex] = formatCurrency(
      finalResult[lastIndex],
      currencyCode,
      false,
      true
    );
    return finalResult;
  } catch (error) {
    return [];
  }
};

//Added Temporarily
export const sortGroupedArrayTemp = (mainArray) => {
  try {
    let items = Object.entries(mainArray);
    let sorting = [
      "basic_message",
      "single_message",
      "a2p_conversation",
      "p2a_conversation",
      // "rich_otp",
      // "revenue",
    ];
    let result = [];

    sorting.forEach(function (key) {
      var found = false;
      items = items.filter(function (item) {
        if (!found && item[0] === key) {
          result.push(item);
          found = true;
          return false;
        } else return true;
      });
    });
    let finalResult = result.map((ele) => ele[1]);
    let lastIndex = finalResult.length - 1;
    // finalResult[lastIndex] = inDollarFormat(finalResult[lastIndex]);
    // finalResult[lastIndex] = makeNumberCompactNi(finalResult[lastIndex]);
    return finalResult;
  } catch (error) {
    return [];
  }
};
/**
 * @param {object[]} array of objects.
 * @param {String} key of which basis array needs to be grouped.
 * @returns {Array} returns array of grouped elements.
 */
export const groupBy = function (array, key) {
  let res = array.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
  return Object.values(res);
};

/**
 * Converts number in en-US representation.
 * @param {Number} number to convert in en-US format
 */
export const inDollarFormat = (number = 0, prettier = false) => {
  try {
    number = number.toFixed(2);
    number = +number;
    let conf = {
      style: "currency",
      currency: "USD",
    };
    let formatted = number.toLocaleString("en-US", conf);
    return prettier ? formatted.toString().replace("$", "$  ") : formatted;
  } catch (error) {
    return "";
  }
};

export const inINRFormat = (number = 0, prettier = false) => {
  if (isNaN(number)) {
    number = 0;
  }
  try {
    if (typeof number === "string") {
      number = parseFloat(number);
    }
    let conf = {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    };
    let formatted = number.toLocaleString("en-IN", conf);

    return prettier ? formatted.toString().replace("₹", "₹ ") : formatted;
  } catch (error) {
    return "";
  }
};
/**
 * A simple hack for checking support for "notation" option by browser's Intl API.
 * Hack: If browser doesn't format 1000 to 1K (gives 1,000 instead of 1K) then it doesn't support.
 *
 * Needs valid solution.
 */
const requirePolyfill = () => {
  let testFormatter = new Intl.NumberFormat("en", {
    notation: "compact",
  });
  return testFormatter.format(1000).indexOf("K") === -1 ? true : false;
};
/**
 * Simple polyfill for browsers that don't fully support Intl API.
 *
 ** @see {@link https://stackoverflow.com/a/9462382|stackoverflow answer}
 * @param {number} num number to format.
 * @param {number} digits to show.
 */
function compactNumberPolyfill(
  num = 0,
  config = { currencySymbol: "$", digits: 2, formatOnly: false }
) {
  if (num < 0) {
    num = Math.abs(num);
  }
  if (/\w/g.test(config.currencySymbol) === false) {
    config.currencySymbol = "";
  }
  // prettier-ignore
  let formats = [
    { value: 1, symbol: "" },
    {value: 1E0, symbol: ""},
    { value: 1E3, symbol: "K" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "G" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];

  let i;
  for (i = formats.length - 1; i > 0; i--) {
    if (Math.abs(num) >= formats[i].value) {
      break;
    }
  }
  let formatted =
    (num / formats[i].value).toFixed(config.digits) + formats[i].symbol;
  if (config.formatOnly === true) {
    return `${formatted}`;
  } else {
    // Uncomment 👇 2 lines if you don't want only zeros in end. e.g. 1.0 -> 1
    // let rx = /\.0+$|(\.[0-9]*[1-9])0+$/; // used to trim trailing zeros e.g. 1.0 becomes 1 and 1.10 becomes 1.1
    // formatted = formatted.replace(rx, "");
    return `${config.currencySymbol} ${formatted}`;
  }
}
export const formatCurrency = (
  number = 0,
  currencyCode = "",
  noCurrency = false,
  htmlFormat = false
) => {
  if (number < 0) {
    number = Math.abs(number);
  }
  try {
    if (noCurrency === false && !currencyCode) {
      return "-";
    }
    if (requirePolyfill()) {
      let polyFormatted = compactNumberPolyfill(number, {
        currencySymbol: currencyCode,
        formatOnly: noCurrency,
      });
      return polyFormatted;
    }
    let formatter = new Intl.NumberFormat("en", {
      notation: "compact",
    });
    let formatted = undefined;
    if (noCurrency === true) {
      formatted = formatter.format(number);
      return formatted;
    } else {
      formatter = new Intl.NumberFormat("en-US", {
        notation: "compact",
        style: "currency",
        currency: currencyCode,
        currencyDisplay: "symbol",
        minimumFractionDigits: 2,
      });
      const strValue = formatter.format(number);
      const value = strValue.replace(/^\D+/g, "");
      const currencySymbol = strValue.replace(value, "");
      formatted = `${currencySymbol}  ${value}`;
      if (htmlFormat && currencySymbol !== "$")
        formatted = `${currencySymbol}&nbsp;&nbsp;&nbsp;${value}`;
      return formatted;
    }
  } catch (error) {
    return "-";
  }
};
/**
 * Makes large number in compact size like: 1.2K 1.5M
 * @param {Number} number to format
 */
export const makeNumberCompact = (number = 0, ...args) => {
  if (requirePolyfill()) {
    let polyFormatted = compactNumberPolyfill(number, { formatOnly: args[0] });
    return polyFormatted;
  }
  let formatter = new Intl.NumberFormat("en", {
    notation: "compact",
  });
  let formatted = undefined;
  if (args[0]) {
    formatted = formatter.format(number);
    return formatted;
  } else {
    formatter = new Intl.NumberFormat("en", {
      notation: "compact",
      style: "currency",
      currency: args ? (args[1] ? args[1] : "USD") : "USD",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
    });
    // formatted = formatter.format(number).toLowerCase().replace(/\$/, "$ ");
    formatted = formatter.format(number).replace(/\$/, "$ ");
    return formatted;
  }
};
export const makeNumberCompactNi = (number = 0, onlyFormat) => {
  if (requirePolyfill()) {
    let polyFormatted = compactNumberPolyfill(number, {
      formatOnly: onlyFormat,
    });
    return polyFormatted.replace(/\$/, "₦ ");
  }
  let formatter = new Intl.NumberFormat("en", {
    notation: "compact",
  });
  let formatted = undefined;
  if (onlyFormat) {
    formatted = formatter.format(number);
    return formatted;
  } else {
    formatter = new Intl.NumberFormat("en", {
      notation: "compact",
      style: "currency",
      currency: "NGN",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
    });
    // formatted = formatter.format(number).toLowerCase().replace(/\$/, "$ ");
    formatted = formatter.format(number).replace(/NGN/, "₦ ");
    return formatted;
  }
};
export const makeNumberCompactRupee = (number = 0, onlyFormat) => {
  if (requirePolyfill()) {
    let polyFormatted = compactNumberPolyfill(number, {
      formatOnly: onlyFormat,
    });
    return polyFormatted.replace(/₹/, "₹ ");
  }
  let formatter = new Intl.NumberFormat("en", {
    notation: "compact",
  });
  let formatted = undefined;
  if (onlyFormat) {
    formatted = formatter.format(number);
    return formatted;
  } else {
    formatter = new Intl.NumberFormat("en", {
      notation: "compact",
      style: "currency",
      currency: "INR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
    });
    // formatted = formatter.format(number).toLowerCase().replace(/\$/, "$ ");
    formatted = formatter.format(number).replace(/₹/, "₹ ");
    return formatted;
  }
};
export const getLastWeekRange = () => {
  let startOfLastWeek = moment()
    .startOf("week")
    .subtract(7, "days")
    .format("YYYY-MM-DD");
  let endOfLastWeek = moment()
    .startOf("week")
    .subtract(1, "day")
    .format("YYYY-MM-DD");
  return [startOfLastWeek, endOfLastWeek];
};

export const getCurrentWeekRange = () => {
  let startOfLastWeek = moment()
    .startOf("week")
    .subtract(6, "days")
    .format("YYYY-MM-DD");
  let endOfLastWeek = moment().startOf("week").format("YYYY-MM-DD");
  return [startOfLastWeek, endOfLastWeek];
};

export const getDefaultDateRange = () => {
  let startDate = moment(DEFAULT_STARTING_DATE);
  let endDate = moment();
  return { startDate, endDate };
};

export const getRSFormatList = (countryList = [], keys = []) => {
  const formatted = countryList.map((ele) => ({
    value: ele[keys[0]],
    label: ele[keys[1]],
    data: ele,
  }));
  return formatted;
};

export const getRSBillingCategoryList = (categoryList = []) => {
  const formatted = categoryList.map((ele) => ({
    value: ele["billingCategoryName"],
    label: ele["billingCategoryName"],
    data: ele,
  }));
  return formatted;
};

export const getSymbolSeparatedString = (
  array = [],
  key = "",
  symbol = " | "
) => {
  try {
    return array
      .map((ele) => ele[key])
      .reduce((acc = "", ele = "") => acc + symbol + ele);
  } catch (error) {
    return "";
  }
};
/**
 * Resets localStorage on browser, removing everything except auth token.
 * Useful when dealing with temp data stored, during submission flows.
 */
export const resetLocalStorage = () => {
  let itemNotToDelete = localStorage.getItem("dotgo-token");
  localStorage.clear();
  localStorage.setItem("dotgo-token", itemNotToDelete);
};
export const LAUNCH_STATUSES = [
  BOT_CONSOLE_LAUNCH_DETAILS_SUBMITTED,
  BOT_STATUS_SUBMITTED_FOR_LAUNCH,
  BOT_STATUS_LAUNCHED,
  BOT_STATUS_LAUNCH_FAILED,
  DOTGO_BOT_STATUS_FORWARDED,
  DOTGO_BOT_STATUS_APPROVED,
  DOTGO_BOT_STATUS_REJECTED,
  DOTGO_STATUS_BOT_TO_BE_APPROVED_BY_CARRIER,
  DOTGO_STATUS_BOT_APPROVED_BY_DOTGO_PENDING_FROM_CARRIER,
];
// checks falsy values except 0 and false
export const looselyFalsy = (value) => {
  if (value === undefined || value === "" || value === null || isNaN(value)) {
    return true;
  }
  return false;
};
export const useBase64Image = (blob) => {
  const [img, setImage] = useState();
  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        resolve(reader.result);
      };
      reader.onerror = () => {
        setImage("");
        resolve("");
      };
      reader.readAsDataURL(blob);
    });
  }
  useEffect(() => {
    if (blob) {
      blobToBase64(blob);
    }
  }, [blob]);
  return { img };
};

export const formatDateFromTimeStamp = (stampedDate) => {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  try {
    const parsedDate = new Date(stampedDate);
    let date = new Date(parsedDate).getDate();

    let month = months[new Date(parsedDate).getMonth()];

    let year = new Date(parsedDate).getFullYear();
    /**
     * Safari browser requires date in different format for "Date()" constructor or Date.parse() to parse the string.
     * Ex.
     * Chrome/Firefox: supports "2011-10-10 14:48:00" and "2011-10-10T14:48:00"
     * Safari: "2011-10-10T14:48:00"
     * refer: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#date_time_string_format
     */
    if (Number.isNaN(date) || Number.isNaN(month) || Number.isNaN(year)) {
      const pollyParsedDate = new Date(stampedDate.replace(/\s/, "T"));
      date = new Date(pollyParsedDate).getDate();
      month = months[new Date(pollyParsedDate).getMonth()];
      year = new Date(pollyParsedDate).getFullYear();
      return `${date} ${month} ${year}`;
    }
    return `${date} ${month} ${year}`;
  } catch (error) {
    return "";
  }
};
// TODO: Use moment library for formatting/validating/parsing dates
// Still has compatibility issues, refer DGDEVCLNT-824
export const formatDateWithTime = (stampedDate) => {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  try {
    const parsedDate = new Date(stampedDate);
    let date = new Date(parsedDate).getDate();

    let month = months[new Date(parsedDate).getMonth()];

    let year = new Date(parsedDate).getFullYear();
    let hour = new Date(parsedDate).getHours();
    let minutes = new Date(parsedDate).getMinutes();
    /**
     * Safari browser requires date in different format for "Date()" constructor or Date.parse() to parse the string.
     * Ex.
     * Chrome/Firefox: supports "2011-10-10 14:48:00" and "2011-10-10T14:48:00"
     * Safari: "2011-10-10T14:48:00"
     * refer: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#date_time_string_format
     */
    if (Number.isNaN(date) || Number.isNaN(month) || Number.isNaN(year)) {
      const pollyParsedDate = new Date(stampedDate.replace(/\s/, "T"));
      date = new Date(pollyParsedDate).getDate();
      month = months[new Date(pollyParsedDate).getMonth()];
      year = new Date(pollyParsedDate).getFullYear();
      hour = new Date(pollyParsedDate).getHours();
      minutes = new Date(pollyParsedDate).getMinutes();

      return `${date} ${month} ${year}`` ${hour}${":"}${minutes}`;
    }
    return `${date} ${month} ${year} ${"  "} ${hour} ${":"} ${minutes}`;
  } catch (error) {
    return "";
  }
};

export const getSubDomain = () => {
  return process.env.REACT_APP_SUBDOMAIN;
};
export const shortenStringBy = (value = "", charLength = 0) =>
  value.length <= charLength ? value : `${value.slice(0, charLength)}...`;
export const getServerTempImage = (uniqueName = "") => {
  return API_ENDPOINT.IMAGE_BASE_URL + uniqueName;
};

export const onDownload = async (ext = "txt") => {
  const temp = await fetch("/documents/Sample_AudienceList." + ext);
  const result = await temp.blob();
  saveAs(result, "Sample_AudienceList." + ext);
};

export const onPersionalisedDownload = async (ext = "txt") => {
  const temp = await fetch("/documents/persionalized." + ext);
  const result = await temp.blob();
  saveAs(result, "persionalized." + ext);
};
export const removeFalsy = (obj) => {
  let newObj = {};
  try {
    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  } catch (error) {
    return obj;
  }
};
export const isFalsy = (val) => {
  const falsy = [0, -0, false, "", "", ``, null, undefined, NaN, 0n];
  return falsy.includes(val);
};
export const manageCarouselNavButtons = (
  parentDivClassName = "",
  innerDivClassName = "",
  leftBtnClassName = "",
  rightBtnClassName = ""
) => {
  try {
    const parentDivWidth = window.$("." + parentDivClassName).width();
    const innerDivWidth = window.$("." + innerDivClassName).width();
    if (innerDivWidth < parentDivWidth) {
      window.$("." + leftBtnClassName).hide();
      window.$("." + rightBtnClassName).hide();
    } else {
      window.$("." + leftBtnClassName).show();
      window.$("." + rightBtnClassName).show();
    }
  } catch (error) {}
};
export const removeNullUndefined = (obj) => {
  let newObj = {};
  const invalidValues = ["undefined", undefined, NaN, null, "null"];
  try {
    Object.keys(obj).forEach((key) => {
      if (!invalidValues.includes(obj[key])) {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  } catch (error) {
    return obj;
  }
};
export const getUniqueUID = () => uuid.v4();

export const separateStringByChar = (array, char = ", ") => {
  try {
    if (!Array.isArray(array)) {
      return "";
    }
    return array.map((ele) => ele).reduce((ac, val) => ac + `${char}` + val);
  } catch (error) {
    return "";
  }
};

export const checkFileLength = (inputName) => {
  return (
    inputName &&
    inputName.toString().length <=
      config.REACT_APP_CAMPAIGN_AUDIANCE_FILE_NAME_CHARACTER_LIMIT
  );
};


export const formatDateFromTimeStampForTemplate = (stampedDate) => {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  try {
    const parsedDate = new Date(stampedDate);
    let date = new Date(parsedDate).getDate();
    let month = months[new Date(parsedDate).getMonth()];
    let year = new Date(parsedDate).getFullYear();
    if (Number.isNaN(date) || Number.isNaN(month) || Number.isNaN(year)) {
      const pollyParsedDate = new Date(stampedDate.replace(/\s/, "T"));
      date = new Date(pollyParsedDate).getDate();
      month = months[new Date(pollyParsedDate).getMonth()];
      year = new Date(pollyParsedDate).getFullYear();
      return `${moment(pollyParsedDate).format("MMM DD, YYYY")} | ${moment(pollyParsedDate).format("hh:mm:ss A")}`;
    }
    return `${moment(parsedDate).format("MMM DD, YYYY")} | ${moment(parsedDate).format("hh:mm:ss A")}`;
  } catch (error) {
    return "";
  }
};

export const checkImgPDFFileExtension = (extension) => {
 if(extension?.split(".")?.pop()?.toLowerCase()==="png" || extension?.split(".")?.pop()?.toLowerCase()==="jpg" || 
 extension?.split(".")?.pop()?.toLowerCase()==="jpeg" || extension?.split(".")?.pop()?.toLowerCase()==="pdf")
 {
  return true
 }
 else return false
};

export const isJsonString=(str)=> {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const encryptWithRSA = (plainText) => {
  const publicKey = PUBLIC_KEY
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    const encrypted = encrypt.encrypt(plainText);
    return encrypted;
};

export const customStylesForBillingCategory = {
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    backgroundColor: isSelected ? "#3584e4" : isFocused ? "#DEEBFF" : "#FFF",
    color: isSelected ? "white" : "black",
  }),
  control: (base) => ({
    ...base,
    height: 45,
  }),
};

export function getActionByEliminateEmail(actionBy) {
  const emailLabel = "Email:";
  if(actionBy?.includes(emailLabel))
  {
    return actionBy?.split(emailLabel)?.[0];
  }else{
    return actionBy;
  }
}

export function splitActionByString(actionBy) {
  const emailLabel = "Email:";
  const emailIndex = actionBy.indexOf(emailLabel);

  if (emailIndex !== -1) {
      const email = actionBy.substring(emailIndex);
      const nameAndRole = actionBy.substring(0, emailIndex).trim();
      return [nameAndRole, email];
  } else {
      return actionBy;
  }
}