import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

const TAG = "viewTemplateDetails.js";

function* workerGetTemplateDetails(payload) {
    console.log(
        "bot id is undefined: Payload=>" + JSON.stringify(payload)
    )
  try {
    if (!payload.data) {
      throw new Error(
        "bot id is undefined: Payload=>" + JSON.stringify(payload)
      );
    }
    const response = yield call(
      Api.makeSecuredGetRequest,
      API_ENDPOINT.GET_TEMPLATE_DETAILS + payload.data
    );
    yield put(memberAction.createActionOf(memberType.GET_TEMPLATE_DETAILS_SUCCESS,1,response));
  } catch (error) {
    console.error(TAG, "Error: while getting template details: ", error.message);   
     yield put(memberAction.createActionOf(memberType.GET_TEMPLATE_DETAILS_FAILED,2,error));

  }
}

export const watchGetTemplateDetailsSaga = takeLatest(
  memberType.GET_TEMPLATE_DETAILS,
  workerGetTemplateDetails
);