import { put, takeLatest, call } from "redux-saga/effects";
import {
    GET_AGGREGATOR_INFO,
    GET_AGGREGATOR_INFO_SUCCESS,
    GET_AGGREGATOR_INFO_FAILED,
} from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";

const TAG = "getAggregatorInfoSaga.js";

function* workerGetAggregatorInfoSaga(payLoad) {
  const API = API_ENDPOINT.GET_AGGREGATOR_INFO + `?partners_profile_id=${payLoad.data.value}`;
  try {
    const response = yield call(
        makeSecuredPostRequest,
        API,
        payLoad.data
    );
    yield put(createActionOf(GET_AGGREGATOR_INFO_SUCCESS, 1, response));
  } catch (error) {
    console.error(TAG, "Get aggregator info failed: " + error.message);
    yield put(createActionOf(GET_AGGREGATOR_INFO_FAILED, 2, error));
  }
}

export const watchGetAggregatorInfo = takeLatest(
    GET_AGGREGATOR_INFO,
    workerGetAggregatorInfoSaga,
);