import { config } from "../../constants/constant"

export const campaignEndpoints = {
  CAMPAIGN_LIST: config.RCS_API_BASE_URI + "/secure/rcsCampaigns",
  CAMPAIGN_DETAILS: config.RCS_API_BASE_URI + "/secure/rcsCampaign/",
  BOT_TEMPLATE_LIST: config.RCS_API_BASE_URI + "/secure/activeBotTemplateList",
  CANCEL_CAMPAIGN: config.RCS_API_BASE_URI + "/secure/cancelRcsCampaign/",
  CAMPAIGN_FILE_UPLOAD: config.RCS_API_BASE_URI + "/secure/audience_file",
  UPDATE_CAMPAIGN_DETAILS:
    config.RCS_API_BASE_URI + "/secure/rcsCampaign/",
}
