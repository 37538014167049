import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import * as Api from "../../appApi/AppApi";

function* workerCategoryList(payLoad) {
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.CATEGORY_LIST,
      {}
    );
    yield put(memberAction.catagoryListSuccess(response));
  } catch (error) {
    yield put(memberAction.catagoryListFailed(error.message));
  }
}

export const watchCategoryList = takeLatest(
  memberType.CATEGORY_LIST_REQUESTED,
  workerCategoryList
);
