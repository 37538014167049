import { LOADING, STATUS_CODE_SUCCESS } from "../../constants/constant";
import {
  SETUP_USER_DETAILS_REQUESTED,
  SETUP_USER_DETAILS_FAILED,
  SETUP_USER_DETAILS_SUCCESS,
  SETUP_USER_DETAILS_RESET,
} from "../actions/ActionType";

const initialState = {
  status: "",
  isLoading: false,
};

export default function setupUserDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case SETUP_USER_DETAILS_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case SETUP_USER_DETAILS_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.status = action.data.status_message;
      userRes.isLoading = false;
      userRes.statusCode = action.data.status_code;
      if (userRes.statusCode !== STATUS_CODE_SUCCESS) {
        userRes.error = true;
        userRes.errorMessage = action.data.message;
        return userRes;
      }
      userRes.message = action.data.message;
      userRes.error = false;
      return userRes;

    case SETUP_USER_DETAILS_FAILED:
      return Object.assign({}, state, action.error);
    
    case SETUP_USER_DETAILS_RESET:
      return initialState;

    default:
      return state;
  }
}
