import { LOADING, STATUS_CODE_SUCCESS } from "../../../constants/constant"
import {
  CREATE_BOT_AGENT,
  CREATE_BOT_AGENT_SUCCESS,
  CREATE_BOT_AGENT_FAILED,
  CREATE_BOT_AGENT_RESET,
} from "../../actions/ActionType"

const initialState = {
  status: "INIT",
  isLoading: false,
}

export default function createBotAgentReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_BOT_AGENT:
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      return reqstState

    case CREATE_BOT_AGENT_SUCCESS: {
      const { data: { resData = {} } = {} } = action
      let userRes = Object.assign({}, state)
      userRes.status = resData.status_message
      userRes.statusCode = resData.status_code
      userRes.isLoading = false

      if (userRes.statusCode === STATUS_CODE_SUCCESS) {
        userRes.botAgentID = resData.data.bot_agent_id
        userRes.brandID = resData.data.brand_id
        userRes.error = false
        return userRes
      } else {
        userRes.error = true
        userRes.errorMessage = resData.data.message
        return userRes
      }
    }
    case CREATE_BOT_AGENT_FAILED:
      return Object.assign({}, state, action.error)

    case CREATE_BOT_AGENT_RESET:
      return initialState

    default:
      return state
  }
}
