/* eslint-disable jsx-a11y/anchor-has-content */
const TermsOfService = (props) => {
  return (
    <div>
      <section className="body-margin-top disabled">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-10 col-lg-offset-1 col-md-offset-1">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <h2 className="font-size-24 font-weight-600">
                    Terms of Service for using RCS Business Messaging as a
                    service on Vi-RBM portal
                  </h2>
                </div>
                <div className="col-lg-12 col-md-12 terms-of-use-table">
                  <p className="font-weight-400 font-size-14 margin-top-15">
                    Thank you for using RCS Business Messaging through Vi-RBM
                    portal, a platform and associated software that enables
                    businesses to reach their customers with rich conversations
                    via the users' default carrier messaging apps ("
                    <strong>RCS Business Messaging</strong>" or "
                    <strong>RBM</strong>").
                  </p>
                  <table>
                    <tbody>
                      <tr>
                        <td width="3%">(A)</td>
                        <td width="97%">
                          These RCS Business Messaging Terms of Service ("
                          <strong>RBM Terms</strong>") are entered into by{" "}
                          <strong>
                            VODAFONE IDEA BUSINESS SERVICES LIMITED
                          </strong>
                          , and you (the entity agreeing to these terms).
                        </td>
                      </tr>
                      <tr>
                        <td>(B)</td>
                        <td>
                          These RBM Terms form a binding agreement between the
                          parties and are effective as of the earliest date you
                          clicked to accept these RBM Terms or used RCS Business
                          Messaging. If an individual is accepting on your
                          behalf such individual represents and warrants that:
                          (i) such individual has full legal authority to bind
                          you to these RBM Terms; (ii) the individual has read
                          and understands these RBM Terms; and (iii) the
                          individual agrees, on your behalf, to these RBM Terms.
                          These RBM Terms govern your access to and use of RCS
                          Business Messaging.
                        </td>
                      </tr>
                      <tr>
                        <td>(C)</td>
                        <td>
                          By accepting these RBM Terms, you are agreeing to
                          guidelines provided under Vi-RBM APIs document for
                          purposes of sending traffic for RCS Business
                          Messaging.
                        </td>
                      </tr>
                      <tr>
                        <td>(D)</td>
                        <td>
                          Additionally, you may have access to other tools and
                          services provided by ViBSL or its affiliates. Any use
                          of such tools and services may be subject to separate
                          terms and conditions.
                        </td>
                      </tr>
                      <tr>
                        <td>(E)</td>
                        <td>
                          We may refer to{" "}
                          <strong>
                            VODAFONE IDEA BUSINESS SERVICES LIMITED
                          </strong>{" "}
                          as "<strong>ViBSL</strong>", "<strong>we</strong>", "
                          <strong>our</strong>", or "<strong>us</strong>" in
                          these RBM Terms. ViBSL may use its affiliates in
                          connection with the performance of its obligations and
                          exercise of its rights under these RBM Terms.
                        </td>
                      </tr>
                      <tr>
                        <td>(F)</td>
                        <td>
                          These RBM Terms apply to you, your agents, the service
                          provider(s) you work with, and both your and their
                          employees, representatives, agents, and suppliers
                          (collectively "<strong>you</strong>" or "
                          <strong>Company</strong>").
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 terms-of-use-table">
                  <table>
                    <tbody>
                      <tr>
                        <td colSpan={2}>
                          <h2 className="font-size-18 font-weight-600">
                            1. Applicable Terms; Modifications
                          </h2>
                        </td>
                      </tr>
                      <tr>
                        <td width={45}>
                          <strong>1.1</strong>
                        </td>
                        <td width="98%">
                          <strong>Incorporation by Reference.</strong> To the
                          extent applicable, the following terms are
                          incorporated by reference into these RBM Terms:
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(a)</div>
                        </td>
                        <td>
                          <strong>Acceptance of Jibe TOS.</strong> The Jibe TOS
                          will apply to and are deemed part of these RBM Terms{" "}
                          <a
                            href="https://developers.google.com/rcs-business-messaging/support/tos/"
                            className="link-purple"
                            target="_blank"
                          >
                            https://developers.google.com/rcs-business-messaging/support/tos/
                          </a>{" "}
                          (or such other URL), as may be updated by Jibe from
                          time to time.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(b)</div>
                        </td>
                        <td>
                          Acceptable Use Policy. All of your products, services,
                          or materials integrated with or used in conjunction
                          with RCS Business Messaging must comply with the
                          Acceptable Use Policy at&nbsp;
                          <a
                            href="https://developers.google.com/business-communications/support/aup"
                            target="_blank"
                            className="link-purple"
                          >
                            https://developers.google.com/business-communications/support/aup
                          </a>
                          &nbsp;(or such other URL as we may provide)
                          (“Acceptable Use Policy”).
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(c)</div>
                        </td>
                        <td>
                          <p>
                            <strong>Scope</strong>
                          </p>
                          <ol type="a">
                            <li className="margin-top-20">
                              RBM is a third party product and provided as a
                              service&nbsp; by ViBSL in the Territory basis its
                              existing arrangement with Jibe;
                            </li>
                            <li className="margin-top-20">
                              Under this Agreement COMPANY is being authorised
                              by ViBSL to&nbsp; offer RBM as a service to
                              interested Brands;
                            </li>
                            <li>
                              The product features of RBM and Jibe service
                              descriptions are more particularly described in{" "}
                              <a
                                href="https://developers.google.com/business-communications/rcs-business-messaging"
                                className="link-purple"
                                target="_blank"
                              >
                                https://developers.google.com/business-communications/rcs-business-messaging
                              </a>
                            </li>
                            <li className="margin-top-20">
                              Jibe owns certain intellectual property rights
                              related to RBM including but not limited to
                              current and future worldwide rights under patent
                              law, copyright law, trade secret law, trademark
                              law, moral rights law, and other similar rights;
                            </li>
                            <li className="margin-top-20">
                              COMPANY shall remain solely responsible for
                              on-boarding of its customers or Brands upon
                              successful completion of Know Your Customer (KYC)
                              process for self and each Brand it brings as a
                              customer. In this regard, COMPANY will collect
                              applicable KYC documents from each Brand and
                              verify the same in accordance with and in strict
                              compliance with Applicable law and/or ViBSL
                              instructions as issued/amended from time to time.
                              COMPANY shall provide copies of all KYC documents
                              (for self and the Brands) to ViBSL promptly on
                              demand by ViBSL. COMPANY shall remain liable to
                              pay penalties to ViBSL (as determined by ViBSL)
                              for breach or non-compliance with KYC and
                              verification process as mandated under Applicable
                              Law and/or ViBSL instructions.&nbsp; COMPANY
                              hereby agrees and undertakes to remain liable to
                              any Government agency with regard to traceability
                              or KYC and associated billing or payment related
                              information especially in case of any Government
                              agency enquiries/orders etc.
                            </li>
                            <li className="margin-top-20">
                              RBM as a service shall be activated by ViBSL for a
                              certain Brand upon successful validation done on
                              the KYC documents and provided RBM Agent.
                            </li>
                            <li className="margin-top-20">
                              Jibe or ViBSL may monitor all use of RBM, and may
                              suspend or terminate any Brand's use of RBM if it
                              violates the Online TOS and AUP and ViBSL’s Terms
                              and Conditions as contained in Schedule 2
                              hereinafter written or where such suspension or
                              termination is otherwise permitted under the
                              Online TOS and AUP.
                            </li>
                            <li className="margin-top-20">
                              Jibe or ViBSL may, in its sole discretion, and
                              without incurring any liability to COMPANY or
                              Brand: (i) upon notice (email to suffice),
                              discontinue the sale or availability of RBM or
                              support for COMPANY or Brands of RBM; or (ii)
                              without notice, change RBM (including adding any
                              features or functionality of RBM, the use of which
                              may be contingent on COMPANY’s or Brands’
                              agreement to additional terms) or the Online TOS
                              and AUP.
                            </li>
                            <li className="margin-top-20">
                              Notwithstanding clause 3(e), Jibe or ViBSL may
                              discontinue or suspend the availability of RBM
                              without notice in the event of a security,
                              technical, or other incident that poses a
                              significant risk or adverse impact to RBM, the RBM
                              platform, and/or to Jibe or Jibe’s other
                              customers.
                            </li>
                            <li className="margin-top-20">
                              Jibe or ViBSL has no obligation to provide COMPANY
                              or Brands with advance notice of any change in RBM
                              or the Online TOS and AUP.
                            </li>
                            <li className="margin-top-20">
                              ViBSL will be responsible for verifying and
                              on-boarding COMPANY for RBM, in accordance with
                              ViBSL’s provisioning process. ViBSL shall verify
                              &amp; register COMPANY’s Brands and their RBM Use
                              Cases based on the details provided by COMPANY
                              and/or its Brands. ViBSL has sole discretion to
                              block / exclude specific Aggregators, Brands
                              and/or Use Cases from RBM.
                            </li>
                            <li className="margin-top-20">
                              COMPANY will and, to the extent within COMPANY’s
                              control ensure that the Brands will promptly
                              report any suspected abuse of RBM.
                            </li>
                            <li className="margin-top-20">
                              All ownership rights, title, and intellectual
                              property rights in and to the content accessed
                              through any RBM are the property of the applicable
                              content owner and may be protected by copyright or
                              other applicable laws. For clarity, (a) RBM will
                              remain the exclusive property of Jibe and its
                              Affiliates, (b) COMPANY will have no right to any
                              intellectual property rights related to RBM, and
                              (c) COMPANY may not (i) copy or sublicense RBM
                              applications or ViBSL Platform (or any part) to
                              any third party, (ii) circumvent,
                              reverse-engineer, modify, disable, or otherwise
                              tamper with any security technology in ViBSL
                              platform or help anyone else to do so; (iii) use
                              RBM other than by means authorized; or (iv) remove
                              any proprietary notices or labels on the RBM
                              applications or ViBSL Platform.
                            </li>
                            <li className="margin-top-20">
                              All goodwill arising from the use by COMPANY or
                              Brands of Jibe’s Brand Features will belong to
                              Jibe and/or its Affiliates.
                            </li>
                            <li className="margin-top-20">
                              ViBSL shall remain entitled to reject any Brand
                              brought by COMPANY basis ViBSL’s internal
                              policies;
                            </li>
                            <li className="margin-top-20">
                              COMPANY shall provide and will ensure that a Brand
                              provides within given timelines any information
                              related to use of RBM which Jibe or ViBSL might
                              require;
                            </li>
                            <li className="margin-top-20">
                              COMPANY will not make any unauthorized, false,
                              misleading, or illegal statements in connection
                              with this Agreement or regarding RBM. COMPANY will
                              not make any representations or warranties
                              concerning RBM on behalf of Jibe or ViBSL or
                              ViBSL’s managed service partners. Jibe and ViBSL
                              or ViBSL’s managed service partners will not be
                              responsible for any representations or warranties
                              made by COMPANY concerning RBM.
                            </li>
                            <li className="margin-top-20">
                              COMPANY will not (and will not instruct or
                              encourage Brands or End Users to) use RBM or any
                              Jibe documentation provided for any purpose other
                              than to provide RBM as permitted hereunder.
                            </li>
                          </ol>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>1.2</strong>
                        </td>
                        <td>
                          <strong>
                            Entire Agreement; All other Terms are Void.
                          </strong>{" "}
                          These RBM Terms are the entire agreement between you
                          and ViBSL relating to its subject and supersede any
                          prior or contemporaneous agreements on that subject
                          (including any previously executed early access
                          agreement). We object to any additional or different
                          terms in your terms of service or other documents,
                          including any of your API terms of service. Those
                          other terms of service and documents will be
                          considered material alterations to these RBM Terms and
                          are void.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>1.3</strong>
                        </td>
                        <td>
                          <strong>Modifications to these RBM Terms.</strong>{" "}
                          ViBSL may make changes to these RBM Terms (including
                          the Policies (as defined below), including any terms
                          relating pricing or payment from time to time. Unless
                          otherwise noted by ViBSL, material changes to these
                          RBM Terms will become effective 30 days after they are
                          posted, except if the changes apply to new
                          functionality in which case they will be effective
                          immediately. If you do not agree to the revised RBM
                          Terms, please stop using RCS Business Messaging. ViBSL
                          will post any modification to these RBM Terms to the
                          Terms URL.
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h2 className="font-size-18 font-weight-600">
                            2. Definitions
                          </h2>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>2.1</strong>
                        </td>
                        <td>
                          <strong>"including"</strong> means "including but not
                          limited to".
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>2.2</strong>
                        </td>
                        <td>
                          "<strong>Your Content</strong>" means all content made
                          available by you to ViBSL through RCS Business
                          Messaging including and in connection with your RBM
                          agent(s), including the developer console, APIs, SDKs,
                          and tools that enable RCS Business Messaging. For
                          clarity, “Your Content” includes all content made
                          available by any third party that you approve or
                          otherwise authorize to use RCS Business Messaging
                          under these RBM Terms.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>2.3</strong>
                        </td>
                        <td>
                          "<strong>Your Services</strong>" means (a) your
                          products, services, and technology (including Your
                          Content) that you make available, integrate, or use in
                          conjunction with RCS Business Messaging; and (b) the
                          products, services, and destinations to which you
                          direct users through your RBM agent. For clarity,
                          “Your Services” includes all products, services, and
                          technology made available, integrated, or used in
                          conjunction with RCS Business Messaging by any third
                          party that you approve or otherwise authorize to use
                          RCS Business Messaging under these RBM Terms.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>2.4</strong>
                        </td>
                        <td>
                          Any examples in these RBM Terms are illustrative and
                          not the sole examples of a particular concept.
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h2 className="font-size-18 font-weight-600">
                            3. RCS Business Messaging
                          </h2>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>3.1</strong>
                        </td>
                        <td>
                          <strong>Modification.</strong> ViBSL may modify,
                          deprecate, suspend, or discontinue RCS Business
                          Messaging, or any of its features, at any time.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>3.2</strong>
                        </td>
                        <td>
                          <strong>Beta Features.</strong> Some features of RCS
                          Business Messaging are identified as{" "}
                          <strong>"Beta"</strong> or otherwise unsupported or
                          confidential (collectively, "
                          <strong>Beta Features</strong>"). You may not disclose
                          the terms or existence of any non-public Beta
                          Features.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>3.3</strong>
                        </td>
                        <td>
                          <strong>Minimum Requirements.</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(a)</div>
                        </td>
                        <td>
                          <strong>Compliance.</strong> In order to use RCS
                          Business Messaging, you and your service providers and
                          agents must comply with any applicable requirements
                          described in the Acceptable Use Policy and any other
                          policies provided to you by ViBSL or ViBSL’s
                          affiliates, including the Communication Services (as
                          defined in the Acceptable Use Policy) policies (the "
                          <strong>Policies</strong>"). In addition:
                          <ol type="i">
                            <li className="margin-top-20">
                              <strong>Accurate Information.</strong> You must
                              provide honest, complete, and accurate information
                              in connection with RCS Business Messaging,
                              including to third parties.
                            </li>
                            <li className="margin-top-20">
                              <strong>Compliance with Policies.</strong> You
                              must comply with all applicable Policies. ViBSL
                              may reject or remove certain of Your Services or
                              Settings at any time if you don't comply with the
                              Policies.
                            </li>
                            <li className="margin-top-20">
                              <strong>Compliance Certification.</strong>
                              &nbsp;You may be required to certify compliance
                              with the requirements from time to time, and as
                              may be described in the Policies.
                            </li>
                            <li className="margin-top-20">
                              <strong>Access Denial.</strong> Access to RCS
                              Business Messaging may be denied if you fail to
                              satisfy the requirements at any time.
                            </li>
                          </ol>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(b)</div>
                        </td>
                        <td>
                          <strong>
                            Cooperation; Right to Review Spam Activity.
                          </strong>
                          &nbsp;You will cooperate if ViBSL or its partners seek
                          to gather information about you or Your Services to
                          verify identity, confirm compliance with requirements,
                          for quality assurance purposes, or as required to
                          operate RCS Business Messaging. You hereby permit
                          ViBSL to review your activity (including any activity
                          of your RBM agents) and/or end user spam reports
                          associated with your activity, and agree to provide
                          reasonable assistance to ViBSL in this regard.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(c)</div>
                        </td>
                        <td>
                          Privacy; Your Terms.&nbsp;You will obtain and maintain
                          any required consents necessary to permit the
                          processing of personal data under these RBM Terms. You
                          will present or make available to each end user (in
                          relation to RCS Business Messaging) an accurate,
                          legally compliant, privacy policy and terms of service
                          for your services associated with RCS Business
                          Messaging, including your RBM agents. Such privacy
                          policy and/or terms of service must (i) ensure the end
                          user authorizes ViBSL to conduct the data processing
                          activities contemplated under these RBM Terms; and
                          (ii) not conflict with or supersede these RBM Terms in
                          any way.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>3.4</strong>
                        </td>
                        <td>
                          <strong>Prohibited Actions.</strong>&nbsp;In
                          connection with RCS Business Messaging, you will not,
                          and will not authorize any third party to:
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(a)</div>
                        </td>
                        <td>
                          generate automated, fraudulent or otherwise invalid
                          activity (including queries, clicks or conversions);
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(b)</div>
                        </td>
                        <td>
                          conceal ad- or transaction-related activity that must
                          be disclosed;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(c)</div>
                        </td>
                        <td>
                          attempt to interfere with the proper functioning of
                          RCS Business Messaging;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(d)</div>
                        </td>
                        <td>
                          collect or use personal and confidential information,
                          such as national identification number or social
                          security number, payment and financial data (e.g.,
                          credit card and bank account numbers), log-in
                          credentials, passwords, or answers to security
                          questions, provided that the foregoing does not
                          exclude the use of two factor authentication or
                          provision of single use passwords with end user
                          consent in compliance with applicable law;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(e)</div>
                        </td>
                        <td>
                          use any information about users' online or offline
                          state for any reason except to directly provide the
                          services to the user, and under no circumstances in a
                          manner that may surprise or disturb a user (including
                          sending a promotion or advertisement based on them
                          coming back online); or
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(f)</div>
                        </td>
                        <td>
                          use or share user data without specific user consent
                          for the specific use of that data.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>3.5</strong>
                        </td>
                        <td>
                          <strong>Research and Testing; Reports.</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(a)</div>
                        </td>
                        <td>
                          To improve RCS Business Messaging, you authorize ViBSL
                          to periodically conduct research and tests that may
                          affect your use of RCS Business Messaging, including
                          sounds, appearances, disclosures, labeling,
                          formatting, size, placement, performance, pricing, and
                          other adjustments. To ensure the timeliness and/or
                          validity of test results, you authorize ViBSL to
                          conduct such research and tests without notice or
                          compensation to you.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(b)</div>
                        </td>
                        <td>
                          ViBSL may, from time to time, require that you provide
                          reports or other aggregated statistical information
                          relating to your parallel (or similar) non-RBM
                          messaging campaigns, for the purposes of enabling
                          ViBSL to better understand, and improve, the efficacy
                          of RCS Business Messaging.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>3.6</strong>
                        </td>
                        <td>
                          <strong>
                            Instruction to Send and Receive Messages.
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(a)</div>
                        </td>
                        <td>
                          In connection with your use of RCS Business Messaging
                          to contact and/or message end users based on phone
                          number and/or by providing any mobile or other
                          telephone number to ViBSL in connection with RCS
                          Business Messaging, you: (i) represent and warrant
                          that you have been duly authorized by the subscriber
                          of record to contact and/or message that telephone
                          number, and (ii) expressly instruct and authorize
                          ViBSL and its representatives to send messages (e.g.,
                          SMS, MMS, RCS messages) to that telephone number on
                          your behalf in connection with RCS Business Messaging.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(b)</div>
                        </td>
                        <td>
                          Standard message and data rates may apply to the
                          sending or receipt of ViBSL messages by you and/or end
                          users.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>3.7</strong>
                        </td>
                        <td>
                          <strong>
                            Instruction to Provide Billing Information to Third
                            Parties.
                          </strong>
                          <p className="margin-top-15">
                            ViBSL may provide summary billing information and/or
                            record level detail to third parties for billing
                            purposes (e.g., telecommunications carriers and/or
                            their affiliates) related to the use of RCS Business
                            Messaging by you and/or your end users, as further
                            described&nbsp;
                            <a
                              href="https://developers.google.com/business-communications/rcs-business-messaging/support/tos/definitions"
                              target="_blank"
                              className="link-purple"
                            >
                              here
                            </a>
                            &nbsp;(as amended by ViBSL from time to time). By
                            using RCS Business Messaging, you (i) represent and
                            warrant that you have been duly authorized by the
                            subscriber of record to provide such information,
                            and (ii) expressly instruct and authorize ViBSL and
                            its representatives to provide such information on
                            your behalf in connection with RCS Business
                            Messaging.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>3.8</strong>
                        </td>
                        <td>
                          <strong>Suspension of services.</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(a)</div>
                        </td>
                        <td>
                          ViBSL has the right to suspend the RBM access provided
                          to COMPANY at any point of time without liability and
                          prior notice where it considers there is a requirement
                          to do so.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(b)</div>
                        </td>
                        <td>
                          ViBSL may without liability at any time and with
                          immediate effect suspend part or all of the operation
                          of RBM where:
                          <ol type="i">
                            <li className="margin-top-20">
                              COMPANY is using RBM in a way which is fraudulent,
                              unlawful, illegal or unauthorized; or not allowed
                              under this Agreement; or
                            </li>
                            <li className="margin-top-20">
                              ViBSL has valid reason to suspect (basis available
                              information) fraudulent, unlawful, illegal or
                              unauthorized use of RBM by COMPANY or by its
                              Brands; or
                            </li>
                            <li>
                              COMPANY has violated any of the terms mentioned in
                              the document.
                            </li>
                          </ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h2 className="font-size-18 font-weight-600">
                            4. Resale Right; Rights Reserved
                          </h2>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>4.1</strong>
                        </td>
                        <td>
                          <strong>Resale Right.</strong> You may resell access
                          and use of RCS Business Messaging to third parties
                          with whom you have a written agreement that is no less
                          protective of ViBSL, ViBSL's affiliates, and RCS
                          Business Messaging, than as set forth in these RBM
                          Terms. For clarity, you will remain liable for the
                          acts and omissions of such third parties.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>4.2</strong>
                        </td>
                        <td>
                          <strong>Rights Reserved.</strong>&nbsp;You acknowledge
                          that ViBSL may, at any time, in accordance with
                          Section 1.4, update these RBM Terms and charge fees
                          for using and/or reselling RCS Business Messaging.
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h2 className="font-size-18 font-weight-600">
                            5. Your Services
                          </h2>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>5.1</strong>
                        </td>
                        <td>
                          <strong>Authorization to Use Your Services.</strong>
                          &nbsp;To participate in RCS Business Messaging, you
                          authorize ViBSL and its affiliates to:
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(a)</div>
                        </td>
                        <td>
                          access Your Services and include them in the RCS
                          Business Messaging platform;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(b)</div>
                        </td>
                        <td>
                          distribute Your Services as part of RCS Business
                          Messaging through any ViBSL, ViBSL-affiliated, or
                          third party product or service (including ViBSL
                          products and services made available on, or integrated
                          with, third-party devices and interfaces);
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(c)</div>
                        </td>
                        <td>
                          format or modify Your Content as necessary for proper
                          functionality with RCS Business Messaging;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(d)</div>
                        </td>
                        <td>
                          use Your Content to provide and improve the RCS
                          Business Messaging platform and related products and
                          services; and
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(e)</div>
                        </td>
                        <td>
                          use and disclose information relating to Your
                          Services, including to: (i meet any applicable legal
                          obligation, including enforceable government requests;
                          (ii) enforce these RBM Terms and investigate potential
                          violations; (iii) detect, prevent, review or otherwise
                          address abuse, fraud, security or technical issues; or
                          (iv) protect against harm to the rights, property, or
                          safety of ViBSL, our users, or the public as required
                          or permitted by law.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>5.2</strong>
                        </td>
                        <td>
                          <strong>Your Responsibilities.</strong>&nbsp;You are
                          solely responsible for:
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(a)</div>
                        </td>
                        <td>
                          Your Services, including any reselling of RCS Business
                          Messaging, including customer service and claims, and
                          communications and reporting among the individuals and
                          entities involved in providing Your Services;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(b)</div>
                        </td>
                        <td>
                          settings and other decisions you make through the RCS
                          Business Messaging developer console, including those
                          where you were assisted by any ViBSL-provided features
                          ("<strong>Settings</strong>"); and
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="padding-left-20">(c)</div>
                        </td>
                        <td>
                          your use of RCS Business Messaging (including your
                          safeguarding of accounts, usernames, and passwords).
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h2 className="font-size-18 font-weight-600">
                            6. Representations and Warranties
                          </h2>
                          <p className="padding-left-20">
                            You represent and warrant that:
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>6.1</strong>
                        </td>
                        <td>
                          <strong>
                            Rights in Your Content and Your Services.
                          </strong>
                          &nbsp;You have and will retain all necessary rights to
                          grant the licenses in these RBM Terms and to provide
                          Your Services through RCS Business Messaging;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>6.2</strong>
                        </td>
                        <td>
                          <strong>Accurate Information.</strong>&nbsp;All
                          information, authorizations, and Settings you provide
                          are complete, correct, and current;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>6.3</strong>
                        </td>
                        <td>
                          <strong>No Deceptive Practices.</strong>&nbsp;You will
                          not engage in deceptive, misleading, and/or unethical
                          practices in connection with Your Services or their
                          promotion and will make no false or misleading
                          representations with regard to ViBSL or its products
                          or services;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>6.4</strong>
                        </td>
                        <td>
                          <strong>Compliance with Laws.</strong>&nbsp;You will
                          comply with all applicable laws, rules, and
                          regulations in connection with RCS Business Messaging
                          (including any applicable opt-in/opt-out messaging
                          requirements); and
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>6.5</strong>
                        </td>
                        <td>
                          <strong>Authorization to Act.</strong>&nbsp;You are
                          authorized to act on behalf of, have bound to these
                          RBM Terms, and will be liable under these RBM Terms
                          for, each individual or entity involved in Your
                          Services.
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h2 className="font-size-18 font-weight-600">
                            7. Disclaimers
                          </h2>
                          <p className="padding-left-20">
                            The following disclaimers apply in addition to those
                            in the General API Terms:
                          </p>
                          <p className="padding-left-20 margin-top-20">
                            TO THE FULLEST EXTENT PERMITTED BY LAW, RCS BUSINESS
                            MESSAGING IS PROVIDED "AS IS," "AS AVAILABLE" AND
                            "WITH ALL FAULTS," AND YOU USE IT (INCLUDING ITS
                            DEVELOPER CONSOLE) AT YOUR (AND ANY SERVICE
                            PROVIDER'S) OWN RISK. NONE OF ViBSL, ITS AFFILIATES,
                            OR ITS PARTNERS MAKE ANY WARRANTY OR GUARANTEE IN
                            CONNECTION WITH RCS BUSINESS MESSAGING OR RESULTS
                            FROM RCS BUSINESS MESSAGING. ViBSL MAKES NO PROMISE
                            TO INFORM YOU OF DEFECTS OR ERRORS.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h2 className="font-size-18 font-weight-600">
                            8. Defense and Indemnity
                          </h2>

                          <ol type="a">
                            <li className="margin-top-20">
                              XXXX (as &quot;Indemnitor&quot;) will defend at
                              its own expense and hold harmless ViBSL, its
                              officers, directors, employees and agents (as
                              &quot;Indemnitee&quot;) from and against any
                              Actions or suits, government investigations or
                              enforcement actions brought by a third party
                              against Indemnitee arising out of, or resulting
                              from, in whole or in part, (i) any
                              misrepresentation, gross negligence, or wilful
                              misconduct of Indemnitor, its employees or agents,
                              (ii) breach of provisions related to
                              confidentiality and data protection, or (iii)
                              violation of any applicable laws or regulations by
                              Indemnitor in connection with Indemnitor’s
                              performance under the Agreement (collectively, the
                              “Claim”), and indemnify for any damages, costs,
                              fines, assessments, penalties and/or expenses,
                              including without limitation reasonable
                              attorney&#39;s fees resulting from the Claim,
                              subject to Indemnitee providing the following to
                              Indemnitor: (a) prompt written notice of the
                              Claim; (b) sole and exclusive control over all
                              defence and settlement of the Claim; and (c) all
                              reasonable cooperation and assistance relating to
                              defence and settlement of the Claim at
                              Indemnitor’s expense. Indemnitee reserves the
                              right, at its own expense, to participate in the
                              defence and settlement of the Claim.
                              Notwithstanding anything herein to the contrary,
                              Indemnitor will not settle any Claim for which it
                              has an obligation to indemnify under this section
                              admitting liability or fault on behalf of
                              Indemnitee, nor create any obligation on behalf of
                              Indemnitee without Indemnitee’s prior written
                              consent.
                            </li>
                            <li className="margin-top-20">
                              XXXX shall indemnify and keep indemnified ViBSL
                              (or its officers, employees, affiliates and their
                              agents) against all damages, costs, fines,
                              assessments, penalties and/or expenses, including
                              without limitation reasonable attorney&#39;s fees,
                              arising under or in connection with: (i) XXXX’s
                              use of the RCS Messaging Services for any purpose
                              other than as specified in this Agreement; (ii)
                              fines / penalties imposed on ViBSL by DoT, TRAI,
                              TERM Cells etc. or other statutory, government or
                              regulatory authorities resulting from XXXX’s acts
                              or omissions under this Agreement.
                            </li>
                          </ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h2 className="font-size-18 font-weight-600">
                            9. Limitations of Liability
                          </h2>

                          <ol type="a">
                            <li className="margin-top-20">
                              Save as otherwise expressly stated, XXXX’s maximum
                              liability to ViBSL for all Actions relating to
                              procurement under this Agreement for this Category
                              in each twelve (12) month period from the date of
                              this Agreement shall be limited to 100% of the
                              aggregate value of all invoices issued in relation
                              to the Actions.
                            </li>
                            <li className="margin-top-20">
                              Save as otherwise expressly stated, ViBSL’s
                              maximum liability to XXXX for all Actions relating
                              to procurement under this Agreement shall be
                              limited to INR 50,000 or the aggregate value of
                              all invoices raised by ViBSL during the period of
                              30 days immediately preceding the date on which
                              such Action was initiated whichever is lower.
                              Notwithstanding anything to the contrary contained
                              herein, ViBSL shall have no liability towards XXXX
                              or its Brands or End Users, as the case may be, in
                              relation to deficiency in service of RBM in any
                              manner whatsoever.
                            </li>
                            <li className="margin-top-20">
                              Direct losses shall be recoverable under this
                              Agreement. However, save as otherwise stated,
                              neither XXXX nor ViBSL shall be liable for any
                              incidental, consequential, special or indirect
                              damages, loss of profits, loss of business or
                              further business, loss of goodwill or anticipated
                              revenue, loss of data, business interruption, or
                              increased cost of operations, or for exemplary or
                              punitive damages arising out of or related to this
                              Agreement or sale of RBM whether or not such Party
                              has been advised of the possibility of such
                              damages.
                            </li>
                            <li className="margin-top-20">
                              Nothing in the Agreement (including sub-clauses
                              (a) to (b) above), excludes or limits liability in
                              relation to: (i) either Party ’s liability for
                              physical damage to property (ii) either Party’s
                              liability for wilful default and wilful
                              abandonment; (iii) either Party’s liability for
                              any claims for infringement of any Intellectual
                              Property Rights; (iv) either Party’s liability for
                              breach of confidentiality provisions either
                              Party’s liability for death or personal injury
                              resulting from its negligence; (v) breaching
                              Party’s liability for its obligations in relation
                              to the Processing of Personal Data under this
                              Agreement; (vi) either Party’s liability for fraud
                              or fraudulent misrepresentation; (vii) either
                              Party’s liability which cannot be excluded by law;
                              (vii) XXXX’s obligation to pay undisputed
                              invoices, properly due under this Agreement or
                              (ix) the use of either Party’s Intellectual
                              Property Rights by the other Party in breach of
                              the provisions set out in this Agreement provided
                              that the non-breaching Party notifies the
                              breaching Party and permits the breaching Party a
                              period of thirty days (or such other period as the
                              Parties may agree) to cure the breach to the
                              reasonable satisfaction of the non-breaching
                              Party;
                            </li>
                          </ol>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h2 className="font-size-18 font-weight-600">
                            10. RCS Ecosystem Protection
                          </h2>
                          <p className="padding-left-20 margin-top-20">
                            To the maximum extent permitted by applicable law,
                            you agree that, during the effective period of these
                            RBM Terms, you will not assert a patent infringement
                            claim based on or arising from: (i) RCS Business
                            Messaging, (ii) any RBM agents developed by ViBSL, a
                            ViBSL affiliate, or any third party for use in
                            connection with RCS Business Messaging, or (iii) any
                            messaging platform products and services used,
                            created, provided or distributed by ViBSL or a ViBSL
                            affiliate, against ViBSL, a ViBSL affiliate, or any
                            other entity that has also entered into an agreement
                            with ViBSL or a ViBSL affiliate in connection with
                            RCS Business Messaging, or any messaging platform
                            products and services used, created, provided or
                            distributed by ViBSL or its affiliates. Any breach
                            of this Section 10 will be deemed a material breach
                            of these RBM Terms. Upon breach of this Section 10
                            all rights and licenses granted hereunder by ViBSL
                            will immediately cease. For the avoidance of doubt,
                            nothing in these RBM Terms prejudices your right (or
                            the right of your affiliates) to challenge the
                            validity, essentiality and infringement of any
                            patents, the use of which is essential for
                            compliance with any technical standards.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h2 className="font-size-18 font-weight-600">
                            11. Data Processing
                          </h2>
                          <p className="padding-left-20 margin-top-20">
                            When personal data from outside the European Union
                            is processed by ViBSL on your behalf:
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>11.1</strong>
                        </td>
                        <td>
                          You will obtain and maintain any required consents
                          necessary to (i) permit the access, storage, and
                          processing of end user data by ViBSL in connection
                          with RBM, and (ii) permit the access, processing and
                          storage of your data provided to ViBSL, in each case
                          for the purpose of providing and improving RBM.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>11.2</strong>
                        </td>
                        <td>
                          You acknowledge that ViBSL is a data processor, and
                          ViBSL may, and you hereby instruct ViBSL to, access,
                          store, or use your data or end user data in order to
                          provide and improve RBM.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>11.3</strong>
                        </td>
                        <td>
                          ViBSL reserves the right to review your data and end
                          user data for ensuring your compliance with these RBM
                          Terms.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>11.4</strong>
                        </td>
                        <td>
                          ViBSL may process, cache, and store your data and end
                          user data anywhere ViBSL or its affiliates maintain
                          facilities.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>11.5</strong>
                        </td>
                        <td>
                          If you are or become a “Covered Entity” or “Business
                          Associate”, as defined in the Health Insurance
                          Portability and Accountability Act of 1996 (as it may
                          be amended from time to time) (“HIPAA”), you will not
                          use RBM for any purpose or in any manner involving
                          “Protected Health Information”, as defined in HIPAA,
                          unless you have received prior written consent to use
                          such from ViBSL.
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h2 className="font-size-18 font-weight-600">
                            12. Confidentiality and PR
                          </h2>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>12.1</strong>
                        </td>
                        <td>
                          <strong>
                            Definition. "Confidential Information"
                          </strong>
                          &nbsp;means information that one party (or an
                          affiliate) discloses to the other party under these
                          RBM Terms, and that is marked as confidential or would
                          normally be considered confidential information under
                          the circumstances. It does not include information
                          that recipient already knew, that becomes public
                          through no fault of the recipient, that was
                          independently developed by the recipient, or that was
                          lawfully given to the recipient by a third party.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>12.2</strong>
                        </td>
                        <td>
                          <strong>Confidentiality Obligations.</strong>
                          <ol type="a">
                            <li className="margin-top-20">
                              Subject to the remainder, a Party may disclose the
                              other Party’s Confidential Information (i)
                              pursuant to a Legal Process or (ii) with the other
                              Party’s written consent.
                            </li>
                            <li className="margin-top-20">
                              Before a Party discloses the other Party’s
                              Confidential Information pursuant to a Legal
                              Process, the disclosing Party will promptly notify
                              the other Party, but notice will not be given
                              prior to such a disclosure if the disclosing Party
                              is informed that it is legally prohibited from
                              giving notice;
                            </li>
                            <li className="margin-top-20">
                              In case Personal Data of an End User is required
                              by ViBSL under a legal process, XXXX will collect
                              such Personal Data through respective Brands and
                              provide the same to ViBSL. XXXX will also ensure
                              that suitable notifications are sent by such
                              Brands to their respective End User before such
                              Personal Data are so shared.
                            </li>
                            <li className="margin-top-20">
                              Each party will cooperate with the other party’s
                              reasonable requests relating to efforts to oppose
                              disclosure of the other party’s Confidential
                              Information.
                            </li>
                            <li className="margin-top-20">
                              These provisions do not apply where the
                              Confidential Information received: (i) is or
                              becomes public knowledge without breach of the
                              Services Agreement; (ii) was already in a Party's
                              possession free of obligations of confidentiality;
                              or (iii) is received from a third party free of
                              obligations of confidentiality.
                            </li>
                          </ol>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>12.3</strong>
                        </td>
                        <td>
                          <strong>Publicity.</strong>&nbsp;Except as set out in
                          Section 12.2 (Confidentiality Obligations), neither
                          party may make any public statement regarding these
                          RBM Terms without the other's written approval.
                          Subject to your prior approval of the specific text
                          (not to be unreasonably withheld), you agree to
                          participate in industry announcements regarding RBM
                          and RCS.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default TermsOfService;
