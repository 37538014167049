import { LOADING } from "../../../constants/constant";
import {
  GET_SINGLE_BRAND_REQUESTED_PREVIEW,
  GET_SINGLE_BRAND_SUCCESS_PREVIEW,
  GET_SINGLE_BRAND_FAILED_PREVIEW,
  GET_SINGLE_BRAND_RESET_PREVIEW
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function getSingleBrandReducerPreview(state = initialState, action) {
  switch (action.type) {
    case GET_SINGLE_BRAND_REQUESTED_PREVIEW:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_SINGLE_BRAND_SUCCESS_PREVIEW:
      let userRes = Object.assign({}, state);
      userRes.status = action.data.status_message;
      userRes.statusCode = action.data.status_code;
      userRes.isLoading = false;
      // userRes.statusCode = action.data.statusCode;
      userRes.brandData = action.data.data;
      if (action.data.data.status_id === 11) {
        userRes.isBrandVerified = true;
      } else {
        userRes.isBrandVerified = false;
      }
      return userRes;

    case GET_SINGLE_BRAND_FAILED_PREVIEW:
      console.error("Error while getting brand details", action.error);
      return Object.assign({}, state, action.error);

      case GET_SINGLE_BRAND_RESET_PREVIEW: {
        return initialState;
      }
    default:
      return state;
  }
}
