import { LOADING } from "../../../constants/constant";
import {
  DELETE_USER_FAILED,
  DELETE_USER_REQUESTED,
  DELETE_USER_RESET,
  DELETE_USER_SUCCESS,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function getDeleteUserReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_USER_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.deleteUserisLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case DELETE_USER_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log(
        "DELETE user reducer => Case => Success: while getting category list: " +
          JSON.stringify(action.data)
      );
      userRes.deleteUserStatus = action.data.status_message;
      userRes.deleteUserStatusCode = action.data.status_code;
      userRes.deleteUserisLoading = false;
      // userRes.reInviteUserMessage = action.data.message;
      // userRes.deleteUserData = action.data.data;

      return userRes;

    case DELETE_USER_FAILED:
      console.error(
        "DELETE User Reducer => Case => Error: while getting category list: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);

    case DELETE_USER_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
