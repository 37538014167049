import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import * as Api from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";

const TAG = "carrierSelection.js";

function* getAllCarrierCountryList(payLoad) {
  console.log(TAG, "worker getAllCarrierCountryList : " + JSON.stringify(payLoad));
  console.log(TAG, "getALLCarrierCountryList : " + JSON.stringify(payLoad.data));

  try {
    const response = yield call(
      Api.makeUnsecuredPostRequest,
      API_ENDPOINT.COUNTRY_AND_CARRIER_LIST,
      payLoad.data.bot_id
    );
    console.log(
      TAG,
      "worker getCarrierCountryList Response: " + JSON.stringify(response)
    );
    yield put(
      memberAction.createActionOf(
        memberType.GET_ALL_CARRIER_AND_COUNTRY_LIST_SUCCESS,
        1,
        response
      )
    );
  } catch (error) {
    console.log(TAG, "Worker getCarrierCountryList error: " + error.message);
    yield put(
      memberAction.createActionOf(
        memberType.GET_ALL_CARRIER_AND_COUNTRY_LIST_FAILED,
        2,
        error
      )
    );
  }
}

export const watchGetCountryCarriersAll = takeLatest(
  memberType.GET_ALL_CARRIER_AND_COUNTRY_LIST_REQUESTED,
  getAllCarrierCountryList
);
