import { LOADING, STATUS_CODE, refreshToken } from "../../constants/constant";
import {
  PARTNER_DETAILS_REQUESTED,
  PARTNER_DETAILS_FAILED,
  PARTNER_DETAILS_SUCCESS,
  PARTNER_DETAILS_RESET,
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function partnerDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case PARTNER_DETAILS_REQUESTED:
      let requestState = Object.assign({}, state);
      requestState.isLoading = true;
      requestState.status = LOADING;
      requestState.statusCode = STATUS_CODE;
      return requestState;

    case PARTNER_DETAILS_SUCCESS:
      let userDetails = Object.assign({}, state);
      userDetails.status = action.data.data.status_message;
      userDetails.isLoading = false;
      userDetails.statusCode = action.data.data.status_code;
      if (action.data.data.message === "basic details not present") {
        userDetails.partnerDetails = action.data.data.message;
        userDetails.mobile_number = action.data.data.data.phoneNumber;
        userDetails.partnerDetailsData = action.data.data.data;
        userDetails.partnerDetailsFullResponse = action.data.data;
        return userDetails;
      }
      if (action.data.data.message === "phone number not verified") {
        userDetails.partnerDetails = action.data.data.message;
        userDetails.email = action.data.data.data.email;
        userDetails.partnerDetailsFullResponse = action.data.data;
        return userDetails;
      }
      userDetails.carrierList = action.data.data.data.admin_carrier_list;
      userDetails.partnerProfileImage = action.data.data.data.profile_image;
      userDetails.partnerDetails = action.data.data.data.partners_profile;
      userDetails.partnerID = action.data.data.data.partners_profile.partner_id;
      userDetails.partnerCompanyDetails = action.data.data.data.company;
      userDetails.partnerAddress = action.data.data.data.address;
      userDetails.profileCompleted = action.data.data.data.profile_completed;
      userDetails.admin_carrier_list =
        action.data?.data?.data?.admin_carrier_list;
      userDetails.isCarrier = action.data.data.data.partners_profile?.isCarrier;
      userDetails.carrierID = action.data.data.data.partners_profile?.carrierId;
      userDetails.verifiedPhone =
        action.data.data.data?.partners_profile?.verifiedPhone === "Y" ?? false;
      userDetails.isAggregator =
        action.data.data.data.partners_profile?.isAggregator;
      userDetails.carrier_name = action.data.data.data.carrier_name;
      //need to remove or checks
      userDetails.isSuperAdmin =
        action.data.data.data.partners_profile.role === "SUPER_ADMIN_DOTGO" ||
        "ADMIN_DOTGO_READONLY" ||
        "ADMIN_DOTGO_READWRITE";
      userDetails.isReadOnlyAdmin =
        action.data.data.data.partners_profile.role ===
          "VI_DOTGO_ADMIN_READONLY" ||
        action.data.data.data.partners_profile.role === "ADMIN_DOTGO_READONLY";
      userDetails.isReadWriteAdmin =
        action.data.data.data.partners_profile.role ===
          "VI_DOTGO_ADMIN_READWRITE" ||
        action.data.data.data.partners_profile.role === "ADMIN_DOTGO_READWRITE";
      // Added for Marketing team input
      userDetails.isMarketingAdmin =
        action.data.data.data.partners_profile.role === "MARKETING_ADMIN";
      // action.data.data.data.partners_profile.role === "SUPER_ADMIN_DOTGO";
      // if(action.data.data.data.partners_profile.role === "VI_DOTGO_ADMIN"){
      // // if(action.data.data.data.partners_profile.role === "VI_CARRIER_ADMIN"){
      //   // userDetails.isDotgoAdmin = true;
      //   userDetails.isDotgoAdmin = true;
      // }
      // else{
      //   userDetails.isDotgoAdmin = false;
      // }
      // userDetails.isVIDotgoAdmin = /VI_DOTGO/.test(action.data.data.data.partners_profile.role);

      // userDetails.isVIAdmin = action.data.data.data.partners_profile.role === /VI_CARRIER/.test(action.data.data.data.partners_profile.role);

      userDetails.isReadOnlyVIAdmin =
        action.data.data.data.partners_profile.role === "VI_CARRIER_READONLY" ||
        action.data.data.data.partners_profile.role ===
          "VI_DOTGO_ADMIN_READONLY";

      userDetails.fullPartnerDetails =
          action.data.data.data
      
      userDetails.isAccountManager = action?.data?.data?.data?.partners_profile?.role === "ACC_MANAGER";    

      try {
        if (action?.data?.data?.data?.partners_profile?.role) {
          userDetails.isVIAdmin =
            action.data.data.data.partners_profile.role.includes("VI_CARRIER_") || action.data.data.data.partners_profile.role.includes("ACC_MANAGER");
          userDetails.isVIDotgoAdmin =
            action.data.data.data.partners_profile.role.includes("VI_DOTGO") || action.data.data.data.partners_profile.role.includes("ACC_MANAGER");
        }
      } catch (error) {}
      console.log(
        "🚀 ~ file: partnersDetailsReducer.js ~ line 94 ~ partnerDetailsReducer ~ userDetails",
        userDetails,
        action.data.data.data.partners_profile.role
      );
      

      return userDetails;

    case "PARTNER_DETAILS_MODIFIED":
          
      const obj= JSON.stringify(state);
      const objCopy=JSON.parse(obj)
      objCopy.partnerDetails.launchApproved=false;
      return objCopy;

    case PARTNER_DETAILS_FAILED:
      refreshToken();
      return Object.assign({}, state, action.error);

    case PARTNER_DETAILS_RESET:
      return initialState;

    default:
      return state;
  }
}
