import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import * as Api from "../../appApi/AppApi";


const TAG = "richPromotionsAndNotificationsCampaignSummary.js";

function* workerGetRichPromotionsAndNotificationsCampaignSummary(payload) {
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY,
      payload.data
    );
    yield put(
      memberAction.getRichPromotionsAndNotificationsCampaignSummarySuccess(
        response
      )
    );
  } catch (error) {
    yield put(
      memberAction.getRichPromotionsAndNotificationsCampaignSummaryFailed(
        error.message
      )
    );
  }
}

export const watchGetRichPromotionsAndNotificationsCampaignSummary = takeLatest(
  memberType.GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY_REQUESTED,
  workerGetRichPromotionsAndNotificationsCampaignSummary
);
