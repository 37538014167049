import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

const TAG = "setBillingUserPasswordSaga.js";

function* workerSetBillingUserPassword(payLoad) {
  console.log(
    TAG,
    "worker billing user SetPassword : " + JSON.stringify(payLoad)
  );
  console.log(
    TAG,
    "worker billing User SetPassword : " + JSON.stringify(payLoad.data)
  );
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.SET_BILLING_USER_PASSWORD,
      payLoad.data
    );
    console.log(
      TAG,
      "worker Billing User SetPassword Response: " + JSON.stringify(response)
    );
    yield put(memberAction.setBillingUserPasswordSuccess(response));
  } catch (error) {
    console.log(TAG, "Worker SetPassword error: " + error.message);
    yield put(memberAction.setBillingUserPasswordFailed(error.message));
  }
}

export const watchSetBillingUserPassword = takeLatest(
  memberType.SET_BILLING_USER_PASSWORD_REQUESTED,
  workerSetBillingUserPassword
);
