import {
  ERROR,
  LOADING,
  STATUS_CODE_CUSTOM,
  STATUS_CODE_SUCCESS,
} from "../../constants/constant";
import {
  SET_PASSWORD_REQUESTED,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILED,
} from "../actions/ActionType";

const initialState = {
  status: "",
  isLoading: false,
};

export default function setPasswordReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PASSWORD_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case SET_PASSWORD_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log("came to the setPasswordReducer   " + JSON.stringify(action));
      userRes.status = action.data.status_message;
      userRes.isLoading = false;
      userRes.statusCode = action.data.status_code;
      if (userRes.statusCode !== STATUS_CODE_SUCCESS) {
        userRes.error = true;
        userRes.errorMessage = action.data.message;
        return userRes;
      }
      userRes.message = action.data.message;
      userRes.error = false;
      return userRes;

    case SET_PASSWORD_FAILED: {
      let error = Object.assign({}, state);
      error.status = ERROR;
      error.statusCode = STATUS_CODE_CUSTOM;
      error.isLoading = false;
      error.error = true;
      error.errorMessage = "Something went wrong";
      error.errorDetails = action;
      return error;
    }

    default:
      return state;
  }
}
