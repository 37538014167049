import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

const TAG = "addTestDevices.js";

function* workerAddTestDevices(payload) {
  try {
    if (!payload.data) {
      throw new Error(
        "bot id is undefined: Payload=>" + JSON.stringify(payload)
      );
    }
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.ADD_TEST_DEVICES,{ "bot_id":payload.bot_id,"phone_number":payload.data}
    );
    // console.log(
    //   TAG,
    //   "[worker get test devices:]" + JSON.stringify(response)
    // );
    yield put(memberAction.addTestDevicesSuccess(response));
  } catch (error) {
    console.error(TAG, "Error: while adding test devices: ", error.message);
    yield put(memberAction.addTestDevicesFailed(error.message));
  }
}

export const watchAddTestDevicesSaga = takeLatest(
  memberType.ADD_TEST_DEVICES_REQUESTED,
  workerAddTestDevices
);