import React from "react";
import { EmailVerifyPage } from "../../../../components/Pages/SignupPages/Index";
import MarginOf from "../../../../components/Layout/MarginOf";
import { withRouter } from "react-router-dom";
const EmailVerify = props => {
  const onClickOk = event => {
    props.history.push({
      pathname: "/",
      state: undefined,
    });
  };

  return (
    <React.Fragment>
      <EmailVerifyPage onClickOk={onClickOk} />
      <MarginOf margin={"100px"} />
    </React.Fragment>
  );
};
export default withRouter(EmailVerify);
