import { LOADING, STATUS_CODE_SUCCESS } from "../../../constants/constant"
import {
  ADD_RICH_TEMPLATE,
  ADD_RICH_TEMPLATE_SUCCESS,
  ADD_RICH_TEMPLATE_FAILED,
  ADD_RICH_TEMPLATE_RESET
} from "../../actions/ActionType"

const initialState = {
  status: "INIT",
  isLoading: false,
  // rand: Math.random()
}

export default function addRichTempalteReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_RICH_TEMPLATE:
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      // reqstState.rand = Math.random()
      return reqstState

    case ADD_RICH_TEMPLATE_SUCCESS: {
      const { data: { resData = {} } = {} } = action
      let userRes = Object.assign({}, state)
      userRes.status = action.data.status_message
      userRes.message = action.data.message
      userRes.statusCode = action.data.status_code
      userRes.isLoading = false
      return userRes
    }
    case ADD_RICH_TEMPLATE_FAILED:
      return Object.assign({}, state, action.error)

    case ADD_RICH_TEMPLATE_RESET:
      return initialState

    default:
      return state
  }
}
