import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import * as Api from "../../appApi/AppApi";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";

function* watchSubmitBotReviewAsync(payLoad) {
  try {
    const response = yield call(
      Api.makePostRequestForMultipart,
      // "http://172.20.20.234:8083/directory/secure/api/v1/submit_bot_review_reply",
      API_ENDPOINT.REPLY_REVIEW,
      payLoad.data
    );
    console.log(
      "Worker watchsubmitBotReview Response: " + JSON.stringify(response)
    );
    yield put(memberAction.submitBotReviewSuccess(response));
  } catch (error) {
    console.log("Worker watchsubmitBotReview error: " + error.message);
    yield put(memberAction.submitBotReviewFailed(error.message));
  }
}

export const watchSubmitBotReview = takeLatest(
  memberType.SEND_BOT_REVIEWS,
  watchSubmitBotReviewAsync
);
