import { LOADING, STATUS_CODE_SUCCESS } from "../../constants/constant"
import {
  CREATE_RCS_BOT,
  CREATE_RCS_BOT_SUCCESS,
  CREATE_RCS_BOT_FAILED,
  CREATE_RCS_BOT_RESET,
} from "../actions/ActionType"

const initialState = {
  status: "INIT",
  isLoading: false,
}

export default function createRCSBotReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_RCS_BOT:
      let tempState = Object.assign({}, state)
      tempState.isLoading = true
      tempState.status = LOADING
      return tempState

    case CREATE_RCS_BOT_SUCCESS:
      let response = Object.assign({}, state)
      response.status = action.data.status_message
      response.statusCode = action.data.status_code
      response.isLoading = false
      if (response.statusCode === STATUS_CODE_SUCCESS) {
        response.data = action.data.data
        return response
      } else {
        response.error = true
        response.errorMessage = action.data.message
        return response
      }

    case CREATE_RCS_BOT_FAILED:
      return Object.assign({}, state, action.error)
    case CREATE_RCS_BOT_RESET:
      return initialState
    default:
      return state
  }
}
