import React from "react";
import MarginOf from "../../Layout/MarginOf";
const EmailVerifyPage = props => {
  return (
    <React.Fragment>
      {/*---==== Verification Email Section Start ======--*/}
      <section className="main-section body-margin-top">
        <div className="container">
          <div className="row">
            <div className="col-md-12 verifying-email">
              <img src="images/icon_submit_successful.svg" alt="Verification Email!" />
              <h2>Verification Email!</h2>
              <span>We have sent you a verification email!</span>
              <p>Please click on the link in your email to verify your login.</p>
              <a type="button" className="pri-btn" href="#">OK</a>
            </div>
          </div>
        </div>
      </section>      
      {/*---==== Verification Email Section End ======--*/}
      
    </React.Fragment>
  );
};
export default EmailVerifyPage;
