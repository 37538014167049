import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import * as Api from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";

function* workerSubmitBotSummary(payLoad) {
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.SUBMIT__SUMMARY,
      payLoad.data
    );
    console.log("worker submitBotSummarySaga Response: ", response);
    yield put(
      memberAction.createActionOf(
        memberType.SUBMIT_BOT_SUMMARY_SUCCESS,
        1,
        response
      )
    );
  } catch (error) {
    console.log("Worker submitBotSummarySaga error: ", error.message);
    yield put(
      memberAction.createActionOf(
        memberType.SUBMIT_BOT_SUMMARY_FAILED,
        2,
        error
      )
    );
  }
}

export const watchSubmitBotSummary = takeLatest(
  memberType.SUBMIT_BOT_SUMMARY,
  workerSubmitBotSummary
);
