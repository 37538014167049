import { put, takeLatest, call } from "redux-saga/effects";
import {createActionOf} from "../../actions/ActionCreator";
import {
    APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN,
    APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN_SUCCESS,
    APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN_FAILED,
    APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN_RESET
} from "../../actions/ActionType";
import {makeSecuredPostRequest} from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";

const TAG = "Adminmybot.js";

function* workerApproveOrRejectAggregator(payLoad) {
  console.log(
    TAG,
    "workerApproveOrRejectAggregator Admin : " + JSON.stringify(payLoad)
  );
  console.log(
    TAG,
    "worker workerApproveOrRejectAggregator Admin: " + JSON.stringify(payLoad.data)
  );
  try {
    const response = yield call(
        makeSecuredPostRequest,
       API_ENDPOINT.APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN,
       payLoad.data
    );
    console.log(
      TAG,
      "worker workerApproveOrRejectAGGREGATOR Response: " + JSON.stringify(response)
    );
    yield put(createActionOf(APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN_SUCCESS, 1, response));
} catch (error) {
  console.error(TAG, "Worker get country failed: " + error.message);
  yield put(createActionOf(APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN_FAILED, 2, error));
}
}

export const watchApproveOrRejectDotgoAggregatorsAdmin = takeLatest(
    APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN,
    workerApproveOrRejectAggregator
);
