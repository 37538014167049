import { LOADING } from "../../constants/constant";
import {
  GET_SINGLE_BRAND_REQUESTED,
  GET_SINGLE_BRAND_SUCCESS,
  GET_SINGLE_BRAND_FAILED,
  GET_SINGLE_BRAND_RESET
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function getSingleBrandReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SINGLE_BRAND_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_SINGLE_BRAND_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.status = action.data.status_message;
      userRes.statusCode = action.data.status_code;
      userRes.isLoading = false;
      // userRes.statusCode = action.data.statusCode;
      userRes.brandData = action.data.data;
      if (action.data.data.status_id === 11) {
        userRes.isBrandVerified = true;
      } else {
        userRes.isBrandVerified = false;
      }
      return userRes;

    case GET_SINGLE_BRAND_FAILED:
      console.error("Error while getting brand details", action.error);
      return Object.assign({}, state, action.error);

      case GET_SINGLE_BRAND_RESET: {
        return initialState;
      }
    default:
      return state;
  }
}
