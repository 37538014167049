import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../../actions/ActionCreator";
import * as memberType from "../../../actions/ActionType";
import { API_ENDPOINT } from "../../../URLs/ApiEndpoints";
import * as Api from "../../../../appApi/AppApi";

const TAG = "adminGetSubmittedBrands.js";

function* workerPendingApprovalBrands(payLoad) {
  console.log(TAG, "get pending brands admin: " + JSON.stringify(payLoad));
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.PENDING_APPROVAL_BRANDS
    );
    yield put(memberAction.createActionOf(memberType.PENDING_APPROVAL_BRANDS_SUCCESS,1,response));
  } catch (error) {
    console.error(TAG, "Worker get submitted brands failed: " + error.message);
     yield put(memberAction.createActionOf(memberType.PENDING_APPROVAL_BRANDS_FAILED,2,error));
    // throw new Error("Error: inside saga", JSON.stringify(error));
  }
}

export const watchPendingApprovalBrands = takeLatest(
  memberType.PENDING_APPROVAL_BRANDS_REQESTED,
  workerPendingApprovalBrands
);
