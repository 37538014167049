import { takeLatest, put, call } from "redux-saga/effects";
import * as API from "../../../appApi/AppApi";
import * as actions from "../../actions/ActionType";
import * as actionCreator from "../../actions/ActionCreator";

function* fetchGeoLocationAsync(payload) {
  try {
    // console.log("calling:",API.fetchGeoLocation)
    const response = yield call(API.fetchGeoLocation);
    console.log("calling:",response)
    yield put(actionCreator.fetchGeoLocationSuccess(response));
  } catch (error) {
    yield put(actionCreator.fetchGeoLocationFailed(error));
  }
}

export function* watchFetchGeoLocation() {
  yield takeLatest(actions.FETCH_GEO_LOCATION, fetchGeoLocationAsync);
}
