import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import * as Api from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";

function* workerDeleteDocs(payLoad) {
  console.log(
    " Payload:: " + JSON.stringify(payLoad)
  );

 const formData=new FormData();
 formData.append("file",payLoad.data)
  try {

    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.DELETE_DOCS, 
    formData
    );
    console.log(
      "Worker: => Response:: " +
        JSON.stringify(response)
    );
    yield put(memberAction.createActionOf(memberType.DELETE_DOCS_SUCCESS,1,response));
  } catch (error) {
    console.error("Worker: => Error::" + error.message);
    yield put(memberAction.createActionOf(memberType.DELETE_DOCS_FAILED,2,error));
  }
}

export const watchDeleteDocs= takeLatest(
  memberType.DELETE_DOCS,
  workerDeleteDocs
);
