import { requestLogin } from "../constants/constant";
import { REACT_APP_AXIOS_TIMEOUT } from "../constants/stringConstants";
import SecureService, { getToken } from "../service/SecureService";
import { API_ENDPOINT } from "../store/URLs/ApiEndpoints";
// import imageDataURI from "image-data-uri"
import Axios from "axios";

export const uploadFile = (event) => {
  let formData = new FormData();
  formData.append("file", event.target.files[0]);
  return formData;
};

export const uploadLOA = (payload) => {
  return SecureService.postCall(API_ENDPOINT.SUBMIT_BOT_LOA, payload);
};
export const getBas64Of = (string) => {
  return Buffer.from(string).toString("base64");
};
export const getAsciiOf = (string) => {
  return Buffer.from(string, "base64").toString("ascii");
};
export const getUtfOf = (string) => {
  return Buffer.from(string, "base64").toString("utf8");
};
export const getBas64OfFile = (blob) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = function () {
      fileReader.abort();
      reject(fileReader.error);
    };
    fileReader.readAsDataURL(blob);
  });
};
export const getBlobOfBas64 = async (bas64string) => {
  const base64Response = await fetch(`${bas64string}`);
  const blob = await base64Response.blob();
  return blob;
};

export const downloadEncryptedFile = async (url) => {
  console.log("File URL downloadEncryptedFile: :", url);
  try {
    const token = await getToken();
    const response = await Axios.get(
      API_ENDPOINT.ENCRYPTED_FILE_DOWNLOAD + url,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
        timeout: REACT_APP_AXIOS_TIMEOUT,
      }
    );
    return response;
  } catch (error) {
    if (error?.response?.status === 401) {
      requestLogin();
    } else {
      throw Error(error);
    }
  }
};
