import { LOADING } from "../../../constants/constant";
import {
  GET_ALL_USERS_FAILED,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_REQUESTED,
  GET_ALL_USERS_RESET,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function getAllUsersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_USERS_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_ALL_USERS_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log(
        "get All Users => Case => Success: while getting category list: " +
          JSON.stringify(action.data)
      );
      userRes.status = action.data.status_message;
      userRes.statusCode = action.data.status_code;
      userRes.isLoading = false;
      //   userRes.statusCode = action.data.status;
      userRes.data = action.data.data.data;

      return userRes;

    case GET_ALL_USERS_FAILED:
      console.error(
        "get all users Reducer => Case => Error: while getting category list: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);

    case GET_ALL_USERS_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
