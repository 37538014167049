/* eslint-disable jsx-a11y/anchor-is-valid */
import { requestLogout } from "../../constants/constant";
import { withRouter, NavLink } from "react-router-dom";
import Header from "./Header";
import { useContext } from "react";
import { AuthContext } from "../../Providers/ContextProviders/AuthContext";
import AsPieProgress2 from "../util/AsPieProgress2";
import {
  BILLING_ANALYTICS_URL,
  IS_TRAFFIC_AVAILABLE,
} from "../../constants/stringConstants";

const routeArray = [
  "/setup-enterprise",
  "/setup-user",
  "/signup-success",
  "/developer/mobile-verification",
];

const activeArray = [
  "/billing/my-bots",
  "/billing/my-brands",
  "/billing/my-aggregators",
  "/billing/my-billing",
  "/billing/view-brand",
  "/billing/bot-details",
];

const AdminNavbar = (props) => {
  const { isLoggedIn } = useContext(AuthContext);

  const getDropdownItems = () => {
    if (props.partnerDetails.isCarrier) {
      if (props.partnerDetails.role === "VI_CARRIER_ADMIN") {
        let list = [
          {
            label: "Manage Users",
            link: "/billing/edit-profile/manage-users",
          },
        ];
        let editProfileLink = "/billing/edit-profile/account-details";
        return { list, editProfileLink };
      } else {
        let list = [
          {
            label: "Edit Profile",
            link: "/billing/edit-profile/account-details",
          },
        ];
        let editProfileLink = "/billing/edit-profile/account-details";
        return { list, editProfileLink };
      }
    } else if (props.partnerDetails.role === "SUPER_ADMIN_VI_DOTGO") {
      let list = [
        {
          label: "Manage Users",
          link: "/dotgo-admin/edit-profile/manage-users",
        },
      ];
      let editProfileLink = "/dotgo-admin/edit-profile/account-details";
      return { list, editProfileLink };
    } else if (props.partnerDetails.role === "ADMIN_DOTGO_READONLY") {
      let list = [
        {
          label: "Edit Profile",
          link: "/dotgo-admin/edit-profile/account-details",
        },
      ];
      let editProfileLink = "/dotgo-admin/edit-profile/account-details";
      return { list, editProfileLink };
    } else if (props.partnerDetails.role === "VI_DOTGO_ADMIN_READWRITE") {
      let list = [
        {
          label: "Edit Profile",
          link: "/dotgo-admin/edit-profile/account-details",
        },
      ];
      let editProfileLink = "/dotgo-admin/edit-profile/account-details";
      return { list, editProfileLink };
    } else if (props.isVIDotgoAdmin === true) {
      let list = [
        {
          label: "Edit Profile",
          link: "/dotgo-admin/edit-profile/account-details",
        },
      ];
      let editProfileLink = "/dotgo-admin/edit-profile/account-details";
      return { list, editProfileLink };
    } else {
      let list = [
        {
          label: "Edit Profile",
          link: "/developer/edit-profile",
        },
      ];
      let editProfileLink = "/edit-profile";
      return { list, editProfileLink };
    }
  };
  return !routeArray.includes(props.location.pathname) ? (
    <nav className="navbar navbar-default navbar-fixed-top navbar-bg">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            aria-expanded="false"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          {!isLoggedIn ? (
            <a className="navbar-brand" href="/">
              <img
                src="images/vi_logo.svg"
                className="vi-navbar-logo-size"
                alt="VI"
              />
            </a>
          ) : props.partnerDetails.role === "ACC_MANAGER" ? (
            <a href={`${BILLING_ANALYTICS_URL}/analytics/dotgo-admin/billing`} className="navbar-brand">
              <img
                src="images/vi_logo.svg"
                className="vi-navbar-logo-size"
                alt="VI"
              />
            </a>
          ) : (
            <NavLink className="navbar-brand" to="/">
              <img
                src="images/vi_logo.svg"
                className="vi-navbar-logo-size"
                alt="VI"
              />
            </NavLink>
          )}
        </div>
        {/* Collect the nav links, forms, and other content for toggling */}
        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          {props.isAccountManager ? (
            <AccountManagerNavList />
          ) : props.isVIDotgoAdmin || props.isVIAdmin ? (
            <DotgoAdminNavList isVIDotgoAdmin={props.isVIDotgoAdmin} />
          ) : (
            <BillingNavList />
          )}
          <ul className="nav navbar-nav navbar-right">
            {/* For future scope */}
            {/* <li onClick={togglePopup}>
                  {isOpen && (
                    <ComingSoonModal handleClose={togglePopup} open={open} />
                  )}
                  <i
                    className="fa fa-1x fa-bell "
                    style={{
                      fontSize: "24px",
                      color: "#ffffff",
                      paddingTop: "12px",
                    }}
                  />
                  <span className="badge badge-blue-bg">4</span>
                </li> */}
            <ProfileDropdown
              dropdownItems={getDropdownItems().list}
              userImage={props.partnerProfileImage}
              profileCompleted={+props.profileCompleted}
              userFirstName={props.partnerDetails.firstName}
              userLastName={props.partnerDetails.lastName}
              userEmail={props.partnerDetails.email}
              editProfileLink={getDropdownItems().editProfileLink}
            />
          </ul>
        </div>
      </div>
    </nav>
  ) : (
    <Header />
  );
};

export default withRouter(AdminNavbar);

const ProfileDropdown = ({
  userImage = "images/default_user.png",
  profileCompleted = 100,
  dropdownItems = [],
  editProfileLink = "",
  userFirstName = "",
  userLastName = "",
  userEmail = "",
  ...props
}) => {
  return (
    <>
     <li id="faq-btn-padding-top-6">
      <NavLink to="/faq" className="ol-nav-link">
          <button className="ol-nav-link faq-btn-post-l">FAQs</button>
      </NavLink>
    </li>
    <li className="dropdown">
      <a
        className="ol-nav-link dropdown-toggle"
        data-toggle="dropdown"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img
          src={`${userImage}?img=${new Date().getTime()}`}
          className="img-circle"
          width={35}
          alt="Welcome User"
          style={{ marginTop: "-8px" }}
        />
        <span className="caret" />
      </a>
      <ul
        id="profile_dropdown"
        className={`dropdown-menu dropdown-menu--profile ${
          profileCompleted === 100
            ? "dropdown-menu--profile_complete"
            : "dropdown-menu--profile_incomplete"
        } `}
      >
        <li hidden={profileCompleted === 100} className="dropdown-menu--pie">
          <NavLink className="ol-nav-link" to={editProfileLink}>
            <div
              className="dropdown-profile-pic"
              style={{ display: "inline-block" }}
            >
              <div
                className="percent"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              >
                <AsPieProgress2 progress={profileCompleted} />
              </div>
            </div>
            <div className="dropdown-profile-text dropdown-profile-text-m">
              <small className="dropdown-profile-text--percent">
                Your Profile is {profileCompleted}% Complete{" "}
              </small>{" "}
              <br />
              <span className="text-red dropdown-menu--pie_complete-link">
                Complete Profile
              </span>
            </div>
          </NavLink>
        </li>

        <li className="dropdown-menu--usercontent">
          <NavLink className="ol-nav-link" to={editProfileLink}>
            <div className="dropdown-profile-pic">
              <img
                src={
                  userImage
                    ? userImage + `?img=${new Date().getTime()}`
                    : "images/default_user.png"
                }
                className="img-circle"
                width={35}
                alt="Welcome User"
              />
            </div>
            <div className="dropdown-profile-text dropdown-profile-text-m ">
              <span
                className="dropdown-profile-text-m--username"
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "150px",
                  display: "inline-block",
                }}
              >
                {userFirstName}
                &nbsp;
                {userLastName}
              </span>
              <br />
              <span className="dropdown-profile-text-m--email text-light-gray text-right">
                <small
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {userEmail}
                </small>
              </span>
            </div>
          </NavLink>
        </li>
        {/* dropdownItems */}
        {dropdownItems.map((ele) => (
          <li key={ele.label}>
            <NavLink to={ele.link}>{ele.label}</NavLink>
          </li>
        ))}
        <li>
          <NavLink className="ol-nav-link" to="/dotgo-admin/change-password">
            Change Password
          </NavLink>
        </li>
        <li>
          <NavLink to="/dotgo-admin/Carrier/Admin/latestdocumentation">
            Downloads
          </NavLink>
        </li>

        <li role="separator" className="divider" />
        <li>
          <a
            onClick={requestLogout}
            className="text-center text-red cursor-pointer"
          >
            Logout
          </a>
        </li>
      </ul>
    </li>
    </>
  );
};

const BillingNavList = withRouter((props) => {
  return (
    <ul className="nav navbar-nav  navbar-left">
      <li className={props.location.pathname.match(activeArray[3]) && "active"}>
        <a
          href={`${BILLING_ANALYTICS_URL}/dotgo-admin/dashboard`}
          className="ol-nav-link"
        >
          MY BILLING
        </a>
      </li>

      <li
        className={
          (props.location.pathname === activeArray[0] && "active") ||
          (props.location.pathname.match(activeArray[5]) && "active")
        }
      >
        <NavLink className="ol-nav-link" to="/billing/my-bots">
          MY BOTS <span className="sr-only">(current)</span>
        </NavLink>
      </li>

      <li
        className={
          props.location.pathname.match(activeArray[1]) ||
          props.location.pathname.match(activeArray[4])
            ? "active"
            : ""
        }
      >
        <NavLink className="ol-nav-link" to="/billing/my-brands">
          MY BRANDS
        </NavLink>
      </li>

      <li className={props.location.pathname.match(activeArray[2]) && "active"}>
        <NavLink className="ol-nav-link" to="/billing/my-aggregators">
          MY AGGREGATORS
        </NavLink>
      </li>
    </ul>
  );
});
const DotgoAdminNavList = withRouter((props) => {
  const dotgoAdminArray = {
    dashboard: ["/dotgo-admin/dashboard", "/dashboard", "/developer/dashboard"],
    bots: [
      "/dotgo-admin/bots",
      "/dotgo-admin/bots/launch",
      "/dotgo-admin/bots/template-verification",
      "/dotgo-admin/bots/verification",
      "/dotgo-admin/bots/creation",
    ],
    viewbots: [
      "/dotgo-admin/viewBotDetails",
      "/dotgo-admin/verification-details/",
      "/dotgo-admin/viewtemplateInfo/1",
    ],
    aggregators: /dotgo-admin\/aggregators/,
    viewAggregator: /dotgo-admin\/viewAggregatorDetails/,
    brands: /dotgo-admin\/my-brands/,
    viewBrand: /dotgo-admin\/view-brand/,
    campaigns: ["/dotgo-admin/campaigns"],
  };

  const activateLink = (...rxList) => {
    return rxList.some((rx) => rx.test(props?.location?.pathname))
      ? "active"
      : "";
  };

  return (
    <ul className="nav navbar-nav  navbar-left">
      <li
        className={
          dotgoAdminArray.dashboard.includes(props.location.pathname) &&
          "active"
        }
      >
        <NavLink className="ol-nav-link" to="/dotgo-admin/dashboard">
          DASHBOARD
        </NavLink>
      </li>

      <li
        className={
          (dotgoAdminArray.bots.includes(props.location.pathname) &&
            "active") ||
          (props.location.pathname.includes(dotgoAdminArray.viewbots[0]) &&
            "active") ||
          (props.location.pathname.includes(dotgoAdminArray.viewbots[1]) &&
            "active") ||
          (props.location.pathname.includes(dotgoAdminArray.viewbots[2]) &&
            "active")
        }
      >
        <NavLink className="ol-nav-link" to="/dotgo-admin/bots">
          BOTS <span className="sr-only">(current)</span>
        </NavLink>
      </li>

      <li
        className={activateLink(
          dotgoAdminArray.brands,
          dotgoAdminArray.viewBrand
        )}
      >
        <NavLink className="ol-nav-link" to="/dotgo-admin/my-brands">
          BRANDS
        </NavLink>
      </li>

      <li
        className={activateLink(
          dotgoAdminArray.aggregators,
          dotgoAdminArray.viewAggregator
        )}
      >
        <NavLink className="ol-nav-link" to="/dotgo-admin/aggregators">
          AGGREGATORS
        </NavLink>
      </li>
      <li
        className={
          (dotgoAdminArray.campaigns.includes(props.location.pathname) &&
            "active") ||
          (props.location.pathname.includes(dotgoAdminArray.campaigns) &&
            "active")
        }
      >
        <NavLink className="ol-nav-link" to="/dotgo-admin/campaigns">
          CAMPAIGNS
        </NavLink>
      </li>
      <li>
        <a
          href={`${BILLING_ANALYTICS_URL}/analytics/dotgo-admin/billing`}
          className="ol-nav-link"
        >
          BILLING
        </a>
      </li>
      <li>
        {IS_TRAFFIC_AVAILABLE === "true" ? (
          <a
            href={`${BILLING_ANALYTICS_URL}/analytics/dotgo-admin/traffic-dashboard`}
            className="ol-nav-link"
          >
            TRAFFIC
          </a>
        ) : null
        // <NavLink to={"/under-development"} className="ol-nav-link">
        //   TRAFFIC
        // </NavLink>
        }
      </li>
    </ul>
  );
});

const AccountManagerNavList = withRouter((props) => {
  return (
    <ul className="nav navbar-nav  navbar-left">
      <li>
        <a href={`${BILLING_ANALYTICS_URL}/analytics/dotgo-admin/billing`} className="ol-nav-link">
          BILLING
        </a>
      </li>
    </ul>
  );
});
