import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import * as Api from "../../appApi/AppApi";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";

function* workerUpdateBrand(payLoad) {
  console.log(
    "Worker: workerUpdateBrand => Payload:: " + JSON.stringify(payLoad.data)
  );
  try {
    const response = yield call(
      Api.makePostRequestForMultipart,
      API_ENDPOINT.UPDATE_BRAND,
      payLoad.data
    );
    console.log(
      "Worker: workerUpdateBrand => Response:: " + JSON.stringify(response)
    );
    yield put(memberAction.updateBrandSuccess(response));
  } catch (error) {
    console.error("Worker: workerUpdateBrand => Error::" + error.message);
    yield put(memberAction.updateBrandFailed(error.message));
  }
}

export const watchUpdateBrand = takeLatest(
  memberType.UPDATE_BRAND_REQUESTED,
  workerUpdateBrand
);
