import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import * as Api from "../../appApi/AppApi";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";

function* watchLoadBotReviewAsync(payLoad) {
  try {
    const response = yield call(
      Api.makePostRequestForMultipart,
      API_ENDPOINT.LOAD_REVIEW,
      // "http://172.20.20.234:8083/directory/secure/api/v1/get_bot_review_reply",
      payLoad.data
    );
    console.log(
      "Worker watchLoadBotReview Response: " + JSON.stringify(response)
    );
    yield put(memberAction.loadBotReviewSuccess(response));
  } catch (error) {
    console.log("Worker watchLoadBotReview error: " + error.message);
    yield put(memberAction.loadBotReviewFailed(error.message));
  }
}

export const watchLoadBotReview = takeLatest(
  memberType.LOAD_BOT_REVIEWS,
  watchLoadBotReviewAsync
);
