import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import * as Api from "../../appApi/AppApi";

const TAG = "getBotList.js";

function* workerGetBotWhatsAppDetails(payload) {
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.GET_BOT_DETAILS,
      payload.data
    );
    console.log(TAG, "worker brandList success: " + JSON.stringify(response));
    yield put(memberAction.getBotWhatsAppDetailsSuccess(response));
  } catch (error) {
    console.log(TAG, "Worker brandList failed: " + error.message);
    yield put(memberAction.getBotWhatsAppDetailsFailed(error.message));
  }
}

export const watchGetBotWhatsAppDetailsSaga = takeLatest(
  memberType.GET_BOT_WHATSAPP_DETAILS,
  workerGetBotWhatsAppDetails
);
