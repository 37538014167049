import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

function* workerGetBotCreationPreview(payload) {
  try {
    let apiEndpoint =
      API_ENDPOINT.GET_BOT_CREATION_PREVIEW_DETAILS +
      payload.data?.bot_id +
      "&channel_type_id=1";
    if (
      /admin/i.test(payload?.whoIsThis) ||
      /admin/i.test(payload.data?.whoIsThis)
    ) {
      apiEndpoint =
        API_ENDPOINT.ADMIN_GET_BOT_CREATION_PREVIEW_DETAILS +
        payload.data?.bot_id +
        "&channel_type_id=1";
    }
    const response = yield call(Api.makeSecuredPostRequest, apiEndpoint);
    yield put(memberAction.getBotCreationPreviewSuccess(response));
  } catch (error) {
    console.error("Error: while getting brand details: ", error.message);
    yield put(memberAction.getBotCreationPreviewFailed(error.message));
  }
}

export const watchGetBotCreationPreviewSaga = takeLatest(
  memberType.GET_BOT_CREATION_PREVIEW_REQUESTED,
  workerGetBotCreationPreview
);
