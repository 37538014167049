import { lazy, Suspense } from "react";
import { useRouteMatch, withRouter } from "react-router-dom";
import Fallback from "../../../components/Layout/Fallback";
import ProtectedRoute from "../../Routes/ProtectedRoute";

const DotgoDeveloper = lazy(() => import("../../Routes/DotgoDeveloper"));

const Developer = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Suspense fallback={<Fallback />}>
        <ProtectedRoute path={[`${path}`]} key="developer-dashboard" component={DotgoDeveloper} />
      </Suspense>
    </>
  );
};

export default withRouter(Developer);
