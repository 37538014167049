/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Controller,
  useForm,
  useFieldArray,
  FormProvider,
} from "react-hook-form";
import PhoneInputWithCountry from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import "react-phone-number-input/style.css";
import Select from "react-select";
import OtpModal from "../../../components/Modals/SignUp/OtpModal";
import { getToken } from "../../../service/SecureService";
import { API_ENDPOINT } from "../../../store/URLs/ApiEndpoints";
import { getRSFormatList, isFalsy, isFilePDF } from "../../../utils/otherUtils";
import { regexFirstAndLastName } from "../../../utils/Regex";
import FormError from "../../Errors/FormError";
import TimezoneSelector from "../../util/TimezoneSelector";
import { MAX_CHAR_LENGTH, MAX_CHAR_LENGTH_128, MAX_CHAR_LENGTH_URL, requestLogin } from "../../../constants/constant";
import validator from "validator";
import { isValidPhoneNumber } from "react-phone-number-input";
import { getCountryIso } from "../../../utils/TelFormatter";
import Spinner from "../../Progress/Spinner";
import IPInputWrapper from "./IPInput";
import CommonModal from "../../Modals/CommonModal";
import { saveAs } from "file-saver";
import CommonModalFile from "../../Modals/FileViewerModal";
import FilePreview from "../Developer/Verification/FilePreview";
import isValidURL from "validator/lib/isURL";
import { SetupUser } from "../../../constants/fieldCharacterValidation";
import { REACT_APP_AXIOS_TIMEOUT } from "../../../constants/stringConstants";

const customStyles = {
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    backgroundColor: isSelected ? "#3584e4" : isFocused ? "#DEEBFF" : "#FFF",
    color: isSelected ? "white" : "black",
  }),
};
const SetupUserDetailsPage = ({ onContinue, ...props }) => {
  const userDetails = JSON.parse(localStorage.getItem("__user__d") ?? "{}");
  const useFormMethods = useForm({
    defaultValues: {
      country: userDetails?.country,
      phone_number: userDetails?.phone_number,
      host_address: userDetails?.host_address,
    },
  });
  const {
    register,
    errors,
    handleSubmit,
    control,
    watch,
    clearErrors,
    getValues,
  } = useFormMethods;
  const addressFields = [
    "address_1",
    "address_2",
    "country",
    "state",
    "city",
    "zip_code",
  ];

  let es = null;

  // const [selectedFile, setSelectedFile] = useState();
  let nameDocs = props.kycFileDetails?.filter(
    (ele) => ele.document_type === "business_name_doc"
  );

  let addressDocs = props.kycFileDetails?.filter(
    (ele) => ele.document_type === "business_address_doc"
  );

  const [isSelected, setIsSelected] = useState(
    addressDocs?.length > 0 ? true : false
  );

  const [isSelected2, setIsSelected2] = useState(true);
  const [isSelected_1, setIsSelected_1] = useState(
    nameDocs?.length > 0 ? true : false
  );

  const [userType, setUserType] = useState("aggregator");
  const [verifiedNumber, setVerified] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [mobile, setMobile] = useState("");
  const [mobileLength, setMobileLength] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState({ status: false, message: "" });
  const [documentTypeState, setDocumentTypeState] = useState(false);
  const [srcList, setSrcList] = useState({
    PanCard: "",
    GSTIN: "",
    RegisterDoc: "",
    BrandRegDoc: "",
    PanCardFile:"",
    GSTINFile: "",
    RegisterDocFile: "",
    BrandRegDocFile: "",
  });
  const [openFileExtensionError, setOpenFileExtensionError] = useState(false);

  // const deleteHandler = (event) => {
  //   props.setDeleteFileName(event);
  //   props.setOpen(true);
  // };
  // console.log(props.DDDKYC?.src.type,"props.DDDKYC?.src")

  useEffect(() => {
    if (isLogin) {
      requestLogin();
      window.location.href = "/developer/dashboard";
    }
  }, [isLogin]);

  useEffect(() => {
    setIsSelected2(nameDocs && nameDocs.length === 1 ? true : false);

    setIsSelected_1(nameDocs && nameDocs.length === 0 ? false : true);
    setIsSelected(addressDocs && addressDocs.length === 0 ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.kycFileDetails]);

  const closeOtpModal = () => {
    setIsOTPSent({
      status: false,
      message: "",
    });
  };

  const generateOtp = async (mobileNo) => {
    let authToken = await getToken();
    axios
      .post(
        API_ENDPOINT.GENERATE_OTP,
        { mobileNo: mobileNo },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          timeout: REACT_APP_AXIOS_TIMEOUT 
        }
      )
      .then((response) => {
        // requestLogin();
        console.log(
          "🚀 ~ file: SetupUserDetailsPage.js ~ line 147 ~ generateOtp ~ response",
          response
        );
        if (response.data.status === "ok") {
          setIsOTPSent({
            status: true,
            message: "OTP sent successfully",
          });
          startEventStream(mobileNo);

          setShowVerify(true);
        } else if (
          response.data.status === "error" &&
          response.data.error_reason === "Rejected - The number is invalid"
        ) {
          setIsOTPSent({
            status: true,
            message: "The number is invalid",
          });
        } else if (
          response.data.status === "error" &&
          response.data.error_reason ===
            "User with this phone number already exists"
        ) {
          setIsOTPSent({
            status: true,
            message: "Mobile number already registered!",
          });
        } else if (
          response.data.status === "error" &&
          response.data.error_code === "2"
        ) {
          setIsOTPSent({
            status: true,
            message: "OTP sent successfully",
          });

          setShowVerify(true);
        }
        setIsOtpLoading(false);
      })
      .catch((err) => {
        // if (err.response.status === 401) setLogin(true);
        if (
          err.response !== undefined &&
          err.response.status !== undefined &&
          err.response.status === 401
        )
          setLogin(true);

        setIsOTPSent({
          status: true,
          message: "Error generating OTP",
        });
        setIsOtpLoading(false);
      });
  };

  const startEventStream = (mobileNo) => {
    es = new EventSource(API_ENDPOINT.OTP_CHANNEL + mobileNo);
    es.onmessage = function (event) {
      var data = JSON.parse(event.data);
      if (data.status === "error") {
        es.close();
      } else if (data.status === "ok") {
        if (data.action === "close" && data.otp_action === "confirm") {
          setVerified(true);

          es.close();
        }
        if (data.action === "close" && data.otp_action === "report_fraud") {
          es.close();
        }
      }
    };
    setTimeout(() => es.close(), 120000);
  };

  useEffect(() => {
    if (mobile.length > 6) {
      setMobileLength(true);
      // console.log("🚀 ~ file: SetupUserDetailsPage.js ~ line 252 ~ useEffect ~ setMobileLength", mobileLength)
    } else {
      setMobileLength(false);
    }
  }, [mobile]);

  useEffect(() => {
    localStorage.setItem("userType", userType);
    if (userType === "developer") {
      clearErrors(["nameDocs", "addressDocs"]);
      localStorage.removeItem("step1details");
    }
  }, [userType]);

  useEffect(() => {
    if (userDetails?.host_address && !isIpAdded) {
      setNoOfIP(userDetails?.host_address.length - 1);
    } else if (!isIpAdded) {
      append({ 0: "" });
    }
  }, [userDetails?.host_address]);

  const validatePIN = (pin) => {
    if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pin)) {
      return false;
    } else {
      return true;
    }
  };
  const ianaTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    clearErrors("kycDoc");
  }, [props.KYCDOC]);

  const onActionFileExtensioError = () => {
    // setIsLoading(false);
    setOpenFileExtensionError(false);
  };

  const uploadDoc = (event, name) => {
    let files = event.target.files;
    console.log("Uploaded File details : ", files[0]);
    if(!props.checkFileLength(files[0].name)){
      setOpenFileExtensionError(true);
      return;
    }
    if (
      files[0].type === "application/pdf" ||
      files[0].type === "image/png" ||
      files[0].type === "image/jpg" ||
      files[0].type === "image/jpeg" ||
      files[0].type === "application/msword" ||
      files[0].type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      if (files[0].size > 5e6) {
        setOpenFileExtensionError(true);
      } else {
        props.setIsLoading(true);
        if (name === "KYC") {
          props.setKYCDOC(files[0].name);
        } else {
          props.setDOC({ ...props.DOC, [name]: files[0].name });
        }

        props.onUploadKYC(
          files[0],
          null,
          (status, response) => {
            if (status === "error") {
              if (name === "KYC") {
                // to counter the state set in above line
                props.setKYCDOC("");
              } else {
                const tempDoc = { ...props.DOC, [name]: files[0].name };
                delete tempDoc[name];
                // to counter the state set in above line
                props.setDOC(tempDoc);
              }
              return console.log("Error while uploading background image");
            }
          },
          files[0].name,
          files[0].type
        );
        if (name === "KYC") {
          setDocumentTypeState("KYC");
        } else if (name === "PanCard") {
          setDocumentTypeState("PanCard");
        } else if (name === "GSTIN") {
          setDocumentTypeState("GSTIN");
        } else if (name === "RegisterDoc") {
          setDocumentTypeState("RegisterDoc");
        } else if (name === "BrandRegDoc") {
          setDocumentTypeState("BrandRegDoc");
        }
      }
    } else {
      setOpenFileExtensionError(true);
    }
  };
  useEffect(() => {
    if (documentTypeState === "KYC") {
      props.setDDDKYC({
        document_type: "kyc_doc",
        document_type_id: "58",
        document_name: "Aggregator Kyc Doc",
        original_file_name: props.uploadedKYCDoc?.name,
        file_name: props.uploadedKYCDoc?.uploadedName,
        mime_type: "pdf",
        src: props.uploadedKYCDoc?.src,
      });
    } else if (documentTypeState === "PanCard") {
      let temp = [...props?.DDD];
      temp.push({
        document_type: "business_name_doc",
        document_type_id: "2",
        document_name: "PAN Card of Company",
        original_file_name: props.uploadedKYCDoc?.name,
        file_name: props.uploadedKYCDoc?.uploadedName,
        mime_type: "pdf",
        src: props.uploadedKYCDoc?.src,
      });
      props.setDDD(temp);
    } else if (documentTypeState === "GSTIN") {
      let temp = [...props?.DDD];
      temp.push({
        document_type: "business_name_doc",
        document_type_id: "1",
        document_name: "GST Document",
        original_file_name: props.uploadedKYCDoc?.name,
        file_name: props.uploadedKYCDoc?.uploadedName,
        mime_type: "pdf",
        src: props.uploadedKYCDoc?.src,
      });
      props.setDDD(temp);
    } else if (documentTypeState === "RegisterDoc") {
      let temp = [...props?.DDD];
      temp.push({
        document_type: "business_name_doc",
        document_type_id: "56",
        document_name: "Company Registration Proof",
        original_file_name: props.uploadedKYCDoc?.name,
        file_name: props.uploadedKYCDoc?.uploadedName,
        mime_type: "pdf",
        src: props.uploadedKYCDoc?.src,
      });
      props.setDDD(temp);
    } else if (documentTypeState === "BrandRegDoc") {
      let temp = [...props?.DDD];
      temp.push({
        document_type: "business_name_doc",
        document_type_id: "57",
        document_name: "Company Brand Registration Proof",
        original_file_name: props.uploadedKYCDoc?.name,
        file_name: props.uploadedKYCDoc?.uploadedName,
        mime_type: "pdf",
        src: props.uploadedKYCDoc?.src,
      });
      props.setDDD(temp);
    }
    // console.log("frreb :", props?.DDDKYC);
  }, [document.getElementById("kyc_doc_list")?.value, props?.uploadedKYCDoc]);

 // console.log("dfvfd :",props?.DDD)
  useEffect(() => {
    setSrcList({
      PanCard: props.DDD?.filter((ele) => ele.document_type_id === "2").map(
        (val) => val.src
      ),
      GSTIN: props.DDD?.filter((ele) => ele.document_type_id === "1").map(
        (val) => val.src
      ),
      RegisterDoc: props.DDD?.filter(
        (ele) => ele.document_type_id === "56"
      ).map((val) => val.src),
      BrandRegDoc: props.DDD?.filter(
        (ele) => ele.document_type_id === "57"
      ).map((val) => val.src),

      PanCardFile: props.DDD?.filter((ele) => ele.document_type_id === "2").map(
        (val) => val.original_file_name
      ),
      GSTINFile: props.DDD?.filter((ele) => ele.document_type_id === "1").map(
        (val) => val.original_file_name
      ),
      RegisterDocFile: props.DDD?.filter(
        (ele) => ele.document_type_id === "56"
      ).map((val) => val.original_file_name),
      BrandRegDocFile: props.DDD?.filter(
        (ele) => ele.document_type_id === "57"
      ).map((val) => val.original_file_name),
    });
  }, [props.DDD]);

  const deleteFile = (id, name) => {
    props?.setDeletingDocID(id);
    props.setDeleteFileName(name);
    props.setOpen(true);
  };
  const validateKYC = () => {
    return props.KYCDOC ? true : false;
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "host_address",
  });
  const [noOfIP, setNoOfIP] = useState(0);
  const [isIpAdded, setIsIpAdded] = useState(false);
  // const Test20 =(data) => {console.log("data20", data);
  //                         console.log("errors",errors)};
  function dupicalteHostAddressValidator(value) {
    const uniqueValues = getValues();
    const countOccurrences = (arr, val) =>
      arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
    if (countOccurrences(uniqueValues["host_address"], value) > 1) {
      return false;
    } else {
      return true;
    }
  }

  const [docsLib, setDocsLib] = useState([]);

  const [openViewer, setOpenViewer] = useState(false);
  let image_extensions = [
    "png",
    "jpg",
    "jpeg",
    "gif",
    "PNG",
    "JPG",
    "JPEG",
    "GIF",
  ];
  let image_extensions_docs = [
    "doc",
    "docx",
    "ppt",
    "pptx",
    "xls",
    "xlsx",
    "DOC",
    "DOCX",
    "PPT",
    "PPTX",
    "XLS",
    "XLSX",
    "pdf",
    "PDF",
  ];


  const filePreviewHandler = (event) => {
    setDocsLib([{ uri: event }]);

    setOpenViewer(true);

  };

  return props?.mobile_number &&
    isValidPhoneNumber("" + props?.mobile_number) ? (
    <Spinner size={"big"} vh content="Loading" active />
  ) : (
    <>
      <section className="body-margin-top">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 margin-bottom-30 padding-left-0">
            <div className="congrates-icon  padding-top-15">
              <img
                src="images/vi_icon_successful.svg"
                alt="Congrates!"
                width={100}
              />
            </div>
            <div className="congrates-contents padding-top-30">
              <h1>
                <strong>Congrats!</strong>
              </h1>
              <p className="font-color-black font-size-17 margin-top-15">
                You have successfully created your VI RBM account.
              </p>
              <p className="font-size-17" />
            </div>
          </div>

          <div className="row">
            <FormProvider {...useFormMethods}>
              <form onSubmit={handleSubmit(onContinue)}>
                <div className="row">
                  <div className="col-lg-12 col-md-12 padding-top-10  margin-bottom-15">
                    <hr />
                    <br />
                    <h2>Complete your aggregator details</h2>
                    <h4 className="margin-top-30">Personal Details</h4>
                  </div>
                </div>

                <div>
                  <div className="row form-group-min-height-70">
                    <div className="col-lg-4 col-md-4 col-sm-6 ">
                      <div className="form-group form-input">
                        <label className="margin-bottom-10">
                          <img
                            src="images/vi_icon_name.svg"
                            width={20}
                            alt=""
                          />{" "}
                          First Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="first_name"
                          ref={register({
                            required: true,
                            pattern: regexFirstAndLastName,
                          })}
                          defaultValue={userDetails?.first_name}
                          placeholder="Enter your first name"
                          maxLength={SetupUser.CHAR_LENGTH_100}
                        />

                        {errors.first_name &&
                          errors.first_name.type === "required" && (
                            <FormError errorMessage="⚠ First name can't be empty" />
                          )}
                        {errors.first_name &&
                          errors.first_name.type === "pattern" && (
                            <FormError errorMessage="Please enter alphabetical letters only." />
                          )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <div className="form-group form-input">
                        <label className="margin-bottom-10">
                          <img
                            src="images/vi_icon_name.svg"
                            width={20}
                            alt=""
                          />{" "}
                          Last Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="last_name"
                          ref={register({
                            required: true,
                            pattern: regexFirstAndLastName,
                          })}
                          placeholder="Enter your last name"
                          defaultValue={userDetails?.last_name}
                          maxLength={SetupUser.CHAR_LENGTH_100}
                        />

                        <div className="">
                          {errors.last_name &&
                            errors.last_name.type === "required" && (
                              <FormError errorMessage="⚠ Last name can't be empty" />
                            )}
                          {errors.last_name &&
                            errors.last_name.type === "pattern" && (
                              <FormError errorMessage="Please enter alphabetical letters only." />
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <div className="form-group form-input">
                        <label className="margin-bottom-10">
                          <img
                            src="images/vi_icon_contact_number.svg"
                            width={20}
                            alt=""
                          />{" "}
                          Contact Number<span style={{ color: "red" }}>*</span>
                        </label>
                        <br />
                        <Controller
                          key={props.partnerDetailsData?.partner_id}
                          rules={{
                            required: true,
                            validate: (value) => isValidPhoneNumber(value),
                          }}
                          render={(initProps) => (
                            <PhoneInputWithCountry
                              {...initProps}
                              flags={flags}
                              international={true}
                              defaultCountry={
                                props?.partnerDetailsData?.phoneNumber
                                  ? getCountryIso(
                                      props?.partnerDetailsData?.phoneNumber
                                    ).toUpperCase()
                                  : props.countryByIP
                                  ? props.countryByIP
                                  : "IN"
                              }
                              name="phone_number"
                              className="__selectDisabled"
                              inputClassName="__input"
                              countryCallingCodeEditable={false}
                              disabled
                            />
                          )}
                          name={"phone_number"}
                          control={control}
                          defaultValue={
                            "+" + props?.partnerDetailsData?.phoneNumber
                          }
                        />

                        <div className="font-color-light-green font-size-14 mobile-number-verified2">
                          Verified{" "}
                          <img
                            src="images/vi_icon_green_tick.svg"
                            width={16}
                            alt="Verified"
                          />
                        </div>
                        <div className="input-error">
                          {errors.phone_number?.type === "required" && (
                            <FormError
                              errorMessage={"⚠ Contact number can't be empty"}
                            />
                          )}
                          {errors.phone_number?.type === "validate" && (
                            <FormError
                              errorMessage={"⚠ Please enter valid phone number"}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row bot-form">
                    <div className="col-lg-4 col-md-4 col-sm-6 margin-top-10">
                      <TimezoneSelector
                        name="preferred_time_zone"
                        defaultValue={ianaTimezone}
                        register={register}
                      />
                    </div>
                  </div>
                  <div className="row form-group-min-height-70 margin-bottom-10">
                    <div className="col-lg-12 col-md-12 business-verification">
                      <h4 className="font-weight-normal">
                        KYC Documents (Aggregator LOA Document)
                        <span style={{ color: "red" }}>*</span>
                      </h4>
                      <p class="font-size-14 font-weight-normal font-color-light-gray">
                        Please upload the following documents to complete
                        aggregator KYC.
                        <a href="Aggregator/documents" target="_blank">
                          Click here
                        </a>{" "}
                        to download the document.
                      </p>
                      <div className="col-lg-9 col-md-9 col-sm-12 business-verification padding-left-0 padding-right-0">
                        <table className="business-verification-table">
                          {props.KYCDOC?.length > 0 && (
                            <>
                              <tr>
                                <td className="padding-right-20">
                                  <div className="form-group padding-top-20">
                                    <span className="address-label font-weight-normal">
                                      Upload Aggregator LOA Document
                                      <span style={{ color: "red" }}>*</span>
                                    </span>
                                  </div>
                                </td>
                                <td className="padding-right-10">
                                  <div className="form-group padding-top-20 form-input">
                                    <span
                                      defaultValue={
                                        userDetails?.partner_kyc_docs
                                          ?.original_file_name
                                      }
                                    >
                                      {props.KYCDOC?.length > 20
                                        ? props.KYCDOC?.slice(0, 20) + "..."
                                        : props.KYCDOC}
                                    </span>

                                    <span className="font-size-14 margin-right-30 font-color-light-green margin-left-20">
                                      Document uploaded
                                    </span>
                                    <a
                                      // href={props.DDDKYC?.src}
                                      // onClick={()=>window.location.href=props.DDDKYC?.src}

                                      onClick={() => {
                                        try {
                                          saveAs(props.DDDKYC?.src ?? "",props.DDDKYC?.original_file_name??"");
                                        } catch (error) {}
                                      }}
                                      // download
                                      // target="_blank"
                                    >
                                      <img
                                        src="images/vi_icon_download.svg"
                                        alt="download"
                                        width="14"
                                        style={{ cursor: "pointer" }}
                                        className="margin-right-15"
                                      />
                                    </a>
                                    <a
                                      onClick={() => {
                                        deleteFile("2", "KYC");
                                        //  props.setKYCDOC("");
                                      }}
                                    >
                                      <img
                                        src="images/vi_icon_delete.svg"
                                        alt="delete"
                                        width="14"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </a>
                                    &nbsp;
                                        <a
                                           onClick={() =>
                                            filePreviewHandler(props.DDDKYC?.src)
                                          }
                                        >
                                          <img
                                            src="images/icon_view.svg"
                                            alt="download"
                                            width="14"
                                            className="margin-left-20"
                                            style={{ cursor: "pointer" }}
                                          />
                                        </a>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}

                          <tr hidden={props.KYCDOC?.length > 0}>
                            <td
                              className=" padding-right-10 "
                              style={{ width: "290px" }}
                            >
                              <div className="form-group padding-top-30">
                                <span className="address-label font-weight-normal">
                                  Upload Aggregator LOA Document
                                  <span style={{ color: "red" }}>*</span>
                                </span>
                              </div>
                            </td>
                            <td className="margin-left-40 ">
                              <div
                                className="form-group padding-top-20 form-input"
                                style={{ width: "250px" }}
                              >
                                <input
                                  type="text"
                                  className="form-control vi-readonly"
                                  style={{ height: "40px" }}
                                  name="kycDoc"
                                  ref={register({
                                    required: true,
                                    validate: validateKYC,
                                  })}
                                  defaultValue={props.KYCDOC}
                                  readOnly={true}
                                />
                                <span
                                  className="files pull-right browse-btn-alignment"
                                  id="files12"
                                >
                                  <span className="btn btn-file btn-browse-text">
                                    Browse
                                    {watch("kyc_doc_list") !== "default" && (
                                      <input
                                        type="file"
                                        name="files12"
                                        onChange={(e) => {
                                          //  props.setIsLoading(true);
                                          uploadDoc(e, "KYC");
                                        }}
                                        onClick={(e) => (e.target.value = "")}
                                        single
                                        accept=".doc,.docx, application/pdf,.jpg,.png"
                                      />
                                    )}
                                  </span>
                                </span>
                                {errors.kycDoc &&
                                  errors.kycDoc.type === "required" && (
                                    <FormError errorMessage="⚠ Please upload document." />
                                  )}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12 row">
                    <h4>
                      <strong>Business Details</strong>
                    </h4>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 margin-bottom-20">
                      <label>
                        <span className="font-weight-normal font-size-16">
                          Transaction Type:
                        </span>
                        {/* <h4 className="font-weight-normal">Transaction Type:</h4> */}
                      </label>

                      <div className="margin-left-20 radio radio-primary radio-inline margin-bottom-10">
                        <input
                          type="radio"
                          id="Radio10"
                          //   defaultValue={userDetails?.transaction}
                          name="transaction"
                          defaultChecked={
                            userDetails?.transaction === "Domestic" ||
                            userDetails?.transaction !== "International" ||
                            userDetails?.transaction !== "Both"
                          }
                          ref={register}
                          value="Domestic"
                        />
                        <label htmlFor="Radio10">
                          <span className="font-weight-normal font-size-16">
                            Domestic
                          </span>
                        </label>
                      </div>
                      <div className="radio radio-primary radio-inline margin-bottom-10">
                        <p />
                        <input
                          type="radio"
                          id="Radio11"
                          // defaultValue
                          name="transaction"
                          ref={register}
                          defaultChecked={
                            userDetails?.transaction === "International"
                          }
                          value="International"
                        />
                        <label htmlFor="Radio11">
                          {" "}
                          <span className="font-weight-normal font-size-16">
                            International
                          </span>
                        </label>
                      </div>
                      <div className="radio radio-primary radio-inline margin-bottom-10">
                        <p />
                        <input
                          type="radio"
                          id="Radio12"
                          // defaultValue
                          name="transaction"
                          ref={register}
                          defaultChecked={userDetails?.transaction === "Both"}
                          value="Both"
                        />
                        <label htmlFor="Radio12">
                          {" "}
                          <span className="font-weight-normal font-size-16">
                            Both
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <IPInputWrapper transactionType={watch("transaction")} />

                  <div className="row form-group-min-height-70">
                    <div className="col-lg-4 col-md-4 col-sm-6 margin-bottom-10">
                      <div className="form-group form-input">
                        <label className="margin-bottom-10">
                          <img
                            src="images/vi_icon_job_title.svg"
                            width={20}
                            alt=""
                          />{" "}
                          Job Title<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          ref={register({
                            required: true,
                            pattern: regexFirstAndLastName,
                          })}
                          type="text"
                          className="form-control"
                          placeholder="Enter your job title"
                          name="job_title"
                          defaultValue={userDetails?.job_title}
                          maxLength={SetupUser.CHAR_LENGTH_100}
                        />
                        {errors.job_title &&
                          errors.job_title.type === "required" && (
                            <FormError errorMessage="⚠ Job title can't be empty" />
                          )}
                        {errors.job_title &&
                          errors.job_title.type === "pattern" && (
                            <FormError errorMessage="⚠ Please enter alphabetical letters only." />
                          )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <div className="form-group form-input">
                        <label className="margin-bottom-10">
                          <img
                            src="images/vi_icon_company_name.svg"
                            width={20}
                            alt=""
                          />{" "}
                          Company Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          ref={register({ required: true, validate: (value) => !!value.trim(), maxLength:128})}
                          type="text"
                          className="form-control"
                          name="company_name"
                          placeholder="Enter your company name"
                          defaultValue={userDetails?.company_name}
                          maxLength={SetupUser.CHAR_LENGTH_128}
                        />
                        {errors.company_name &&
                          (errors.company_name.type === "required"||errors.company_name.type === "validate") && (
                            <FormError errorMessage="⚠ Company name can't be empty" />
                          )}
                         {errors.company_name &&
                          (errors.company_name.type === "maxLength") && (
                            <FormError errorMessage="⚠ Max length excedded." />
                          )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <div className="form-group form-input">
                        <label className="margin-bottom-10">
                          <img
                            src="images/vi_icon_company_website.svg"
                            width={20}
                            alt=""
                          />{" "}
                          Company Website<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                           ref={register({
                            required: true,
                            validate: (value) => isValidURL(value,{protocols: ['https'],require_protocol: true}),
                          })}
                          type="text"
                          className="form-control"
                          name="company_website"
                          placeholder="https://"
                          defaultValue={userDetails?.company_website}
                          maxLength={SetupUser.CHAR_LENGTH_2048}
                        />
                        {errors.company_website &&
                          errors.company_website.type === "required" && (
                            <FormError errorMessage="⚠ Company Website can't be empty" />
                          )}
                        {errors.company_website &&
                          (errors.company_website.type === "validate") && (
                            <FormError errorMessage="Invalid URL" />
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12   margin-bottom-10">
                    <label>
                      <img src="images/vi_icon_address.svg" width alt="" />{" "}
                      Company Address
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="form-group form-input">
                      <label className="address-label font-weight-normal">
                        Address Line 1<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="address_1"
                        ref={register({ required: true,validate: (value) => !!value.trim(),})}
                        placeholder="Enter address line 1 "
                        className="form-control"
                        defaultValue={userDetails?.address_1}
                        maxLength={SetupUser.CHAR_LENGTH_100}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="form-group form-input">
                      <label className="address-label font-weight-normal">
                        Address Line 2
                      </label>
                      <input
                        type="text"
                        name="address_2"
                        ref={register}
                        placeholder="Enter address line 2"
                        className="form-control"
                        defaultValue={userDetails?.address_2}
                        maxLength={SetupUser.CHAR_LENGTH_100}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="form-group form-input-text">
                      <label className="address-label font-weight-normal">
                        Country<span style={{ color: "red" }}>*</span>
                      </label>
                      <Controller
                        rules={{
                          required: true,
                        }}
                        as={
                          <Select
                            key={
                              userDetails?.country
                                ? "country-selected"
                                : "country-unselected"
                            }
                            placeholder={"Select country"}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 5,
                              colors: {
                                ...theme.colors,
                                primary: "neutral5",
                              },
                            })}
                            styles={customStyles}
                            closeMenuOnSelect={true}
                          />
                        }
                        options={getRSFormatList(props.countryList, [
                          "id",
                          "country_name",
                        ])}
                        isSearchable={props?.address?.countryId ? false : true}
                        menuIsOpen={
                          props?.address?.countryId ? false : undefined
                        }
                        name="country"
                        isClearable={props?.address?.countryId ? false : true}
                        control={control}
                      />
                      <br />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="form-group form-input">
                      <label className="address-label font-weight-normal">
                        State/Province/Region
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="state"
                        ref={register({
                          required: true,
                          pattern: regexFirstAndLastName,
                        })}
                        placeholder="Enter state/province/region "
                        className="form-control"
                        defaultValue={userDetails?.state}
                        maxLength={SetupUser.CHAR_LENGTH_100}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="form-group form-input">
                      <label className="address-label font-weight-normal">
                        City<span style={{ color: "red" }}>*</span>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="city"
                        ref={register({
                          required: true,
                          pattern: regexFirstAndLastName,
                        })}
                        className="form-control"
                        placeholder="Enter city"
                        defaultValue={userDetails?.city}
                        maxLength={SetupUser.CHAR_LENGTH_100}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="form-group form-input">
                      <label className="address-label font-weight-normal">
                        ZIP Code<span style={{ color: "red" }}>*</span>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="zip_code"
                        ref={register({
                          required: true,
                          validate: (value) => validatePIN(value),
                        })}
                        className="form-control"
                        placeholder="Enter ZIP code"
                        defaultValue={userDetails?.zip_code}
                        maxLength={SetupUser.CHAR_LENGTH_100}
                      />
                      {errors.zip_code?.type === "validate" && (
                        <FormError
                          errorMessage={"⚠ Please enter a valid zip code"}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    {Object.keys(errors).some(
                      (field) => addressFields.indexOf(field) >= 0
                    ) && (
                      <FormError errorMessage="⚠ Please enter the company address" />
                    )}
                    {errors.state && errors.state.type === "pattern" && (
                      <FormError errorMessage="⚠ In State field please enter alphabetical letters only." />
                    )}
                    {errors.city && errors.city.type === "pattern" && (
                      <FormError errorMessage="⚠ In city field please enter alphabetical letters only." />
                    )}
                    {errors.address_1 && errors.address_1.type === "validate" && (
                      <FormError errorMessage="⚠ Address line 1 can't be empty" />
                    )}

                    {!errors.check &&
                      userType === "aggregator" &&
                      (errors.nameDocs?.type === "required" &&
                      errors.addressDocs?.type === "required" ? (
                        <p>
                          (Please upload atleast one document for business name
                          and one document for business address verification.)
                        </p>
                      ) : (
                        <>
                          {errors.nameDocs?.type === "required" && (
                            <p>
                              (Please upload atleast one document for business
                              name verification.)
                            </p>
                          )}
                          {errors.addressDocs?.type === "required" && (
                            <p>
                              (Please upload atleast one document for business
                              address verification.)
                            </p>
                          )}
                        </>
                      ))}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 margin-top-15  margin-bottom-15">
                    <h4>
                      Upload Documents<span style={{ color: "red" }}>*</span>{" "}
                    </h4>
                    <p class="font-size-15 font-weight-normal font-color-light-gray">
                      Please upload following documents to verify your business.
                    </p>

                    <div className="col-lg-12 col-md-12 padding-left-0 padding-right-0">
                      <div className="business-verification margin-left-0">
                        <table className="business-verification-table margin-left-0">
                          <tbody className="font-weight-normal">
                            <tr>
                              {props.DOC?.PanCard?.length > 0 ? (
                                srcList.PanCard != "" ? (
                                  <td width="50%" className="padding-left-0">
                                    <div className="col-lg-4 col-md-4 padding-left-0">
                                      <div className="form-group">
                                        <label className="address-label font-weight-normal">
                                          <strong>
                                            Upload PAN Proof
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </strong>
                                        </label>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="col-lg-8 col-md-8">
                                        <span>
                                          {props.DOC?.PanCard?.length > 20
                                            ? props.DOC?.PanCard?.slice(0, 20) +
                                              "..."
                                            : props.DOC?.PanCard}
                                        </span>
                                        <span className="margin-left-20 font-size-14 font-color-light-green">
                                          Document uploaded
                                        </span>
                                        {/* {console.log("sfgd :",srcList.PanCard[0]," d:",srcList.PanCardFile[0])} */}
                                        <a
                                          // href={srcList.PanCard}
                                          // download
                                          // target="_blank"
                                          onClick={() => {
                                            try {
                                              saveAs(srcList.PanCard[0] ?? "",srcList.PanCardFile[0]??"");
                                            } catch (error) {}
                                          }}
                                        >
                                          <img
                                            src="images/vi_icon_download.svg"
                                            alt="download"
                                            width="14"
                                            className="margin-left-40"
                                            style={{ cursor: "pointer" }}
                                          />
                                        </a>
                                        <img
                                          src="images/vi_icon_delete.svg"
                                          alt=""
                                          width="14"
                                          className="margin-left-20"
                                          onClick={() =>
                                            deleteFile("2", "PanCard")
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                        <a
                                           onClick={() =>
                                            filePreviewHandler(srcList.PanCard[0])
                                          }
                                        >
                                          <img
                                            src="images/icon_view.svg"
                                            alt="download"
                                            width="14"
                                            className="margin-left-20"
                                            style={{ cursor: "pointer" }}
                                          />
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                ) : (
                                  <td width="50%" className="padding-left-0">
                                    <div className="col-lg-4 col-md-4 padding-left-0">
                                      <div className="form-group">
                                        <label className="address-label font-weight-normal">
                                          <strong>
                                            Upload PAN Proof
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </strong>
                                        </label>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="col-lg-8 col-md-8">
                                        <span>
                                          {props.DOC?.PanCard?.length > 20
                                            ? props.DOC?.PanCard?.slice(0, 20) +
                                              "..."
                                            : props.DOC?.PanCard}
                                        </span>
                                        <span className="margin-left-20 font-size-14 font-color-light-green">
                                          Uploading document...
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                )
                              ) : (
                                <td width="50%" className="padding-left-0">
                                  <div className="col-lg-4 col-md-4 col-sm-6 padding-left-0">
                                    <div className="form-group">
                                      <label className="address-label font-weight-normal margin-top-10">
                                        Upload PAN Proof
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="form-group form-input ">
                                      <input
                                        style={{ height: "40px" }}
                                        type="text"
                                        className="form-control vi-readonly"
                                        Doc
                                        Uploaded
                                        name="pan"
                                        ref={register({
                                          required: true,
                                        })}
                                        readOnly={true}
                                      />

                                      <span
                                        className="files pull-right browse-btn-alignment"
                                        id="PANProof1"
                                      >
                                        <span className="btn btn-file btn-browse-text">
                                          Browse
                                          <input
                                            type="file"
                                            name="files1"
                                            onChange={(e) => {
                                              uploadDoc(e, "PanCard");
                                            }}
                                            onClick={(e) =>
                                              (e.target.value = "")
                                            }
                                            single
                                            accept=".doc,.docx, application/pdf,.jpg,.png"
                                          />
                                        </span>
                                      </span>
                                      {errors.pan &&
                                        errors.pan.type === "required" && (
                                          <FormError errorMessage="⚠ Please upload document." />
                                        )}
                                    </div>
                                  </div>
                                </td>
                              )}
                            </tr>

                            <tr>
                              {props.DOC?.GSTIN?.length > 0 ? (
                                srcList.GSTIN != "" ? (
                                  <td width="50%" className="padding-left-0">
                                    <div className="col-lg-4 col-md-4 padding-left-0">
                                      <div className="form-group">
                                        <label className="address-label font-weight-normal">
                                          <strong>
                                            Upload GSTIN Proof
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </strong>
                                        </label>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="col-lg-8 col-md-8">
                                        <span>
                                          {props.DOC?.GSTIN?.length > 20
                                            ? props.DOC.GSTIN?.slice(0, 20) +
                                              "..."
                                            : props.DOC?.GSTIN}
                                        </span>
                                        <span className="margin-left-20 font-size-14 font-color-light-green">
                                          Document uploaded
                                        </span>
                                        <a
                                          // href={srcList.GSTIN}
                                          onClick={() => {
                                            try {
                                              saveAs(srcList.GSTIN[0] ?? "",srcList.GSTINFile[0]??"");
                                            } catch (error) {}
                                          }}
                                          // download
                                          // target="_blank"
                                        >
                                          <img
                                            src="images/vi_icon_download.svg"
                                            alt="download"
                                            width="14"
                                            className="margin-left-40"
                                            style={{ cursor: "pointer" }}
                                          />
                                        </a>
                                        &nbsp;
                                        <img
                                          src="images/vi_icon_delete.svg"
                                          alt=""
                                          width="14"
                                          className="margin-left-20"
                                          onClick={() =>
                                            deleteFile("1", "GSTIN")
                                          }
                                          style={{ cursor: "pointer" }}
                                        /> &nbsp;
                                        <a
                                           onClick={() =>
                                            filePreviewHandler(srcList.GSTIN[0])
                                          }
                                        >
                                          <img
                                            src="images/icon_view.svg"
                                            alt="download"
                                            width="14"
                                            className="margin-left-20"
                                            style={{ cursor: "pointer" }}
                                          />
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                ) : (
                                  <td width="50%" className="padding-left-0">
                                    <div className="col-lg-4 col-md-4 padding-left-0">
                                      <div className="form-group">
                                        <label className="address-label font-weight-normal">
                                          <strong>
                                            Upload GSTIN Proof
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </strong>
                                        </label>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="col-lg-8 col-md-8">
                                        <span>
                                          {props.DOC?.GSTIN?.length > 20
                                            ? props.DOC.GSTIN?.slice(0, 20) +
                                              "..."
                                            : props.DOC?.GSTIN}
                                        </span>
                                        <span className="margin-left-20 font-size-14 font-color-light-green">
                                          Uploading document...
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                )
                              ) : (
                                <td width="50%" className="padding-left-0">
                                  <div className="col-lg-4 col-md-4 col-sm-6 padding-left-0">
                                    <div className="form-group">
                                      <label className="address-label font-weight-normal margin-top-10">
                                        Upload GSTIN Proof
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="form-group form-input">
                                      <input
                                        style={{ height: "40px" }}
                                        type="text"
                                        className="form-control vi-readonly"
                                        name="gstin"
                                        ref={register({
                                          required: true,
                                        })}
                                        readOnly={true}
                                      />
                                      <span
                                        className="files pull-right browse-btn-alignment"
                                        id="GSTINProof"
                                      >
                                        <span className="btn btn-file btn-browse-text">
                                          Browse
                                          <input
                                            type="file"
                                            name="files1"
                                            onChange={(e) => {
                                              // props.setIsLoading(true);
                                              uploadDoc(e, "GSTIN");
                                            }}
                                            onClick={(e) =>
                                              (e.target.value = "")
                                            }
                                            single
                                            accept=".doc,.docx, application/pdf,.jpg,.png"
                                          />
                                        </span>
                                      </span>
                                      {errors.gstin &&
                                        errors.gstin.type === "required" && (
                                          <FormError errorMessage="⚠ Please upload document." />
                                        )}
                                    </div>
                                  </div>
                                </td>
                              )}
                            </tr>

                            <tr>
                              {props.DOC?.RegisterDoc?.length > 0 ? (
                                srcList.RegisterDoc != "" ? (
                                  <td width="50%" className="padding-left-0">
                                    <div className="col-lg-4 col-md-4 padding-left-0">
                                      <div className="form-group">
                                        <label className="address-label font-weight-normal ">
                                          <strong>
                                            Company Registration Proof
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </strong>
                                        </label>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="col-lg-8 col-md-8">
                                        <span>
                                          {props.DOC?.RegisterDoc?.length > 20
                                            ? props.DOC.RegisterDoc?.slice(
                                                0,
                                                20
                                              ) + "..."
                                            : props.DOC?.RegisterDoc}
                                        </span>
                                        <span className="margin-left-20 font-size-14 font-color-light-green">
                                          Document uploaded
                                        </span>
                                        <a
                                          // href={srcList.RegisterDoc}
                                          // download
                                          // target="_blank"
                                          onClick={() => {
                                            try {
                                              saveAs(
                                                srcList.RegisterDoc[0] ?? "",
                                                srcList.RegisterDocFile[0] ?? ""
                                              );
                                            } catch (error) {}
                                          }}
                                        >
                                          <img
                                            src="images/vi_icon_download.svg"
                                            alt="download"
                                            width="14"
                                            class="margin-left-40"
                                            style={{ cursor: "pointer" }}
                                          />
                                        </a>
                                        &nbsp;
                                        <img
                                          src="images/vi_icon_delete.svg"
                                          alt=""
                                          width="14"
                                          class="margin-left-20"
                                          onClick={() =>
                                            deleteFile("56", "RegisterDoc")
                                          }
                                          style={{ cursor: "pointer" }}
                                        />&nbsp;
                                        <a
                                           onClick={() =>
                                            filePreviewHandler(srcList.RegisterDoc[0])
                                          }
                                        >
                                          <img
                                            src="images/icon_view.svg"
                                            alt="download"
                                            width="14"
                                            className="margin-left-20"
                                            style={{ cursor: "pointer" }}
                                          />
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                ) : (
                                  <td width="50%" className="padding-left-0">
                                    <div className="col-lg-4 col-md-4 padding-left-0">
                                      <div className="form-group">
                                        <label className="address-label font-weight-normal ">
                                          <strong>
                                            Company Registration Proof
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </strong>
                                        </label>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="col-lg-8 col-md-8">
                                        <span>
                                          {props.DOC?.RegisterDoc?.length > 20
                                            ? props.DOC.RegisterDoc?.slice(
                                                0,
                                                20
                                              ) + "..."
                                            : props.DOC?.RegisterDoc}
                                        </span>
                                        <span className="margin-left-20 font-size-14 font-color-light-green">
                                          Uploading document...
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                )
                              ) : (
                                <td width="50%" className="padding-left-0">
                                  <div className="col-lg-4 col-md-4 col-sm-6 padding-left-0">
                                    <div className="form-group">
                                      <label className="address-label font-weight-normal margin-top-10">
                                        Company Registration Proof
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="form-group form-input">
                                      <input
                                        style={{ height: "40px" }}
                                        type="text"
                                        className="form-control vi-readonly"
                                        name="regdoc"
                                        ref={register({
                                          required: true,
                                        })}
                                        readOnly={true}
                                      />
                                      <span
                                        className="files pull-right browse-btn-alignment"
                                        id="RegistrationProof"
                                      >
                                        <span className="btn btn-file btn-browse-text">
                                          Browse
                                          <input
                                            type="file"
                                            name="files1"
                                            onChange={(e) => {
                                              // props.setIsLoading(true);
                                              uploadDoc(e, "RegisterDoc");
                                            }}
                                            onClick={(e) =>
                                              (e.target.value = "")
                                            }
                                            single
                                            accept=".doc,.docx, application/pdf,.jpg,.png"
                                          />
                                        </span>
                                      </span>
                                      {errors.regdoc &&
                                        errors.regdoc.type === "required" && (
                                          <FormError errorMessage="⚠ Please upload document." />
                                        )}
                                    </div>
                                  </div>
                                </td>
                              )}
                            </tr>
                            <tr>
                              {props.DOC?.BrandRegDoc?.length > 0 ? (
                                srcList.BrandRegDoc != "" ? (
                                  <td width="50%" className="padding-left-0">
                                    <div className="col-lg-4 col-md-4 padding-left-0">
                                      <div className="form-group">
                                        <label className="address-label font-weight-normal">
                                          <strong>
                                            Company Brand Registration Proof
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </strong>
                                        </label>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="col-lg-8 col-md-8">
                                        <span>
                                          {props.DOC?.BrandRegDoc?.length > 20
                                            ? props.DOC?.BrandRegDoc?.slice(
                                                0,
                                                20
                                              ) + "..."
                                            : props.DOC?.BrandRegDoc}
                                        </span>
                                        <span className="margin-left-20 font-size-14 font-color-light-green">
                                          Document uploaded
                                        </span>
                                        <a
                                          // href={srcList.BrandRegDoc}
                                          // download
                                          // target="_blank"
                                          onClick={() => {
                                            try {
                                              saveAs(
                                                srcList.BrandRegDoc[0] ?? "",
                                                srcList.BrandRegDocFile[0] ?? ""
                                              );
                                            } catch (error) {}
                                          }}
                                        >
                                          <img
                                            src="images/vi_icon_download.svg"
                                            alt="download"
                                            width="14"
                                            class="margin-left-40"
                                            style={{ cursor: "pointer" }}
                                          />
                                        </a>
                                        &nbsp;
                                        <img
                                          src="images/vi_icon_delete.svg"
                                          alt=""
                                          width="14"
                                          class="margin-left-20"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            deleteFile("57", "BrandRegDoc")
                                          }
                                        />&nbsp;
                                        <a
                                           onClick={() =>
                                            filePreviewHandler( srcList.BrandRegDoc[0])
                                          }
                                        >
                                          <img
                                            src="images/icon_view.svg"
                                            alt="download"
                                            width="14"
                                            className="margin-left-20"
                                            style={{ cursor: "pointer" }}
                                          />
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                ) : (
                                  <td width="50%" className="padding-left-0">
                                    <div className="col-lg-4 col-md-4 padding-left-0">
                                      <div className="form-group">
                                        <label className="address-label font-weight-normal">
                                          <strong>
                                            Company Brand Registration Proof
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </strong>
                                        </label>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="col-lg-8 col-md-8">
                                        <span>
                                          {props.DOC?.BrandRegDoc?.length > 20
                                            ? props.DOC?.BrandRegDoc?.slice(
                                                0,
                                                20
                                              ) + "..."
                                            : props.DOC?.BrandRegDoc}
                                        </span>
                                        <span className="margin-left-20 font-size-14 font-color-light-green">
                                          Uploading document...
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                )
                              ) : (
                                <td width="50%" className="padding-left-0">
                                  <div className="col-lg-4 col-md-4 col-sm-6 padding-left-0">
                                    <div className="form-group">
                                      <label className="address-label font-weight-normal margin-top-10">
                                        Company Brand Registration Proof
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="form-group form-input">
                                      <input
                                        style={{ height: "40px" }}
                                        type="text"
                                        className="form-control vi-readonly"
                                        width="50%"
                                        name="branddoc"
                                        ref={register({
                                          required: true,
                                        })}
                                        readOnly={true}
                                      />
                                      <span
                                        className="files pull-right browse-btn-alignment"
                                        id="AuthorisationLetter"
                                      >
                                        <span className="btn btn-file btn-browse-text">
                                          Browse
                                          <input
                                            type="file"
                                            name="files1"
                                            onChange={(e) => {
                                              // props.setIsLoading(true);
                                              uploadDoc(e, "BrandRegDoc");
                                            }}
                                            onClick={(e) =>
                                              (e.target.value = "")
                                            }
                                            single
                                            accept=".doc,.docx, application/pdf,.jpg,.png"
                                          />
                                        </span>
                                      </span>
                                      {errors.branddoc &&
                                        errors.branddoc.type === "required" && (
                                          <FormError errorMessage="⚠ Please upload document." />
                                        )}
                                    </div>
                                  </div>
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {/* {console.log("svf fd :", srcList?.PanCard.length)} */}
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 margin-bottom-35 margin-top-35">
                      <button
                        className="btn btn-red-round"
                        disabled={
                          srcList?.PanCard.length === 0 ||
                          srcList.BrandRegDoc.length === 0 ||
                          srcList.GSTIN.length === 0 ||
                          srcList.RegisterDoc.length === 0
                        }
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </section>

      <OtpModal
        closeOTPModal={closeOtpModal}
        message={isOTPSent}
        generateOtp={generateOtp}
        mobileNumber={mobile}
        setOtp={setOtp}
      />
      <CommonModal
        dismissButton={false}
        actionButton={true}
        actionButtonLabel={"Ok"}
        open={openFileExtensionError}
        modalHeader={
          <>
            <p style={{ fontSize: "20px" }}>
              Only docx, doc, pdf, jpeg, jpg, png files are supported
              <br />
              with max size of 5MB and file name should be less than 50 characters.
            </p>
          </>
        }
        onAction={onActionFileExtensioError}
      />
      {docsLib && (
        <>
          <CommonModalFile
            dismissButton={false}
            closeButton={true}
            // actionButtonLabel={"Back"}
            open={openViewer}
            isImage={image_extensions.includes(
              docsLib[0]?.uri?.split(".")?.pop()
            )}
            maxWidth1={
              image_extensions_docs.includes(docsLib[0]?.uri?.split(".")?.pop())
                ? "lg"
                : ""
            }
            modalText={
              <FilePreview
              uri={docsLib[0]?.uri}
              fileExtension={docsLib[0]?.uri?.split(".")?.pop()}
              />
            }
            onDismiss={() => {
              setOpenViewer(false);
            }}
          />
        </>
      )}

    </>
  );
};
export default SetupUserDetailsPage;
