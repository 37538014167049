import { useEffect } from "react"
import { withRouter } from "react-router-dom"
import { requestLogin } from "../../constants/constant"
/**
 * Just redirects to SSO sign in page.
 */
const SignInRedirect = () => {
  useEffect(() => {
    requestLogin()
  }, [])
  return <div style={{ height: "100vh" }}></div>
}
export default withRouter(SignInRedirect)
