import { call, put, takeLatest } from "redux-saga/effects"
import { createActionOf } from "../../actions/ActionCreator"
import { makeSecuredGetRequest } from "../../../appApi/AppApi"
import {
  GET_CAMPAIGN_LIST,
  GET_CAMPAIGN_LIST_FAILED,
  GET_CAMPAIGN_LIST_SUCCESS,
  GET_BOT_TEMPLATES_LIST_REQUESTED,
  GET_BOT_TEMPLATES_LIST_SUCCESS,
  GET_BOT_TEMPLATES_LIST_FAILED,

} from "../../actions/ActionType"
import { API_ENDPOINT } from "../../URLs/ApiEndpoints"

function* workerGetAllCampaigns(payLoad) {
  try {
    const response = yield call(
      makeSecuredGetRequest,
      API_ENDPOINT.CAMPAIGN_LIST
    )
    yield put(createActionOf(GET_CAMPAIGN_LIST_SUCCESS, 1, response))
  } catch (error) {
    yield put(createActionOf(GET_CAMPAIGN_LIST_FAILED, 2, error))
  }
}
function* workerGetBotsTemplates(payLoad) {
  try {
    const response = yield call(
      makeSecuredGetRequest,
      API_ENDPOINT.BOT_TEMPLATE_LIST
    )
    yield put(createActionOf(GET_BOT_TEMPLATES_LIST_SUCCESS, 1, response))
  } catch (error) {
    yield put(createActionOf(GET_BOT_TEMPLATES_LIST_FAILED, 2, error))
  }
}

export const watchGetAllCampaigns = takeLatest(
  GET_CAMPAIGN_LIST,
  workerGetAllCampaigns
)
export const watchGetBotAndTEmplates = takeLatest(
  GET_BOT_TEMPLATES_LIST_REQUESTED,
  workerGetBotsTemplates
)
