import { lazy, Suspense } from "react";
import { Route, withRouter } from "react-router-dom";
import Fallback from "../../../components/Layout/Fallback";

const AggregatorDocumentationPage = lazy(() => import("../../../components/Pages/DocumentaionPages/AggregatorDocumentation"));

const AggregatorDocs = () => {
  return (
    <>
      <Suspense fallback={<Fallback />}>
        <Route path="/Aggregator/documents" exact component={AggregatorDocumentationPage} />
      </Suspense>
    </>
  );
};

export default withRouter(AggregatorDocs);
