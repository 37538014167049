import { combineReducers } from "redux";
import verifyEmailReducer from "./verifyEmailReducer";
import setPasswordReducer from "./setPasswordReducer";
import setupUserDetailsReducer from "./setupUserDetailsReducer";
import partnerDetailsReducer from "./partnersDetailsReducer";
import botListReducer from "./botListReducer";
import categoryListReducer from "./categoryListReducer";
import carrierListReducer from "./carrierListReducer";
import countryListReducer from "./countryListReducer";
import getBrandsReducer from "./getBrandsReducer";
import getBotDetailsReducer from "./getBotDetailsReducer";
import stepTwoReducer from "./LaunchRequest/stepTwoReducer";
import getLaunchCarrierList from "./LaunchRequest/getLaunchCarrierList";
import getInitialDetailsReducer from "./LaunchRequest/getInitialDetailsReducer";
import getPartnersBrandsListReducer from "./getPartnersBrandsListReducer";
import getIndustryTypesReducer from "./getIndustryTypesReducer";
import submitBrandReducer from "./SubmitBrandReducer";
import getSingleBrandReducer from "./getSingleBrandReducer";
import getSingleTemplateReducer from "./getSingleTemplateReducer";
import getVerifiedCarriers from "./Aggregator/getVerifiedCarriers";
import submitVerifiedCarriers from "./Aggregator/submitVerifiedCarriers";
import submitBotSummary from "./Aggregator/submitBotSummary";
import updateBrandReducer from "./updateBrandReducer";
import getPartnersCarriers from "./Aggregator/getPartnersCarriers";
import getLaunchSummaryReducer from "./Aggregator/getLaunchSummaryReducer";
import getBotWhatsAppReducer from "./getBotWhatsAppReducer";
import updatePartnersProfileReducer from "./updatePartnersProfileReducer";
import changePasswordReducer from "./changePasswordReducer";
import getPricingReducer from "./getPricingReducer";
import getAllUsersAdminPortalReducer from "./AdminPortalReducers/getAllUsersAdminPortalReducer";
import getInviteUserReducer from "./billlingReducers/InviteUserReducer";
import getReInviteUserReducer from "./billlingReducers/ReInviteUserReducer";
import getAllUsersReducer from "./billlingReducers/getAllUsersReducer";
import getDeleteUserReducer from "./billlingReducers/deleteUserReducer";
import getBillingUserPasaswordReducer from "./billlingReducers/setBillingUserPasswordReducer";
import getInviteUserAdminPortalReducer from "./AdminPortalReducers/InviteUserAdminPortalReducer";
import getDotgoSummaryReducer from "./AdminPortalReducers/getDotgoSummary";
import GeoLocationReducer from "./Onboarding/GeoLocationReducer";
import submitWhatsAppBotReducer from "./submitWhatsAppBotReducer";
import getSingleBrandReducerPreview from "./Onboarding/getSingleBrandReducerPreview";
import getBotCreationPreviewReducer from "./Onboarding/botCreationPreviewReducer";
import getVerifiedBotDetailsReducer from "./Onboarding/getVerifiedBotDetailsReducer";
import updateRCSBotOnboardingReducer from "./Onboarding/updateRCSBotOnboardingReducer";
import getDocumentTypes from "./Aggregator/getDocumentTypes.reducer";
import getRichPromotionsAndNotificationsCampaignSummaryReducer from "./getRichPromotionsAndNotificationsCampaignSummaryReducer";
import getRichPromotionsAndNotificationsCampaignDetailedReportReducer from "./getRichPromotionsAndNotificationsCampaignDetailedReportReducer";

import { getAggrigatorsReducer, getBotsReducer, getBrandsReducer as getBillingBrandsReducer, getSummaryCardsReducer, graphData as lineGraph } from "./billlingReducers/getAllListsReducers";
import filterDashboardSummary from "./billlingReducers/filterDashboardSummary";
import UpdateCarrierProfileReducer from "./billlingReducers/updateCarrierProfileReducer";
import CarrierGetSubmittedBrandsReducer from "./billlingReducers/CarrierGetSubmittedBrandsReducer";
import CarrierGetRejectedBrandsReducer from "./billlingReducers/CarrierGetRejectedBrandsReducer";
import CarrierGetApprovedBrandsReducer from "./billlingReducers/CarrierGetApprovedBrandsReducer";
import getCarrierSubmittedBotsReducer from "./billlingReducers/get_submitted_bots_for_carrier";
import getCarrierApprovedBotsReducer from "./billlingReducers/get_approved_bots_Reducer";
import getListOfAggregatorReducer from "./billlingReducers/getListOfAggregatorsReducer";
import getCarrierRejectedBotsReducer from "./billlingReducers/getRejectedBotsForCarrierReducer";
import approveORRejectBotReducer from "./billlingReducers/approve_or_reject_botReducer";
import ApproveOrRejectBrandReducer from "./billlingReducers/ApproveOrRejectBrandReducer";
import getSubmittedAggregatorsForCarrierReducer from "./billlingReducers/getSubmittedAggregatorsForCarrierReducer";
import getApprovedAggregatorsForCarrierReducer from "./billlingReducers/getApprovedAggregatorsForCarrierReducer";
import getRejectedAggregatorsForCarrierReducer from "./billlingReducers/getRejectedAggregatorsForCarrierReducer";
import getAggregatorInfoReducer from "./billlingReducers/getAggregatorInfoReducer";
import approveOrRejectCarrierAggregatorReducer from "./billlingReducers/approveOrRejectCarrierAggregatorReducer";

import getAdminSubmittedBotsReducer from "../reducers/AdminPortalReducers/get_admin_submitted_botsReducer";
import getAdminApprovedBotsReducer from "../reducers/AdminPortalReducers/get_admin_approved_botsReducer";
import getAdminRejectedBotsReducer from "../reducers/AdminPortalReducers/get_admin_rejected_botsReducer";
import getAdminForwardedBotsReducer from "../reducers/AdminPortalReducers/get_admin_forwarded_botsReducer";
import AdminapproveORRejectBotReducer from "../reducers/AdminPortalReducers/admin_approve_or_reject_bot_Reducer";
import getBotHistoyReducer from "../reducers/AdminPortalReducers/bot_history_reducer";
import forwardBotsReducer from "../reducers/AdminPortalReducers/forward_bots_carrier_Reducer";
import forwardBrandReducer from "./AdminPortalReducers/My Brands/ForwardBrandReducer";

import loadBotReviewsReducer from "./loadBotReviewsReducer";
import SubmitBotReviewsReducer from "./SubmitBotReviewsReducer";

import getReInviteUserAdminPortalReducer from "./AdminPortalReducers/reInviteUserAdminPortalReducer";
import getDeleteUserAdminPortalReducer from "./AdminPortalReducers/deleteUserAdminPortalReducer";
import updateCarrierProfileAdminPortalReducer from "./AdminPortalReducers/updateCarrierProfileReducerAdminPortal";
import getAdminSubmittedAggregatorsReducer from "./AdminPortalReducers/get_admin_submitted_aggregators_Reducer";
import getAdminApprovedAggregatorsReducer from "./AdminPortalReducers/get_admin_approved_aggregators_reducer";
import getAdminRejectedAggregatorsReducer from "./AdminPortalReducers/get_admin_rejected_aggregators_reducer";
import getAdminForwardedAggregatorReducer from "./AdminPortalReducers/get_pending_aggregators_reducer";
import approveORRejectAggregatorReducerAdmin from "./AdminPortalReducers/admin_approve_or_reject_agg_reducer";
import approveORRejectDotgoAggregatorReducerAdmin from "./AdminPortalReducers/admin_approve_or_reject_dotgo_agg_reducer";
import approveORRejectDotgoTemplateReducerAdmin from "./AdminPortalReducers/admin_approve_or_reject_dotgo_templ_reducer";
import forwardAggregatorReducer from "./AdminPortalReducers/forward_aggregator_reducer";
import getAggHistoyReducer from "./AdminPortalReducers/get_aggregator_historyReducer";

import getAdminSubmittedDotgoAggregatorsReducer from "./AdminPortalReducers/get_admin_submitted_dotgo_aggregators_Reducer";
import getAdminTemplatesReducer from "./AdminPortalReducers/get_admin_templates_Reducer";

import AdminGetSubmittedBrandsReducer from "./AdminPortalReducers/My Brands/AdminGetSubmittedBrandsReducer";
import AdminGetRejectedBrandsReducer from "./AdminPortalReducers/My Brands/AdminGetRejectedBrandsReducer";
import AdminGetApprovedBrandsReducer from "./AdminPortalReducers/My Brands/AdminGetApprovedBrandsReducer";
import AdminApproveOrRejectBrandReducer from "./AdminPortalReducers/My Brands/AdminApproveOrRejectBrandReducer";
import PendingApprovalBrandsReducer from "./AdminPortalReducers/My Brands/PendingApprovalReducer";
import getBrandHistoyReducer from "./AdminPortalReducers/My Brands/BrandHIstoryReducer";
import createBotAgentReducer from "./Onboarding/createBotAgentReducer";
import submitBotAgentCarriers from "./Onboarding/submitBotAgentCarriers";
import getPricePageDetailsReducer from "./Aggregator/getPricePageDetailsReducer";

import getAllCarrierAndCountryDetailsReducer from "./Onboarding/getAllCarrierAndCountryDetailsReducer";
import storeSelectedCarrierReducer from "./Onboarding/storeSelectedCarrierReducer";
import submitPreselectedCarriersListReducer from "./Onboarding/submitPreselectedCarriersListReducer";
import createRCSBotReducer from "./createRCSBot.reducer";
import addRichTempalteReducer from "./Onboarding/addTemplateReducer";
import botConsoleReducer from "./developer/botConsole.reducer";
import submitSelectedCarriersReducer from "./Onboarding/submitSelectedCarriersReducer";
import storeVerificationDetails from "./storeVerificationDetails";
import getBotTemplates from "./getBotTemplates";
import updateListingReducer from "./Onboarding/updateListingReducer";
import getBotScreensReducer from "./Onboarding/botScreensReducer";
import { adminSubmittedForListing, adminApproveBotForListing, adminRejectBotForListing, adminListingHistory } from "./AdminPortalReducers/AdminListingReducers/adminSubmitedForListing.reducer";
import { adminSubmittedForVerification, adminApproveRejectForVerification } from "./AdminPortalReducers/AdminVerificationReducers/adminSubmitedForVeririfcation.reducer";
import deleteBotsReducer from "./Onboarding/deleteBotsReducer";
import submitCarrierReducer from "./LaunchRequest/submitCarrierReducer";

import deleteTemplateReducer from "./Onboarding/deleteTemplateReducer";
import deleteDocsReducer from "./Onboarding/deleteDocsReducer";
import getTestDevicesReducer from "./Onboarding/getTestDevicesReducer";
import addTestDevicesReducer from "./Onboarding/addTestDeviceReducer";
import deleteTestDevicesReducer from "./Onboarding/deleteTestDeviceReducer";
import sendMessageReducer from "./Onboarding/sendMessageReducer";
import {
  adminApproveRejectForCreation,
  adminInsertAgent,
  adminKRCSCreation,
  adminGetAgentInfo,
  adminInsertMaaPAgent,
  adminGetCreationList,
} from "./AdminPortalReducers/AdminCreationReducers/AdminCreation.reducers";
import getTemplateDetailsReducer from "./Onboarding/viewTemplateDetailsReducer";
import getTemplateHistoryReducer from "./Onboarding/getTemplateHistoryReducer";
import getTemplateJSONReducer from "./Onboarding/getTemplateJSON.Reducer";
import getAdminCampaignReducer from "./AdminPortalReducers/get_admin_campaign_reducer";
import { campaignListReducer, campaignDetailsReducer, botTemplateListReducer, AddNewCampaignDataReducer } from "./campaign/campaignReducers";
import { getAllListsAggregatorBilling, getAggregatorBillingInfo } from "./Aggregator/Billing/AggregatorBilling.reducer";
import generateOtpReducer from "./generateOtpReducer";
import validateOtpReducer from "./validateOtpReducer";
import testTemplteReducer from "./testTemplate/testTemplateReducer";
import getTestTemplateSummaryReducer from "./testTemplate/testTemplateSummaryReducer";
import getRetestTemplateReducer from "./testTemplate/reTestEmplateReducer";
import agentMsgTypeListReducer from "./agentMsgTypeListReducer";
import { suspendedBotsReducer } from "./AdminPortalReducers/Launch/suspendedBotsReducer";

const rootReducer = combineReducers({
  getAggrigatorsReducer,
  getBotsReducer,
  getBillingBrandsReducer,
  filterDashboardSummary,
  getSummaryCardsReducer,
  verifyEmailReducer,
  setPasswordReducer,
  setupUserDetailsReducer,
  partnerDetailsReducer,
  botListReducer,
  categoryListReducer,
  carrierListReducer,
  countryListReducer,
  getBrandsReducer,
  getBotDetailsReducer,
  stepTwoReducer,
  getLaunchCarrierList,
  getInitialDetailsReducer,
  getPartnersBrandsListReducer,
  getIndustryTypesReducer,
  submitBrandReducer,
  getSingleBrandReducer,
  getSingleTemplateReducer,
  getVerifiedCarriers,
  updateBrandReducer,
  submitVerifiedCarriers,
  submitBotSummary,
  getPartnersCarriers,
  getBotWhatsAppReducer,
  updatePartnersProfileReducer,
  getLaunchSummaryReducer,
  changePasswordReducer,
  getPricingReducer,
  getInviteUserReducer,
  getReInviteUserReducer,
  getBillingUserPasaswordReducer,
  getDeleteUserReducer,
  getAllUsersReducer,
  UpdateCarrierProfileReducer,
  CarrierGetSubmittedBrandsReducer,
  CarrierGetRejectedBrandsReducer,
  CarrierGetApprovedBrandsReducer,
  getCarrierSubmittedBotsReducer,
  getCarrierApprovedBotsReducer,
  getListOfAggregatorReducer,
  getCarrierRejectedBotsReducer,
  approveORRejectBotReducer,
  ApproveOrRejectBrandReducer,
  getSubmittedAggregatorsForCarrierReducer,
  getApprovedAggregatorsForCarrierReducer,
  getRejectedAggregatorsForCarrierReducer,
  getAggregatorInfoReducer,
  approveOrRejectCarrierAggregatorReducer,
  lineGraph,
  getAllUsersAdminPortalReducer,
  getAdminSubmittedBotsReducer,
  getAdminApprovedBotsReducer,
  getAdminRejectedBotsReducer,
  getAdminForwardedBotsReducer,
  AdminapproveORRejectBotReducer,
  getBotHistoyReducer,
  forwardBotsReducer,
  getInviteUserAdminPortalReducer,
  getReInviteUserAdminPortalReducer,
  getDeleteUserAdminPortalReducer,
  updateCarrierProfileAdminPortalReducer,
  getDotgoSummaryReducer,
  getAdminSubmittedAggregatorsReducer,
  getAdminSubmittedDotgoAggregatorsReducer,
  getAdminTemplatesReducer,
  getAdminApprovedAggregatorsReducer,
  getAdminRejectedAggregatorsReducer,
  getAdminForwardedAggregatorReducer,
  approveORRejectAggregatorReducerAdmin,
  approveORRejectDotgoAggregatorReducerAdmin,
  approveORRejectDotgoTemplateReducerAdmin,
  forwardAggregatorReducer,
  getAggHistoyReducer,
  AdminGetApprovedBrandsReducer,
  AdminGetRejectedBrandsReducer,
  AdminGetSubmittedBrandsReducer,
  AdminApproveOrRejectBrandReducer,
  PendingApprovalBrandsReducer,
  forwardBrandReducer,
  getBrandHistoyReducer,
  __createBotAgent: createBotAgentReducer,
  __submitBotAgentCarriers: submitBotAgentCarriers,
  loadBotReviewsReducer,
  SubmitBotReviewsReducer,
  getPricePageDetailsReducer,
  getAdminCampaignReducer,
  getAllCarrierAndCountryDetailsReducer,
  storeSelectedCarrierReducer,
  submitPreselectedCarriersListReducer,
  createRCSBotReducer,
  addRichTempalteReducer,
  botConsoleReducer,
  submitSelectedCarriersReducer,
  storeVerificationDetails,
  GeoLocationReducer,
  submitWhatsAppBotReducer,
  getBotTemplates,
  getBotScreensReducer,
  updateListingReducer,
  adminSubmittedForListing,
  adminApproveBotForListing,
  adminRejectBotForListing,
  adminListingHistory,
  adminSubmittedForVerification,
  adminApproveRejectForVerification,
  deleteBotsReducer,
  getBotCreationPreviewReducer,
  getSingleBrandReducerPreview,
  getVerifiedBotDetailsReducer,
  submitCarrierReducer,
  deleteTemplateReducer,
  deleteDocsReducer,
  getTestDevicesReducer,
  addTestDevicesReducer,
  deleteTestDevicesReducer,
  sendMessageReducer,
  adminApproveRejectForCreation,
  adminInsertAgent,
  adminKRCSCreation,
  getTemplateDetailsReducer,
  adminGetAgentInfo,
  adminInsertMaaPAgent,
  getTemplateHistoryReducer,
  updateRCSBotOnboardingReducer,
  campaignListReducer,
  campaignDetailsReducer,
  botTemplateListReducer,
  AddNewCampaignDataReducer,
  getDocumentTypes,
  getAllListsAggregatorBilling,
  getAggregatorBillingInfo,
  generateOtpReducer,
  validateOtpReducer,
  getRichPromotionsAndNotificationsCampaignSummaryReducer,
  getRichPromotionsAndNotificationsCampaignDetailedReportReducer,
  testTemplteReducer,
  getTestTemplateSummaryReducer,
  getRetestTemplateReducer,
  agentMsgTypeListReducer,
  getTemplateJSONReducer,
  adminGetCreationList,
  suspendedBotsReducer,
});

export default rootReducer;
