import { LOADING } from "../../../constants/constant";
import {
  UPDATE_CARRIER_PROFILE,
  UPDATE_CARRIER_PROFILE_FAILED,
  UPDATE_CARRIER_PROFILE_RESET,
  UPDATE_CARRIER_PROFILE_SUCCESS
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
  statusCode : LOADING,
};

export default function updateCarrierProfileReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CARRIER_PROFILE: {
      let reqState = Object.assign({}, state); //doubt y this instead of simple object creation
      reqState.isLoading = true;
      reqState.status = LOADING;
      return reqState;
    }

    case UPDATE_CARRIER_PROFILE_SUCCESS: {
      let userResponse = Object.assign({}, state);
      console.log(
        "updateCarrierProfileReducer ====>>> carrier profile updated successfully " +
          JSON.stringify(action.data)
      );
      userResponse.status = action.data.status_message;
      userResponse.statusCode = action.data.status_code;
      userResponse.isLoading = false;
      // userResponse.updatedCarrierProfileData = action.data.data;

      return userResponse;
    }
    case UPDATE_CARRIER_PROFILE_FAILED: {
      console.error(
        "updateCarrierProfileReducer =====>> error while updating carrier details: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);
    }

    case UPDATE_CARRIER_PROFILE_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
