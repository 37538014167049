import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import * as Api from "../../appApi/AppApi";

const TAG = "getIndustryTypes.js";

function* workerGetIndustryTypes(payload) {
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.GET_ALL_INDUSTRY_TYPES,
      {}
    );
    console.log(TAG, "workerGetIndustryTypes: " + JSON.stringify(response));
    yield put(memberAction.getAllIndustryTypesSuccess(response));
  } catch (error) {
    console.log(TAG, "workerGetIndustryTypes: " + error.message);
    yield put(memberAction.getAllIndustryTypesFailed(error.message));
  }
}

export const watchGetIndustryTypes = takeLatest(
  memberType.GET_ALL_INDUSTRY_TYPES_REQUESTED,
  workerGetIndustryTypes
);
