import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import * as Api from "../../appApi/AppApi";

const TAG = "partnersBrandList.js";

function* workerGetPartnersBrandList(payload) {
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.PARTNERS_BRAND_LIST,
      {}
    );
    yield put(memberAction.PartnersBrandListSuccess(response));
  } catch (error) {
    console.log(TAG, "workerGetPartnersBrandList: " + error.message);
    yield put(memberAction.PartnersBrandListFailed(error.message));
  }
}

export const watchGetPartnersBrandList = takeLatest(
  memberType.PARTNER_BRANDS_LIST_REQUESTED,
  workerGetPartnersBrandList
);
