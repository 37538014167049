import { LOADING } from "../../../constants/constant";
import {
  LAUNCH_SETUP_COMPANY_DETAILS,
  LAUNCH_SETUP_COMPANY_DETAILS_SUCCESS,
  LAUNCH_SETUP_COMPANY_DETAILS_FAILED,
  LAUNCH_SETUP_COMPANY_DETAILS_RESET,
} from "../../actions/ActionType";

const initialState = {
  status: "",
  isLoading: false,
};

export default function stepTwoReducer(state = initialState, action) {
  switch (action.type) {
    case LAUNCH_SETUP_COMPANY_DETAILS:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case LAUNCH_SETUP_COMPANY_DETAILS_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log("came to the stepTwoReducer   " + JSON.stringify(action));
      userRes.status = action.data.status_message;
      userRes.isLoading = false;
      userRes.statusCode = action.data.status_code;
      userRes.entity = action.data.message;
      return userRes;

    case LAUNCH_SETUP_COMPANY_DETAILS_FAILED:
      return Object.assign({}, state, action.error);
    case LAUNCH_SETUP_COMPANY_DETAILS_RESET:
      return initialState;

    default:
      return state;
  }
}
