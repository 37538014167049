import * as actionCreators from "../../actions/ActionType";
const initialState = {
  data: {
    isLoading: true,
    error: false,
  },
};
const GeoLocationReducer = (state = initialState, action) => {
  // console.log("[STATE]", state);
  let newState = {};
  switch (action.type) {
    case actionCreators.FETCH_GEO_LOCATION:
      newState = { ...state, isLoading: true };
      return newState;
    case actionCreators.FETCH_GEO_LOCATION_SUCCESS:
      let rqstState = {
        data: action.data,
        isLoading: false,
      };
      return rqstState;
    case actionCreators.FETCH_GEO_LOCATION_FAILED:
      return { ...action.data, isLoading: false, error: true };
    default:
      return state;
  }
};
export default GeoLocationReducer;
