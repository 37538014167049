import { put, takeLatest, call } from "redux-saga/effects";
import { createActionOf } from "../../actions/ActionCreator";
import {
  APPROVE_OR_REJECT_DOTGO_TEMPLATE_FOR_ADMIN,
  APPROVE_OR_REJECT_DOTGO_TEMPLATE_FOR_ADMIN_SUCCESS,
  APPROVE_OR_REJECT_DOTGO_TEMPLATE_FOR_ADMIN_FAILED,
  APPROVE_OR_REJECT_DOTGO_TEMPLATE_FOR_ADMIN_RESET,
} from "../../actions/ActionType";
import { makeSecuredPostRequest } from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";

const TAG = "AdminviewTemplateInfoContainer.js";

function* workerApproveOrRejectTemplate(payLoad) {
  console.log(
    TAG,
    "workerApproveOrRejectTemplate Admin : " + JSON.stringify(payLoad)
  );

  let payloadData = payLoad.data.data;
  let url =""

  if(payLoad.data.isVIAdmin){
    url = API_ENDPOINT.APPROVE_REJECT_TEMPLATES_FOR_CARRIER_ADMIN
  }
  else{
    url = API_ENDPOINT.APPROVE_OR_REJECT_TEMPLATE
    if (payLoad.data.status === 86){
      url = API_ENDPOINT.REJECT_TEMPLATE_ADMIN
    }
  }

  payloadData={
    ...payloadData,
    "status_id":payLoad.data.status
  }

  console.log("🚀 ~ file: admin_approve_or_reject_dotgo_templ_saga.js ~ line 38 ~ payloadData", payloadData);


  try {
    const response = yield call(
      makeSecuredPostRequest,
      url,
      payloadData
    );
    console.log(
      TAG,
      "worker workerApproveOrRejectTEMPLATE Response: " +
        JSON.stringify(response)
    );
    yield put(
      createActionOf(
        APPROVE_OR_REJECT_DOTGO_TEMPLATE_FOR_ADMIN_SUCCESS,
        1,
        response
      )
    );
  } catch (error) {
    console.error(TAG, "Worker get country failed: " + error.message);
    yield put(
      createActionOf(
        APPROVE_OR_REJECT_DOTGO_TEMPLATE_FOR_ADMIN_FAILED,
        2,
        error
      )
    );
  }
}

export const watchApproveOrRejectDotgoTemplateAdmin = takeLatest(
  APPROVE_OR_REJECT_DOTGO_TEMPLATE_FOR_ADMIN,
  workerApproveOrRejectTemplate
);
