export const GET_CAMPAIGN_LIST = "GET_CAMPAIGN_LIST";
export const GET_CAMPAIGN_LIST_SUCCESS = "GET_CAMPAIGN_LIST_SUCCESS";
export const GET_CAMPAIGN_LIST_FAILED = "GET_CAMPAIGN_LIST_FAILED";
export const GET_CAMPAIGN_LIST_RESET = "GET_CAMPAIGN_LIST_RESET"

export const GET_CAMPAIGN_DETAILS = "GET_CAMPAIGN_DETAILS"
export const GET_CAMPAIGN_DETAILS_SUCCESS = "GET_CAMPAIGN_DETAILS_SUCCESS"
export const GET_CAMPAIGN_DETAILS_FAILED = "GET_CAMPAIGN_DETAILS_FAILED"
export const GET_CAMPAIGN_DETAILS_RESET = "GET_CAMPAIGN_DETAILS_RESET"

export const GET_BOT_TEMPLATES_LIST_REQUESTED = "GET_BOT_TEMPLATES_LIST_REQUESTED";
export const GET_BOT_TEMPLATES_LIST_SUCCESS = "GET_BOT_TEMPLATES_LIST_SUCCESS";
export const GET_BOT_TEMPLATES_LIST_FAILED = "GET_BOT_TEMPLATES_LIST_FAILED";
export const GET_BOT_TEMPLATES_LIST_RESET = "GET_BOT_TEMPLATES_LIST_RESET"

export const GET_CAMPAIGN_BOT_TEMPLATE_DATA = "GET_CAMPAIGN_BOT_TEMPLATE_DATA";
export const GET_CAMPAIGN_BOT_TEMPLATE_RESET = "GET_CAMPAIGN_BOT_TEMPLATE_RESET"
