import MarginOf from "../../Layout/MarginOf"
import { useForm } from "react-hook-form"
import { Popup } from "semantic-ui-react"
import { withRouter } from "react-router-dom"
import FormError from "../../Errors/FormError"
import PlaceHolder from "../../util/PlaceHolder"
import isValidURL from "validator/lib/isURL"
import { MAX_CHAR_LENGTH_URL } from "../../../constants/constant"

const SetUpEnterpriseDetailsPage = ({ onSubmit, onSkip, ...rest }) => {
  const { register, errors, handleSubmit } = useForm()
  return (
    <>
      <PlaceHolder />
      <section
        className="congrats_complete_your_details"
        style={{ margin: "4.5em 0px 0px", minHeight: "100vh" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 text-center">
              <p className="text-18">
                <b>
                  Are you creating the developer account on behalf of your
                  company?
                </b>
              </p>
              <p className="text-16">
                If Yes, Please enter the following details:
              </p>
            </div>
          </div>
          <div className="row">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className="col-lg-12 col-md-12 text-center"
                style={{ paddingTop: "40px" }}
              >
                {/* <h2>Complete your details</h2> */}
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group form-input">
                  <label>
                    <img
                      src="images/icon_company_name.svg"
                      width={20}
                      alt="Company Name"
                    />{" "}
                    Company Name*
                  </label>
                  <br />
                  <input
                    ref={register({ required: true })}
                    type="text"
                    className="form-control"
                    name="company_name"
                  />
                  {errors.company_name &&
                    errors.company_name.type === "required" && (
                      <span id="error-msg" className="text-red">
                        Please enter company name
                      </span>
                    )}
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group form-input">
                  <label>
                    <img
                      src="images/icon_job_title.svg"
                      width={20}
                      alt="Job Title"
                    />{" "}
                    Job Title*
                  </label>
                  <input
                    ref={register({ required: true })}
                    type="text"
                    className="form-control"
                    name="job_title"
                  />
                  {errors.job_title && errors.job_title.type === "required" && (
                    <span id="error-msg" className="text-red">
                      Please enter job title
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group form-input">
                  <label>
                    <img
                      src="images/icon_company_website.svg"
                      width={20}
                      alt="Company Website"
                    />{" "}
                    Company Website*
                  </label>
                  <input
                    ref={register({
                      required: true,
                      validate: (value) => isValidURL(value,{protocols: ['https'],require_protocol: true}),
                    })}
                    type="text"
                    className="form-control"
                    name="company_website"
                    maxLength={MAX_CHAR_LENGTH_URL}
                  />
                  {errors.company_website && (
                    <FormError errorMessage="Please enter a valid url" />
                  )}
                </div>
              </div>
              <div
                className="col-lg-12 col-md-12"
                style={{ paddingTop: "15px" }}
              >
                <h5 className="text-18">How do you want to use this portal?</h5>
                <br />
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group form-input">
                  <div className="radio radio-primary">
                    <input
                      defaultChecked
                      type="radio"
                      ref={register}
                      id="Radio1"
                      value={0}
                      name="agent_type"
                    />
                    <label htmlFor="Radio1" className=" text-16">
                      {" "}
                      Manage bots for my company{" "}
                    </label>
                    <p id={"way-info"}>
                      <small className="text-gray">
                        Manage &amp; list RCS &amp; WhatsApp bots on Dotgo Bot
                        Store for your company
                      </small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group form-input">
                  <div className="radio radio-primary">
                    <input
                      type="radio"
                      ref={register}
                      id="Radio2"
                      value={1}
                      name="agent_type"
                    />
                    <label htmlFor="Radio2" className=" text-16">
                      {" "}
                      Manage bot for other brands
                    </label>
                    <p id={"way-info"}>
                      <small className="text-gray">
                        I am a developer who builds bots.
                        <br />
                        Manage &amp; list RCS &amp; WhatsApp bots on Dotgo Bot
                        Store for your clients
                      </small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group form-input">
                  <div className="radio radio-primary">
                    <input
                      type="radio"
                      ref={register}
                      id="Radio5"
                      value={2}
                      name="agent_type"
                    />
                    <label htmlFor="Radio5" className=" text-16">
                      {" "}
                      Sign-up as an Aggregator with carriers{" "}
                    </label>{" "}
                    <Popup
                      trigger={
                        <i className="fa fa-info-circle text-light-gray" />
                      }
                      wide="10%"
                      pinned
                      hoverable
                      hideOnScroll={false}
                      position="bottom center"
                      content={
                        <p style={{ fontSize: "75%", color: "gray" }}>
                          <p className="text-underline">Carrier List</p>

                          {rest.launchCarrierList &&
                            rest.launchCarrierList.map((carrier) => (
                              <>
                                {carrier.name}
                                <br />
                              </>
                            ))}
                        </p>
                      }
                    />
                    {/* <a
                      href=""
                      data-toggle="popover"
                      data-trigger="hover"
                      data-content="Carrier list: 9mobile Nigeria, CelC South Africa, Three UK, EE UK, Claro Argentina"
                      className="link-light-gray"
                    >
                      <i className="fa fa-info-circle" />
                    </a> */}
                    {/* </a> */}
                    <p id={"way-info"}>
                      <small className="text-gray">
                        Get a direct RCS relationship with one or more supported
                        <br />
                        carriers.Also, manage &amp; list RCS &amp; WhatsApp bots
                        on
                        <br />
                        Dotgo Bot Store for your clients
                      </small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 text-center margin-bottom-25">
                <div className="form-group margin-top-25 padding-top-15 padding-bottom-15">
                  {/* </button> */}
                  <input
                    type="reset"
                    onClick={onSkip}
                    className="btn btn-red-btr-round"
                    value={"Skip"}
                  />
                  &nbsp; &nbsp;
                  <button className="btn btn-red-round">Submit</button>
                </div>
                {rest.error && <FormError errorMessage={rest.errorMessage} />}
              </div>
            </form>
          </div>
        </div>
      </section>
      <MarginOf margin="100px" />
    </>
  )
}
export default withRouter(SetUpEnterpriseDetailsPage)
