import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

const TAG = "templateHistory.js";

function* workerGetRetest(payload) {
  try {
    if (!payload.data) {
      throw new Error(
        "bot id is undefined: Payload=>" + JSON.stringify(payload)
      );
    }
    const response = yield call(
      Api.makeSecuredGetRequest,
      API_ENDPOINT.RETEST_TEMPLATE + encodeURIComponent(payload.data)
    );
    yield put(memberAction.createActionOf(memberType.RETEST_TEMPLATE_SUCCESS,1,response));
  } catch (error) {
    console.error(TAG, "Error: while getting test template summary: ", error.message);   
     yield put(memberAction.createActionOf(memberType.RETEST_TEMPLATE_FAILED,2,error));

  }
}

export const watchRetestTemplateSaga = takeLatest(
  memberType.RETEST_TEMPLATE,
  workerGetRetest
);