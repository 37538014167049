import { LOADING } from "../../../constants/constant";
import {
    GET_APPROVED_BOTS_FOR_CARRIER,
    GET_APPROVED_BOTS_FOR_CARRIER_SUCCESS,
    GET_APPROVED_BOTS_FOR_CARRIER_FAILED,
    GET_APPROVED_BOTS_FOR_CARRIER_RESET,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function getCarrierApprovedBotsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_APPROVED_BOTS_FOR_CARRIER: {
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;
    }

    case GET_APPROVED_BOTS_FOR_CARRIER_SUCCESS: {
      let userRes = Object.assign({}, state);
      // console.log(
      //   "getCarrierApprovedBotsReducer => Case => Success: while getting submitted bots list: " +
      //     JSON.stringify(action.data.data)
      // );
      userRes.status = action.data.statusMessage;
      userRes.statusCode = action.data.statusCode;
      userRes.isLoading = false;
      //   userRes.statusCode = action.data.status;
      userRes.botList = action.data.data;

      return userRes;
    }
    case GET_APPROVED_BOTS_FOR_CARRIER_FAILED: {
      console.error(
        "getCarrierApprovedBotsReducer => Case => Error: while getting submitted bots list: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);
    }

    case GET_APPROVED_BOTS_FOR_CARRIER_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
