import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_APPROVED_AGGREGATOR_FOR_ADMIN_SUCCESS,
  GET_APPROVED_AGGREGATOR_FOR_ADMIN,
  GET_APPROVED_AGGREGATOR_FOR_ADMIN_FAILED
} from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";

const TAG = "get_approved_bots_for_admin.js";

function* workerGetAggregator(payLoad) {
  console.log("payload sub-------------------->",payLoad);
  try {
    const response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.GET_APPROVED_AGGREGATOR_FOR_ADMIN
      // payLoad.data
    );
    console.log("getSubmittedBotsReducer",JSON.stringify( response));
    yield put(createActionOf(GET_APPROVED_AGGREGATOR_FOR_ADMIN_SUCCESS, 1, response));
  } catch (error) {
    console.error(TAG, "Worker get bots failed: " + error.message);
    yield put(createActionOf(GET_APPROVED_AGGREGATOR_FOR_ADMIN_FAILED, 2, error));
  }
}

export const watchGetAdminApprovedAggregators = takeLatest(
  GET_APPROVED_AGGREGATOR_FOR_ADMIN,
  workerGetAggregator
);
