import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

function* workerGetSingleBrandDetailsPreview(payload) {
  try {
    if (!payload.data) {
      throw new Error(
        "Brand id is undefined: Payload=>" + JSON.stringify(payload)
      );
    }
    let apiEndpoint =
      API_ENDPOINT.GET_SINGLE_BRAND_DETAILS_PREVIEW + payload.data?.brand_id;

    if (/admin/i.test(payload.data?.whoIsThis)) {
      apiEndpoint =
        API_ENDPOINT.ADMIN_GET_SINGLE_BRAND_DETAILS + payload.data?.brand_id?.replace("?status=i","");
    }
    const response = yield call(Api.makeSecuredPostRequest, apiEndpoint);
    console.log("[worker single brand details:]" + JSON.stringify(response));
    yield put(memberAction.getBrandSuccessPreview(response));
  } catch (error) {
    console.error("Error: while getting brand details: ", error.message);
    yield put(memberAction.getBrandFailedPreview(error.message));
  }
}

export const watchGetBrandDetailsSagaPreview = takeLatest(
  memberType.GET_SINGLE_BRAND_REQUESTED_PREVIEW,
  workerGetSingleBrandDetailsPreview
);
