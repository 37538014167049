import { useEffect, useState, useMemo } from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthContext } from "./Providers/ContextProviders/AuthContext";
import Main from "./containers/Main";
import Layout from "./components/Layout/Layout";
import ScrollToTop from "./components/util/ScrollToTop";
import ErrorBoundary from "./components/util/ErrorBoundary";
import { JWT_SECRETE_KEY } from "./store/URLs/ApiEndpoints";
import { version, name } from "../package.json";
import "./components/styles/scss/util.css";
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const loginValues = useMemo(() => ({ isLoggedIn: isLoggedIn, setIsLoggedIn: setIsLoggedIn }), [isLoggedIn]);
  useEffect(() => {
    /**
     * Only works in staging and development not in production.
     * Remove/Comment useEffect if it causes any issue.
     */
    window.getVersion = (portal, key) => {
      return key === JWT_SECRETE_KEY && portal === name ? "Version: 👉 " + version + " 👈" : "Not Authorized";
    };
  }, []);
  return (
    <>
      <AuthContext.Provider
        // value={{
        //   isLoggedIn: isLoggedIn,
        //   setIsLoggedIn: setIsLoggedIn,
        // }}
        value={loginValues}
      >
        <BrowserRouter>
          <Layout>
            <ScrollToTop>
              <ErrorBoundary>
                <Main />
              </ErrorBoundary>
            </ScrollToTop>
          </Layout>
        </BrowserRouter>
      </AuthContext.Provider>
    </>
  );
}
export default App;
