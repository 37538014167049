import { LOADING } from "../../../constants/constant";
import {
  ADD_TEST_DEVICES_REQUESTED,
  ADD_TEST_DEVICES_SUCCESS,
  ADD_TEST_DEVICES_FAILED,
 ADD_TEST_DEVICES_RESET
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function addTestDevicesReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TEST_DEVICES_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case ADD_TEST_DEVICES_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.status = action.data.status_message;
      userRes.statusCode = action.data.status_code;
      userRes.message = action.data.message;
      userRes.isLoading = false;
      console.log("test devices response: ",action.data);
      return userRes;

    case ADD_TEST_DEVICES_FAILED:
      let errorRes = Object.assign({}, state, action.data);
      errorRes.error=action.data
      errorRes.isLoading = false;
      console.error("Error while test devices",action.data);
      return errorRes;

      case ADD_TEST_DEVICES_RESET: {
        return initialState;
      }
    default:
      return state;
  }
}
