import { LOADING } from "../../constants/constant";
import {
  GET_SINGLE_TEMPLATE_REQUESTED,
  GET_SINGLE_TEMPLATE_SUCCESS,
  GET_SINGLE_TEMPLATE_FAILED,
  GET_SINGLE_TEMPLATE_RESET
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function getSingleTemplateReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SINGLE_TEMPLATE_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_SINGLE_TEMPLATE_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.status = action.data.status_message;
      userRes.statusCode = action.data.status_code;
      userRes.isLoading = false;
      // userRes.statusCode = action.data.statusCode;
      userRes = action.data;
      // if (action.data.data.status_id === 11) {
      //   userRes.isTemplateVerified = true;
      // } else {
      //   userRes.isTemplateVerified = false;
      // }
      return userRes;

    case GET_SINGLE_TEMPLATE_FAILED:
      console.error("Error while getting TEMPLATE details", action.error);
      return Object.assign({}, state, action.error);

      case GET_SINGLE_TEMPLATE_RESET: {
        return initialState;
      }
    default:
      return state;
  }
}
