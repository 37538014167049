import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../../actions/ActionCreator";
import * as memberType from "../../../actions/ActionType";
import { API_ENDPOINT } from "../../../URLs/ApiEndpoints";
import * as Api from "../../../../appApi/AppApi";

const TAG = "AdminGetRejectedBrands.js";

function* workerAdminGetRejectedBrands(payLoad) {
  console.log(TAG, "get rejected brands : " + JSON.stringify(payLoad));
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.ADMIN_GET_REJECTED_BRANDS , payLoad.data
    );
    yield put(memberAction.createActionOf(memberType.ADMIN_GET_REJECTED_BRANDS_SUCCESS,1,response));
  } catch (error) {
    console.error(TAG, "Worker get rejected brands failed: " + error.message);
     yield put(memberAction.createActionOf(memberType.ADMIN_GET_REJECTED_BRANDS_FAILED,2,error));
    // throw new Error("Error: inside saga", JSON.stringify(error));
  }
}

export const watchAdminGetRejectedBrands = takeLatest(
  memberType.ADMIN_GET_REJECTED_BRANDS_REQESTED,
  workerAdminGetRejectedBrands
);
