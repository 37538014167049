import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import * as Api from "../../appApi/AppApi";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";

function* workerUpdateWhatsAppBot(payLoad) {
  console.log(
    "Worker: workerUpdateWhatsAppBot => Payload:: " + JSON.stringify(payLoad)
  );
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.UPDATE_BOT,
      payLoad.data
    );
    console.log(
      "Worker: workerUpdateWhatsAppBot => Response:: " +
        JSON.stringify(response)
    );
    yield put(memberAction.updateWhatsAppBotSuccess(response));
  } catch (error) {
    console.error(
      "Worker: workerUpdateWhatsAppBot => Error:: " + error.message
    );
    yield put(memberAction.updateWhatsAppBotFailed(error.message));
  }
}

export const watchUpdateWhatsAppBot = takeLatest(
  memberType.UPDATE_WHATSAPP_BOT,
  workerUpdateWhatsAppBot
);
