import { lazy, Suspense } from "react";
import { useRouteMatch, withRouter } from "react-router-dom";
import RoleProtectedRoute from "../../Routes/RoleProtectedRoute";
import Fallback from "../../../components/Layout/Fallback";

const DotGoAdmin = lazy(() => import("../../Routes/DotgoAdmin"));

const DotgoAdmin = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Suspense fallback={<Fallback />}>
        <RoleProtectedRoute
          secureFor={["SUPER_ADMIN_VI_DOTGO", "VI_DOTGO_ADMIN_READWRITE", "VI_DOTGO_ADMIN_READONLY", "VI_CARRIER_ADMIN", "VI_CARRIER_READONLY", "VI_CARRIER_READWRITE", "ACC_MANAGER"]}
          key="dotgo-admin-root"
          path={`${path}`}
          component={DotGoAdmin}
        />
      </Suspense>
    </>
  );
};
export default withRouter(DotgoAdmin);
