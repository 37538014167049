import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import * as Api from "../../../appApi/AppApi";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";

const TAG = "getPricePageDetailsSaga.js";

function* getPricePageDetails(payLoad) {
  console.log(TAG, "worker getPricePageDetails : " + JSON.stringify(payLoad));
  console.log(TAG, "getPricePageDetails : " + JSON.stringify(payLoad.data));

  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.GET_PRICE_PAGE_DETAILS
    );
    // console.log(
    //   TAG,
    //   "worker getVerifiedCarrier Response: " + JSON.stringify(response)
    // );
    yield put(memberAction.createActionOf( memberType.GET_PRICE_PAGE_DETAILS_SUCCESS,  1,response) );
  } catch (error) {
    console.log(TAG, "Worker getVerifiedCarrier error: " + error.message);
    yield put( memberAction.createActionOf(memberType.GET_PRICE_PAGE_DETAILS_FAILED,2,error));
  }
}

export const watchGetPricePageDetails = takeLatest(
  memberType.GET_PRICE_PAGE_DETAILS,
  getPricePageDetails
);
