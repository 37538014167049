import React from "react";
import "./pp.css"
const PrivacyPolicy = (props) => {
  return (
    <React.Fragment>
      <a className="anchor" id="privacy.html"></a>
      <section
      className="developer-pp"
        style={{ marginTop: "50px", paddingTop: "60px", paddingBottom: "60px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h6>&nbsp;</h6>
              <h2>
                DOTGO DEVELOPER PRIVACY POLICY
              </h2>
              <p>
                <strong className="fw-5">Version of Jun 16, 2021</strong>
              </p>
              <br />
              <p>
                This Privacy Policy (“<strong>Privacy Policy</strong>”) is
                entered into by Dotgo Systems Inc. (“<strong>Dotgo USA</strong>
                ”), unless you are located in a country where Dotgo USA has an
                Affiliate and your order is placed upon and processed by Dotgo
                USA’s Affiliate in your country, in which case, Dotgo USA’s
                Affiliate in that country is the contracting entity (“
                <strong>Company</strong>” or “<strong>we</strong>” or “
                <strong>Dotgo</strong>”), and the entity accepting this Privacy
                Policy (“<strong>You</strong>” or “<strong>Client</strong>”).
              </p>

              <p>
                This Privacy Policy governs the manner in which the Company
                collects, uses, maintains and discloses information collected
                from You. This Privacy Policy applies to the Website and its
                associated Content and Services, and to all products and
                services offered by the Company, unless a more specific privacy
                policy has been explicitly notified by the Company in relation
                to that product or service.
              </p>
              <p>
                BY USING THE WEBSITE OR BY OTHERWISE GIVING US YOUR INFORMATION,
                YOU WILL BE DEEMED TO HAVE READ, UNDERSTOOD AND AGREED TO THE
                PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY AND AGREE
                TO BE BOUND BY THE PRIVACY POLICY. YOU HEREBY CONSENT TO OUR
                COLLECTION, USE AND SHARING, DISCLOSURE OF YOUR INFORMATION AS
                DESCRIBED IN THIS PRIVACY POLICY. WE RESERVE THE RIGHT TO
                CHANGE, MODIFY, ADD OR DELETE PORTIONS OF THE TERMS OF THIS
                PRIVACY POLICY, AT OUR SOLE DISCRETION, AT ANY TIME. IF YOU DO
                NOT AGREE WITH THIS PRIVACY POLICY AT ANY TIME, DO NOT USE THE
                WEBSITE OR GIVE US ANY OF YOUR INFORMATION. IF YOU USE THE
                WEBSITE ON BEHALF OF SOMEONE ELSE (SUCH AS A MANAGER,
                SUPERVISOR, CO-WORKER) OR AN ENTITY (SUCH AS YOUR EMPLOYER OR
                YOUR CUSTOMER), YOU REPRESENT THAT YOU ARE AUTHORISED BY SUCH
                INDIVIDUAL OR ENTITY TO (I) ACCEPT THIS PRIVACY POLICY ON SUCH
                INDIVIDUAL’S OR ENTITY’S BEHALF, AND (II) CONSENT ON BEHALF OF
                SUCH INDIVIDUAL OR ENTITY TO OUR COLLECTION, USE AND DISCLOSURE
                OF SUCH INDIVIDUAL’S OR ENTITY’S INFORMATION AS DESCRIBED IN
                THIS PRIVACY POLICY.
              </p>
              <h4>
                <strong>Please note:</strong>
              </h4>
              <ol type="a" style={{ lineHeight: "1.8" }}>
                <li>
                  TOS refers to Terms of Service between the Company and You, as
                  listed at developer.dotgo.com/developer-tos.
                </li>
                <li>
                  For the purpose of this Privacy Policy, various terms shall
                  have the meaning as defined in the TOS.
                </li>
                <li>
                  This Privacy Policy applies to the Website, and associated
                  Content and Services.
                </li>
                <li>
                  You acknowledge that information you provide on your profile
                  can be seen by others and used by us as described in this
                  Privacy Policy and our TOS.
                </li>
                <li>
                  If You submit Content to us, we may use that information to
                  operate, maintain, and improve the functioning of the
                  Services.
                </li>
                <li>
                  We reserve the right to monitor some of the information you
                  provide for quality of content.
                </li>
                <li>
                  We will not sell or rent information about You and we will not
                  disclose information about You in a manner inconsistent with
                  this Privacy Policy except as required by law or government
                  regulation.
                </li>
                <li>
                  We use commercially reasonable physical, managerial, and
                  technical safeguards to preserve the integrity and security of
                  Your Content.
                </li>
                <li>
                  The Company shall terminate Your access to the Website, if You
                  are not in compliance with this Privacy Policy.
                </li>
                <li>
                  The Company shall store on its servers, Your Information, in
                  accordance with the applicable laws.
                </li>
                <li>
                  “<strong>Your Information</strong>” or “
                  <strong>Client Information</strong>” includes information that
                  alone or when in combination with other information may be
                  used to readily identify, contact, or locate You.
                </li>
              </ol>
              <ol type={1}>
                <li>
                  <h3>
                    <u>INFORMATION WE COLLECT</u>
                  </h3>
                </li>
                <ol type="a" style={{ lineHeight: "1.8" }}>
                  <li>
                    <h4>Client Provided Information</h4>
                    <p>
                      You provide us information about the nature of your
                      entity, entity’s name, e-mail address, identification
                      numbers, and other information if You register for a
                      Client Account on the Website. If you correspond with us
                      by e-mail or chat, we may retain the content of your
                      e-mail and chat messages, your e-mail address and chat id,
                      and our responses. As part of using the Services, You may
                      provide Us with Your Content. While creating the Client
                      account, You should avoid giving any Personal Information
                      about any of Your employees or agents. Any information
                      used for creating Client account shall be construed as
                      Your Information.
                    </p>
                  </li>
                  <br />
                  <li>
                    <h4>Non-personal identification information</h4>
                    <p>
                      We may collect non-personal identification information
                      about Your usage whenever you interact with our Website.
                      Non-personal identification information may include the
                      browser name, the type of computer and technical
                      information about Your means of connection to our Website,
                      such as the operating system and the Internet Service
                      Providers utilized and other similar information.
                    </p>
                  </li>
                  <br />
                  <li>
                    <h4>Cookies Information</h4>
                    <p>
                      When you visit the Website, we may send one or more
                      cookies - a small text file containing a string of
                      alphanumeric characters - to your computer that uniquely
                      identifies your browser and lets us help you log in faster
                      and enhance your navigation through the Website. A cookie
                      may also convey information about how you browse the
                      Service to us. A cookie does not collect Personal
                      Information about You. A persistent cookie remains on your
                      hard drive after you close your browser. Persistent
                      cookies may be used by your browser on subsequent visits
                      to the Website. Persistent cookies can be removed by
                      following your web browser's directions. A session cookie
                      is temporary and disappears after you closes your browser.
                      You can reset your web browser to refuse all cookies or to
                      indicate when a cookie is being sent. However, some
                      features of the Website may not function properly if the
                      ability to accept cookies is disabled.
                    </p>
                  </li>
                  <br />
                  <li>
                    <h4>Log File Information</h4>
                    <p>
                      Log file information is automatically reported by your
                      browser each time you access a web page. When you use the
                      Website, our servers automatically record certain log file
                      information. These server logs may include information
                      such as your web request, Internet Protocol address,
                      browser type, referring / exit pages and URLs, number of
                      clicks and how you interact with links on the Website,
                      domain names, landing pages, pages viewed, and other such
                      information.
                    </p>
                  </li>
                  <br />
                  <li>
                    <h4>Information about brands and chatbots</h4>
                    <p>
                      We collect information about Your brand, or the brands on
                      whose behalf You submit chatbots, including and not
                      limited to, information that helps us verify the brands,
                      to configure, verify, and launch the chatbots, and to
                      populate the Bot Store. We may share the information about
                      brands and chatbots with third parties working with us for
                      providing Services, such as sub-processors and vendors
                      involved in providing Services.
                    </p>
                  </li>
                  <br />
                  <li>
                    <h4>Location Information</h4>
                    <p>
                      You may choose to provide your location and update Your
                      address in the Website. We may also use other data from
                      Your device to determine Your location; for example,
                      information about wireless networks or cell towers near
                      Your mobile device, or Your IP address. We may use and
                      store information about Your location to provide features
                      of our Services and to improve and customize the Services.
                    </p>
                  </li>
                </ol>
                <li>
                  <h3>
                    <u>HOW WE USE COLLECTED INFORMATION</u>
                  </h3>
                </li>
                <p>
                  The Company may collect and use Your Information for the
                  following purposes:
                </p>
                <ol type="a" style={{ lineHeight: "1.6" }}>
                  <li>
                    <h4>To provide, support, and improve Services</h4>
                    <p>
                      We use Your Information to provide, operate, and maintain
                      the Services, to respond to your service requests and
                      support needs, to perform obligations arising from any PO
                      or contract between You and Us, and to improve the
                      Services.
                    </p>
                  </li>
                  <br />
                  <li>
                    <h4>To personalize Your experience</h4>
                    <p>
                      We may use information in the aggregate to understand how
                      You use the Services. We may use certain of Your
                      Information internally for purposes such as analyzing how
                      the Services are used, diagnosing service or technical
                      problems, maintaining security, detect, investigate,
                      prevent fraud and other abuse and personalizing content.
                    </p>
                  </li>
                  <br />
                  <li>
                    <h4>To manage our relationship.</h4>
                    <p>
                      We use Your Information to manage our relationship with
                      You. We use the information to perform functions such as
                      accounting, filing of taxes, and performing any other
                      action as mandated under the law.
                    </p>
                  </li>
                  <br />
                  <li>
                    <h4>To send periodic e-mails</h4>
                    <p>
                      We may use the e-mail address to respond to your
                      inquiries, questions, and/or other requests. You will also
                      receive e-mails that may include company news, updates,
                      related product or service information, etc. If at any
                      time You would like to unsubscribe from receiving future
                      e-mails, we include detailed unsubscribe instructions at
                      the bottom of each e-mail, or You may contact us via our
                      Website.
                    </p>
                    <p>
                      You consent to our using the e-mail address to send you
                      Service-related notices, including any notices required by
                      law, in lieu of communication by postal mail.
                    </p>
                    <p>
                      We may use your e-mail address to send you other messages,
                      such as newsletters, changes to features of the Service,
                      or special offers. If you do not want to receive such
                      e-mail messages, you may unsubscribe using the process
                      described in this section. Opting out may prevent you from
                      receiving e-mail messages regarding updates, improvements,
                      or offers. You may not opt out of Service-related e-mails.
                    </p>
                  </li>
                  <br />
                  <li>
                    <h4>For backup, archival or audit</h4>
                    <p>
                      Following termination of Your Account, we may retain your
                      Your Information for a commercially reasonable time in
                      accordance with data retention periods for backup,
                      archival, or audit purposes. Furthermore, we may retain
                      and continue to use indefinitely all information
                      (including parts of Your Content) contained in Your
                      communications to other users or posted to public or
                      semi-public areas of the Website, after termination or
                      deactivation of Your Account. We may also preserve records
                      including personal information if required under law. We
                      may delete the records when there exists no legal
                      obligation to retain them.
                    </p>
                  </li>
                  <br />
                  <li>
                    <h4>Other purposes</h4>
                    <p>We use cookies and log file information to:</p>
                  </li>
                  <ol type="a">
                    <li>
                      <p>
                        Remember information so that you will not have to
                        re-enter it during your visit or the next time you visit
                        the Website; and
                      </p>
                    </li>
                    <li>
                      <p>For verification of brands and chatbots submitted.</p>
                    </li>
                  </ol>
                </ol>
                <br />
                <li>
                  <h3>
                    <strong>
                      <u>HOW WE PROTECT YOUR INFORMATION</u>
                    </strong>
                  </h3>
                  <p>
                    We adopt appropriate data collection, storage and processing
                    practices and security measures to protect against
                    unauthorized access, alteration, disclosure or destruction
                    of Your Information, username, password, transaction
                    information and data stored on our Website.
                  </p>
                  <p>
                    Sensitive and private data exchange between the Website and
                    Client happens over an SSL (Secure Sockets Layer) secured
                    communication channel and is encrypted and protected with
                    digital signatures.
                  </p>
                  <p>
                    We cannot, however, ensure or warrant the security of any
                    information you transmit, or guarantee that your information
                    on the Website may not be accessed, disclosed, altered, or
                    destroyed by breach of any of our physical, technical, or
                    managerial safeguards. Your privacy settings may also be
                    affected by changes to the functionality of the Company’s
                    distributors and suppliers, such as sub-processors and
                    vendors involved in providing Services. We are not
                    responsible for the lapses in functionality or security
                    measures of any third party.
                  </p>
                  <p>
                    You are responsible for maintaining the secrecy of your
                    unique password and account information, and for controlling
                    access to your e-mail communications from the Company, at
                    all times.
                  </p>
                </li>
                <br />
                <li>
                  <h3>
                    <strong>
                      <u>SHARING YOUR PERSONAL INFORMATION</u>
                    </strong>
                  </h3>
                  <p>
                    In case You provide us with Personal Information, we shall
                    assume that consent has been given to Us by You for
                    obtaining the same.
                  </p>
                  <p>
                    We may use third party service providers to help us operate
                    our business and the Website or administer activities on our
                    behalf, such as providing Services, or sending out
                    newsletters or surveys. We may share your Personal
                    Information with these third parties if needed to carry out
                    the obligations and provide you with the services in an
                    efficient manner.
                  </p>
                  <p>
                    Except as otherwise described in this Privacy Policy, we
                    will not disclose Personal Information to any third party
                    unless required to do so by law or if we believe that such
                    action is necessary to conform to the law, comply with legal
                    process served on us or our affiliates, or investigate,
                    prevent, or act regarding suspected or actual illegal
                    activities; to enforce our Terms of Use, take precautions
                    against liability, to investigate and defend ourselves
                    against any third-party claims or allegations, to assist
                    government enforcement agencies, or to protect the security
                    or integrity of our Website; and to exercise or protect the
                    rights, property, or personal safety of the Company, our
                    users or others.
                  </p>
                </li>
                <br />
                <li>
                  <h3>
                    <strong>
                      <u>THIRD PARTY WEBSITES</u>
                    </strong>
                  </h3>
                  <p>
                    You may find advertising or other content on our Website
                    that links to the sites and services of our partners,
                    suppliers, advertisers, sponsors, licensors and other third
                    parties. We do not control the content or links that appear
                    on these sites and are not responsible for the practices
                    employed by websites linked to or from our Website. In
                    addition, these sites or services, including their content
                    and links, may be constantly changing. Please remember that
                    when you use a link to go from the Website to another
                    website, our Privacy Policy is no longer in effect. These
                    sites and services may have their own privacy policies and
                    customer service policies. Browsing and interaction on any
                    other website, including websites which have a link to our
                    Website, is subject to that website's own terms and
                    policies.
                  </p>
                  <p>
                    We are not responsible for the practices employed by
                    websites linked to or from the Website, nor the information
                    or content contained therein. Your browsing and interaction
                    on any other website, including those that have a link on
                    our "Website", is subject to that website's own rules and
                    policies. Please read over those rules and policies before
                    proceeding.
                  </p>
                </li>
                <br />
                <li>
                  <h3>
                    <strong>
                      <u>ADVERTISING</u>
                    </strong>
                  </h3>
                  <p>
                    Ads appearing on our Website may be delivered to You by
                    advertising partners, who may set cookies. These cookies
                    allow the ad server to recognize your computer each time
                    they send you an online advertisement to compile
                    non-personal identification information about you or others
                    who use your computer. This information allows ad networks
                    to, among other things, deliver targeted advertisements that
                    they believe will be of most interest to you. This Privacy
                    Policy does not cover the use of cookies by any advertisers.
                    All revenue generated through advertising on the Website
                    belongs to the Company.
                  </p>
                </li>
                <br />
                <li>
                  <h3>
                    <strong>
                      <u>GOOGLE ADSENSE</u>
                    </strong>
                  </h3>
                  <p>
                    Some of the ads on our Website and Bot Store may be served
                    by Google. Google's use of the DART cookie enables it to
                    serve ads to You based on their visit to our Website and
                    other sites on the Internet. DART uses "non-personally
                    identifiable information" and does NOT track Information
                    about You, such as your name, e-mail address, physical
                    address, etc. You may opt out of the use of the DART cookie
                    by visiting the Google ad and content network privacy policy
                    at{" "}
                    <a
                      href="http://www.google.com/privacy_ads.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://www.google.com/privacy_ads.html
                    </a>
                    .
                  </p>
                </li>
                <br />
                <li>
                  <h3>
                    <strong>
                      <u>YOUR RIGHTS</u>
                    </strong>
                  </h3>
                  <p>
                    Unless subject to an exemption, You have the following
                    rights with respect to your data:
                  </p>
                </li>
                <ol type="a" style={{ lineHeight: "1.8" }}>
                  <li>
                    The right to request a copy of your data which we hold about
                    you;
                  </li>
                  <li>
                    The right to request for any correction to any data if it is
                    found to be inaccurate or out of date;
                  </li>
                  <li>
                    The right to withdraw Your consent to use the data at any
                    time;
                  </li>
                  <li>The right to object to the processing of data;</li>
                  <li>
                    The right to lodge a complaint with a supervisory authority;
                  </li>
                  <li>
                    The right to obtain information as to whether data are
                    transferred to a third country or to an international
                    organization.
                  </li>
                </ol>
                <br />
                <li>
                  <h3>
                    <strong>JURISDICTION AND ARBITRATION</strong>
                  </h3>
                  <p>
                    The Privacy Policy shall be governed by the{" "}
                    <strong>Jurisdiction and Arbitration</strong> clause of the
                    TOS, accessible at developer.dotgo.com/developer-tos, which
                    is incorporated here by reference.
                  </p>
                </li>
                <br />
                <li>
                  <h3>
                    <strong>
                      <u>CHANGES TO THIS PRIVACY POLICY</u>
                    </strong>
                  </h3>
                  <p>
                    We may revise this Privacy Policy from time to time. If we
                    do, the revised Privacy Policy will supersede prior
                    versions. We will provide you advance notice of any material
                    revisions of the Privacy Policy at least fifteen (15) days
                    prior to the revisions becoming effective, unless the
                    regulatory bodies, legal requirements, telecom carriers, or
                    IP Messaging Platforms necessitate a faster change.  We also
                    encourage You to frequently check this page for any changes
                    to the Privacy Policy.
                  </p>
                  <p>
                    What constitutes a "material revision" will be determined at
                    Our sole discretion, in good faith and using common sense
                    and reasonable judgement. Your continued access or use of
                    our Services constitutes your acceptance of any revisions.
                    If you don’t agree to the revisions, you should stop using
                    Services, and in such a case, Company is not obligated to
                    provide you with the Services.
                  </p>
                </li>
                <br />
                <li>
                  <h3>
                    <strong>
                      <u>YOUR ACCEPTANCE OF THESE TERMS </u>
                    </strong>
                  </h3>
                  <p>
                    By using this Website or Services, you signify your
                    acceptance of this Privacy Policy. If you do not agree to
                    this Privacy Policy, please do not use our Website and
                    Services. Your continued use of the Website or Services
                    following the posting of changes to the Privacy Policy will
                    be deemed your acceptance of those changes.
                  </p>
                </li>
                <br />
                <li>
                  <h3>
                    <strong>
                      <u>SEVERABILITY</u>
                    </strong>
                  </h3>
                  <p>
                    Each paragraph of this Privacy Policy shall be and remain
                    separate from and independent of and severable from all and
                    any other paragraphs herein except where otherwise expressly
                    indicated or indicated by the context of the agreement. The
                    decision or declaration that one or more of the paragraphs
                    are null and void shall have no effect on the remaining
                    paragraphs of this Privacy Policy.
                  </p>
                </li>
                <br />
                <li>
                  <h3>
                    <strong>
                      <u>
                        SPECIAL NOTICE TO INTERNATIONAL VISITORS AND CLIENTS
                      </u>
                    </strong>
                  </h3>
                  <p>
                    Because we operate globally, we may transfer Your
                    Information within our global operations to fulfil our
                    obligations to You, but always subject to the limitations of
                    applicable data protection law and this Privacy Policy.
                    Privacy laws differ across the globe. To help visitors whose
                    native language may not be English understand our privacy
                    practices, we may provide special information on our Privacy
                    Policies that may be applicable to these visitors in
                    different languages. The Company’s entities outside USA may
                    have supplemental privacy policies that may apply in those
                    countries. If You are visiting from the European Union or
                    other regions with laws governing data collection and use
                    that may differ from New Jersey law, including those whose
                    privacy laws may be more strict than New Jersey law, please
                    note that You are transferring Your Information to outside
                    the European Union, to us. By providing Your Information you
                    consent to that transfer and processing.
                  </p>
                </li>
                <br />
                <li>
                  <h3>
                    <strong>
                      <u>EUROPEAN ECONOMIC AREA (“EEA”) CLIENTS</u>
                    </strong>
                  </h3>
                  <p>
                    European data protection law including the Regulation (EU)
                    2016/679 (“<strong>GDPR</strong>”) requires organizations
                    like the Company to provide a lawful basis to collect and
                    use the Client’s information. The Company’s lawful basis to
                    collect and use information from the Company’s EEA Clients
                    include when:
                  </p>
                  <ol type="a">
                    <li>
                      <p>
                        The Company needs it in order to provide the Client with
                        the Services and to carry out the core activities
                        related to the Company’s provision of the Services.
                      </p>
                    </li>
                    <li>
                      <p>
                        The Company needs to comply with a legal obligation.
                      </p>
                    </li>
                    <li>
                      <p>
                        The Company has a legitimate interest (which is not
                        overridden by the Client’s data protection interests),
                        such as for research and development, to market and
                        promote the Services and to protect the Company’s legal
                        rights and interests.
                      </p>
                    </li>
                    <li>
                      <p>
                        The Client provides the Company with its consent to do
                        so for a specific purpose.
                      </p>
                    </li>
                  </ol>
                  <p>
                    As data processor, the Company will own and control Client’s
                    information in accordance with GDPR in relation to the
                    Clients on whom GDPR is applicable. The sub-processors used
                    by the Company are listed at{" "}
                    <a
                      href="https://www.dotgo.com/dataprocessors"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.dotgo.com/dataprocessors
                    </a>
                  </p>
                </li>
                <br />

                <li>
                  <h3>
                    <strong>
                      <u>QUERIES AND GRIEVANCE OFFICER</u>
                    </strong>
                  </h3>
                  <p>
                    Should you have questions about this Privacy Policy or the
                    Company's information collection, use and disclosure
                    practices, you may contact, the Data Officer appointed by
                    the Company. The Officer will respond to the queries within
                    30 (thirty) days of receiving the queries. If You have any
                    grievance with respect to Our use of Your Information, You
                    may communicate such grievance to the Data Officer:
                  </p>
                  <p>
                    Name: Data Officer
                    <br />
                    Address: 100 Connell Drive, Suite 2300, Berkeley Heights, NJ
                    07922
                    <br />
                    E-mail ID: <a href="mailto:dpo@dotgo.com">dpo@dotgo.com</a>
                    <br />
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
};
export default PrivacyPolicy;
