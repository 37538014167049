import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

const TAG = "getBotList.js";

function* workerGetSingleTemplateDetails(payload) {
  console.log("🚀 ~ file: getSingleTemplate.js ~ line 10 ~ function*workerGetSingleTemplateDetails ~ payload", payload)

  try {
    if (!payload.data.data) {
      throw new Error(
        "Template id is undefined: Payload=>" + JSON.stringify(payload.data)
      );
    }
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.GET_TEMPLATE_VERIFICATION_DETAILS + payload.data.data
    );
    console.log(
      TAG,
      "[worker single template details:]" + JSON.stringify(response)
    );
    yield put(memberAction.getTemplateSuccess(response));
  } catch (error) {
    console.error(TAG, "Error: while getting Template details: ", error.message);
    yield put(memberAction.getTemplateFailed(error.message));
  }
}

export const watchGetSingleTemplateDetailsSaga = takeLatest(
  memberType.GET_SINGLE_TEMPLATE_REQUESTED,
  workerGetSingleTemplateDetails
);
