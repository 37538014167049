import xss from "xss";

/**
 * Sanitizes the input payload by to avoid xss attack.
 * @param {Object} payload - object to be sanitized.
 * @param {Object} [options] - Optional configuration options for the XSS filtering.
 * @returns {Object} The sanitized payload if processing is successful, or the original payload if an error occurs.
 */
export const sanitizePayload = (payload, options) => {
  try {
    return JSON.parse(xss(JSON.stringify(payload)), options);
  } catch (error) {
    return payload;
  }
};

function containsMaliciousCodeJSON(input) {
  // https://gupshupinc.atlassian.net/browse/RN-750 refer ticket for more details
  // const jsonString = JSON.stringify(input);
  // const clean = xss(jsonString);
  // return clean !== jsonString;
  return false;
}

function containsMaliciousCodeFormData(formData) {
  // https://gupshupinc.atlassian.net/browse/RN-750 refer ticket for more details
  // for (let [, value] of formData.entries()) {
  //   if (typeof value === "string") {
  //     const clean = xss(value);
  //     if (clean !== value) {
  //       return true; // script detected
  //     }
  //   }
  // }
  return false;
}

/**
 * Checks if input contains script
 * @param {Object} payload - object to be checked for scripts.
 * @returns {Boolean}
 */
export const containsMaliciousCode = (payload) => {
  try {
    if (payload instanceof FormData) {
      return containsMaliciousCodeFormData(payload);
    } else {
      return containsMaliciousCodeJSON(payload);
    }
  } catch (error) {
    return false;
  }
};
