import { useEffect,useRef } from "react";
import { SetPasswordPage } from "../../../../components/Pages/SignupPages/Index";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createActionOf } from "../../../../store/actions/ActionCreator";
import { LOADING, STATUS_CODE_SUCCESS } from "../../../../constants/constant";
import { SET_PASSWORD_REQUESTED } from "../../../../store/actions/ActionType";
import JSEncrypt from "jsencrypt";
import { PUBLIC_KEY } from "../../../../constants/stringConstants";
/**
 * Gets redirected from email with token in address bar.
 * Requires token for further flow.
 */
const SetPassword = props => {
  const dispatch = useDispatch();
  const count= useRef("")

  const handleEncrypt = (plaintext) => {
    const publicKey = PUBLIC_KEY
    // console.log("PublicKey : ",publicKey)
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    const encrypted = encrypt.encrypt(plaintext);
    return encrypted;
  };

  const onSetPassword = data => {
    console.info("[Form data] setpasword", data);
    if(count.current){
    count.current.disabled=true
    }
    let temp = props.location.search;
    let breakindex = temp.indexOf("=");
    let token = temp.substring(breakindex + 1);
    let encPass = handleEncrypt(data.new_password)
    dispatch(
        createActionOf(SET_PASSWORD_REQUESTED, 0, {
          token: token,
          // password: data.new_password,
          password: encPass,
    }));
     // console.log("sdffd  :",new Date().getTime())
  //}
    
  };

  const response = useSelector(state => ({
    isLoading: state.setPasswordReducer.isLoading,
    statusCode: state.setPasswordReducer.statusCode,
    statusMessage: state.setPasswordReducer.message,
    error: state.setPasswordReducer.error,
    errorMessage: state.setPasswordReducer.errorMessage,
    status:state.setPasswordReducer.status
  }));
  useEffect(() => {
    if (response.statusCode === STATUS_CODE_SUCCESS) {
      console.log("successfully set password");
      // props.history.push({
      //   pathname: "/developer/setup-user",
      //   state: { status: "ok", isAuthorized: true },
      // });
    } else {
      return console.error("error while setting password", response.message);
    }
  }, [response.statusCode]);

  useEffect(()=>{
    if((response?.statusCode===STATUS_CODE_SUCCESS||response.error)&&count.current)
    {
      count.current.disabled=false
    }
  },[response])

  return (
      <SetPasswordPage
        error={response.error}
        errorMessage={response.errorMessage}
        onSetPassword={onSetPassword}
        serverResponse={response}
        domHistory={props.history}
        statusCode={response.statusCode}
        isLoading={response.status===LOADING}
        count={count}
      />
  );
};
export default withRouter(SetPassword);
