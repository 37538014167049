import { ERROR, LOADING, STATUS_CODE_CUSTOM } from "../../constants/constant";
import {
  VERIFY_EMAIL_REQUESTED,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILED,
  VERIFY_EMAIL_RESET,
} from "../actions/ActionType";

const initialState = {
  status: "",
  isLoading: false,
};

export default function verifyEmailReducer(state = initialState, action) {
  switch (action.type) {
    case VERIFY_EMAIL_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case VERIFY_EMAIL_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.statusMessage = action.data.status_message;
      userRes.message = action.data.message;
      userRes.isLoading = false;
      userRes.statusCode = action.data.status_code;
      return userRes;

    case VERIFY_EMAIL_FAILED: {
      let error = {};
      // error.status = ERROR;
      error.statusCode = action?.data?.status_code;
      error.isLoading = false;
      error.error = true;
      console.log("action?.data?.message ", action?.data?.message);
      error.errorMessage = (action?.data?.message ?? "Something went wrong");
      return error;
    }
    case VERIFY_EMAIL_RESET:
      return initialState;

    default:
      return state;
  }
}
