import { put, takeLatest, call } from "redux-saga/effects";
import {createActionOf} from "../../../actions/ActionCreator";
import {
   
    APPROVE_OR_REJECT_ADMIN_BRANDS_SUCCESS,
    APPROVE_OR_REJECT_ADMIN_BRANDS_FAILED,
    APPROVE_OR_REJECT_ADMIN_BRANDS_RESET,
    APPROVE_OR_REJECT_ADMIN_BRANDS_REQUESTED
} from "../../../actions/ActionType";
import {makeSecuredPostRequest} from "../../../../appApi/AppApi";
import { API_ENDPOINT } from "../../../URLs/ApiEndpoints";

const TAG = "mybot.js";

function* workerAdminApproveOrRejectBrands(payLoad) {
  console.log(
    TAG,
    "worker admin ApproveOrRejectBots : " + JSON.stringify(payLoad)
  );
  console.log(
    TAG,
    "worker admin workerApproveOrRejectBots : " + JSON.stringify(payLoad.data)
  );
  try {
    const response = yield call(
        makeSecuredPostRequest,
       API_ENDPOINT.ADMIN_APPROVE_OR_REJECT_CARRIER_BRANDS,
       payLoad.data
    );
    console.log(
      TAG,
      "worker admin workerApproveOrRejectBrands Response: " + JSON.stringify(response)
    );
    yield put(createActionOf(APPROVE_OR_REJECT_ADMIN_BRANDS_SUCCESS,1,response));
} catch (error) {
  console.error(TAG, "Worker get country failed: " + error.message);
  yield put(createActionOf(APPROVE_OR_REJECT_ADMIN_BRANDS_FAILED,2,error));
}
}

export const watchAdminApproveOrRejectBrands = takeLatest(
    APPROVE_OR_REJECT_ADMIN_BRANDS_REQUESTED,
    workerAdminApproveOrRejectBrands
);
