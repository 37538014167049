import { act } from "react-dom/test-utils";
import { LOADING, STATUS_CODE_SUCCESS } from "../../../constants/constant";
import { APPROVE_OR_REJECT_CARRIER_BOTS, APPROVE_OR_REJECT_CARRIER_BOTS_SUCCESS, APPROVE_OR_REJECT_CARRIER_BOTS_FAILED, APPROVE_OR_REJECT_CARRIER_BOTS_RESET } from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function approveORRejectBotReducer(state = initialState, action) {
  switch (action.type) {
    case APPROVE_OR_REJECT_CARRIER_BOTS: {
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;
    }

    case APPROVE_OR_REJECT_CARRIER_BOTS_SUCCESS: {
      let userRes = Object.assign({}, state);

      userRes.status = action.data.statusMessage;
      userRes.statusCode = action.data.status_code;
      userRes.statusMessage = action.data.status_message;
      userRes.isLoading = false;
      if (action.data.status_code !== STATUS_CODE_SUCCESS) {
        userRes.error = true;
        userRes.errorMessage = action.data?.message || action.data?.data;
      }

      userRes.statusSting = action.data.data;

      return userRes;
    }
    case APPROVE_OR_REJECT_CARRIER_BOTS_FAILED: {
      let error = Object.assign({}, state);
      error.error = true;
      error.errorMessage = action.error?.message || "Something went wrong! Please try again later.";
      return error;
    }

    case APPROVE_OR_REJECT_CARRIER_BOTS_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
