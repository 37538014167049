import Navbar from "../../../../components/Shared/Navbar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Header from "../../../../components/Shared/Header";
import AdminNavbar from "../../../../components/Shared/AdminNavbar";
import { useEffect } from "react";
import { partnerDetails } from "../../../../store/actions/ActionCreator";

const NavbarContainer = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!response.partnerDetails) {
      dispatch(partnerDetails());
    }
  }, []);
  const response = useSelector((state) => {
    return {
      partnerDetails: state.partnerDetailsReducer.partnerDetails,
      isVIAdmin: state.partnerDetailsReducer.isVIAdmin,
      isVIDotgoAdmin: state.partnerDetailsReducer.isVIDotgoAdmin,
      profileCompleted: state.partnerDetailsReducer.profileCompleted,
      partnerProfileImage: state.partnerDetailsReducer.partnerProfileImage,
      isMarketingAdmin: state.partnerDetailsReducer?.isMarketingAdmin,
      isAccountManager: state.partnerDetailsReducer?.isAccountManager,
    };
  }, shallowEqual);

  return response.partnerDetails ? (
    <>
      {response.partnerDetails.isCarrier ||
      response.isVIAdmin ||
      response.isVIDotgoAdmin === true ||
      response.isAccountManager ? (
        <AdminNavbar
          partnerDetails={response.partnerDetails}
          profileCompleted={response.profileCompleted}
          partnerProfileImage={response.partnerProfileImage}
          isVIDotgoAdmin={response.isVIDotgoAdmin}
          isVIAdmin={response.isVIAdmin}
          isAccountManager={response.isAccountManager}
        />
      ) : response.isMarketingAdmin ? (
        <Header />
      ) : (
        <Navbar
          partnerDetails={response.partnerDetails}
          isDataPresent={
            response.partnerDetails === "basic details not present"
          }
          profileCompleted={response.profileCompleted}
          partnerProfileImage={response.partnerProfileImage}
          isMarketingAdmin={response.isMarketingAdmin}
        />
      )}
    </>
  ) : (
    <Header />
  );
};
export default NavbarContainer;
