import { ERROR, LOADING } from "../../constants/constant";
import {
  GENERATE_OTP,
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAILED,
  GENERATE_OTP_RESET
} from "../actions/ActionType";

const initialState = {
  status: "",
  isLoading: true,
};

export default function generateOtpReducer(state = initialState, action) {
  switch (action.type) {
    case GENERATE_OTP:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GENERATE_OTP_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log("Otp generated", action);
      try {
        userRes.status = action.data.status
        userRes.message = action.data.message;
        userRes.isLoading = false;
        return userRes;
      } catch (error) {
        throw new Error(error);
      }

    case GENERATE_OTP_FAILED:
      {
        let userRes = Object.assign({}, state, action.data);
        // console.log("Error while geenerating otp", action.data.data);
        userRes.status=ERROR
        if(action.data.data)
        {
            userRes.status = action.data.data.status;
          userRes.statusCode = action.data.data.error_code;
          userRes.message = action.data.data.data;
          userRes.errorMessage = action.data.data.message;
          userRes.error_reason = action.data.data.error_reason;
        }
        userRes.HTTPStatus=action.data.status;
        userRes.isLoading = false;
        userRes.error = true;
        return userRes;
      }

    case GENERATE_OTP_RESET:
      return initialState;

    default:
      return state;
  }
}