import { LOADING } from "../../constants/constant";
import {
  CATEGORY_LIST_FAILED,
  CATEGORY_LIST_REQUESTED,
  CATEGORY_LIST_SUCCESS,
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function categoryListReducer(state = initialState, action) {
  switch (action.type) {
    case CATEGORY_LIST_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case CATEGORY_LIST_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log(
        "categoryListReducer => Case => Success: while getting category list: " +
          JSON.stringify(action.data)
      );
      userRes.status = action.data.status_message;
      userRes.statusCode = action.data.status_code;
      userRes.isLoading = false;
      //   userRes.statusCode = action.data.status;
      userRes.categoryList = action.data.data;

      return userRes;

    case CATEGORY_LIST_FAILED:
      console.error(
        "categoryListReducer => Case => Error: while getting category list: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);

    default:
      return state;
  }
}
