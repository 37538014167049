import {
  STORE_SELECTED_CARRIER,
  REMOVE_SELECTED_CARRIER,
  STORE_ALL_SELECTED_CARRIER,
  REMOVE_ALL_SELECTED_CARRIER,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  selectedCarriers: [],
};

export default function storeSelectedCarrierReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case STORE_SELECTED_CARRIER:
      let reqstState = Object.assign({}, state);
      reqstState.selectedCarriers = reqstState.selectedCarriers.concat(
        action.carrierId
      );

      return reqstState;

    case REMOVE_SELECTED_CARRIER: {
      let reqstState = Object.assign({}, state);
      reqstState.selectedCarriers = reqstState.selectedCarriers.filter(
        //eslint-disable-next-line
        (each) => each != action.carrierId
      );

      return reqstState;
    }

    case STORE_ALL_SELECTED_CARRIER:
      return { ...state, selectedCarriers: action.fullCarrierList };

    case REMOVE_ALL_SELECTED_CARRIER:
      return { ...state, selectedCarriers: [] };

    default:
      return state;
  }
}
