import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import * as Api from "../../appApi/AppApi";
import { GET_PRICE } from "../URLs/ApiEndpoints";

function* workerGetPricing(payLoad) {
  console.log(
    "Worker: workerGetPrining => Payload:: " + JSON.stringify(payLoad)
  );
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      GET_PRICE + payLoad.data
    );
    console.log(
      "Worker: workerGetPricing => Response:: " + JSON.stringify(response)
    );
    yield put(memberAction.pricingSuccess(response));
  } catch (error) {
    console.error("Worker: workerGetPricing => Error:: " + error.message);
    yield put(memberAction.pricingFailed(error.message));
  }
}

export const watchGetPricing = takeLatest(
  memberType.PRICING_REQUESTED,
  workerGetPricing
);
