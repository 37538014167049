import React, { useEffect } from "react";
import $ from "jquery";

const AsPieProgress2 = ({ progress = 70, ...props }) => {
  useEffect(() => {
    $(document).ready(function () {
      window.$(".pie_progress").asPieProgress({
        namespace: "pie_progress",
      });
      // Example with grater loading time - loads longer
      window.$(".pie_progress--slow").asPieProgress({
        namespace: "pie_progress",
        goal: 1000,
        min: 0,
        max: 1000,
        speed: 200,
        easing: "linear",
      });
      // Example with grater loading time - loads longer
      window.$(".pie_progress--countdown").asPieProgress({
        namespace: "pie_progress",
        easing: "linear",
        first: 120,
        max: 120,
        goal: 0,
        speed: 1200, // 120 s * 1000 ms per s / 100
        numberCallback: function (n) {
          var minutes = Math.floor(this.now / 60);
          var seconds = this.now % 60;
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          return minutes + ": " + seconds;
        },
      });
    });
    $(document).ready(function () {
      window.$(".pie_progress").asPieProgress("go", progress);
    });
  }, [progress]);
  return (
    <React.Fragment>
      <div className="percent" style={{ width: "40px", height: "40px" }}>
        <div
          className="pie_progress"
          role="progressbar"
          data-goal={-50}
          data-barcolor="#5F004B"
          data-trackcolor="#d4d4d4"
          data-barsize={30}
          aria-valuemin={-100}
          aria-valuemax={100}
        >
          {/* <div className="pie_progress__content">{progress} %</div> */}
          {/* <div className="pie_progress__content">{progress} %</div> */}
        </div>
      </div>
    </React.Fragment>
  );
};
export default AsPieProgress2;
