import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    margin: 0,
    width: "auto",
    height: "auto",
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function OtpModal({ message, ...rest }) {
  console.log("[otp modal]", message);
  useEffect(() => {}, [message]);
  const [open, setOpen] = React.useState(message.status);
  console.log("message: :: ", message)
  const handleClose = event => {
    if (message.message == "Sorry! OTP has been expired") {
      rest.setOtp(null);
      rest.generateOtp(rest.mobileNumber);
    }
    rest.closeOTPModal(event);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={message.status}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          &nbsp; &nbsp;
        </DialogTitle>
        <DialogContent>
          {/* Modal content*/}
          <div style={{ textAlign: "center", padding: "0px 30px" }}>
            <h4>{message.message}</h4>
            {/*rest.isMobileFraud ? message.message :<h4>{message.message}</h4>*/}

            {/* <p>
              If you do not get a chat message, please make sure you are
              connected to data, and your messages app is active for chats, and
              try again.
            </p> */}
            {/* <br /> */}
            {/* <p>
              <a className="text-underline text-blue">
                <b>Resend Email</b>
              </a>
            </p> */}
            <br />
            {message.message == "Sorry! OTP has been expired" ? (
              <button
                onClick={handleClose}
                data-dismiss="modal"
                className="btn btn-newsletter"
              >
                Resend OTP
              </button>
            ) : (
              <button
                onClick={handleClose}
                data-dismiss="modal"
                className="btn btn-newsletter"
              >
                Got it!
              </button>
            )}

            <br />
            <div
              className="modal-footer"
              style={{ border: "0px", height: "20px" }}
            >
              {/*<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>*/}
            </div>
          </div>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
