import { LOADING, STATUS_CODE_SUCCESS } from "../../constants/constant";
import {
  SUBMIT_WHATSAPP_BOT,
  SUBMIT_WHATSAPP_BOT_SUCCESS,
  SUBMIT_WHATSAPP_BOT_FAILED,
  SUBMIT_WHATSAPP_BOT_RESET,
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: false,
};

export default function submitWhatsAppBotReducer(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_WHATSAPP_BOT:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case SUBMIT_WHATSAPP_BOT_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.status = action.data.status_message;
      userRes.isLoading = false;
      userRes.statusCode = action.data.status_code;
      if (userRes.statusCode !== STATUS_CODE_SUCCESS) {
        userRes.error = true;
        userRes.errorMessage = action.data.message;
        return userRes;
      }
      userRes.botID = action.data.data.bot_id;
      userRes.botChannelID = action.data.data.bot_channel_id;
      userRes.botChannelType = action.data.data.bot_channel_type_id;

      userRes.brandID = action.data.data.brand_id;

      return userRes;

    case SUBMIT_WHATSAPP_BOT_FAILED:
      return Object.assign({}, state, action.error);

    case SUBMIT_WHATSAPP_BOT_RESET:
      return initialState;

    default:
      return state;
  }
}
