import {put, call, takeLatest} from "redux-saga/effects";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";
import {UPDATE_CARRIER_PROFILE_SUCCESS_ADMIN_PORTAL,
    UPDATE_CARRIER_PROFILE_FAILED_ADMIN_PORTAL,
    UPDATE_CARRIER_PROFILE_RESET_ADMIN_PORTAL,
    UPDATE_CARRIER_PROFILE_ADMIN_PORTAL
} from "../../actions/ActionType";


const TAG = "AccountDetailsAdminPortal.js";

function* workerUpdateCarrierProfileAdminPortal (payload){
    console.log(TAG, "account details : " + JSON.stringify(payload.data));
    try{
        const response = yield call(

            Api.makeSecuredPostRequest,
            API_ENDPOINT.UPDATE_CARRIER_PROFILE_ADMIN_PORTAL,
            payload.data
            );
            yield put(createActionOf(UPDATE_CARRIER_PROFILE_SUCCESS_ADMIN_PORTAL, 1, response));      

    }   catch (error){
        console.error(TAG,"update carrier profile failed",+ error.message);
        yield put(createActionOf(UPDATE_CARRIER_PROFILE_FAILED_ADMIN_PORTAL,2,error));
    }

}

export const watchUpdateCarrierProfileAdminPortal = takeLatest(
    UPDATE_CARRIER_PROFILE_ADMIN_PORTAL,
    workerUpdateCarrierProfileAdminPortal
);