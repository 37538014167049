import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import * as Api from "../../appApi/AppApi";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";

function* workerSubmitWhatsAppBot(payLoad) {
  console.log(
    "Worker: workerSubmitWhatsAppBot => Payload:: " + JSON.stringify(payLoad)
  );
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.SUBMIT_BOT,
      payLoad.data
    );
    console.log(
      "Worker: workerSubmitWhatsAppBot => Response:: " +
        JSON.stringify(response)
    );
    yield put(memberAction.submitWhatsAppBotSuccess(response));
  } catch (error) {
    console.error("Worker: workerSubmitWhatsAppBot => Error::" + error.message);
    yield put(memberAction.submitWhatsAppBotFailed(error.message));
  }
}

export const watchSubmitWhatsAppBot = takeLatest(
  memberType.SUBMIT_WHATSAPP_BOT,
  workerSubmitWhatsAppBot
);
