import { LOADING, STATUS_CODE_SUCCESS } from "../../constants/constant";
import {
  SUBMIT_BRAND_SUCCESS,
  SUBMIT_BRAND_REQUESTED,
  SUBMIT_BRAND_FAILED,
  SUBMIT_BRAND_RESET,
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: false,
};
export default function submitBrandReducer(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_BRAND_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case SUBMIT_BRAND_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log(
        "came to the submitBrandReducer success   " + JSON.stringify(action)
      );
      userRes.status = action.data.status_message;
      userRes.statusCode = action.data.status_code;
      userRes.isLoading = false;
      if (userRes.statusCode !== STATUS_CODE_SUCCESS) {
        userRes.error = true;
        userRes.errorMessage = action.data.message;
        return userRes;
      }
      userRes.data = action.data.data;
      userRes.error = false;
      return userRes;

    case SUBMIT_BRAND_FAILED:
      return Object.assign({}, state, action.error);

    case SUBMIT_BRAND_RESET:
      return initialState;

    default:
      return state;
  }
}
