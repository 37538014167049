import { LOADING, STATUS_CODE_SUCCESS } from "../../../constants/constant"
import {
  BOT_AGENT_SUBMIT_CARRIERS,
  BOT_AGENT_SUBMIT_CARRIERS_SUCCESS,
  BOT_AGENT_SUBMIT_CARRIERS_FAILED,
  BOT_AGENT_SUBMIT_CARRIERS_RESET,
} from "../../actions/ActionType"

const initialState = {
  status: "INIT",
  isLoading: false,
}

export default function submitBotAgentCarriers(state = initialState, action) {
  switch (action.type) {
    case BOT_AGENT_SUBMIT_CARRIERS:
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      return reqstState

    case BOT_AGENT_SUBMIT_CARRIERS_SUCCESS: {
      const { data: { resData = {} } = {} } = action
      console.log("resData: :: :", action)
      let userRes = Object.assign({}, state)
      userRes.status = resData.status_message
      userRes.statusCode = resData.status_code
      userRes.isLoading = false

      if (userRes.statusCode === STATUS_CODE_SUCCESS) {
        userRes.botAgentID = resData.data.bot_agent_id
        userRes.brandID = resData.data.brand_id
        userRes.error = false
        return userRes
      } else {
        userRes.error = true
        userRes.errorMessage = resData.data.message
        return userRes
      }
    }
    case BOT_AGENT_SUBMIT_CARRIERS_FAILED:
      return Object.assign({}, state, action.error, { error: true })

    case BOT_AGENT_SUBMIT_CARRIERS_RESET:
      return initialState

    default:
      return state
  }
}
