import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_TEMPLATES_FOR_ADMIN_SUCCESS,
  GET_TEMPLATES_FOR_ADMIN,
  GET_TEMPLATES_FOR_ADMIN_FAILED,
} from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";

const TAG = "get_submitted_bots_for_admin.js";

function* workerGetAggregators(payLoad) {
  console.log("🚀 ~ file: get_admin_templates_saga.js ~ line 14 ~ function*workerGetAggregators ~ payLoad", payLoad)
  console.log("payload sub-------------------->", payLoad);
  try {
    const response = yield call(
      makeSecuredPostRequest,
      payLoad.data.isVIAdmin
        ? API_ENDPOINT.GET_TEMPLATES_FOR_CARRIER_ADMIN
        : API_ENDPOINT.GET_TEMPLATES_FOR_ADMIN
      // payLoad.data
    );
    yield put(createActionOf(GET_TEMPLATES_FOR_ADMIN_SUCCESS, 1, response));
  } catch (error) {
    console.error(TAG, "Worker get bots failed: " + error.message);
    yield put(createActionOf(GET_TEMPLATES_FOR_ADMIN_FAILED, 2, error));
  }
}

export const watchGetAdminTemplates = takeLatest(
  GET_TEMPLATES_FOR_ADMIN,
  workerGetAggregators
);
