export const availableCampaignStatuses = {
  p: { status: "Pending", class: "font-color-golden" },
  c: { status: "Completed", class: "font-color-green" },
  r: { status: "Running", class: "font-color-light-blue" },
  s: { status: "Scheduled", class: "font-color-voilet" },
  i: { status: "Failed", class: "font-color-red" },
  d: { status: "Cancelled", class: "text-gray" },
}
export const availableCampaignStatusesVi = {
  p: { status: "Pending", class: "font-color-golden-vi" },
  c: { status: "Completed", class: "font-color-green-vi" },
  r: { status: "Running", class: "font-color-blue-vi" },
  s: { status: "Scheduled", class: "font-color-voilet-vi" },
  i: { status: "Failed", class: "font-color-red-vi" },
  d: { status: "Cancelled", class: "text-gray-vi" },
}
