import { LOADING } from "../../../constants/constant";
import {
    GET_SUBMITTED_AGGREGATORS_FOR_CARRIER,
    GET_SUBMITTED_AGGREGATORS_FOR_CARRIER_SUCCESS,
    GET_SUBMITTED_AGGREGATORS_FOR_CARRIER_FAILED,
    GET_SUBMITTED_AGGREGATORS_FOR_CARRIER_RESET,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function getSubmittedAggregatorsForCarrierReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUBMITTED_AGGREGATORS_FOR_CARRIER: {
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;
    }

    case GET_SUBMITTED_AGGREGATORS_FOR_CARRIER_SUCCESS: {
      let userRes = Object.assign({}, state);
      console.log(
        "getSubmittedAggregatorsForCarrierReducer => Case => Success: while getting submitted aggregators list: " +
          JSON.stringify(action.data)
      );
      userRes.status = action.data.status_message;
      userRes.statusCode = action.data.status_code;
      userRes.isLoading = false;
      userRes.aggregators = action.data.data;

      return userRes;
    }
    case GET_SUBMITTED_AGGREGATORS_FOR_CARRIER_FAILED: {
      console.error(
        "getSubmittedAggregatorsForCarrierReducer => Case => Error: while getting submitted aggregators list: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);
    }

    case GET_SUBMITTED_AGGREGATORS_FOR_CARRIER_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}